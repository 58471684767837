import { Close, SendOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ChatMessagesPreview from "./ChatMessagesPreview";
import { UseFetchGet } from "../../../hooks/fetchFunctions";
import axios from "axios";
import { IsJsonString } from "../../../libs/helpers";
import { enqueueSnackbar } from "notistack";
import { phoneGlobalRegex } from "../../../libs/regexes";

function ForwardMessagesToWebhookPreview({
  open,
  onClose,
  onSubmit,
  userConfig,
  chatCustomizationSettings,
  messages,
  scrollToMessage,
  templatesList,
  funnerUsers,
  chatUsersGlobalRef,
  handleMediaPreview,
  selectedUserId,
  companyId,
  companyPlatforms,
}) {
  const forwardMessagesButtonsWebhook = useMemo(() => {
    let webhook = null,
      status = false;
    if (IsJsonString(companyPlatforms)) {
      const platformsObject = JSON.parse(companyPlatforms);
      const webHooksList = platformsObject.webHooks;
      if (webHooksList) {
        const findedForwardMessagesButtonsWebhook = webHooksList.find(
          (webhook) =>
            webhook.WebhookIdentifier === "forwardMessagesButtonsWebhook",
        );
        if (findedForwardMessagesButtonsWebhook) {
          webhook = findedForwardMessagesButtonsWebhook.WebhookUrl;
          const webhookActiveStates = platformsObject.webHooksActiveStates;
          status = webhookActiveStates?.forwardMessagesButtonsWebhook ?? false;
        }
      }
    }
    return { url: webhook, status };
  }, [companyPlatforms]);

  const buttonsEnabled =
    companyId === "987a2eaa-4d89-43e5-8e58-c7006513b9f4" &&
    // companyId === "cff2e550-af71-4ba5-9314-65824e04e484" &&
    forwardMessagesButtonsWebhook?.status;

  const [buttons, setButtons] = useState([]);
  const [buttonsLoading, setButtonsLoading] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [description, setDescription] = useState("");

  const closePopup = () => {
    onClose();
    setTimeout(() => {
      setButtons([]);
      setDescription("");
      setSelectedButton(null);
    }, 300);
  };

  async function getButtons() {
    try {
      setButtonsLoading(true);
      // const res = await UseFetchGet(
      //   "/api/services/getButtons",
      //   {
      //     phoneNumber: selectedUserId,
      //   },
      //   null,
      //   false,
      //   "history",
      // );
      if (!forwardMessagesButtonsWebhook.url)
        return enqueueSnackbar("No url set to webhook", {
          variant: "error",
        });

      if (
        !forwardMessagesButtonsWebhook.url
          .toLowerCase()
          .includes("{phoneNumber}".toLowerCase())
      )
        return enqueueSnackbar(
          "Webhook does not contain {phoneNumber} placeholder, which is required for correct work",
          {
            variant: "error",
          },
        );

      const userFormattedPhoneNumber = phoneGlobalRegex.test(selectedUserId)
        ? `0${selectedUserId.slice(3, 5)}-${selectedUserId.slice(5)}`
        : selectedUserId;
      // const userFormattedPhoneNumber = phoneGlobalRegex.test("972522676961")
      //   ? `0${"972522676961".slice(3, 5)}-${"972522676961".slice(5)}`
      //   : "972522676961";
      const resultLink = forwardMessagesButtonsWebhook.url
        .toLowerCase()
        .replace("{phoneNumber}".toLowerCase(), userFormattedPhoneNumber);
      const res = await axios.get(resultLink);

      if (res?.data?.data?.length) {
        setButtons(res.data.data);
      } else {
        setButtons([]);
      }
    } catch (error) {
      console.error(error);
      setButtons([]);
    } finally {
      setButtonsLoading(false);
    }
  }

  useEffect(() => {
    if (open && buttonsEnabled) {
      getButtons();
    }
  }, [open, buttonsEnabled]);

  return (
    <Dialog
      open={open}
      onClose={closePopup}
      PaperProps={{
        sx: {
          minWidth: 500,
          maxWidth: 991.98,
        },
      }}
    >
      <DialogTitle>
        <Grid container spacing={1.5} alignItems="center">
          <Grid item flexGrow={1}>
            <Typography fontWeight={600} fontSize={20}>
              Forward messages
            </Typography>
          </Grid>
          {/* <Grid item xs="auto">
            <IconButton
              onClick={() => {
                onClose();
                onSubmit(messages);
              }}
            >
              <SendOutlined />
            </IconButton>
          </Grid>
          <Grid item xs="auto">
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          </Grid> */}
        </Grid>
      </DialogTitle>
      <DialogContent>
        {buttonsLoading ? (
          <Box
            sx={{
              width: "100%",
              minHeight: 120,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={50} color="secondary" />
          </Box>
        ) : (
          <Grid container spacing={2.5}>
            {buttonsEnabled && buttons.length > 0 && (
              <Grid item xs={12}>
                <ToggleButtonGroup
                  color="primary"
                  value={selectedButton}
                  exclusive
                  onChange={(e, newValue) => setSelectedButton(newValue)}
                  aria-label="Buttons"
                  size="small"
                  sx={{
                    flexWrap: "wrap",
                  }}
                >
                  {buttons.map((btn) => (
                    <ToggleButton value={btn.description} key={btn.description}>
                      {btn.title}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={"Description"}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{ mt: 1, maxWidth: 500 }}
                multiline
                minRows={1}
                maxRows={5}
              />
            </Grid>
          </Grid>
        )}

        {/* <ChatMessagesPreview
          userConfig={userConfig}
          chatCustomizationSettings={chatCustomizationSettings}
          showMessagesList={messages}
          scrollToMessage={scrollToMessage}
          templatesList={templatesList}
          funnerUsers={funnerUsers}
          chatUsersGlobalRef={chatUsersGlobalRef}
          handleMediaPreview={handleMediaPreview}
        /> */}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            closePopup();
            onSubmit(messages, {
              button: selectedButton,
              description,
              phoneNumber: selectedUserId,
            });
          }}
          disabled={!selectedButton && buttonsEnabled}
        >
          Send
        </Button>
        <Button variant="outlined" onClick={() => closePopup()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ForwardMessagesToWebhookPreview;
