import React, { useEffect, useState } from "react";
import { IsJsonString } from "../../../../libs/helpers";

function LocationMessage({ Object }) {
  const [coordinates, setCoordinates] = useState(null);

  useEffect(() => {
    if (Object) {
      const objectParsed = IsJsonString(Object) ? JSON.parse(Object) : Object;
      const location = {
        latitude: objectParsed?.latitude,
        longitude: objectParsed?.longitude,
        picture: objectParsed?.picture,
        address: objectParsed?.address,
      };
      setCoordinates(location);
    }
  }, []);

  return (
    <div className="message__upload-container message__upload-container_location">
      <div className="message__location location-message">
        <div className="location-message__map-container">
          <img src={coordinates?.picture} alt="" />
        </div>
        <a
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${coordinates?.latitude},${coordinates?.longitude}`}
          className="location-message__link"
          rel="noreferrer"
        >
          {coordinates?.address}
        </a>
      </div>
    </div>
  );
}

export default LocationMessage;
