import React, { useState } from "react";
import localizations from "../../../localizations.json";

export default function GetToken({
  getPowerLinkToken,
  handleCloseGetTokenPopup,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].auth.getToken,
  );

  return (
    <>
      {/* <Header handleCloseGetTokenPopup={handleCloseGetTokenPopup}/> */}
      <div className="get-token-popup__window get-token-popup__window_1">
        <p className="get-token-popup__main-text">{localization.text}</p>
        <button
          onClick={getPowerLinkToken}
          className="get-token-popup__main-btn popup__blue-btn"
        >
          {localization.btn}
        </button>
      </div>
    </>
  );
}
