import React from "react";
import { closeIcon } from "../../../../libs/images";

function TemplateReplyTemplate({ sentOrReceived, userName, text }) {
  return (
    <div className="message__upload-container message__upload-container_reply">
      <a
        href="#"
        className={`message-attached-reply message__reply-audio reply ${
          sentOrReceived === " incoming" ? "incoming" : "outcoming"
        }`}
      >
        <div className="reply__text-part">
          <p className="reply__top-text">
            {sentOrReceived == "incoming" ? userName : "אתה"}
          </p>
          <div className="reply__info">
            <p className="reply__text">{text}</p>
          </div>
        </div>
      </a>
    </div>
  );
}

export default TemplateReplyTemplate;
