import React, { useEffect, useRef, useState } from "react";
import * as animFunctions from "../../../libs/animFunctions";
import {
  carSmiles,
  coffeeSmiles,
  flagSmiles,
  happySmiles,
  lampSmiles,
  petSmiles,
  recentSmiles,
  sportsSmiles,
} from "../../../libs/images";
import { emojiesArray, emojiesCategories } from "../../../libs/emojies";
import { applicationType } from "../../../applicationType";
import { Box, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { position } from "stylis";

function SmilesPanelBottom({
  textareaRef,
  isOpen,
  textareaValue,
  setTextareaValue,
  toggleSmilesPanelBottom,
}) {
  const [activeTab, setActiveTab] = useState("recent");
  const [searchInputValue, setSearchInputValue] = useState("");

  const smilePanelRef = useRef(null);
  const emojiBodyRef = useRef(null);

  const handleSearchValue = (value) => {
    setSearchInputValue(value);
  };
  const handleInputEmojy = (emoji) => {
    const textarea = textareaRef.current;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const textBefore = textareaValue.substring(0, start);
    const textAfter = textareaValue.substring(end);

    const newValue = textBefore + emoji + textAfter;
    setTextareaValue(newValue);
    textarea.selectionStart = textarea.selectionEnd = start + emoji.length;
    textarea.focus();
  };

  function scrollToEmojiBlock(event) {
    const btn = event.target.closest(".smile-panel__tabs-title");
    const id = btn.getAttribute("data-id");
    let parent = btn.parentElement.parentElement;

    const element = parent.querySelector(`#${id}`);
    if (element) {
      const emojiBody = emojiBodyRef.current;
      if (emojiBody) {
        emojiBody.scrollTop = element.offsetTop - 140;
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      animFunctions._slideDown(smilePanelRef.current, 500);
    } else {
      animFunctions._slideUp(smilePanelRef.current, 500);
    }
  }, [isOpen]);

  return (
    <div
      className={`smile-panel bottom ${isOpen ? "active" : ""}`}
      hidden
      ref={smilePanelRef}
    >
      <div className="smile-panel__tabs" style={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <IconButton size="small" onClick={toggleSmilesPanelBottom}>
            <Close />
          </IconButton>
        </Box>
        <nav className="smile-panel__tabs-navigation">
          <button
            data-id="smileysEmotion"
            type="button"
            className={`smile-panel__tabs-title ${
              activeTab === "recent" ? "_tab-active" : ""
            }`}
            onClick={(e) => {
              setActiveTab("recent");
              scrollToEmojiBlock(e);
            }}
          >
            <img src={recentSmiles} alt="" />
          </button>
          <button
            data-id="peopelBody"
            type="button"
            className={`smile-panel__tabs-title ${
              activeTab === "happy" ? "_tab-active" : ""
            }`}
            onClick={(e) => {
              setActiveTab("happy");
              scrollToEmojiBlock(e);
            }}
          >
            <img src={happySmiles} alt="" />
          </button>
          <button
            data-id="animalsNature"
            type="button"
            className={`smile-panel__tabs-title ${
              activeTab === "pet" ? "_tab-active" : ""
            }`}
            onClick={(e) => {
              setActiveTab("pet");
              scrollToEmojiBlock(e);
            }}
          >
            <img src={petSmiles} alt="" />
          </button>
          <button
            data-id="travelPlaces"
            type="button"
            className={`smile-panel__tabs-title ${
              activeTab === "flag" ? "_tab-active" : ""
            }`}
            onClick={(e) => {
              setActiveTab("flag");
              scrollToEmojiBlock(e);
            }}
          >
            <img src={flagSmiles} alt="" />
          </button>
          <button
            data-id="activities"
            type="button"
            className={`smile-panel__tabs-title ${
              activeTab === "coffee" ? "_tab-active" : ""
            }`}
            onClick={(e) => {
              setActiveTab("coffee");
              scrollToEmojiBlock(e);
            }}
          >
            <img src={coffeeSmiles} alt="" />
          </button>
          <button
            data-id="emojiObjects"
            type="button"
            className={`smile-panel__tabs-title ${
              activeTab === "sports" ? "_tab-active" : ""
            }`}
            onClick={(e) => {
              setActiveTab("sports");
              scrollToEmojiBlock(e);
            }}
          >
            <img src={sportsSmiles} alt="" />
          </button>
          <button
            type="button"
            data-id="emojiSymbols"
            className={`smile-panel__tabs-title ${
              activeTab === "car" ? "_tab-active" : ""
            }`}
            onClick={(e) => {
              setActiveTab("car");
              scrollToEmojiBlock(e);
            }}
          >
            <img src={carSmiles} alt="" />
          </button>
          <button
            data-id="emojiFlags"
            type="button"
            className={`smile-panel__tabs-title ${
              activeTab === "lamp" ? "_tab-active" : ""
            }`}
            onClick={(e) => {
              setActiveTab("lamp");
              scrollToEmojiBlock(e);
            }}
          >
            <img src={lampSmiles} alt="" />
          </button>
        </nav>
        <div className="smile-panel__search bottom">
          <input
            type="text"
            placeholder="חפש/י אימוג׳י..."
            name=""
            value={searchInputValue}
            onChange={(e) => handleSearchValue(e.target.value)}
          />
        </div>
        <div className="smile-panel__tabs-content bottom" ref={emojiBodyRef}>
          <div className="smile-panel__tabs-body">
            <div className="smile-panel__smiles-block">
              {Object.entries(emojiesCategories).map(([id, category]) => (
                <div
                  id={id}
                  key={id}
                  className={
                    id === "emojiFlags"
                      ? "smile-panel__smiles smile-panel__smiles_recent"
                      : "smile-panel__smiles smile-panel__smiles_current"
                  }
                >
                  <div className="smile-panel__smiles-list">
                    {emojiesArray
                      .filter((emoji) =>
                        emoji.aliases.find((item) =>
                          item.includes(searchInputValue),
                        ),
                      )
                      // .slice(0, 1000)
                      .map((emoji) =>
                        emoji.category === category ? (
                          <button
                            type="button"
                            title={emoji.description}
                            className="smile-panel__smile"
                            onClick={() => handleInputEmojy(emoji.emoji)}
                            key={`${emoji.emoji}-${emoji.category}`}
                          >
                            <span
                              style={{
                                fontSize: applicationType === "web" ? 22 : 18,
                              }}
                            >
                              {emoji.emoji}
                            </span>
                          </button>
                        ) : null,
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmilesPanelBottom;
