import { Close, SyncOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
} from "@mui/material";
import React from "react";
import SingleMessageReplyTemplate from "../SingleMessage/SingleMessageReplyTemplate";
import InfoMessageTemplate from "../SingleMessage/InfoMessageTemplate";
import SingleMessageTemplate from "../SingleMessage/SingleMessageTemplate";

function ChatMessagesPreview({
  userConfig,
  chatCustomizationSettings,
  showMessagesList,
  scrollToMessage,
  templatesList,
  funnerUsers,
  chatUsersGlobalRef,
  handleMediaPreview,
}) {
  return (
    <Box className="root-whatsapp rtl chat w-0">
      <div
        className={`page whatsappPage ${
          "_" + (userConfig?.fontSize || "14")
        } width main-content ${
          userConfig.isMssgRTL === false || userConfig.isMssgRTL === "false"
            ? "swap-msgs"
            : ""
        }`}
        style={{
          //?
          position: "static",
          width: "100%",
        }}
      >
        <Box
          className={"chat-content-wrap"}
          sx={{
            boxShadow: "none !important",
          }}
        >
          <div
            className={`chat-content perfect-scrollbar context-menu-container`}
          >
            <Box
              id="funner-global__messages-container"
              className="messages-container"
              // ref={messagesFeedRef}
              // onScroll={(e) => {
              //   const scroller = e.target;
              //   scrollDirection =
              //     scroller.scrollTop > prevScrollTop ? "bottom" : "top";
              //   prevScrollTop = scroller.scrollTop;

              //   if (
              //     scroller.scrollHeight -
              //       scroller.offsetHeight -
              //       scroller.scrollTop >
              //       100 &&
              //     !isGoBotBtn
              //   ) {
              //     setIsGoBotBtn(true);
              //     isGoBotBtnRef.current = true;
              //   } else if (
              //     scroller.scrollHeight -
              //       scroller.offsetHeight -
              //       scroller.scrollTop <=
              //       100 &&
              //     isGoBotBtn
              //   ) {
              //     isGoBotBtnRef.current = false;
              //     setIsGoBotBtn(false);
              //   }
              // }}
              sx={{
                position: "static !important",
                maxHeight: "65vh !important",
                mx: "-16px !important",
                px: "16px !important",
                width: "calc(100% + 32px) !important",
                background:
                  (chatCustomizationSettings?.find(
                    (option) => option.type === "conversationBackground",
                  )?.value === "image"
                    ? `url(${
                        chatCustomizationSettings.find(
                          (option) => option.type === "conversationBackground",
                        ).customColor.backgroundImage
                      }) center / cover no-repeat`
                    : chatCustomizationSettings?.find(
                        (option) => option.type === "conversationBackground",
                      )?.value === "color"
                    ? chatCustomizationSettings.find(
                        (option) => option.type === "conversationBackground",
                      ).customColor.background
                    : "#fff") + "!important",
                "&::-webkit-scrollbar": {
                  bgcolor: "rgba(255, 255, 255, 0.25) !important",
                },
              }}
            >
              {showMessagesList?.map((message, index) => {
                if (message.FileType === "error")
                  return <Alert severity="error">{message.text}</Alert>;
                if (message.FileType === "reaction") return null;
                if (message.FileType === "buffer")
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        height: "2px",
                        visibility: "hidden",
                      }}
                      // id={
                      //   chatType === "fireberry"
                      //     ? `fireberry-buffer-${message.Id}`
                      //     : `funner-buffer-${message.Id}`
                      // }
                      key={`buffer-${message.Id}`}
                    ></Box>
                  );

                const prevMessage = showMessagesList[index - 1];
                const isSameSender =
                  prevMessage?.Type === message.Type &&
                  prevMessage?.GlobalUserId === message.GlobalUserId;
                const isFunnerAvatarVisible =
                  !isSameSender || prevMessage?.FileType === "info";

                return message.Reply ? (
                  <SingleMessageReplyTemplate
                    key={message.TempId || message.Id}
                    message={message}
                    userConfig={userConfig}
                    onMessagesContext={() => {}}
                    onEmojiReplyIconClicked={() => {}}
                    scrollToReplyMessage={scrollToMessage}
                    templatesList={templatesList}
                    funnerUsers={funnerUsers}
                    isFunnerAvatarVisible={isFunnerAvatarVisible}
                    chatUsersGlobalRef={chatUsersGlobalRef}
                    chatCustomizationSettings={chatCustomizationSettings}
                    forwardMessage={{}}
                    toggleMessageInForwardList={() => {}}
                    disableInteractions={true}
                  />
                ) : message.FileType === "info" ? (
                  <InfoMessageTemplate
                    key={message.TempId || message.Id}
                    message={message}
                    userConfig={userConfig}
                    chatCustomizationSettings={chatCustomizationSettings}
                  />
                ) : (
                  <SingleMessageTemplate
                    key={message.TempId || message.Id}
                    message={message}
                    userConfig={userConfig}
                    onMessagesContext={() => {}}
                    onEmojiReplyIconClicked={() => {}}
                    handleMediaPreview={handleMediaPreview}
                    templatesList={templatesList}
                    funnerUsers={funnerUsers}
                    isFunnerAvatarVisible={isFunnerAvatarVisible}
                    chatCustomizationSettings={chatCustomizationSettings}
                    forwardMessage={{}}
                    toggleMessageInForwardList={() => {}}
                    disableInteractions={true}
                  />
                );
              })}
            </Box>
          </div>
        </Box>
      </div>
    </Box>
  );
}

export default ChatMessagesPreview;
