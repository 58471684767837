import React, { useEffect, useRef, useState } from "react";
import { audioIcon } from "../../../../libs/images";
import { convertSecondstoTime } from "../../../../libs/helpers";

function AudioReplyTemplate({ sentOrReceived, userName, file }) {
  const [audioDuration, setAudioDuration] = useState("");

  const audioFileRef = useRef(null);

  useEffect(() => {
    const audio = audioFileRef.current;
    const handleMetadataLoaded = () => {
      setAudioDuration(convertSecondstoTime(audio.duration));
    };

    if (audio) {
      audio.addEventListener("loadedmetadata", handleMetadataLoaded);
    }

    return () => {
      if (audio) {
        audio.removeEventListener("loadedmetadata", handleMetadataLoaded);
      }
    };
  }, [file]);

  return (
    <a
      className={`message__reply-audio reply reply_audio  ${
        sentOrReceived == "incoming" ? "incoming" : "outcoming"
      }`}
    >
      <div className="reply__text-part">
        <p className="reply__top-text">
          {sentOrReceived === "incoming" ? userName : "אתה"}
        </p>
        <div className="reply__info">
          <div className="reply__icon">
            <img src={audioIcon} alt="" />
          </div>
          <audio
            src={file}
            className="audio-message__main-audio"
            ref={audioFileRef}
          ></audio>
          <p className="reply__text duration">{audioDuration}</p>
        </div>
      </div>
    </a>
  );
}

export default AudioReplyTemplate;
