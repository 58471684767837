import React, { useRef, useState } from "react";
import { applicationType } from "../../../applicationType";
import { UseFetchPostAnonymos } from "../../../hooks/fetchFunctions";
import applicationConfig from "../../../config";
import { eye, greenCheck, insertLink, mailIcon } from "../../../libs/images";
import { validateFullPhoneNumber } from "../../../libs/forms";
import { passwRegex } from "../../../libs/regexes";
import localizations from "../../../localizations.json";
import { Box, Grid, TextField } from "@mui/material";
import { ErrorOutlineOutlined } from "@mui/icons-material";

function ResetPassword({
  isOnlyResetPass,
  isPowerLinkPage,
  sendOTP,
  sendOTPByPhone,
  handleSetNotif,
  authPopupType,
  handleAuthPopupWindow,
  otpData,
  handleOtpParams,
  userData,
  resetPasswordData,
  handleResetPasswordParams,
  validationOptions,
  setValidationOptions,
  setPopupTitle,
  handleLogouStep,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].auth.resetPassword,
  );
  const [notificationsLocalization, setNotificationsLocalization] = useState(
    localizations["he"].auth.notifications.resetPassword,
  );

  const [isResendText, setIsResetText] = useState(false);
  const [windowStep, setWindowStep] = useState("sendOTPCode");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const [showPassUpdate, setShowPassUpdate] = useState(false);
  const [showPassUpdate2, setShowPassUpdate2] = useState(false);

  const [isOtpSendError, setIsOtpSendError] = useState(null);

  const phoneInputRef = useRef(null);

  async function handleIsForgetPassword(phone) {
    // uncomment after testing !
    if (phone) {
      setWindowStep("verifyOTP");
      sendOTPByPhone(phone)
        .then(async (res) => {
          console.log(res, "sendOTP");
          // const response = await data.json();
          if (res.data.data) {
            handleSetNotif(
              notificationsLocalization.otpSend.success,
              "success",
            );
            // handleAuthPopupWindow("resetPassword");

            setIsResetText(true);
            setIsOtpSendError(false);
            // setWindowStep("verifyOTP");
          } else {
            setIsOtpSendError(true);
          }
        })
        .catch((err) => {
          setIsOtpSendError(true);
          console.log(err);
        });
    }
  }
  const handlePhoneNumber = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
  };
  function toggleShowPassUpdate() {
    setShowPassUpdate(!showPassUpdate);
  }
  function toggleShowPassUpdateTwo() {
    setShowPassUpdate2(!showPassUpdate2);
  }

  async function handleVerifyOTPStep() {
    const verifyResult = await handleVerifyOTPResetPassword(phoneNumber);

    if (verifyResult) {
      setWindowStep("updatePassword");
    }
  }
  async function handleVerifyOTPResetPassword(phoneNumber = null) {
    if (phoneNumber) {
      const params = {
        PhoneNumber: phoneNumber,
        OTP: otpData.otp,
      };

      try {
        const res = await UseFetchPostAnonymos(
          "/api/auth/VerifyOTPByPhoneNumber",
          params,
          "config",
        );
        let verifyResult = null;
        if (res.data.success == false) {
          // alert("Wrong OTP code!");
          handleSetNotif(notificationsLocalization.verifyOTP.error, "error");
          handleOtpParams({ otp: "" });
          verifyResult = false;
        } else {
          handleSetNotif(
            notificationsLocalization.verifyOTP.success,
            "success",
          );
          //console.log('otp_else')
          if (authPopupType === "resetPassword") {
            setIsResetDisabled(false);
          }
          verifyResult = true;
        }

        return verifyResult;
      } catch (err) {
        console.log(err);
      }
    }
  }
  const handleUpdatePassword = async (phone = null) => {
    const validationErrors = [];
    if (!passwRegex.test(resetPasswordData.passwordUpdate)) {
      validationErrors.push(false);
    }
    if (
      resetPasswordData.passwordUpdate !== resetPasswordData.passwordUpdate2
    ) {
      validationErrors.push(false);
    }

    if (validationErrors.length !== 0) {
      handleSetNotif(
        notificationsLocalization.updatePassword.validationError,
        "error",
      );
      return;
    }

    const params = phone
      ? {
          Password: resetPasswordData.passwordUpdate,
          phoneNumber: phone,
          OTP: otpData.otp,
        }
      : {
          Password: resetPasswordData.passwordUpdate,
          Globaluserid: userData.Udid,
          OTP: otpData.otp,
        };

    if (phone) {
      try {
        const res = await UseFetchPostAnonymos(
          "/api/auth/resetPasswordByPhoneNumber",
          params,
          "config",
        );

        if (res?.data?.result) {
          handleSetNotif(
            notificationsLocalization.updatePassword.success,
            "success",
          );
          handleAuthPopupWindow("login");
          setPopupTitle("FUNNER");

          if (isOnlyResetPass) {
            // setIsNeedResetPassPopup(false);
            handleLogouStep();
          }
        } else {
          // alert(res.data.message)
          handleSetNotif(
            res?.data
              ? res.data.message
              : notificationsLocalization.updatePassword.error,
            "error",
          );
          //console.log(res.data.message, 'res.data.message')
        }
      } catch (error) {
        console.log(error);
        handleSetNotif(
          notificationsLocalization.updatePassword.requestError,
          "error",
        );
      }
    }
  };

  const handlePasswordValidation = (e) => {
    const value = e.target.value;

    if (value.length >= 8) {
      setValidationOptions((prevOptions) => {
        return { ...prevOptions, minimumCharacters: true };
      });
    } else {
      setValidationOptions((prevOptions) => {
        return { ...prevOptions, minimumCharacters: false };
      });
    }

    handleResetPasswordParams({ passwordUpdate: value });

    if (passwRegex.test(value)) {
      // setPasswordErr(false);
    }
  };
  const handleVerifyPasswordValidation = (e) => {
    const value = e.target.value;
    handleResetPasswordParams({ passwordUpdate2: value });

    if (validationOptions.minimumCharacters) {
      if (value === resetPasswordData.passwordUpdate) {
        setValidationOptions((prevOptions) => {
          return { ...prevOptions, samePassword: true };
        });
      } else {
        setValidationOptions((prevOptions) => {
          return { ...prevOptions, samePassword: false };
        });
      }
    } else {
      setValidationOptions((prevOptions) => {
        return { ...prevOptions, samePassword: false };
      });
    }
  };

  return (
    <div className="get-token-popup__window get-token-popup__window_6">
      <div
        className="get-token-popup__form form-get-token-popup"
        style={
          applicationType === "web" ? { maxWidth: 500, width: "100%" } : null
        }
      ></div>

      {windowStep === "sendOTPCode" && (
        <div className="form-get-token-popup__block reset">
          <label
            htmlFor="inp-8"
            className="form-get-token-popup__input-wrapper"
          >
            <span className="input-title">
              {localization.sendOTP.phoneTitle}
            </span>
            <input
              type="text"
              id="inp-8"
              autoComplete="off"
              className="form-get-token-popup__input"
              ref={phoneInputRef}
              value={phoneNumber}
              onChange={handlePhoneNumber}
            />
          </label>
          <button
            onClick={(e) => {
              if (validateFullPhoneNumber(phoneInputRef.current)) {
                handleIsForgetPassword(phoneNumber);
              } else {
                handleSetNotif(
                  notificationsLocalization.notCorrectPhone,
                  "error",
                );
              }
            }}
            className="get-token-popup__main-btn popup__blue-btn reset h42"
            style={
              applicationType === "web"
                ? { marginBottom: "0px", maxWidth: "calc(50% - 5px)" }
                : { marginBottom: "0px" }
            }
          >
            {isResendText
              ? localization.sendOTP.resendText
              : localization.sendOTP.sendText}
          </button>
        </div>
      )}
      {windowStep === "verifyOTP" && (
        <>
          <div className="form-get-token-popup__block reset">
            {/* <label
              htmlFor="inp-7"
              className="form-get-token-popup__input-wrapper"
            >
              <span className="input-title">
                {localization.verifyOTP.codeTitle}
              </span>
              <input
                onChange={(e) => handleOtpParams({ otp: e.target.value })}
                value={otpData.otp}
                type="text"
                id="inp-7"
                autoComplete="off"
                className="form-get-token-popup__input"
              />
            </label> */}
            <Box
              sx={{
                flexBasis: "calc(50% - 5px)",
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <TextField
                label={localization.verifyOTP.codeTitle}
                value={otpData.otp}
                onChange={(e) => handleOtpParams({ otp: e.target.value })}
                fullWidth
                type="tel"
                size="small"
                InputProps={{
                  sx: {
                    fontSize: 14,
                    "& input": {
                      py: 1.5,
                      textAlign: "right",
                    },
                  },
                }}
                error={isOtpSendError}
                helperText={
                  isOtpSendError ? (
                    <Grid
                      container
                      spacing={0.5}
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                    >
                      <Grid item>
                        <Box>{localization.verifyOTP.sendOtpError}</Box>
                      </Grid>
                      <Grid item>
                        <ErrorOutlineOutlined
                          sx={{
                            width: 12,
                            height: 12,
                            display: "block",
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : null
                }
                sx={{
                  direction: "rtl",
                }}
              />
            </Box>

            <button
              onClick={handleVerifyOTPStep}
              type="submit"
              className="get-token-popup__main-btn popup__blue-btn reset h42"
              style={
                applicationType === "web"
                  ? { marginBottom: "0px", maxWidth: "calc(50% - 5px)" }
                  : { marginBottom: "0px" }
              }
            >
              {localization.verifyOTP.submitBtn}
            </button>
            <div
              className="form-get-token-popup__buttons-col"
              style={{ padding: "0px", display: "flex", gap: "10px" }}
            ></div>
            <div
              className="form-get-token-popup__buttons-col"
              style={{ padding: "0px", display: "flex", gap: "10px" }}
            >
              <button
                onClick={(e) => {
                  handleIsForgetPassword(phoneNumber);
                }}
                className="get-token-popup__main-btn popup__blue-btn reset h42"
                style={
                  applicationType === "web"
                    ? { marginBottom: "0px", maxWidth: "calc(50% - 5px)" }
                    : { marginBottom: "0px" }
                }
              >
                {isResendText
                  ? localization.verifyOTP.resendText
                  : localization.verifyOTP.sendText}
              </button>
            </div>
          </div>
        </>
      )}
      {windowStep === "updatePassword" && (
        <div className="form-get-token-popup__block">
          <label
            htmlFor="inp-9"
            className="form-get-token-popup__input-wrapper"
          >
            <span className="input-title">
              {localization.updatePassword.passwordTitle}
            </span>

            <input
              value={resetPasswordData.passwordUpdate}
              onChange={(e) =>
                // handleResetPasswordParams({ passwordUpdate: e.target.value })
                handlePasswordValidation(e)
              }
              type={`${showPassUpdate ? "text" : "password"}`}
              id="inp-9"
              className={`form-get-token-popup__input ${
                isResetDisabled ? "disabled" : ""
              }`}
              style={{ textAlign: "left" }}
            />

            <button
              onClick={toggleShowPassUpdate}
              className={`show-hide-password-btn ${
                showPassUpdate ? "green" : ""
              }`}
            >
              <img src={eye} alt="" />
            </button>
          </label>

          <label
            htmlFor="inp-6"
            className="form-get-token-popup__input-wrapper"
          >
            <span className="input-title">
              {localization.updatePassword.verifyPasswordTitle}
            </span>
            <input
              value={resetPasswordData.passwordUpdate2}
              onChange={(e) =>
                // handleResetPasswordParams({ passwordUpdate2: e.target.value })
                handleVerifyPasswordValidation(e)
              }
              type={`${showPassUpdate2 ? "text" : "password"}`}
              id="inp-6"
              className={`form-get-token-popup__input ${
                isResetDisabled ? "disabled" : ""
              }`}
              style={{ textAlign: "left" }}
            />

            <button
              onClick={toggleShowPassUpdateTwo}
              className={`show-hide-password-btn ${
                showPassUpdate2 ? "green" : ""
              }`}
            >
              <img src={eye} alt="" />
            </button>
          </label>

          <div
            className="form-get-token-popup__block"
            style={{ margin: "10px 0 0 0 " }}
          >
            <div className="form-get-token-popup__check-list">
              <div
                className={`form-get-token-popup__check-list-item  ${
                  validationOptions.minimumCharacters ? "valid" : ""
                }`}
              >
                <img src={greenCheck} className="valid-icon" alt="" />
                {localization.updatePassword.minChars}
              </div>
              <div
                className={`form-get-token-popup__check-list-item  ${
                  validationOptions.samePassword ? "valid" : ""
                }`}
              >
                <img src={greenCheck} className="valid-icon" alt="" />
                {localization.updatePassword.samePassword}
              </div>
            </div>
          </div>

          <div
            className="form-get-token-popup__block"
            style={{ width: "100%" }}
          >
            <button
              onClick={() => {
                handleUpdatePassword(phoneNumber);
              }}
              type="submit"
              style={{ maxWidth: "265px", width: "100%" }}
              className={`get-token-popup__main-btn popup__blue-btn h42 ${
                isResetDisabled ? "disabled" : ""
              }`}
              disabled={isResetDisabled}
            >
              {localization.updatePassword.submitBtn}
            </button>
          </div>
        </div>
      )}

      {!isOnlyResetPass && applicationType !== "extension" && (
        <div className="form-get-token-popup__button-wrap form-get-token-popup__button-wrap--register">
          <div className="btns-wrapper">
            <div
              className="text"
              onClick={() => handleAuthPopupWindow("login")}
            >
              {localization.loginLinkText}
            </div>
            {/* <div
              className="text"
              onClick={() => handleAuthPopupWindow("register")}
            >
              {localization.registerLinkText}
            </div> */}
          </div>
        </div>
      )}

      <div className="get-token-popup__links-footer">
        <div className="get-token-popup__links-footer-col">
          <p className="get-token-popup__links-footer-text">
            {localizations["he"].auth.footer.version}{" "}
            {applicationConfig.version}
          </p>
          <p className="get-token-popup__links-footer-text">
            {localizations["he"].auth.footer.text}
          </p>
        </div>
        <div className="get-token-popup__links-footer-col">
          <a
            href={`https://${localizations["he"].auth.funnerSupportLink}`}
            className="get-token-popup__link-footer-link get-token-popup__icon-link"
          >
            {localizations["he"].auth.footer.funnerSupportLink}
            <img src={insertLink} alt="" />
          </a>
          <a
            href={`mailto:${localizations["he"].auth.funnerSupportEmail}`}
            className="get-token-popup__link-footer-link get-token-popup__icon-link"
          >
            {localizations["he"].auth.footer.funnerSupportEmail}
            <img src={mailIcon} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
