import React, { useEffect, useState } from "react";
import { contactFace } from "../../../../libs/images";
import { IsJsonString } from "../../../../libs/helpers";

function ContactsMessage({ object }) {
  const [contact, setContact] = useState(null);

  useEffect(() => {
    if (object) {
      const parsedObject = IsJsonString(object) ? JSON.parse(object) : object;
      const name = parsedObject?.name?.first_name;
      if (parsedObject?.phones) {
        const phone = parsedObject?.phones[0]?.phone;
        const phoneLink = parsedObject?.phones[0]?.wa_id;
        setContact({ name, phone, phoneLink });
      }
    }
  }, []);

  return (
    <div className="message__upload-container message__upload-container_contact">
      <a href="#" className="message__contact contact-message">
        <div className="contact-message__avatar">
          <img src={contactFace} alt="" />
        </div>
      </a>
      <div className="contact-message__details">
        <a className="message__contact contact-message">
          <p className="contact-message__name">{contact?.name}</p>
        </a>
        <a href={`tel:${contact?.phoneLink}`} className="reply__text">
          <p className="phone">{contact?.phone}</p>
        </a>
      </div>
    </div>
  );
}

export default ContactsMessage;
