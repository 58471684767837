import React, { useState } from "react";
import { IsJsonString } from "../../../../libs/helpers";
import { Box } from "@mui/material";

function ButtonsMessage({
  Object,
  chatCustomizationSettings,
  FileType,
  sentOrRecieved,
}) {
  const buttonsObject = IsJsonString(Object) ? JSON.parse(Object) : Object;

  return (
    <div className="preview-content-manage-templates__buttons-container">
      {buttonsObject?.type === "button" &&
        buttonsObject.action.buttons.map((button, index) => (
          <div
            className="preview-content-manage-templates__button preview-content-manage-templates__button"
            key={index}
          >
            <span className="_button-text">{button?.reply?.title}</span>
            <span className="_button-icon">
              <svg
                fill="#1976d2"
                width="64px"
                height="64px"
                viewBox="0 0 32 32"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <title>reply</title>{" "}
                  <path d="M3.488 15.136q0 0.96 0.8 1.472l10.72 7.136q0.416 0.288 0.896 0.32t0.928-0.224 0.704-0.672 0.256-0.896v-3.584q3.456 0 6.208 1.984t3.872 5.152q0.64-1.792 0.64-3.552 0-2.912-1.44-5.376t-3.904-3.904-5.376-1.44v-3.584q0-0.48-0.256-0.896t-0.704-0.672-0.928-0.224-0.896 0.32l-10.72 7.136q-0.8 0.512-0.8 1.504z"></path>
                </g>
              </svg>
            </span>
          </div>
        ))}
      {buttonsObject?.type === "cta_url" && (
        <a
          href={buttonsObject.action?.parameters?.url}
          target="_blank"
          className="preview-content-manage-templates__button preview-content-manage-templates__button"
          rel="noreferrer"
        >
          <span className="_button-text">
            {buttonsObject?.action?.parameters?.display_text}
          </span>
          <span className="_button-icon">
            <svg
              width="64px"
              height="64px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#000000"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M11 4H4V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V13"
                  stroke="#1976d2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></path>
                <path
                  d="M9 15L20 4"
                  stroke="#1976d2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></path>
                <path
                  d="M15 4H20V9"
                  stroke="#1976d2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                ></path>
              </g>
            </svg>
          </span>
        </a>
      )}
      {buttonsObject?.type === "list" && (
        <>
          <Box
            sx={{
              color:
                chatCustomizationSettings?.find(
                  (option) =>
                    option.type ===
                    (FileType === "note"
                      ? "noteMessage"
                      : sentOrRecieved === "outcoming"
                      ? "outcomingMessage"
                      : "incomingMessage"),
                )?.value === "custom"
                  ? chatCustomizationSettings?.find(
                      (option) =>
                        option.type ===
                        (FileType === "note"
                          ? "noteMessage"
                          : sentOrRecieved === "outcoming"
                          ? "outcomingMessage"
                          : "incomingMessage"),
                    ).customColor.text + " !important"
                  : null,
              textAlign: "center",
              my: 1,
              opacity: 0.8,
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {buttonsObject.action.sections[0]?.title}
          </Box>
          {buttonsObject.action.sections[0].rows.map((row) => (
            <div
              className="preview-content-manage-templates__button preview-content-manage-templates__button"
              key={row.id}
            >
              <span className="_button-text">{row.title}</span>
              <span className="_button-icon">
                <svg
                  fill="#1976d2"
                  width="64px"
                  height="64px"
                  viewBox="0 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>reply</title>{" "}
                    <path d="M3.488 15.136q0 0.96 0.8 1.472l10.72 7.136q0.416 0.288 0.896 0.32t0.928-0.224 0.704-0.672 0.256-0.896v-3.584q3.456 0 6.208 1.984t3.872 5.152q0.64-1.792 0.64-3.552 0-2.912-1.44-5.376t-3.904-3.904-5.376-1.44v-3.584q0-0.48-0.256-0.896t-0.704-0.672-0.928-0.224-0.896 0.32l-10.72 7.136q-0.8 0.512-0.8 1.504z"></path>
                  </g>
                </svg>
              </span>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default ButtonsMessage;
