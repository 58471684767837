import React, { useEffect, useRef } from "react";
import { audioIcon } from "../../../../libs/images";
import { convertSecondstoTime } from "../../../../libs/helpers";

function AudioMessage({ Id, file, Reply, audioDurationRef, audioTrackRef }) {
  const audioElementRef = useRef(null);
  const playBtnRef = useRef(null);
  // const audioDurationRef = useRef(null);
  // const audioTrackRef = useRef(null);
  const audioContainerRef = useRef(null);

  function handleAudioControl() {
    const audioElement = audioElementRef.current;
    const playBtn = playBtnRef.current;
    const audioMessageDuration = audioDurationRef.current;
    const audioTrackElement = audioTrackRef.current;
    const audioContainer = audioContainerRef.current;

    function updateAudioTrack(e) {
      const time = convertSecondstoTime(e.target.currentTime);
      const duration = convertSecondstoTime(e.target.duration);
      if (audioTrackElement) {
        audioTrackElement.textContent = `${time} / ${duration}`;
      }
    }

    function handleAudioDuration() {
      if (audioElement && audioElement.duration) {
        const duration = convertSecondstoTime(
          Math.round(audioElement.duration),
        );
        if (audioTrackElement) {
          audioTrackElement.textContent = `0:00 / ${duration}`;
        }
      }
    }

    function handleAudioDurationReply() {
      if (audioElement && audioElement.duration) {
        const duration = convertSecondstoTime(
          Math.round(audioElement.duration),
        );

        if (audioMessageDuration) {
          audioMessageDuration.textContent = `0:00 / ${duration}`;
        }
      }
    }

    function handleAudio() {
      if (audioElement.paused) {
        audioElement.play();
        playBtn.classList.add("paused");
        audioContainer.classList.add("playing-now");
      } else {
        audioElement.pause();
        playBtn.classList.remove("paused");
        audioContainer.classList.remove("playing-now");
      }
    }

    function handleAudioEnded() {
      audioElement.currentTime = 0; // Reset the audio to the beginning
      playBtn.classList.remove("paused");
      audioContainer.classList.remove("playing-now");
    }

    // Add the 'ended' event listener to the audio element
    if (!Reply) {
      audioElement.addEventListener("ended", () => handleAudioEnded());

      audioElement.addEventListener("timeupdate", (event) => {
        updateAudioTrack(event);
      });

      playBtn.addEventListener("click", () => {
        handleAudio();
      });
    } else {
      audioElement?.addEventListener("loadedmetadata", () => {
        handleAudioDurationReply();
      });
    }

    audioElement?.addEventListener("loadedmetadata", () => {
      handleAudioDuration();
    });
  }

  useEffect(() => {
    handleAudioControl();
  }, []);

  return (
    <div className="message__upload-container message__upload-container_audio playing">
      <div
        className={`message__audio audio-message funner-global-audio-message-${Id}`}
        ref={audioContainerRef}
      >
        <div className="audio-message__container">
          <audio
            id={`funner-global-audio-message__main-audio-${Id}`}
            ref={audioElementRef}
            className="audio-message__main-audio"
            src={file}
          />
          <div
            id={`audio-message__play-container-${Id}`}
            className="audio-message__play-container"
          >
            <button
              type="button"
              id={`funner-global-audio-message__play-btn-${Id}`}
              className="audio-message__play-btn"
              ref={playBtnRef}
            />
          </div>
          <div className="audio-message__audio-wave">
            <svg
              width={200}
              height={50}
              viewBox="0 0 200 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_915_13353)">
                <line
                  x1="11.5"
                  y1="19.5"
                  x2="11.5"
                  y2="30.5"
                  stroke="#757575"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="16.5"
                  y1="17.5"
                  x2="16.5"
                  y2="32.5"
                  stroke="#757575"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="21.5"
                  y1="17.5"
                  x2="21.5"
                  y2="32.5"
                  stroke="#757575"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="26.5"
                  y1={11}
                  x2="26.5"
                  y2={39}
                  stroke="#757575"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="31.5"
                  y1="14.5"
                  x2="31.5"
                  y2="35.5"
                  stroke="#757575"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="36.5"
                  y1="14.5"
                  x2="36.5"
                  y2="35.5"
                  stroke="#757575"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="41.5"
                  y1="20.5"
                  x2="41.5"
                  y2="29.5"
                  stroke="#757575"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="46.5"
                  y1={21}
                  x2="46.5"
                  y2={29}
                  stroke="#757575"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="51.5"
                  y1="19.5"
                  x2="51.5"
                  y2="30.5"
                  stroke="#757575"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="56.5"
                  y1="4.5"
                  x2="56.5"
                  y2="45.5"
                  stroke="#757575"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="61.5"
                  y1="12.5"
                  x2="61.5"
                  y2="37.5"
                  stroke="#757575"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="66.5"
                  y1={11}
                  x2="66.5"
                  y2={39}
                  stroke="#757575"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="71.5"
                  y1="7.5"
                  x2="71.5"
                  y2="42.5"
                  stroke="#757575"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="76.5"
                  y1="14.5"
                  x2="76.5"
                  y2="35.5"
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="81.5"
                  y1="19.5"
                  x2="81.5"
                  y2="30.5"
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="86.5"
                  y1={22}
                  x2="86.5"
                  y2={28}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="91.5"
                  y1={19}
                  x2="91.5"
                  y2={31}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="96.5"
                  y1="12.5"
                  x2="96.5"
                  y2="37.5"
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="101.5"
                  y1={7}
                  x2="101.5"
                  y2={43}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="106.5"
                  y1={21}
                  x2="106.5"
                  y2={29}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="111.5"
                  y1={20}
                  x2="111.5"
                  y2={30}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="116.5"
                  y1={22}
                  x2="116.5"
                  y2={28}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="121.5"
                  y1="17.5"
                  x2="121.5"
                  y2="32.5"
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="126.5"
                  y1="13.5"
                  x2="126.5"
                  y2="36.5"
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="131.5"
                  y1={11}
                  x2="131.5"
                  y2={39}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="136.5"
                  y1={8}
                  x2="136.5"
                  y2={42}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="141.5"
                  y1={11}
                  x2="141.5"
                  y2={39}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="146.5"
                  y1={16}
                  x2="146.5"
                  y2={34}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="151.5"
                  y1={19}
                  x2="151.5"
                  y2={31}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="156.5"
                  y1="17.5"
                  x2="156.5"
                  y2="32.5"
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="161.5"
                  y1={20}
                  x2="161.5"
                  y2={30}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="166.5"
                  y1={22}
                  x2="166.5"
                  y2={28}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <circle cx={72} cy={25} r={7} fill="#1976D2" />
                <line
                  x1="171.5"
                  y1="15.5"
                  x2="171.5"
                  y2="34.5"
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="176.5"
                  y1={8}
                  x2="176.5"
                  y2={42}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="181.5"
                  y1={11}
                  x2="181.5"
                  y2={39}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="186.5"
                  y1={16}
                  x2="186.5"
                  y2={34}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="191.5"
                  y1={19}
                  x2="191.5"
                  y2={31}
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
                <line
                  x1="196.5"
                  y1="17.5"
                  x2="196.5"
                  y2="32.5"
                  stroke="#BDBDBD"
                  strokeWidth={3}
                  strokeLinecap="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_915_13353">
                  <rect width={200} height={50} fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="audio-message__status">
            <img src={audioIcon} alt="" />
          </div>
          <div
            id={`funner-global-audio-message__time-playing-${Id}`}
            className="audio-message__time-playing"
          />
        </div>
      </div>
    </div>
  );
}

export default AudioMessage;
