import React, { useEffect, useRef, useState } from "react";
import { eye, greenCheck, insertLink, mailIcon } from "../../../libs/images";
import { emailRegex, passwRegex, phoneRegex } from "../../../libs/regexes";
import { UseFetchPostAnonymos } from "../../../hooks/fetchFunctions";
import { getGlobalScripsData } from "../../../libs/helpers";
import localizations from "../../../localizations.json";

function Register({
  handleAuthPopupWindow,
  isPowerLinkPage,
  registerData,
  handleRegisterParams,
  validationOptions,
  setValidationOptions,
  userData,
  handleSetNotif,
  sendOTPByPhone,
  handleOtpParams,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].auth.register,
  );

  const [emailErr, setEmailErr] = useState(false);
  const [powerlinkTokenError, setPowerlinkTokenError] = useState(false);
  const [companyPassCodeError, setСompanyPassCodeError] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [companyNameErr, setCompanyNameErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [verifyPasswordErr, setVerifyPasswordErr] = useState(false);
  const [phoneNumberErr, setPhoneNumberErr] = useState(false);
  const [confRegError, setConfRegError] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordVerify, setShowPasswordVerify] = useState(false);

  const fireberryRegisterData = useRef(null);

  function toggleShowPassword(e) {
    e.stopPropagation();
    setShowPassword(!showPassword);
  }
  function toggleShowPasswordVerify() {
    setShowPasswordVerify(!showPasswordVerify);
  }

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    const validationErrors = [];

    if (!emailRegex.test(registerData.email)) {
      setEmailErr(true);
      validationErrors.push(false);
    }
    if (registerData.powerlinkToken === "") {
      setPowerlinkTokenError(true);
      // if (applicationType === "extension") {
      if (isPowerLinkPage) {
        validationErrors.push(false);
      }
    }
    if (registerData.companyPassCode === "") {
      setСompanyPassCodeError(true);
    }
    if (registerData.firstName === "") {
      setFirstNameErr(true);
    }
    if (registerData.lastName === "") {
      setLastNameErr(true);
    }
    if (registerData.companyName === "") {
      setCompanyNameErr(true);
    }
    /* if (login.length < 6) {
      setLoginErr(true)
    } */
    if (!passwRegex.test(registerData.password)) {
      setPasswordErr(true);
      validationErrors.push(false);
    }
    if (registerData.verifyPassword !== registerData.password) {
      //console.log('verifyPassword:', verifyPassword, 'email-', email);
      //console.log(verifyPassword !== password);
      setVerifyPasswordErr(true);
      validationErrors.push(false);
    }
    if (!phoneRegex.test(registerData.phoneNumberLogin)) {
      setPhoneNumberErr(true);
      validationErrors.push(false);
    }

    if (!validationOptions.confirmRegulations) {
      validationErrors.push(false);
      setConfRegError(true);
    }

    let params;
    if (isPowerLinkPage) {
      params = {
        PowerLinkToken: registerData.powerlinkToken,
        Email: registerData.email,
        Password: registerData.password,
        UserGlobalId: fireberryRegisterData.current.globalID,
        PhoneNumber: registerData.phoneNumberLogin,
        FirstName: registerData.firstName,
        LastName: registerData.lastName,
        CompanyId: fireberryRegisterData.current.companyID,
        CompanyName: registerData.companyName,
        Standalone: false,
      };
    } else {
      params = {
        // PowerLinkToken: powerlinkToken,
        Email: registerData.email,
        Password: registerData.password,
        // UserGlobalId: userData.Udid,
        PhoneNumber: registerData.phoneNumberLogin,
        FirstName: registerData.firstName,
        LastName: registerData.lastName,
        // CompanyId: companyID,
        // CompanyName: companyName,
        companyPassCode: registerData.companyPassCode,
        Standalone: true,
      };
    }

    if (validationErrors.length == 0) {
      //console.log(validationErrors)
      UseFetchPostAnonymos("/api/auth/registerUser", params, "config")
        .then((res) => {
          // console.log(res, "res registerUser");
          if (res?.data?.result === false || res?.data?.success === false) {
            // alert(res.data.message)
            handleSetNotif(res.data.message, "error");
            return;
          } else {
            //?
            // handleGetGlobalUserStatus();
            handleRegisterParams({
              powerlinkToken: "",
              companyPassCode: "",
              companyName: "",
              email: "",
              password: "",
              verifyPassword: "",
              phoneNumberLogin: "",
              firstName: "",
              lastName: "",
            });
          }
          if (res.data) {
            handleAuthPopupWindow("verifyOTP");
            handleOtpParams({ phone: params.PhoneNumber });
            sendOTPByPhone(params.PhoneNumber).then(async (data) => {
              const response = await data.json();
              if (response.success == true) {
                handleSetNotif(
                  localizations["he"].auth.notifications.register.otpSend,
                  "success",
                );
              }
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      handleSetNotif(
        localizations["he"].auth.notifications.register.error,
        "error",
      );
    }
  };

  const handleRegulationConfirmation = (e) => {
    const checked = e.target.checked;
    setValidationOptions((prevOptions) => {
      return { ...prevOptions, confirmRegulations: checked };
    });

    if (checked) {
      setConfRegError(false);
    }
  };
  const handlePasswordValidation = (e) => {
    const value = e.target.value;

    if (value.length >= 8) {
      setValidationOptions((prevOptions) => {
        return { ...prevOptions, minimumCharacters: true };
      });
    } else {
      setValidationOptions((prevOptions) => {
        return { ...prevOptions, minimumCharacters: false };
      });
    }

    handleRegisterParams({ password: value });

    if (passwRegex.test(value)) {
      setPasswordErr(false);
    }
  };
  const handleVerifyPasswordValidation = (e) => {
    const value = e.target.value;
    handleRegisterParams({ verifyPassword: value });
    //console.log('e.target.value', value, 'password', password);

    if (
      // validationOptions.lowerCaseLetter &&
      // validationOptions.number &&
      // validationOptions.specialSign &&
      validationOptions.minimumCharacters
      // validationOptions.upperCaseLetter
    ) {
      if (value === registerData.password) {
        setVerifyPasswordErr(false);
        setValidationOptions((prevOptions) => {
          return { ...prevOptions, samePassword: true };
        });
      } else {
        setValidationOptions((prevOptions) => {
          return { ...prevOptions, samePassword: false };
        });
      }
    } else {
      setValidationOptions((prevOptions) => {
        return { ...prevOptions, samePassword: false };
      });
    }
  };

  async function handleFireberryRegisterData() {
    fireberryRegisterData.current = {
      ...(await getGlobalScripsData()),
    };
  }

  useEffect(() => {
    if (isPowerLinkPage) handleFireberryRegisterData();
  }, [isPowerLinkPage]);

  return (
    <div className="get-token-popup__window get-token-popup__window_3">
      <form
        onSubmit={handleRegisterSubmit}
        className="get-token-popup__form form-get-token-popup"
      >
        <div className="form-get-token-popup__block">
          {/* {applicationType === "extension" && ( */}
          {isPowerLinkPage && (
            <label
              htmlFor="inp-token"
              className="form-get-token-popup__input-wrapper form-get-token-popup__input-wrapper_100"
            >
              <span className="input-title">
                {localization.powerlinkTokenTitle}
              </span>
              <input
                onChange={(e) => {
                  const value = e.target.value;
                  handleRegisterParams({ powerlinkToken: value });
                  if (value.length > 0 && powerlinkTokenError) {
                    setPowerlinkTokenError(false);
                  }
                }}
                value={registerData.powerlinkToken}
                type="text"
                id="inp-token"
                className={`form-get-token-popup__input ${
                  powerlinkTokenError ? "error" : ""
                }`}
              />
            </label>
          )}

          {/* {applicationType === "extension" && ( */}
          {isPowerLinkPage && (
            <label
              htmlFor="inp-1"
              className="form-get-token-popup__input-wrapper form-get-token-popup__input-wrapper_100"
            >
              <span className="input-title">
                {localization.companyNameTitle}
              </span>
              <input
                onChange={(e) => {
                  const value = e.target.value;
                  handleRegisterParams({ companyName: value });
                  if (value.length > 0 && companyNameErr) {
                    setCompanyNameErr(false);
                  }
                }}
                value={registerData.companyName}
                type="text"
                id="inp-1"
                className={`form-get-token-popup__input ${
                  companyNameErr ? "error" : ""
                }`}
              />
            </label>
          )}

          {/* {applicationType === "web" && ( */}
          {!isPowerLinkPage && (
            <label
              htmlFor="inp-company-passcode"
              className="form-get-token-popup__input-wrapper form-get-token-popup__input-wrapper_100"
            >
              <span className="input-title">
                {localization.companyPasscodeTitle}
              </span>
              <input
                onChange={(e) => {
                  const value = e.target.value;
                  handleRegisterParams({ companyPassCode: value });
                  if (value.length > 0 && companyPassCodeError) {
                    setСompanyPassCodeError(false);
                  }
                }}
                value={registerData.companyPassCode}
                type="text"
                id="inp-company-passcode"
                className={`form-get-token-popup__input ${
                  companyPassCodeError ? "error" : ""
                }`}
              />
            </label>
          )}
          <label
            htmlFor="inp-2"
            className="form-get-token-popup__input-wrapper"
          >
            <span className="input-title">{localization.firstNameTitle}</span>
            <input
              onChange={(e) => {
                const value = e.target.value;
                handleRegisterParams({ firstName: value });
                if (value.length > 0 && firstNameErr) {
                  setFirstNameErr(false);
                }
              }}
              value={registerData.firstName}
              type="text"
              id="inp-2"
              className={`form-get-token-popup__input ${
                firstNameErr ? "error" : ""
              }`}
            />
          </label>
          <label
            htmlFor="inp-3"
            className="form-get-token-popup__input-wrapper"
          >
            <span className="input-title">{localization.lastNameTitle}</span>
            <input
              onChange={(e) => {
                const value = e.target.value;
                handleRegisterParams({ lastName: value });
                if (value.length > 0 && lastNameErr) {
                  setLastNameErr(false);
                }
              }}
              value={registerData.lastName}
              type="text"
              id="inp-3"
              className={`form-get-token-popup__input ${
                lastNameErr ? "error" : ""
              }`}
            />
          </label>
          <label
            htmlFor="inp-4"
            className="form-get-token-popup__input-wrapper"
          >
            <span className="input-title">{localization.emailTitle}</span>
            <input
              onChange={(e) => {
                const value = e.target.value;
                handleRegisterParams({ email: value });
                if (emailRegex.test(value) && emailErr) {
                  setEmailErr(false);
                }
              }}
              value={registerData.email}
              type="text"
              id="inp-4"
              className={`form-get-token-popup__input ${
                emailErr ? "error" : ""
              }`}
            />
          </label>
          <label
            htmlFor="inp-5"
            className="form-get-token-popup__input-wrapper"
          >
            <span className="input-title">{localization.phoneTitle}</span>
            <input
              onChange={(e) => {
                const value = e.target.value;
                handleRegisterParams({ phoneNumberLogin: value });
                if (phoneRegex.test(value) && phoneNumberErr) {
                  setPhoneNumberErr(false);
                }
              }}
              value={registerData.phoneNumberLogin}
              type="text"
              id="inp-5"
              className={`form-get-token-popup__input ${
                phoneNumberErr ? "error" : ""
              }`}
            />
          </label>
        </div>
        <div className="form-get-token-popup__block form-get-token-popup__block_border-top">
          <label
            htmlFor="inp-7"
            className="form-get-token-popup__input-wrapper"
          >
            <span className="input-title">{localization.passwordTitle}</span>
            <input
              type={`${showPassword ? "text" : "password"}`}
              value={registerData.password}
              onChange={(e) => handlePasswordValidation(e)}
              id="inp-7"
              className={`form-get-token-popup__input ${
                passwordErr ? "error" : ""
              }`}
              style={{ textAlign: "left" }}
            />
            <div
              onClick={toggleShowPassword}
              className={`show-hide-password-btn ${
                showPassword ? "green" : ""
              }`}
            >
              <img src={eye} alt="" />
            </div>
          </label>

          <label
            htmlFor="inp-6"
            className="form-get-token-popup__input-wrapper"
          >
            <span className="input-title">
              {localization.passwordVerifyTitle}
            </span>
            <input
              type={`${showPasswordVerify ? "text" : "password"}`}
              value={registerData.verifyPassword}
              onChange={(e) => handleVerifyPasswordValidation(e)}
              id="inp-6"
              className={`form-get-token-popup__input ${
                verifyPasswordErr ? "error" : ""
              }`}
              style={{ textAlign: "left" }}
            />
            <div
              onClick={toggleShowPasswordVerify}
              className={`show-hide-password-btn ${
                showPasswordVerify ? "green" : ""
              }`}
            >
              <img src={eye} alt="" />
            </div>
          </label>
        </div>

        <div className="form-get-token-popup__block">
          <div className="form-get-token-popup__check-list">
            <div
              className={`form-get-token-popup__check-list-item  ${
                validationOptions.minimumCharacters ? "valid" : ""
              }`}
            >
              <img src={greenCheck} className="valid-icon" alt="" />
              {localization.passwordValidation.minChars}
            </div>
            <div
              className={`form-get-token-popup__check-list-item  ${
                validationOptions.samePassword ? "valid" : ""
              }`}
            >
              <img src={greenCheck} className="valid-icon" alt="" />
              {localization.passwordValidation.verifyPassEqual}
            </div>
          </div>
        </div>

        <div className="form-get-token-popup__block form-get-token-popup__block_border-top">
          <div className="form-get-token-popup__gray-check">
            <input
              onChange={handleRegulationConfirmation}
              name="confirm-policy"
              type="checkbox"
              checked={validationOptions.confirmRegulations}
              className="confirm-policy-checkbox"
            />
            <label
              htmlFor="confirm-policy"
              className={`form-get-token-popup__checkbox confirm-policy ${
                confRegError ? "error" : ""
              }`}
            >
              <img src={greenCheck} className="valid-icon" alt="" />
            </label>
            <a href="">{localization.confirmRegulationsLabel}</a>
          </div>

          <div className="form-get-token-popup__buttons-col">
            <button
              type="submit"
              className="get-token-popup__main-btn popup__blue-btn h42"
            >
              {localization.submitBtn}
            </button>
          </div>
        </div>
      </form>

      <div className="form-get-token-popup__button-wrap form-get-token-popup__button-wrap--register">
        <div className="btns-wrapper">
          <div className="text" onClick={() => handleAuthPopupWindow("login")}>
            {localization.loginLinkText}
          </div>
        </div>
      </div>

      <div className="get-token-popup__bottom-links">
        <a
          href="#"
          className="get-token-popup__bottom-link get-token-popup__icon-link"
        >
          <img src={mailIcon} className="icon" alt="" />
          {localization.footer.email1}
        </a>
        <a
          href={`mailto:${localization.footer.email2}`}
          className="get-token-popup__bottom-link get-token-popup__icon-link"
        >
          <img src={mailIcon} className="icon" alt="" />
          {localization.footer.email2}
        </a>
        <a
          href={`https://${localization.footer.site}`}
          className="get-token-popup__bottom-link get-token-popup__icon-link"
        >
          <img src={insertLink} className="icon" alt="" />
          {localization.footer.site}
        </a>
      </div>
    </div>
  );
}

export default Register;
