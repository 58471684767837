import React, { useEffect, useState } from "react";
import { IsJsonString } from "../../../../libs/helpers";

function OrderMessage({ Object }) {
  const [orderObject, setOrderObject] = useState(null);

  useEffect(() => {
    if (Object) {
      const orderObjectParsed = IsJsonString(Object)
        ? JSON.parse(Object)
        : Object;
      setOrderObject(orderObjectParsed);
    }
  }, []);

  return (
    <span
      className="message-order-summary"
      style={{ display: "block", marginTop: 5 }}
    >
      <strong>Order summary:</strong>
      <br />
      {orderObject?.product_items?.map((item, index) => (
        <span
          key={index}
          className="message-order-summary__item"
          style={{ marginTop: 3, display: "block" }}
        >
          Quantity: {item.quantity}
          <br />
          Total price: {Number(item.quantity) * Number(item.item_price)}{" "}
          {item.currency}
        </span>
      ))}
    </span>
  );
}

export default OrderMessage;
