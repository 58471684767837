import React, { useEffect, useState } from "react";

function LocationReplyTemplate({ sentOrReceived, userName, object }) {
  const [location, setLocation] = useState(null);

  function getLocation(object) {
    return JSON.parse(object);
  }

  useEffect(() => {
    if (object) {
      const thisContact = getLocation(object);
      setLocation(thisContact);
    }
  }, [object]);

  return (
    <a
      className={`message__reply-location reply reply_location ${
        sentOrReceived === "incoming" ? "incoming" : "outcoming"
      }`}
    >
      <div className="reply__text-part">
        <p className="reply__top-text">
          {sentOrReceived === "incoming" ? userName : "אתה"}
        </p>
        <div className="reply__info">
          <p className="reply__text">{location.address}</p>
        </div>
      </div>
      <div className="reply__map-part">
        <div className="reply__map">
          <img src={location.picture} alt="" />
        </div>
      </div>
    </a>
  );
}

export default LocationReplyTemplate;
