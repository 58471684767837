import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import localizations from "../../../localizations.json";

const FilesTableView = ({
  files,
  handleDeleteFileFromGallery,
  onEditExistingNameClick,
  handleLoadFilesListOnScroll,
  noActions,
  onClickAction,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [localization, setLocalization] = React.useState(
    localizations["he"].settingsPopup.uploadFiles,
  );
  const open = Boolean(anchorEl);

  const handleClick = (event, file) => {
    setAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setSelectedFile(null);
    }, 300);
  };

  return (
    <TableContainer
      sx={{ maxHeight: "100%", px: 1 }}
      onScroll={(e) => handleLoadFilesListOnScroll(e, 125)}
    >
      <Table stickyHeader size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell>{localization.fileName}</TableCell>
            <TableCell align="right">{localization.lastModified}</TableCell>
            {!noActions && (
              <TableCell align="right">{localization.actions}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file) => (
            <TableRow
              key={file.Id}
              hover
              onClick={() => onClickAction(file.Link)}
              sx={{ cursor: "pointer" }}
            >
              <TableCell>
                <Box
                  sx={{
                    maxWidth: 400,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {file.Name}
                </Box>
              </TableCell>
              <TableCell align="right">{file.DateCreate}</TableCell>
              {!noActions && (
                <TableCell align="right">
                  <IconButton onClick={(event) => handleClick(event, file)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            onClick={() => {
              handleDeleteFileFromGallery(selectedFile);
              handleClose();
            }}
          >
            {localization.menu.delete}
          </MenuItem>
          <MenuItem
            onClick={() => {
              onEditExistingNameClick(selectedFile);
              handleClose();
            }}
          >
            {localization.menu.editName}
          </MenuItem>
        </Menu>
      </Table>
    </TableContainer>
  );
};

export default FilesTableView;
