import React, { useEffect, useRef, useState } from "react";
import { convertSecondstoTime } from "../../../../libs/helpers";

function VideoReplyTemplate({ sentOrReceived, userName, file }) {
  const [videoDuration, setVideoDuration] = useState("");

  const videoFileRef = useRef(null);

  useEffect(() => {
    const video = videoFileRef.current;
    if (video.readyState >= HTMLMediaElement.HAVE_METADATA) {
      // Metadata is already loaded, access the duration immediately
      //console.log('videoDuration: video.duration', video.duration)
      setVideoDuration(convertSecondstoTime(video.duration));
    } else {
      // Wait for the metadata to be loaded
      video.addEventListener("loadedmetadata", function () {
        // Access the duration property once the metadata is available
        //console.log('videoDuration: video.duration', video.duration)

        setVideoDuration(convertSecondstoTime(video.duration));
      });
    }
  }, [file]);

  return (
    <a
      className={`message__reply-video reply reply_video ${
        sentOrReceived == "incoming" ? "incoming" : "outcoming"
      }`}
    >
      <div className="reply__text-part">
        <p className="reply__top-text">
          {sentOrReceived === "incoming" ? userName : "אתה"}
        </p>
        <div className="reply__info ">
          <p className="reply__text duration">{videoDuration}</p>
        </div>
      </div>

      <div className="reply__info-video">
        <div className="reply__icon-video">
          <video
            className="video-message__main-video"
            ref={videoFileRef}
            src={file}
          />
        </div>
      </div>
    </a>
  );
}

export default VideoReplyTemplate;
