import React, { Fragment, useEffect, useRef, useState } from "react";
import localizations from "../../localizations.json";
import useClickOutside from "../../libs/UseClickOutside";
import { findPriorityPageType, getProcedureNameByTitle } from "./PriorityData";
import { UseFetchGet, UseFetchPost } from "../../hooks/fetchFunctions";
import DropdownIndicator from "../../modules/DropdownIndicator";
import ReactSelect from "react-select";
import TemplatesMenu from "../Templates/TemplatesMenu";
import { IsJsonString } from "../../libs/helpers";
import {
  phoneGlobalRegex,
  phoneRegex,
  phoneSlicedRegex,
} from "../../libs/regexes";
import { enqueueSnackbar } from "notistack";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  useTheme,
} from "@mui/material";
import { Close, OpenInNew } from "@mui/icons-material";
import axios from "axios";

function PriorityDocuments({
  businessInfo,
  isPriorityDocumentWindow,
  handlePriorityDocumentsWindow,
  priorityDocumentId,
  priorityDocumentIdRef,
  globalID,
  handleSetNotif,
  templatesList,
  fetchWhatsappTemplates,
  handleSendPriorityUploadState,
  openFunnerChatWithUser,
  priorityDocTypes,
  funnerUsers,
  chatUsersGlobal,
  chatUsersGlobalRef,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].priority,
  );
  // const theme = useTheme();
  // const popupWidth = theme.breakpoints.

  const [loading, setLoading] = useState(false);
  const [isDocFileLoading, setIsDocFileLoading] = useState(false);
  const [showDocPreview, setShowDocPreview] = useState(false);
  const [showTemplateChoose, setShowTemplateChoose] = useState(false);

  const [selectedDocType, setSelectedDocType] = useState(null);
  const [docInfo, setDocInfo] = useState(null);
  const [procedureObject, setProcedureObject] = useState(null);
  const [sendDocFormatOptions, setSendDocFormatOptions] = useState([]);
  const [sendDocOptions, setSendDocOptions] = useState({
    format: "normal",
    target: "invoiceContact",
    type: "directSend",
  });
  const [showCustomersOnly, setShowCustomersOnly] = useState(true);

  const [sendDocTargetStates, setSendDocTargetStates] = useState({
    customer: false,
    all: false,
  });
  const [showAllSendTargets, setShowAllSendTargets] = useState(false);
  const [visiblePhoneBookContacts, setVisiblePhoneBookContacts] = useState({
    customerPhonebookContacts: [],
    allPhonebookContacts: [],
  });
  const [templateWithDocFile, setTemplatesWithDocFile] = useState([]);
  const [phoneBookSelectedContact, setPhoneBookSelectedContact] = useState({
    customer: null,
    all: null,
  });
  const [selectedFunnerUser, setSelectedFunnerUser] = useState(null);
  const [contactCreate, setContactCreate] = useState({
    name: "",
    phone: "",
  });
  const [testPhone, setTestPhone] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const fetchCancelTokensRef = useRef({
    contacts: null,
    file: null,
  });

  const defaultTemplate =
    IsJsonString(businessInfo.CompanyPlatforms) &&
    JSON.parse(businessInfo.CompanyPlatforms).priority?.configurations
      ?.defaultTemplate
      ? JSON.parse(businessInfo.CompanyPlatforms).priority?.configurations
          ?.defaultTemplate?.sendObject
      : null;
  // {
  //     to: "",
  //     templateName: "file_message",
  //     languageCode: "he",
  //     components: `{"BODY":{"text":"הי {{1}} בברכה צוות פאנר."},"HEADER":{"format":"DOCUMENT","text":""}}`,
  //     frontTemplateObj: `{"messaging_product":"whatsapp","to":"","type":"template","template":{"name":"file_message","language":{"code":"he"},"components":[{"type":"BODY","parameters":[{"type":"text","text":""}]},{"type":"HEADER","parameters":[{"type":"document","document":{"link":"", "filename":""}}]}]}}`,
  //     userglobalid: globalID,
  //     templateText: "",
  //   };

  const sendDocTargetphoneBookCustomerRef = useClickOutside(() => {
    handleSendDocTargetState("customer", false);
  });
  const sendDocTargetinvoiceAllRef = useClickOutside(() => {
    handleSendDocTargetState("all", false);
  });

  const sendDocTargetOptions = [
    {
      value: "invoiceContact",
      label:
        localization.docInfo.target.contactTypeSelect.invoiceContact +
        // selectedDocType?.title,
        procedureObject?.docTitle,
      isDisabled: !visiblePhoneBookContacts?.customerPhonebookContacts?.length,
    },
    {
      value: "phoneBookCustomer",
      label: localization.docInfo.target.contactTypeSelect.phoneBookCustomer,
    },
    {
      value: "phoneBookAll",
      label: localization.docInfo.target.contactTypeSelect.phoneBookAll,
    },
    {
      value: "newContact",
      label: localization.docInfo.target.contactTypeSelect.newContact,
    },
    {
      value: "test",
      label: localization.docInfo.target.contactTypeSelect.test,
    },
  ];

  const docInfoRef = useRef(null);
  const selectedDocTypeRef = useRef(null);
  const phoneBookSelectedContactRef = useRef(null);
  const contactCreateRef = useRef(null);
  const testPhoneRef = useRef(null);
  const selectedTemplateRef = useRef(null);
  const selectedFunnerUserRef = useRef(null);
  const isPriorityDocumentWindowRef = useRef(null);
  const showDocPreviewRef = useRef(null);
  const showTemplateChooseRef = useRef(null);
  const isDocFileLoadingRef = useRef(null);
  const sendDocOptionsRef = useRef(null);
  const dialogRef = useRef(null);
  const sendBtnRef = useRef(null);

  const closePopup = (clear = true) => {
    handlePriorityDocumentsWindow(false);
    if (clear) clearAllSelection();
  };
  const closePreview = () => {
    setShowDocPreview(false);
  };
  const closeTemplateChoose = () => {
    setShowTemplateChoose(false);
  };
  const closePreviewBackground = (e) => {
    if (!e.target.closest(".priority-documents-popup__content"))
      setShowDocPreview(false);
  };
  const closeTemplateChooseBackground = (e) => {
    if (!e.target.closest(".priority-documents-popup__content"))
      setShowTemplateChoose(false);
  };

  const handleSendDocOptions = (option, value) => {
    setSendDocOptions((prevObj) => {
      const newObj = { ...prevObj };
      newObj[option] = value;
      return newObj;
    });
  };
  const handleSendDocTargetState = (target, state) => {
    setSendDocTargetStates((prevObj) => {
      const newObj = { ...prevObj };
      newObj[target] = state;
      return newObj;
    });
  };
  const handleCustomersOnly = (checked) => {
    setShowCustomersOnly(checked);
    if (checked && sendDocOptions.target === "phoneBookAll") {
      handleSendDocOptions("target", "phoneBookCustomer");
    } else if (!checked && sendDocOptions.target === "phoneBookCustomer") {
      handleSendDocOptions("target", "phoneBookAll");
    }
  };
  const toggleShowAllSendTargets = () => {
    const prevValue = showAllSendTargets;
    setShowAllSendTargets(!prevValue);
  };
  const handlePhoneBookSelectedContact = (list, contact) => {
    setPhoneBookSelectedContact((prevObj) => {
      const newObj = { ...prevObj };
      newObj[list] = contact;
      return newObj;
    });
  };
  const handleSearchContacts = (list, value) => {
    const filteredList =
      value !== ""
        ? docInfo.PhonebookContacts[list].filter(
            (contact) =>
              contact.NAME.toLowerCase().includes(value.toLowerCase()) ||
              contact.PHONENUM.toLowerCase().includes(value.toLowerCase()),
          )
        : [...docInfo.PhonebookContacts[list]];

    setVisiblePhoneBookContacts((prevObj) => {
      const newObj = { ...prevObj };
      newObj[list] = [...filteredList];
      return newObj;
    });
  };
  const handleContactCreateParam = (param, value) => {
    setContactCreate((prevObj) => {
      const newObj = { ...prevObj };
      newObj[param] = value;
      return newObj;
    });
  };
  const handleTestPhone = (value) => {
    setTestPhone(value);
  };

  function onDocBtnClick(priorityDocId) {
    const headerTitleElement = document.querySelector(
      ".header-panel .upper-section .bread-crumbs [anchor-id='form_header_title']",
    )?.children[0];
    const headerTitle = headerTitleElement && headerTitleElement.innerText;
    const invoicePageType = headerTitle && findPriorityPageType(headerTitle);
    console.log(priorityDocTypes);
    const docType = priorityDocTypes.find(
      (docType) => docType.docIndetificator === invoicePageType.type,
    );
    setSelectedDocType(docType);
    selectedDocTypeRef.current = docType;
    console.log(docType);
    console.log(docType.formats[0].format);

    getDocumentInfo(
      priorityDocId,
      docType.docIndetificator,
      docType.formats[0].format,
    );
    // getDocumentInfo(priorityDocId, invoicePageType.type);
  }
  function clearAllSelection() {
    setLoading(false);
    setShowDocPreview(false);
    setSelectedDocType(null);
    selectedDocTypeRef.current = null;
    setShowTemplateChoose(false);
    setShowCustomersOnly(true);
    setProcedureObject(null);
    setDocInfo(null);
    setSendDocOptions({
      format: "normal",
      target: "invoiceContact",
      type: "directSend",
    });
    setShowAllSendTargets(false);
    setVisiblePhoneBookContacts({
      customerPhonebookContacts: [],
      allPhonebookContacts: [],
    });
    // setTemplatesWithDocFile([]);
    setPhoneBookSelectedContact({
      customer: null,
      all: null,
    });
    setContactCreate({
      name: "",
      phone: "",
    });
    setTestPhone("");
    setSelectedTemplate(null);
    if (fetchCancelTokensRef.current.contacts) {
      fetchCancelTokensRef.current.contacts.cancel();
    }
    if (fetchCancelTokensRef.current.file) {
      fetchCancelTokensRef.current.file.cancel();
    }
  }

  const handleDocAction = async () => {
    const sendType = sendDocOptions.type;

    if (sendType === "directSend") {
      if (
        !docInfoRef.current ||
        (docInfoRef.current && !docInfoRef.current.previewImageLink)
      ) {
        closePopup(false);
        let i = 0;
        const waitForInfoInterval = setInterval(() => {
          if (
            docInfoRef.current &&
            docInfoRef.current.previewImageLink &&
            (docInfoRef.current.procedureObject || docInfoRef.current.docObject)
          ) {
            clearInterval(waitForInfoInterval);
            sendAction();
          } else if (i >= 1000) {
            clearInterval(waitForInfoInterval);
          }
          i++;
        }, 50);
      } else sendAction();

      async function sendAction() {
        let targetPhone;
        console.log(sendDocOptionsRef.current.target);
        console.log(docInfoRef.current);
        if (sendDocOptionsRef.current.target === "invoiceContact") {
          targetPhone = docInfoRef.current?.customerPhone
            ? "972" + docInfoRef.current.customerPhone.slice(1)
            : null;
        } else if (sendDocOptionsRef.current.target === "phoneBookCustomer") {
          targetPhone = phoneBookSelectedContactRef.current?.customer
            ? "972" +
              phoneBookSelectedContactRef.current.customer.PHONENUM.slice(1)
            : null;
        } else if (sendDocOptionsRef.current.target === "phoneBookAll") {
          targetPhone = phoneBookSelectedContactRef.current?.all
            ? "972" + phoneBookSelectedContactRef.current.all.PHONENUM.slice(1)
            : null;
        } else if (sendDocOptionsRef.current.target === "newContact") {
          if (!phoneRegex.test(contactCreateRef.current.phone))
            return handleSetNotif(
              localization.notifications.documents.phoneWrong,
              "error",
            );
          targetPhone = phoneRegex.test(contactCreateRef.current.phone)
            ? "972" + contactCreateRef.current.phone.slice(1)
            : contactCreateRef.current.phone;
        } else if (sendDocOptionsRef.current.target === "test") {
          targetPhone =
            selectedFunnerUserRef.current?.UserDisplayPhoneNumber || null;
        }

        if (!targetPhone || targetPhone === "" || targetPhone === "972") {
          return enqueueSnackbar(
            <Box>
              <Box>{localization.notifications.documents.phoneRequired}</Box>
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="customWhite"
                  onClick={() => handlePriorityDocumentsWindow(true)}
                >
                  {localization.notifications.error.tryAgain}
                </Button>
              </Box>
            </Box>,
            {
              variant: "error",
              autoHideDuration: null,
              hideIconVariant: true,
            },
          );
        }
        closePopup(false);
        const result = await sendTemplateMsgWithDoc(true);
        if (result) {
          clearAllSelection();
        } else {
          handlePriorityDocumentsWindow(true);
        }
      }
    } else if (sendType === "showPreview") {
      setLoading(true);
      // const getDocFileRes = await getDocumentFile();
      // if (getDocFileRes) {
      setTimeout(() => {
        setShowDocPreview(true);
        setLoading(false);
      }, 1000);
      // } else
      //   handleSetNotif(localization.notifications.documentLoadError, "error");
    } else if (sendType === "showChooseTemplate") {
      // setLoading(true);
      setShowTemplateChoose(true);
      // const getDocFileRes = await getDocumentFile();
      // if (!getDocFileRes) {
      //   handleSetNotif(localization.notifications.documentLoadError, "error");
      //   setShowTemplateChoose(false);
      // }
      // setLoading(false);
    } else if (sendType === "regularMessage") {
      let phone;
      if (sendDocOptions.target === "invoiceContact") {
        phone = "972" + docInfoRef.current.customerPhone.slice(1);
      } else if (sendDocOptions.target === "phoneBookCustomer") {
        phone =
          "972" +
          phoneBookSelectedContactRef.current.customer.PHONENUM.slice(1);
      } else if (sendDocOptions.target === "phoneBookAll") {
        phone =
          "972" + phoneBookSelectedContactRef.current.all.PHONENUM.slice(1);
      } else if (sendDocOptions.target === "newContact") {
        phone = phoneRegex.test(contactCreateRef.current.phone)
          ? "972" + contactCreateRef.current.phone.slice(1)
          : contactCreateRef.current.phone;

        if (
          !chatUsersGlobalRef.current.notMyChats?.some(
            (user) => user.UserDisplayPhoneNumber === phone,
          )
        ) {
          const nameWords = contactCreateRef.current.name.split(" ");
          const createUserParams = {
            firstName: nameWords[0],
            lastName: nameWords[1] ? nameWords[1] : "",
            phone: contactCreateRef.current.phone,
            isFireberry: false,
            // phoneType: selectedPhoneNumberType,
          };

          const result = await UseFetchPost(
            "/api/services/CreateChatUser",
            createUserParams,
            "config",
          );

          if (result?.data?.UserId) {
            phone = "972" + contactCreateRef.current.phone.slice(1);
          } else {
            handleSetNotif(
              localizations["he"].priority.notifications.userCreationError,
              "error",
            );
            return false;
          }
        }
      } else if (sendDocOptions.target === "test") {
        phone = testPhoneRef.current;
      }
      openFunnerChatWithUser(phone);

      handleSendPriorityUploadState({
        isUpload: true,
        file: docInfoRef.current.previewImageLink.pdfLinkString,
      });

      closePopup(false);
      clearAllSelection();
    }
  };
  const handleSendInPreview = async () => {
    closePopup(false);
    const result = await sendTemplateMsgWithDoc();
    if (result) {
      clearAllSelection();
    } else {
      handlePriorityDocumentsWindow(true);
    }
  };
  const handleSendInChooseTemplate = async () => {
    closePopup(false);
    const result = await sendSelectedTemplateMsgWithDoc();
    if (result) {
      clearAllSelection();
    } else {
      handlePriorityDocumentsWindow(true);
    }
  };

  async function getDocumentInfo(docId, docType, format) {
    getDocumentContacts(docId, docType);
    getDocumentFile(docType, format);
  }
  async function getDocumentContacts(docId, docType) {
    console.log("Priority trying to get Doc Info");

    try {
      setLoading(true);
      if (fetchCancelTokensRef.current.contacts) {
        fetchCancelTokensRef.current.contacts.cancel();
      }
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      fetchCancelTokensRef.current.contacts = source;
      const res = await UseFetchGet(
        `/api/priority/getPriorityDocContacts`,
        {
          docId: docId,
          docType: docType,
        },
        null,
        undefined,
        "priority",
        source.token,
      );
      if (res?.status === 200 && res.data?.isDocReady) {
        // setDocInfo(res.data);
        setDocInfo((prevObj) => ({ ...prevObj, ...res.data }));
        const localDocInfo = res.data;
        let allPhoneBookContacts =
          res.data.PhonebookContacts.allPhonebookContacts;
        if (localDocInfo.customerPhone && localDocInfo.customerPhone !== "") {
          const customer = allPhoneBookContacts.find(
            (cust) => String(cust.PHONENUM) === localDocInfo.customerPhone,
          );
          if (customer) {
            allPhoneBookContacts = allPhoneBookContacts
              .filter((cust) => cust.PHONENUM !== customer?.PHONENUM)
              .unshift(customer);
          }
        }

        setVisiblePhoneBookContacts({
          allPhonebookContacts: allPhoneBookContacts,
          customerPhonebookContacts:
            res.data.PhonebookContacts.customerPhonebookContacts,
        });
        setProcedureObject(res.data.procedureObject);
        const formatsList = res.data.procedureObject
          ? res.data.procedureObject.formats.map((format) => {
              return {
                ...format,
                label: format.title,
                value: format.format,
              };
            })
          : [];

        setSendDocFormatOptions(formatsList);
        if (
          !res.data.PhonebookContacts.customerPhonebookContacts?.length ||
          !res.data.PhonebookContacts.customerPhonebookContacts?.[0]?.PHONE
        ) {
          handleSendDocOptions("target", "phoneBookCustomer");
        }
        handleSendDocOptions("format", formatsList[0].value);
        handlePhoneBookSelectedContact("all", allPhoneBookContacts[0]);

        setTimeout(() => sendBtnRef.current?.focus(), 300);

        console.log("Priority Get Doc Info: ", res.data);
      } else {
        setDocInfo(res?.code === "ERR_CANCELED" ? null : false);
        console.log(
          "Priority getting doc info result error (status or isDocReady param) ",
          res,
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setDocInfo(false);
      console.log("Priority getting doc info error:\n", error);
      fetchCancelTokensRef.current.contacts = null;
    }
  }
  async function getDocumentFile(docType, format) {
    console.log("Priority trying to get Doc File");
    setIsDocFileLoading(true);
    try {
      if (fetchCancelTokensRef.current.file) {
        fetchCancelTokensRef.current.file.cancel();
      }
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      fetchCancelTokensRef.current.file = source;

      const docInfoRes = await UseFetchGet(
        `/api/priority/getPriorityDoc`,
        {
          docId: priorityDocumentIdRef.current,
          docType: docType,
          format: format
            ? format
            : selectedDocTypeRef.current.formats[0].format,
        },
        null,
        undefined,
        "priority",
        source.token,
      );

      if (docInfoRes?.status === 200) {
        if (docInfoRes.data?.isDocReady && docInfoRef.current !== false) {
          setDocInfo({
            ...docInfoRef.current,
            previewImageLink: docInfoRes.data.previewImageLink,
          });
          // docInfoRef.current.previewImageLink =
          //   docInfoRes.data.previewImageLink;
        } else if (docInfoRes.data.errorMessage) {
          enqueueSnackbar(docInfoRes.data.errorMessage, {
            variant: "error",
          });
        }
        console.log("Priority Get Doc File: ", docInfoRes.data);
        return true;
      } else {
        if (docInfoRes?.code !== "ERR_CANCELED")
          handleSetNotif(
            localizations["he"].priority.notifications.documentLoadError,
            "error",
          );
        console.log("Priority Get Doc File status error: ", docInfoRes);
        return false;
      }
    } catch (error) {
      console.log("Priority Get Doc File error: ", error);
      handleSetNotif(
        localizations["he"].priority.notifications.documentLoadError,
        "error",
      );
      return false;
    } finally {
      setIsDocFileLoading(false);
      fetchCancelTokensRef.current.file = null;
    }
  }
  async function sendTemplateMsgWithDoc(defaultSend = false) {
    if (!defaultTemplate)
      return enqueueSnackbar(localization.notifications.noDefaultTemplate, {
        variant: "error",
      });
    let actionResult;
    let params;
    const currentDocId = priorityDocumentIdRef.current;
    let targetPhone;
    let targetName;

    try {
      const components = JSON.parse(defaultTemplate.components);
      if (sendDocOptionsRef.current.target === "invoiceContact") {
        targetPhone = docInfoRef.current?.customerPhone
          ? "972" + docInfoRef.current.customerPhone.slice(1)
          : null;
        targetName = docInfoRef.current.customerName;
      } else if (sendDocOptionsRef.current.target === "phoneBookCustomer") {
        targetPhone =
          "972" +
          phoneBookSelectedContactRef.current.customer.PHONENUM.slice(1);
        targetName = phoneBookSelectedContactRef.current.customer.NAME;
      } else if (sendDocOptionsRef.current.target === "phoneBookAll") {
        targetPhone =
          "972" + phoneBookSelectedContactRef.current.all.PHONENUM.slice(1);
        targetName = phoneBookSelectedContactRef.current.all.NAME;
      } else if (sendDocOptionsRef.current.target === "newContact") {
        if (!phoneRegex.test(contactCreateRef.current.phone))
          return handleSetNotif("phone number is wrong", "error");
        targetPhone = phoneRegex.test(contactCreateRef.current.phone)
          ? "972" + contactCreateRef.current.phone.slice(1)
          : contactCreateRef.current.phone;
        const existingUser = chatUsersGlobalRef.current.notMyChats?.find(
          (user) => user.UserDisplayPhoneNumber === targetPhone,
        );

        if (existingUser) {
          targetName = existingUser.FullName;
        } else {
          const nameWords = contactCreateRef.current.name.split(" ");
          const createUserParams = {
            firstName: nameWords[0],
            lastName: nameWords[1] ? nameWords[1] : "",
            phone: contactCreateRef.current.phone,
            isFireberry: false,
            // phoneType: selectedPhoneNumberType,
          };

          const result = await UseFetchPost(
            "/api/services/CreateChatUser",
            createUserParams,
            "config",
          );
          if (result?.data?.UserId) {
            targetPhone = "972" + contactCreateRef.current.phone.slice(1);
            targetName = contactCreateRef.current.name;
          } else {
            handleSetNotif(
              localizations["he"].priority.notifications.userCreationError,
              "error",
            );
            return false;
          }
        }
      } else if (sendDocOptionsRef.current.target === "test") {
        targetPhone =
          selectedFunnerUserRef.current?.UserDisplayPhoneNumber || null;
        targetName = selectedFunnerUserRef.current?.FullName || null;
      }

      warningNotif(targetName, targetPhone);

      if (defaultTemplate.templateName === "file_message") {
        components.BODY.text = components.BODY.text.replace(
          "{{1}}",
          targetName,
        );
      }

      components.HEADER.text =
        docInfoRef.current.previewImageLink.pdfLinkString;

      const frontTemplateObj = JSON.parse(defaultTemplate.frontTemplateObj);
      frontTemplateObj.to = targetPhone;
      const headerComp = frontTemplateObj.template.components.find(
        (comp) => comp.type === "HEADER",
      );
      headerComp.parameters[0].document = {
        link: docInfoRef.current.previewImageLink.pdfLinkString,
        filename: `${currentDocId}.pdf`,
      };
      if (defaultTemplate.templateName === "file_message") {
        const bodyComp = frontTemplateObj.template.components.find(
          (comp) => comp.type === "BODY",
        );
        bodyComp.parameters[0].text = targetName;
      }

      params = {
        ...defaultTemplate,
        to: targetPhone,
        components: JSON.stringify(components),
        frontTemplateObj: JSON.stringify(frontTemplateObj),
        userglobalid: globalID,
      };

      const noWhatsappErr = "No phone number WhatsApp account";

      const response = await UseFetchPost(
        "/api/whatsapp/SendWhatsappTemplateMessage",
        params,
        "messages",
      );
      if (response.data.result) {
        successNotif(targetName, targetPhone);
        actionResult = true;
        // throw new Error("Error: " + response.data.message);
      } else {
        if (response.data.message === noWhatsappErr) {
          errorNotif(
            targetName,
            targetPhone,
            localization.notifications.error.errorReason.noWhatsappNumber,
            true,
          );
        } else errorNotif(targetName, targetPhone, response.data.message, true);
        actionResult = false;
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      actionResult = false;
      errorNotif(
        targetName,
        targetPhone,
        localization.notifications.error.errorReason.sendError,
        true,
      );
    }
    function warningNotif(name, phone) {
      enqueueSnackbar(
        <div style={{ textAlign: "right" }}>
          <div style={{ fontSize: "1.15em" }}>
            {localization.notifications.warning.title}
          </div>
          <div>
            {localization.notifications.warning.docIdText} {currentDocId}
          </div>
          <div>
            {localization.notifications.warning.templateNameText}{" "}
            {defaultTemplate.templateName}
          </div>
          <div>
            {localization.notifications.warning.phoneNumText}{" "}
            {`${name} (${
              phoneGlobalRegex.test(phone) ? "0" + phone.slice(3) : phone
            })`}
          </div>
        </div>,
        {
          variant: "info",
          hideIconVariant: true,
          autoHideDuration: 7000,
        },
      );
    }
    function successNotif(name, phone) {
      enqueueSnackbar(
        <div style={{ textAlign: "right" }}>
          <div style={{ fontSize: "1.15em" }}>
            {/* {selectedDocType?.title} {currentDocId}{" "} */}
            {procedureObject?.title} {currentDocId}{" "}
            {localization.notifications.success.title}
          </div>
          <div>
            {localization.notifications.success.docIdText} {currentDocId}
          </div>
          <div>
            {localization.notifications.success.templateNameText}{" "}
            {defaultTemplate.templateName}
          </div>
          <div>
            {localization.notifications.warning.phoneNumText}{" "}
            {`${name} (${
              phoneGlobalRegex.test(phone) ? "0" + phone.slice(3) : phone
            })`}
          </div>
        </div>,
        {
          variant: "success",
          hideIconVariant: true,
          autoHideDuration: 7000,
        },
      );
    }
    function errorNotif(name, phone, errorMessage, infinite = false) {
      enqueueSnackbar(
        <div style={{ textAlign: "right" }}>
          <div style={{ fontSize: "1.15em" }}>
            {localization.notifications.error.title.beforeText}{" "}
            {/* {selectedDocType?.title}{" "} */}
            {procedureObject?.title}{" "}
            {localization.notifications.error.title.afterText}
          </div>
          <div>
            {localization.notifications.error.docIdText} {currentDocId}
          </div>
          <div>
            {localization.notifications.warning.phoneNumText}{" "}
            {`${name} (${
              phoneGlobalRegex.test(phone) ? "0" + phone.slice(3) : phone
            })`}
          </div>
          <div>{errorMessage}</div>
          <Box>
            <Button
              variant="outlined"
              size="small"
              color="customWhite"
              onClick={() => handlePriorityDocumentsWindow(true)}
            >
              {localization.notifications.error.tryAgain}
            </Button>
          </Box>
        </div>,
        {
          variant: "error",
          hideIconVariant: true,
          autoHideDuration: infinite ? null : 7000,
        },
      );
    }
    return actionResult;
  }
  async function sendSelectedTemplateMsgWithDoc() {
    let actionResult;
    let params = { ...selectedTemplateRef.current };
    let currentDocId = priorityDocumentIdRef.current;

    // const getDocFileRes = await getDocumentFile();
    let targetPhone;
    let targetName;

    try {
      if (sendDocOptions.target === "invoiceContact") {
        params.to = "972" + docInfoRef.current.customerPhone.slice(1);
        const parsedFrontObj = JSON.parse(params.frontTemplateObj);
        parsedFrontObj.to = "972" + docInfoRef.current.customerPhone.slice(1);
        params.frontTemplateObj = JSON.stringify(parsedFrontObj);
        targetPhone = docInfoRef.current?.customerPhone
          ? "972" + docInfoRef.current.customerPhone.slice(1)
          : null;
        targetName = docInfoRef.current.customerName;
      } else if (sendDocOptions.target === "phoneBookCustomer") {
        params.to =
          "972" +
          phoneBookSelectedContactRef.current.customer.PHONENUM.slice(1);
        const parsedFrontObj = JSON.parse(params.frontTemplateObj);
        parsedFrontObj.to =
          "972" +
          phoneBookSelectedContactRef.current.customer.PHONENUM.slice(1);
        params.frontTemplateObj = JSON.stringify(parsedFrontObj);
        targetPhone =
          "972" +
          phoneBookSelectedContactRef.current.customer.PHONENUM.slice(1);
        targetName = phoneBookSelectedContactRef.current.customer.NAME;
      } else if (sendDocOptions.target === "phoneBookAll") {
        params.to =
          "972" + phoneBookSelectedContactRef.current.all.PHONENUM.slice(1);
        const parsedFrontObj = JSON.parse(params.frontTemplateObj);
        parsedFrontObj.to =
          "972" + phoneBookSelectedContactRef.current.all.PHONENUM.slice(1);
        params.frontTemplateObj = JSON.stringify(parsedFrontObj);

        targetPhone =
          "972" + phoneBookSelectedContactRef.current.all.PHONENUM.slice(1);
        targetName = phoneBookSelectedContactRef.current.all.NAME;
      } else if (sendDocOptions.target === "newContact") {
        targetPhone = phoneRegex.test(contactCreateRef.current.phone)
          ? "972" + contactCreateRef.current.phone.slice(1)
          : contactCreateRef.current.phone;
        const existingUser = chatUsersGlobalRef.current.notMyChats?.find(
          (user) => user.UserDisplayPhoneNumber === targetPhone,
        );
        if (existingUser) {
          params.to = targetPhone;
          const parsedFrontObj = JSON.parse(params.frontTemplateObj);
          parsedFrontObj.to = targetPhone;
          params.frontTemplateObj = JSON.stringify(parsedFrontObj);
          targetName = existingUser.FullName;
        } else {
          const nameWords = contactCreateRef.current.name.split(" ");
          const createUserParams = {
            firstName: nameWords[0],
            lastName: nameWords[1] ? nameWords[1] : "",
            phone: contactCreateRef.current.phone,
            isFireberry: false,
            // phoneType: selectedPhoneNumberType,
          };

          const result = await UseFetchPost(
            "/api/services/CreateChatUser",
            createUserParams,
            "config",
          );
          if (result?.data?.UserId) {
            params.to = "972" + contactCreateRef.current.phone.slice(1);
            const parsedFrontObj = JSON.parse(params.frontTemplateObj);
            parsedFrontObj.to = "972" + contactCreateRef.current.phone.slice(1);
            params.frontTemplateObj = JSON.stringify(parsedFrontObj);
            targetPhone = "972" + contactCreateRef.current.phone.slice(1);
            targetName = contactCreateRef.current.name;
          } else {
            handleSetNotif(
              localizations["he"].priority.notifications.userCreationError,
              "error",
            );
            return false;
          }
        }
      } else if (sendDocOptions.target === "test") {
        params.to =
          selectedFunnerUserRef.current?.UserDisplayPhoneNumber || null;
        const parsedFrontObj = JSON.parse(params.frontTemplateObj);
        parsedFrontObj.to =
          selectedFunnerUserRef.current?.UserDisplayPhoneNumber || null;
        params.frontTemplateObj = JSON.stringify(parsedFrontObj);

        targetPhone =
          selectedFunnerUserRef.current?.UserDisplayPhoneNumber || null;
        targetName = selectedFunnerUserRef.current?.FullName || null;
      }

      warningNotif(targetName, targetPhone);

      if (!docInfo?.previewImageLink?.pdfLinkString) {
        errorNotif(
          targetName,
          targetPhone,
          localization.notifications.error.errorReason.sendError,
          false,
        );
        return false;
      }

      if (!params.to || params.to === "" || params.to === "972") {
        handleSetNotif("Phone number is required", "error");
        return "noPhone";
      }

      const noWhatsappErr = "No phone number WhatsApp account";
      // setLoading(true);

      const response = await UseFetchPost(
        "/api/whatsapp/SendWhatsappTemplateMessage",
        params,
        "messages",
      );
      if (response.data.result) {
        successNotif(targetName, targetPhone);
        actionResult = true;
        // throw new Error("Error: " + response.data.message);
      } else {
        if (response.data.message === noWhatsappErr) {
          errorNotif(
            targetName,
            targetPhone,
            localization.notifications.error.errorReason.noWhatsappNumber,
            true,
          );
        } else errorNotif(targetName, targetPhone, response.data.message, true);
        actionResult = false;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      actionResult = false;
      errorNotif(
        targetName,
        targetPhone,
        localization.notifications.error.errorReason.sendError,
        true,
      );
    }

    function warningNotif(name, phone) {
      enqueueSnackbar(
        <div style={{ textAlign: "right" }}>
          <div style={{ fontSize: "1.15em" }}>
            {localization.notifications.warning.title}
          </div>
          <div>
            {localization.notifications.warning.docIdText} {currentDocId}
          </div>
          <div>
            {localization.notifications.warning.templateNameText}{" "}
            {params.templateName}
          </div>
          <div>
            {localization.notifications.warning.phoneNumText}{" "}
            {`${name} (${
              phoneGlobalRegex.test(phone) ? "0" + phone.slice(3) : phone
            })`}
          </div>
        </div>,
        {
          variant: "info",
          hideIconVariant: true,
          autoHideDuration: 7000,
        },
      );
    }
    function successNotif(name, phone) {
      enqueueSnackbar(
        <div style={{ textAlign: "right" }}>
          <div style={{ fontSize: "1.15em" }}>
            {/* {selectedDocType?.title} {currentDocId}{" "} */}
            {procedureObject?.title} {currentDocId}{" "}
            {localization.notifications.success.title}
          </div>
          <div>
            {localization.notifications.success.docIdText} {currentDocId}
          </div>
          <div>
            {localization.notifications.success.templateNameText}{" "}
            {params.templateName}
          </div>
          <div>
            {localization.notifications.warning.phoneNumText}{" "}
            {`${name} (${
              phoneGlobalRegex.test(phone) ? "0" + phone.slice(3) : phone
            })`}
          </div>
        </div>,
        {
          variant: "success",
          hideIconVariant: true,
          autoHideDuration: 7000,
        },
      );
    }
    function errorNotif(name, phone, errorMessage, infinite = false) {
      enqueueSnackbar(
        <div style={{ textAlign: "right" }}>
          <div style={{ fontSize: "1.15em" }}>
            {localization.notifications.error.title.beforeText}{" "}
            {/* {selectedDocType?.title}{" "} */}
            {procedureObject?.title}{" "}
            {localization.notifications.error.title.afterText}
          </div>
          <div>
            {localization.notifications.error.docIdText} {currentDocId}
          </div>
          <div>
            {localization.notifications.warning.phoneNumText}{" "}
            {`${name} (${
              phoneGlobalRegex.test(phone) ? "0" + phone.slice(3) : phone
            })`}
          </div>
          <div>{errorMessage}</div>
          <Box>
            <Button
              variant="outlined"
              size="small"
              color="customWhite"
              onClick={() => handlePriorityDocumentsWindow(true)}
            >
              {localization.notifications.error.tryAgain}
            </Button>
          </Box>
        </div>,
        {
          variant: "error",
          hideIconVariant: true,
          autoHideDuration: infinite ? null : 7000,
        },
      );
    }
    return actionResult;
  }

  const submitFormOnEnter = (e) => {
    const focusableElements =
      dialogRef.current?.querySelectorAll(
        '.MuiDialog-container [tabindex]:not([tabindex="-1"])',
      ) || [];
    const focusArray = Array.from(focusableElements).filter(
      (item) => !item.disabled,
    );
    const activeIndex = focusArray.indexOf(document.activeElement);
    if (
      (e.keyCode === 13 || e.code === 13) &&
      isPriorityDocumentWindowRef.current &&
      docInfoRef.current &&
      activeIndex === -1
    ) {
      if (showDocPreviewRef.current) {
        handleSendInPreview();
      } else if (showTemplateChooseRef.current) {
        if (selectedTemplateRef.current && !isDocFileLoadingRef.current)
          handleSendInChooseTemplate();
      } else {
        handleDocAction();
      }
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", submitFormOnEnter);

    return () => document.removeEventListener("keydown", submitFormOnEnter);
  }, []);

  useEffect(() => {
    if (isPriorityDocumentWindow) {
      // clearAllSelection();
      // setDocInfo(null);
      if (!docInfoRef.current) onDocBtnClick(priorityDocumentIdRef.current);
    } else {
      setTimeout(() => {
        // clearAllSelection();
        // setDocInfo(null);
      }, 500);
    }

    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
        const focusableElements = dialogRef.current.querySelectorAll(
          '.MuiDialog-container [tabindex]:not([tabindex="-1"])',
        );
        const focusArray = Array.from(focusableElements).filter(
          (item) => !item.disabled,
        );
        const index = focusArray.indexOf(document.activeElement);
        let nextIndex = index;

        if (event.key === "ArrowRight") {
          nextIndex =
            index === -1
              ? 0
              : index - 1 >= 0
              ? index - 1
              : focusArray.length - 1;
        } else if (event.key === "ArrowLeft") {
          nextIndex =
            index === -1 ? 0 : index + 1 < focusArray.length ? index + 1 : 0;
        }

        focusArray[nextIndex].focus();
        event.preventDefault();
      }
    };

    if (isPriorityDocumentWindow) {
      dialogRef.current.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (dialogRef.current) {
        dialogRef.current.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [isPriorityDocumentWindow]);

  useEffect(() => {
    docInfoRef.current = docInfo;
    phoneBookSelectedContactRef.current = phoneBookSelectedContact;
    contactCreateRef.current = contactCreate;
    testPhoneRef.current = testPhone;
    selectedFunnerUserRef.current = selectedFunnerUser;
    isPriorityDocumentWindowRef.current = isPriorityDocumentWindow;
    showDocPreviewRef.current = showDocPreview;
    showTemplateChooseRef.current = showTemplateChoose;
    isDocFileLoadingRef.current = isDocFileLoading;
    sendDocOptionsRef.current = sendDocOptions;
  }, [
    docInfo,
    phoneBookSelectedContact,
    contactCreate,
    testPhone,
    selectedFunnerUser,
    isPriorityDocumentWindow,
    showDocPreview,
    showTemplateChoose,
    isDocFileLoading,
    sendDocOptions,
  ]);

  useEffect(() => {
    console.log("docInfo: ", docInfo);
  }, [docInfo]);

  useEffect(() => {
    const filteredTemplates = templatesList.templates.filter(
      (template) =>
        template.components.find((comp) => comp.type === "HEADER")?.format ===
        "DOCUMENT",
    );
    setTemplatesWithDocFile([...filteredTemplates]);
  }, [templatesList]);

  // const nameError = false,
  //   phoneError = false;
  const nameError =
    (sendDocOptions.target === "invoiceContact" &&
      (!visiblePhoneBookContacts.customerPhonebookContacts[0]?.NAME ||
        visiblePhoneBookContacts.customerPhonebookContacts[0]?.NAME === "")) ||
    (sendDocOptions.target === "phoneBookCustomer" &&
      !phoneBookSelectedContact?.customer?.NAME) ||
    (sendDocOptions.target === "phoneBookAll" &&
      !phoneBookSelectedContact?.all?.NAME) ||
    (sendDocOptions.target === "newContact" &&
      (!contactCreate.name || contactCreate.name === "")) ||
    (sendDocOptions.target === "test" && !selectedFunnerUser);
  const phoneError =
    (sendDocOptions.target === "invoiceContact" &&
      !visiblePhoneBookContacts.customerPhonebookContacts[0]?.PHONE) ||
    (sendDocOptions.target === "phoneBookCustomer" &&
      !phoneBookSelectedContact?.customer?.PHONENUM) ||
    (sendDocOptions.target === "phoneBookAll" &&
      !phoneBookSelectedContact?.all?.PHONENUM) ||
    (sendDocOptions.target === "newContact" &&
      (!contactCreate.phone || contactCreate.phone === "")) ||
    (sendDocOptions.target === "test" && !selectedFunnerUser);

  return (
    <Fragment>
      <Dialog
        open={isPriorityDocumentWindow}
        onClose={() => closePopup()}
        maxWidth="md"
        PaperProps={{
          sx: {
            maxWidth: 815,
          },
        }}
        transitionDuration={330}
        ref={dialogRef}
        fullWidth={true}
        className="priority-documents-popup priority-documents-popup--no-style"
        disablePortal={true}
      >
        <DialogContent
          sx={{
            px: 5,
            py: 8.75,
            minHeight: 624,
          }}
        >
          {/* <div
      className={`priority-documents-popup priority-documents-popup--anim root-whatsapp ${
        isPriorityDocumentWindow ? "visible" : ""
      }`}
      onClick={closePopupBackground}
    >
      <div className="priority-documents-popup__wrapper">
        <div
          className="priority-documents-popup__content"
          style={{
            opacity: showDocPreview || showTemplateChoose ? 0 : 1,
            visibility:
              showDocPreview || showTemplateChoose ? "hidden" : "visible",
          }}
        > */}
          <div
            className="priority-documents-popup__close funner-icon"
            onClick={closePopup}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
            </svg>
          </div>
          <div className="priority-documents-popup__header">
            <div
              className="funner-icon"
              style={{ width: 30, height: 30, flex: "0 0 30px" }}
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAYhJREFUSA3tlDtLA1EQhXdF0cJHZRNLK0URIvZBsbA2XWztLOwt8xtstfEPiL3a2SkBwULFShu7BASr9TvhTri5+8gVwSoDJzN3cuZ1d3eSZCwjbiAd8X+SZdkcnDUwXcKt4X8Dj2ma9ko4xW6SN0EPxIh4zTBT6QSQ1fkHeAJtUNTdPv4jcAbWwQqo+ZNM4igTXcssaBNwVUSiiQ3n/0KLswUUdwf6UlXA7lyj12HPuxhTXQwllmgKk28zpKsKGG8J48YOgT5w52N0B3SZ9t7nxBR4J6AFFv1A7E+gZyTpkPi2bwU/MQU0wUUQZ0ebwM45HVMgZoJcYnPEFPiXCTbpqOgtCn3W+EBPDCzPcB/ZqnPpXfcTTXHWVPoAfy8kj10PL3D3gKRRVmnoGUDUejgHth7UuR6yiaY6BddgG+jqKmWoAMzK9UADluwVQwV2nGPo6zWSdFjA1sNyydiW8NAlaaCfwYM7VyuS1kGMnEDaBS0wU5U1t64JKFpsfo4F1sKl7xjbf7qBH7S/56VIJxNkAAAAAElFTkSuQmCC"
                alt=""
              />
            </div>
            {/* {localization.header.title}{" "} */}
            {/* {selectedDocType?.title} {priorityDocumentId} */}
            {procedureObject?.title} {priorityDocumentId}
          </div>

          <div className="priority-documents-popup__body">
            {loading && (
              <div
                className="whatsapp-loader loader active"
                style={{ marginBottom: 20 }}
              >
                <div className="loader__element"></div>
              </div>
            )}
            {docInfo ? (
              <div className="priority-documents-popup__doc-info">
                <div className="priority-documents-popup__format">
                  <div
                    className="priority-documents-popup__title"
                    style={{ marginBottom: 30 }}
                  >
                    {localization.docInfo.format.title}
                  </div>
                  <ReactSelect
                    tabIndex={0}
                    options={sendDocFormatOptions}
                    isClearable={false}
                    // isRtl={true}
                    // hideSelectedOptions={true}
                    placeholder={localization.docInfo.format.placeholder}
                    value={
                      sendDocFormatOptions.some(
                        (option) => option.value === sendDocOptions.format,
                      )
                        ? sendDocFormatOptions.find(
                            (option) => option.value === sendDocOptions.format,
                          )
                        : null
                    }
                    onChange={(e) => handleSendDocOptions("format", e.value)}
                    // theme={}
                    components={{
                      DropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        zIndex: 10,
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        borderColor: "#0000003B",
                        borderRadius: "4px",
                        color: "#000000DE",
                        fontSize: "14px",
                        lineHeight: "18px",
                        letterSpacing: "0.15px",
                        cursor: "pointer",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        fontWeight: 500,
                        color: "rgba(0, 0, 0, 0.87)",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        cursor: "pointer",
                        fontWeight: 500,
                        color: "rgba(0, 0, 0, 0.87)",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: "4px",
                      colors: {
                        ...theme.colors,
                        primary25: "rgba(60, 174, 241, 0.25)",
                        primary50: "rgba(60, 174, 241, 0.5)",
                        primary75: "rgba(60, 174, 241, 0.75)",
                        primary: "rgb(60, 174, 241)",
                      },
                    })}
                  />
                </div>

                <div className="priority-documents-popup__target">
                  <div className="priority-documents-popup__target-choose">
                    <div className="priority-documents-popup__target-item-wrapper">
                      <span className="input-title">
                        {localization.docInfo.target.contactTypeSelect.title}
                      </span>
                      <ReactSelect
                        tabIndex={0}
                        // options={sendDocTargetOptions.filter((opt) =>
                        //   showCustomersOnly
                        //     ? opt.value !== "phoneBookAll"
                        //     : opt.value !== "phoneBookCustomer",
                        // )}
                        options={sendDocTargetOptions}
                        isClearable={false}
                        // isRtl={true}
                        // hideSelectedOptions={true}
                        value={sendDocTargetOptions.find(
                          (option) => option.value === sendDocOptions.target,
                        )}
                        onChange={(option) =>
                          handleSendDocOptions("target", option.value)
                        }
                        components={{
                          DropdownIndicator,
                          IndicatorSeparator: () => null,
                        }}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor: "#0000003B",
                            borderRadius: "4px",
                            color: "#80939C",
                            fontSize: "14px",
                            lineHeight: "18px",
                            letterSpacing: "0.15px",
                            cursor: "pointer",
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            color: state.isDisabled
                              ? "hsl(0, 0%, 80%)"
                              : "#80939C",
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            cursor: "pointer",
                            color: state.isDisabled
                              ? "hsl(0, 0%, 80%)"
                              : "#80939C",
                          }),
                          menuList: (provided, state) => ({
                            ...provided,
                            maxHeight: 250,
                          }),
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: "4px",
                          colors: {
                            ...theme.colors,
                            primary25: "rgba(60, 174, 241, 0.25)",
                            primary50: "rgba(60, 174, 241, 0.5)",
                            primary75: "rgba(60, 174, 241, 0.75)",
                            primary: "rgb(60, 174, 241)",
                          },
                        })}
                      />
                    </div>
                    {sendDocOptions.target === "invoiceContact" && (
                      <>
                        <div className="priority-documents-popup__target-item-wrapper">
                          <span className="input-title">
                            {localization.docInfo.target.invoiceContact.name
                              .title + procedureObject?.docTitle}
                          </span>
                          <ReactSelect
                            tabIndex={0}
                            options={[]}
                            isDisabled={true}
                            isClearable={false}
                            // isRtl={true}
                            // hideSelectedOptions={true}
                            value={{
                              // label: docInfo.customerName,
                              // value: docInfo.customerName,
                              label:
                                visiblePhoneBookContacts
                                  .customerPhonebookContacts[0]?.NAME,
                              value:
                                visiblePhoneBookContacts
                                  .customerPhonebookContacts[0]?.NAME,
                            }}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: "#0000003B",
                                borderRadius: "4px",
                                color: "#80939C",
                                backgroundColor: "#fff",
                                fontSize: "14px",
                                lineHeight: "18px",
                                letterSpacing: "0.15px",
                                cursor: "pointer",
                              }),
                              singleValue: (provided, state) => ({
                                ...provided,
                                color: "#80939C",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                cursor: "pointer",
                                color: "#80939C",
                                wordBreak: "break-all",
                              }),
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: "4px",
                              colors: {
                                ...theme.colors,
                                primary25: "rgba(60, 174, 241, 0.25)",
                                primary50: "rgba(60, 174, 241, 0.5)",
                                primary75: "rgba(60, 174, 241, 0.75)",
                                primary: "rgb(60, 174, 241)",
                              },
                            })}
                          />
                        </div>
                        <div className="priority-documents-popup__target-item-wrapper">
                          <span className="input-title">
                            {
                              localization.docInfo.target.invoiceContact.phone
                                .title
                            }
                          </span>
                          <ReactSelect
                            tabIndex={0}
                            options={[]}
                            isDisabled={true}
                            isClearable={false}
                            // isRtl={true}
                            // hideSelectedOptions={true}
                            value={{
                              // label: docInfo.customerPhone,
                              // value: docInfo.customerPhone,
                              label: !String(
                                visiblePhoneBookContacts
                                  .customerPhonebookContacts[0]?.PHONE,
                              ).startsWith("0")
                                ? "0" +
                                  visiblePhoneBookContacts
                                    .customerPhonebookContacts[0]?.PHONE
                                : visiblePhoneBookContacts
                                    .customerPhonebookContacts[0]?.PHONE,
                              value: !String(
                                visiblePhoneBookContacts
                                  .customerPhonebookContacts[0]?.PHONE,
                              ).startsWith("0")
                                ? "0" +
                                  visiblePhoneBookContacts
                                    .customerPhonebookContacts[0]?.PHONE
                                : visiblePhoneBookContacts
                                    .customerPhonebookContacts[0]?.PHONE,
                            }}
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: "#0000003B",
                                borderRadius: "4px",
                                color: "#80939C",
                                backgroundColor: "#fff",
                                fontSize: "14px",
                                lineHeight: "18px",
                                letterSpacing: "0.15px",
                                cursor: "pointer",
                              }),
                              singleValue: (provided, state) => ({
                                ...provided,
                                color: "#80939C",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                cursor: "pointer",
                                color: "#80939C",
                              }),
                            }}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: "4px",
                              colors: {
                                ...theme.colors,
                                primary25: "rgba(60, 174, 241, 0.25)",
                                primary50: "rgba(60, 174, 241, 0.5)",
                                primary75: "rgba(60, 174, 241, 0.75)",
                                primary: "rgb(60, 174, 241)",
                              },
                            })}
                          />
                        </div>
                      </>
                    )}
                    {sendDocOptions.target === "phoneBookCustomer" && (
                      <>
                        {visiblePhoneBookContacts?.customerPhonebookContacts
                          ?.length > 0 ? (
                          <>
                            <div className="priority-documents-popup__target-item-wrapper">
                              <span className="input-title">
                                {localization.docInfo.target.phoneBookCustomer
                                  .name.title +
                                  " ב" +
                                  procedureObject?.docTitle}
                              </span>
                              <ReactSelect
                                tabIndex={0}
                                options={visiblePhoneBookContacts.customerPhonebookContacts.map(
                                  (contact) => {
                                    return {
                                      ...contact,
                                      value: contact,
                                      label: contact.NAME,
                                    };
                                  },
                                )}
                                isClearable={true}
                                isSearchable={true}
                                // isRtl={true}
                                placeholder={
                                  localization.docInfo.target.phoneBookCustomer
                                    .name.placeholder
                                }
                                // hideSelectedOptions={true}
                                value={
                                  phoneBookSelectedContact?.customer
                                    ? {
                                        label:
                                          phoneBookSelectedContact.customer
                                            .NAME,
                                        value:
                                          phoneBookSelectedContact.customer,
                                      }
                                    : null
                                }
                                onChange={(option) =>
                                  handlePhoneBookSelectedContact(
                                    "customer",
                                    option ? option.value : null,
                                  )
                                }
                                components={{
                                  DropdownIndicator,
                                  IndicatorSeparator: () => null,
                                }}
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    borderColor: "#0000003B",
                                    borderRadius: "4px",
                                    color: "#80939C",
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    letterSpacing: "0.15px",
                                    cursor: "pointer",
                                  }),
                                  singleValue: (provided, state) => ({
                                    ...provided,
                                    color: "#80939C",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    cursor: "pointer",
                                    color: "#80939C",
                                    wordBreak: "break-all",
                                  }),
                                }}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "4px",
                                  colors: {
                                    ...theme.colors,
                                    primary25: "rgba(60, 174, 241, 0.25)",
                                    primary50: "rgba(60, 174, 241, 0.5)",
                                    primary75: "rgba(60, 174, 241, 0.75)",
                                    primary: "rgb(60, 174, 241)",
                                  },
                                })}
                              />
                            </div>
                            <div className="priority-documents-popup__target-item-wrapper">
                              <span className="input-title">
                                {
                                  localization.docInfo.target.phoneBookCustomer
                                    .phone.title
                                }
                              </span>
                              <ReactSelect
                                tabIndex={0}
                                options={visiblePhoneBookContacts.customerPhonebookContacts.map(
                                  (contact) => {
                                    return {
                                      ...contact,
                                      value: contact,
                                      label: contact.PHONENUM,
                                    };
                                  },
                                )}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={true}
                                // isRtl={true}
                                placeholder={
                                  localization.docInfo.target.phoneBookCustomer
                                    .phone.placeholder
                                }
                                // hideSelectedOptions={true}
                                value={
                                  phoneBookSelectedContact?.customer
                                    ? {
                                        label:
                                          phoneBookSelectedContact.customer
                                            .PHONENUM,
                                        value:
                                          phoneBookSelectedContact.customer,
                                      }
                                    : null
                                }
                                onChange={(option) =>
                                  handlePhoneBookSelectedContact(
                                    "customer",
                                    option ? option.value : null,
                                  )
                                }
                                components={{
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    borderColor: "#0000003B",
                                    borderRadius: "4px",
                                    color: "#80939C",
                                    backgroundColor: "#fff",
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    letterSpacing: "0.15px",
                                    cursor: "pointer",
                                  }),
                                  singleValue: (provided, state) => ({
                                    ...provided,
                                    color: "#80939C",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    cursor: "pointer",
                                    color: "#80939C",
                                  }),
                                }}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "4px",
                                  colors: {
                                    ...theme.colors,
                                    primary25: "rgba(60, 174, 241, 0.25)",
                                    primary50: "rgba(60, 174, 241, 0.5)",
                                    primary75: "rgba(60, 174, 241, 0.75)",
                                    primary: "rgb(60, 174, 241)",
                                  },
                                })}
                              />
                            </div>
                          </>
                        ) : (
                          <div className="priority-documents-popup__target-item-wrapper">
                            <div className="priority-documents-popup__target-text">
                              {
                                localization.docInfo.target.phoneBookCustomer
                                  .noContactsText
                              }
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {sendDocOptions.target === "phoneBookAll" && (
                      <>
                        {visiblePhoneBookContacts?.allPhonebookContacts
                          ?.length > 0 ? (
                          <>
                            <div className="priority-documents-popup__target-item-wrapper">
                              <span className="input-title">
                                {
                                  localization.docInfo.target.phoneBookAll.name
                                    .title
                                }
                              </span>
                              <ReactSelect
                                tabIndex={0}
                                options={visiblePhoneBookContacts?.allPhonebookContacts.map(
                                  (contact) => {
                                    return {
                                      ...contact,
                                      value: contact,
                                      label: contact.NAME,
                                    };
                                  },
                                )}
                                isClearable={true}
                                isSearchable={true}
                                // isRtl={true}
                                placeholder={
                                  localization.docInfo.target.phoneBookAll.name
                                    .placeholder
                                }
                                // hideSelectedOptions={true}
                                value={
                                  phoneBookSelectedContact?.all
                                    ? {
                                        label:
                                          phoneBookSelectedContact.all.NAME,
                                        value: phoneBookSelectedContact.all,
                                      }
                                    : null
                                }
                                onChange={(option) =>
                                  handlePhoneBookSelectedContact(
                                    "all",
                                    option ? option.value : null,
                                  )
                                }
                                components={{
                                  DropdownIndicator,
                                  IndicatorSeparator: () => null,
                                }}
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    borderColor: "#0000003B",
                                    borderRadius: "4px",
                                    color: "#80939C",
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    letterSpacing: "0.15px",
                                    cursor: "pointer",
                                  }),
                                  singleValue: (provided, state) => ({
                                    ...provided,
                                    color: "#80939C",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    cursor: "pointer",
                                    color: "#80939C",
                                    wordBreak: "break-all",
                                  }),
                                }}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "4px",
                                  colors: {
                                    ...theme.colors,
                                    primary25: "rgba(60, 174, 241, 0.25)",
                                    primary50: "rgba(60, 174, 241, 0.5)",
                                    primary75: "rgba(60, 174, 241, 0.75)",
                                    primary: "rgb(60, 174, 241)",
                                  },
                                })}
                              />
                            </div>
                            <div className="priority-documents-popup__target-item-wrapper">
                              <span className="input-title">
                                {
                                  localization.docInfo.target.phoneBookAll.phone
                                    .title
                                }
                              </span>
                              <ReactSelect
                                tabIndex={0}
                                options={visiblePhoneBookContacts?.allPhonebookContacts.map(
                                  (contact) => {
                                    return {
                                      ...contact,
                                      value: contact,
                                      label: contact.PHONENUM,
                                    };
                                  },
                                )}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={true}
                                // isRtl={true}
                                placeholder={
                                  localization.docInfo.target.phoneBookAll.phone
                                    .placeholder
                                }
                                // hideSelectedOptions={true}
                                value={
                                  phoneBookSelectedContact?.all
                                    ? {
                                        label:
                                          phoneBookSelectedContact.all.PHONENUM,
                                        value: phoneBookSelectedContact.all,
                                      }
                                    : null
                                }
                                onChange={(option) =>
                                  handlePhoneBookSelectedContact(
                                    "all",
                                    option ? option.value : null,
                                  )
                                }
                                components={{
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    borderColor: "#0000003B",
                                    borderRadius: "4px",
                                    color: "#80939C",
                                    backgroundColor: "#fff",
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    letterSpacing: "0.15px",
                                    cursor: "pointer",
                                  }),
                                  singleValue: (provided, state) => ({
                                    ...provided,
                                    color: "#80939C",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    cursor: "pointer",
                                    color: "#80939C",
                                  }),
                                }}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "4px",
                                  colors: {
                                    ...theme.colors,
                                    primary25: "rgba(60, 174, 241, 0.25)",
                                    primary50: "rgba(60, 174, 241, 0.5)",
                                    primary75: "rgba(60, 174, 241, 0.75)",
                                    primary: "rgb(60, 174, 241)",
                                  },
                                })}
                              />
                            </div>
                          </>
                        ) : (
                          <div className="priority-documents-popup__target-item-wrapper">
                            <div className="priority-documents-popup__target-text">
                              {
                                localization.docInfo.target.phoneBookAll
                                  .noContactsText
                              }
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {sendDocOptions.target === "newContact" && (
                      <>
                        <div className="priority-documents-popup__target-item-wrapper">
                          <span className="input-title">
                            {
                              localization.docInfo.target.newContact
                                .nameInputTitle
                            }
                          </span>
                          <input
                            tabIndex={0}
                            type="text"
                            value={contactCreate.name}
                            className="form-get-token-popup__input"
                            onChange={(e) =>
                              handleContactCreateParam("name", e.target.value)
                            }
                          />
                        </div>
                        <div className="priority-documents-popup__target-item-wrapper">
                          <span className="input-title">
                            {
                              localization.docInfo.target.newContact
                                .phoneInputTitle
                            }
                          </span>
                          <input
                            tabIndex={0}
                            type="text"
                            value={contactCreate.phone}
                            className="form-get-token-popup__input"
                            onChange={(e) =>
                              handleContactCreateParam("phone", e.target.value)
                            }
                          />
                        </div>
                      </>
                    )}
                    {sendDocOptions.target === "test" && (
                      <>
                        {chatUsersGlobal?.notMyChats?.length > 0 && (
                          <>
                            <div className="priority-documents-popup__target-item-wrapper">
                              <span className="input-title">
                                {localization.docInfo.target.test.name.title}
                              </span>
                              <ReactSelect
                                tabIndex={0}
                                options={chatUsersGlobal.notMyChats.map(
                                  (user) => {
                                    return {
                                      ...user,
                                      value: user,
                                      label: user.FullName,
                                    };
                                  },
                                )}
                                isClearable={true}
                                isSearchable={true}
                                // isRtl={true}
                                placeholder={
                                  localization.docInfo.target.test.name
                                    .placeholder
                                }
                                // hideSelectedOptions={true}
                                value={
                                  selectedFunnerUser
                                    ? {
                                        label: selectedFunnerUser.FullName,
                                        value: selectedFunnerUser,
                                      }
                                    : null
                                }
                                onChange={(option) =>
                                  setSelectedFunnerUser(
                                    option ? option.value : null,
                                  )
                                }
                                components={{
                                  DropdownIndicator,
                                  IndicatorSeparator: () => null,
                                }}
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    borderColor: "#0000003B",
                                    borderRadius: "4px",
                                    color: "#80939C",
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    letterSpacing: "0.15px",
                                    cursor: "pointer",
                                  }),
                                  singleValue: (provided, state) => ({
                                    ...provided,
                                    color: "#80939C",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    cursor: "pointer",
                                    color: "#80939C",
                                  }),
                                }}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "4px",
                                  colors: {
                                    ...theme.colors,
                                    primary25: "rgba(60, 174, 241, 0.25)",
                                    primary50: "rgba(60, 174, 241, 0.5)",
                                    primary75: "rgba(60, 174, 241, 0.75)",
                                    primary: "rgb(60, 174, 241)",
                                  },
                                })}
                              />
                            </div>
                            <div className="priority-documents-popup__target-item-wrapper">
                              <span className="input-title">
                                {localization.docInfo.target.test.phone.title}
                              </span>
                              <ReactSelect
                                tabIndex={0}
                                options={chatUsersGlobal.notMyChats.map(
                                  (user) => {
                                    return {
                                      ...user,
                                      value: user,
                                      label: user.UserDisplayPhoneNumber,
                                    };
                                  },
                                )}
                                isClearable={true}
                                isSearchable={true}
                                isDisabled={true}
                                // isRtl={true}
                                placeholder={
                                  localization.docInfo.target.test.phone
                                    .placeholder
                                }
                                // hideSelectedOptions={true}
                                value={
                                  selectedFunnerUser
                                    ? {
                                        label:
                                          selectedFunnerUser.UserDisplayPhoneNumber,
                                        value: selectedFunnerUser,
                                      }
                                    : null
                                }
                                components={{
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                                styles={{
                                  control: (provided, state) => ({
                                    ...provided,
                                    borderColor: "#0000003B",
                                    borderRadius: "4px",
                                    color: "#80939C",
                                    backgroundColor: "#fff",
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    letterSpacing: "0.15px",
                                    cursor: "pointer",
                                  }),
                                  singleValue: (provided, state) => ({
                                    ...provided,
                                    color: "#80939C",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    cursor: "pointer",
                                    color: "#80939C",
                                  }),
                                }}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: "4px",
                                  colors: {
                                    ...theme.colors,
                                    primary25: "rgba(60, 174, 241, 0.25)",
                                    primary50: "rgba(60, 174, 241, 0.5)",
                                    primary75: "rgba(60, 174, 241, 0.75)",
                                    primary: "rgb(60, 174, 241)",
                                  },
                                })}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {(nameError || phoneError) &&
                  sendDocOptions.target !== "newContact" && (
                    <span
                      className="error"
                      style={{
                        fontSize: "0.75em",
                        marginTop: -10,
                        display: "inline-block",
                        marginBottom: 8,
                      }}
                      dangerouslySetInnerHTML={{
                        __html: localization.docInfo.target.error,
                      }}
                    ></span>
                  )}
                {(sendDocOptions.target === "phoneBookCustomer" ||
                  sendDocOptions.target === "phoneBookAll") && (
                  <div
                    className="priority-documents-popup__check"
                    style={{ padding: "15px 0" }}
                  >
                    <div className="funner-priority-checkbox">
                      <input
                        onChange={(e) => {
                          const checked = e.target.checked;

                          handleCustomersOnly(checked);
                        }}
                        name="leave-closed"
                        id="leave-closed"
                        type="checkbox"
                        checked={showCustomersOnly}
                        className="funner-priority-checkbox__input"
                      />
                      <label
                        htmlFor="leave-closed"
                        className={`funner-priority-checkbox__label`}
                        tabIndex={0}
                      >
                        <span className="funner-priority-checkbox__check-icon">
                          <svg
                            width="64px"
                            height="64px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                d="M4 12.6111L8.92308 17.5L20 6.5"
                                stroke="#00adee"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />{" "}
                            </g>
                          </svg>
                        </span>

                        <span className="funner-priority-checkbox__text">
                          {localization.docInfo.onlyCustomersCheck}
                        </span>
                      </label>
                    </div>
                  </div>
                )}

                <div className="priority-documents-popup__buttons-row">
                  <div className="priority-documents-popup__btn-wrapper">
                    <label
                      className={`priority-documents-popup__button ${
                        sendDocOptions.type === "directSend" ? "_checked" : ""
                      }`}
                      tabIndex={0}
                      htmlFor="priority-send-type--directSend"
                      onKeyUp={(e) => {
                        if (e.code === 13 || e.keyCode === 13) {
                          e.target
                            .closest("label")
                            .querySelector("input")
                            .click();
                        }
                      }}
                    >
                      {sendDocOptions.type === "directSend" && (
                        <svg
                          width="64px"
                          height="64px"
                          viewBox="0 0 48 48"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#6FC5BB"
                          style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            width: 16,
                            height: 16,
                          }}
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <g id="SVGRepo_iconCarrier">
                            {"{"}" "{"}"}
                            <title>check-circle-solid</title>
                            {"{"}" "{"}"}
                            <g id="Layer_2" data-name="Layer 2">
                              {"{"}" "{"}"}
                              <g id="invisible_box" data-name="invisible box">
                                {"{"}" "{"}"}
                                <rect width={48} height={48} fill="none" />
                                {"{"}" "{"}"}
                              </g>
                              {"{"}" "{"}"}
                              <g id="icons_Q2" data-name="icons Q2">
                                {"{"}" "{"}"}
                                <path d="M24,2A22,22,0,1,0,46,24,21.9,21.9,0,0,0,24,2ZM35.4,18.4l-14,14a1.9,1.9,0,0,1-2.8,0l-5.9-5.9a2.2,2.2,0,0,1-.4-2.7,2,2,0,0,1,3.1-.2L20,28.2,32.6,15.6a2,2,0,0,1,2.8,2.8Z" />
                                {"{"}" "{"}"}
                              </g>
                              {"{"}" "{"}"}
                            </g>
                            {"{"}" "{"}"}
                          </g>
                        </svg>
                      )}
                      <input
                        type="radio"
                        name="priority-send-type"
                        id="priority-send-type--directSend"
                        value={"directSend"}
                        checked={sendDocOptions.type === "directSend"}
                        onChange={() =>
                          handleSendDocOptions("type", "directSend")
                        }
                      />
                      <div className="priority-documents-popup__icon funner-icon">
                        <svg
                          width={49}
                          height={50}
                          viewBox="0 0 49 50"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M38.8939 10.5243C35.076 6.68592 29.9922 4.58301 24.5818 4.58301C13.4343 4.58301 4.34888 13.6684 4.34888 24.8159C4.34888 28.3888 5.28805 31.8597 7.04388 34.9222L4.18555 45.4163L14.9043 42.5988C17.8647 44.2118 21.1926 45.0693 24.5818 45.0693C35.7293 45.0693 44.8147 35.9838 44.8147 24.8363C44.8147 19.4259 42.7118 14.3422 38.8939 10.5243ZM24.5818 41.6393C21.5601 41.6393 18.5997 40.8226 16.0068 39.2913L15.3943 38.9238L9.0243 40.598L10.7189 34.3913L10.3105 33.7584C8.63638 31.0838 7.73805 27.9805 7.73805 24.8159C7.73805 15.5468 15.2922 7.99259 24.5614 7.99259C29.053 7.99259 33.2793 9.74843 36.4439 12.9334C39.6289 16.1184 41.3643 20.3447 41.3643 24.8363C41.4051 34.1055 33.851 41.6393 24.5818 41.6393ZM33.8101 29.0626C33.2997 28.8176 30.8089 27.5926 30.3597 27.4088C29.8901 27.2455 29.5635 27.1638 29.2164 27.6538C28.8693 28.1643 27.9097 29.3076 27.6239 29.6343C27.338 29.9813 27.0318 30.0222 26.5214 29.7568C26.011 29.5118 24.3776 28.9605 22.4585 27.2455C20.9476 25.898 19.9472 24.2443 19.641 23.7338C19.3551 23.2234 19.6001 22.958 19.8655 22.6926C20.0901 22.468 20.376 22.1005 20.621 21.8147C20.866 21.5288 20.968 21.3043 21.1314 20.9776C21.2947 20.6305 21.213 20.3447 21.0905 20.0997C20.968 19.8547 19.9472 17.3638 19.5389 16.343C19.1305 15.363 18.7018 15.4855 18.3955 15.4651C18.0893 15.4651 17.7626 15.4651 17.4155 15.4651C17.0685 15.4651 16.5376 15.5876 16.068 16.098C15.6189 16.6084 14.3122 17.8334 14.3122 20.3243C14.3122 22.8151 16.1293 25.2243 16.3743 25.5509C16.6193 25.898 19.9472 31.0022 25.0105 33.1868C26.2151 33.7176 27.1543 34.0238 27.8893 34.2484C29.0939 34.6363 30.1964 34.5751 31.0743 34.4526C32.0543 34.3097 34.0755 33.2276 34.4839 32.0434C34.9126 30.8593 34.9126 29.8588 34.7697 29.6343C34.6268 29.4097 34.3205 29.3076 33.8101 29.0626Z"
                            fill="#80939C"
                          />
                        </svg>
                      </div>
                      <div className="priority-documents-popup__button-text">
                        {localization.docInfo.sendOptionsRow.sendDirectTemplate}
                      </div>
                    </label>
                  </div>
                  <div className="priority-documents-popup__btn-wrapper">
                    <label
                      className={`priority-documents-popup__button ${
                        sendDocOptions.type === "showChooseTemplate"
                          ? "_checked"
                          : ""
                      }`}
                      tabIndex={0}
                      htmlFor="priority-send-type--showChooseTemplate"
                      onKeyUp={(e) => {
                        if (e.code === 13 || e.keyCode === 13) {
                          e.target
                            .closest("label")
                            .querySelector("input")
                            .click();
                        }
                      }}
                    >
                      {sendDocOptions.type === "showChooseTemplate" && (
                        <svg
                          width="64px"
                          height="64px"
                          viewBox="0 0 48 48"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#6FC5BB"
                          style={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            width: 20,
                            height: 20,
                          }}
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <g id="SVGRepo_iconCarrier">
                            {"{"}" "{"}"}
                            <title>check-circle-solid</title>
                            {"{"}" "{"}"}
                            <g id="Layer_2" data-name="Layer 2">
                              {"{"}" "{"}"}
                              <g id="invisible_box" data-name="invisible box">
                                {"{"}" "{"}"}
                                <rect width={48} height={48} fill="none" />
                                {"{"}" "{"}"}
                              </g>
                              {"{"}" "{"}"}
                              <g id="icons_Q2" data-name="icons Q2">
                                {"{"}" "{"}"}
                                <path d="M24,2A22,22,0,1,0,46,24,21.9,21.9,0,0,0,24,2ZM35.4,18.4l-14,14a1.9,1.9,0,0,1-2.8,0l-5.9-5.9a2.2,2.2,0,0,1-.4-2.7,2,2,0,0,1,3.1-.2L20,28.2,32.6,15.6a2,2,0,0,1,2.8,2.8Z" />
                                {"{"}" "{"}"}
                              </g>
                              {"{"}" "{"}"}
                            </g>
                            {"{"}" "{"}"}
                          </g>
                        </svg>
                      )}
                      <input
                        type="radio"
                        name="priority-send-type"
                        id="priority-send-type--showChooseTemplate"
                        value={"showChooseTemplate"}
                        checked={sendDocOptions.type === "showChooseTemplate"}
                        onChange={() =>
                          handleSendDocOptions("type", "showChooseTemplate")
                        }
                      />
                      <div className="priority-documents-popup__icon funner-icon">
                        <svg
                          width={63}
                          height={63}
                          viewBox="0 0 63 63"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M52.5 5.25H10.5C7.6125 5.25 5.27625 7.6125 5.27625 10.5L5.25 57.75L15.75 47.25H52.5C55.3875 47.25 57.75 44.8875 57.75 42V10.5C57.75 7.6125 55.3875 5.25 52.5 5.25ZM52.5 42H13.5712L10.5 45.0713V10.5H52.5V42ZM27.5625 36.75H47.25V31.5H32.8125L27.5625 36.75ZM37.695 21.3412C38.22 20.8162 38.22 20.0025 37.695 19.4775L33.0487 14.8313C32.5238 14.3062 31.71 14.3062 31.185 14.8313L15.75 30.2662V36.75H22.2338L37.695 21.3412Z"
                            fill="#80939C"
                          />
                        </svg>
                      </div>
                      <div className="priority-documents-popup__button-text">
                        {localization.docInfo.sendOptionsRow.showPreview}
                      </div>
                    </label>
                  </div>
                  <div className="priority-documents-popup__btn-wrapper">
                    <label
                      className={`priority-documents-popup__button ${
                        sendDocOptions.type === "showPreview" ? "_checked" : ""
                      }`}
                      tabIndex={0}
                      htmlFor="priority-send-type--showPreview"
                      onKeyUp={(e) => {
                        if (e.code === 13 || e.keyCode === 13) {
                          e.target
                            .closest("label")
                            .querySelector("input")
                            .click();
                        }
                      }}
                    >
                      {sendDocOptions.type === "showPreview" && (
                        <svg
                          width="64px"
                          height="64px"
                          viewBox="0 0 48 48"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#6FC5BB"
                          style={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            width: 20,
                            height: 20,
                          }}
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <g id="SVGRepo_iconCarrier">
                            {"{"}" "{"}"}
                            <title>check-circle-solid</title>
                            {"{"}" "{"}"}
                            <g id="Layer_2" data-name="Layer 2">
                              {"{"}" "{"}"}
                              <g id="invisible_box" data-name="invisible box">
                                {"{"}" "{"}"}
                                <rect width={48} height={48} fill="none" />
                                {"{"}" "{"}"}
                              </g>
                              {"{"}" "{"}"}
                              <g id="icons_Q2" data-name="icons Q2">
                                {"{"}" "{"}"}
                                <path d="M24,2A22,22,0,1,0,46,24,21.9,21.9,0,0,0,24,2ZM35.4,18.4l-14,14a1.9,1.9,0,0,1-2.8,0l-5.9-5.9a2.2,2.2,0,0,1-.4-2.7,2,2,0,0,1,3.1-.2L20,28.2,32.6,15.6a2,2,0,0,1,2.8,2.8Z" />
                                {"{"}" "{"}"}
                              </g>
                              {"{"}" "{"}"}
                            </g>
                            {"{"}" "{"}"}
                          </g>
                        </svg>
                      )}
                      <input
                        type="radio"
                        name="priority-send-type"
                        id="priority-send-type--showPreview"
                        value={"showPreview"}
                        checked={sendDocOptions.type === "showPreview"}
                        onChange={() =>
                          handleSendDocOptions("type", "showPreview")
                        }
                      />
                      <div className="priority-documents-popup__icon funner-icon">
                        <svg
                          width={62}
                          height={62}
                          viewBox="0 0 62 62"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M31.0002 16.7917C40.791 16.7917 49.5227 22.2942 53.7852 31C49.5227 39.7058 40.8168 45.2083 31.0002 45.2083C21.1835 45.2083 12.4777 39.7058 8.21516 31C12.4777 22.2942 21.2093 16.7917 31.0002 16.7917ZM31.0002 11.625C18.0835 11.625 7.05266 19.6592 2.5835 31C7.05266 42.3408 18.0835 50.375 31.0002 50.375C43.9168 50.375 54.9477 42.3408 59.4168 31C54.9477 19.6592 43.9168 11.625 31.0002 11.625ZM31.0002 24.5417C34.5652 24.5417 37.4585 27.435 37.4585 31C37.4585 34.565 34.5652 37.4583 31.0002 37.4583C27.4352 37.4583 24.5418 34.565 24.5418 31C24.5418 27.435 27.4352 24.5417 31.0002 24.5417ZM31.0002 19.375C24.5935 19.375 19.3752 24.5933 19.3752 31C19.3752 37.4067 24.5935 42.625 31.0002 42.625C37.4068 42.625 42.6252 37.4067 42.6252 31C42.6252 24.5933 37.4068 19.375 31.0002 19.375Z"
                            fill="#80939C"
                          />
                        </svg>
                      </div>
                      <div className="priority-documents-popup__button-text">
                        {localization.docInfo.sendOptionsRow.chooseTemplate}
                      </div>
                    </label>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: 15,
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    className="funner-priority-btn funner-priority-btn--outline"
                    onClick={closePopup}
                    tabIndex={0}
                  >
                    {localization.docInfo.buttons.close}
                  </button>
                  <button
                    className="funner-priority-btn funner-priority-btn--fill"
                    onClick={handleDocAction}
                    ref={sendBtnRef}
                    tabIndex={0}
                    disabled={nameError || phoneError}
                    // disabled={!sendDocOptions.type || !docInfo.previewImageLink}
                  >
                    {localization.docInfo.buttons.action}
                  </button>
                </div>
              </div>
            ) : docInfo === false ? (
              <div className="priority-documents-popup__text">
                {localization.docNotReady}
              </div>
            ) : (
              <Grid container spacing={3.75}>
                <Grid item xs={12}>
                  <Skeleton width={105} height={24} variant="rounded" />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton width={"100%"} height={38} variant="rounded" />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2.25}>
                    <Grid item xs={4}>
                      <Skeleton width={"100%"} height={38} variant="rounded" />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton width={"100%"} height={38} variant="rounded" />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton width={"100%"} height={38} variant="rounded" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1.25}>
                    <Grid item xs="auto">
                      <Skeleton variant="rounded" width={130} height={124} />
                    </Grid>
                    <Grid item xs="auto">
                      <Skeleton variant="rounded" width={130} height={124} />
                    </Grid>
                    <Grid item xs="auto">
                      <Skeleton variant="rounded" width={130} height={124} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1.875} justifyContent={"flex-end"}>
                    <Grid item xs="auto">
                      <Skeleton variant="rounded" width={120} height={40} />
                    </Grid>
                    <Grid item xs="auto">
                      <Skeleton variant="rounded" width={120} height={40} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
          {/* </div>
      </div> */}
        </DialogContent>
      </Dialog>
      {/* {showDocPreview && (
        <div
          className="priority-documents-popup__preview"
          onClick={closePreviewBackground}
        >
          <div
            className="priority-documents-popup__content"
            style={{
              width: "auto",
              paddingTop: 50,
              paddingBottom: 25,
            }}
          > */}
      <Dialog
        open={showDocPreview}
        onClose={() => closePreview()}
        maxWidth="md"
        PaperProps={{
          sx: {
            height: "92vh",
            width: "65.05vh",
            maxWidth: 815,
            maxHeight: 1152,
          },
        }}
        transitionDuration={330}
        // ref={dialogRef}
        fullWidth={true}
        className="priority-documents-popup priority-documents-popup--no-style"
        disablePortal={true}
      >
        <DialogTitle>
          <Grid container spacing={1.5} justifyContent={"flex-end"}>
            <Grid item xs="auto">
              <IconButton
                // onClick={() => closePreview()}
                href={docInfo?.previewImageLink?.pdfLinkString}
                target="_blank"
              >
                <OpenInNew />
              </IconButton>
            </Grid>
            <Grid item xs="auto">
              <IconButton onClick={() => closePreview()}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div className="priority-documents-popup__body">
            {isDocFileLoading && (
              <div
                className="whatsapp-loader loader active"
                style={{ marginBottom: 20 }}
              >
                <div className="loader__element"></div>
              </div>
            )}

            <div className="priority-documents-popup__images-container">
              {docInfo?.previewImageLink?.pdfList?.map((imageLink, index) => (
                <a
                  key={imageLink}
                  href={imageLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={imageLink} alt="" />
                </a>
              ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: "flex",
              gap: 15,
              justifyContent: "flex-end",
            }}
          >
            <button
              className="funner-priority-btn funner-priority-btn--outline"
              onClick={closePreview}
            >
              {localization.docPreview.buttons.close}
            </button>
            <button
              className="funner-priority-btn funner-priority-btn--fill"
              onClick={handleSendInPreview}
              style={{ margin: 0 }}
            >
              {localization.docPreview.buttons.action}
            </button>
          </div>
        </DialogActions>
      </Dialog>

      {/* </div>
        </div>
      )}  */}
      {/* {showTemplateChoose && (
        <div
          className="priority-documents-popup__preview"
          onClick={closeTemplateChooseBackground}
        >
          <div className="priority-documents-popup__content"> */}
      <Dialog
        open={showTemplateChoose}
        onClose={() => closeTemplateChoose()}
        maxWidth="md"
        PaperProps={{
          sx: {
            maxWidth: 815,
          },
        }}
        transitionDuration={330}
        // ref={dialogRef}
        fullWidth={true}
        className="priority-documents-popup priority-documents-popup--no-style"
        disablePortal={true}
      >
        <DialogTitle>
          <Grid container spacing={1.5} justifyContent={"flex-end"}>
            {/* <Grid item xs="auto">
                <IconButton
                  // onClick={() => closePreview()}
                  href={docInfo?.previewImageLink?.pdfLinkString}
                  target="_blank"
                >
                  <OpenInNew />
                </IconButton>
              </Grid> */}
            <Grid item xs="auto">
              <IconButton onClick={() => closeTemplateChoose()}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div className="priority-documents-popup__body">
            {loading && (
              <div
                className="whatsapp-loader loader active"
                style={{ marginBottom: 20 }}
              >
                <div className="loader__element"></div>
              </div>
            )}

            <TemplatesMenu
              templatesLoading={false}
              templatesList={templateWithDocFile}
              whatsappTemplatesList={templatesList.whatsappTemplates}
              languages={templatesList.languages}
              closeAccordion={() => {}}
              chatUserName={""}
              currentContact={""}
              globalID={globalID}
              handleSetNotif={handleSetNotif}
              setTextareaValue={() => {}}
              textareaRef={null}
              resetTextArea={() => {}}
              templatesWithSend={false}
              noSendHandleFunc={(templatesObj) => {
                setSelectedTemplate(templatesObj.sendObject);
                selectedTemplateRef.current = templatesObj.sendObject;
              }}
              noUpload={true}
              // existingFile={docInfoRef.current.previewImageLink.pdfLinkString}
              existingFile={
                docInfo?.previewImageLink?.pdfLinkString
                  ? docInfo.previewImageLink.pdfLinkString
                  : null
              }
              fetchWhatsappTemplates={fetchWhatsappTemplates}
            />

            <div
              style={{
                display: "flex",
                gap: 15,
                justifyContent: "flex-end",
              }}
            >
              <button
                className="funner-priority-btn funner-priority-btn--outline"
                onClick={closeTemplateChoose}
              >
                {localization.chooseTemplate.buttons.close}
              </button>
              <button
                className="funner-priority-btn funner-priority-btn--fill"
                onClick={handleSendInChooseTemplate}
                disabled={!selectedTemplate || isDocFileLoading}
              >
                {localization.chooseTemplate.buttons.action}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* </div>
        </div>
      )} */}
    </Fragment>

    // </div>
  );
}

export default PriorityDocuments;
