import React, { useEffect, useState } from "react";
import { closeIcon } from "../../../libs/images";
import TextReplyTemplate from "./Templates/TextReplyTemplate";
import TemplateReplyTemplate from "./Templates/TemplateReplyTemplate";
import { IsJsonString } from "../../../libs/helpers";
import AudioReplyTemplate from "./Templates/AudioReplyTemplate";
import VideoReplyTemplate from "./Templates/VideoReplyTemplate";
import ContactsReplyTemplate from "./Templates/ContactsReplyTemplate";
import LocationReplyTemplate from "./Templates/LocationReplyTemplate";
import FileReplyTemplate from "./Templates/FileReplyTemplate";

function SendReplyTemplate({ sendReply, closeHandler }) {
  const [fileType, setFileType] = useState(null);
  const [file, setFile] = useState(null);
  const [reply, setReply] = useState(null);
  const [sentOrReceived, setSentOrReceived] = useState(null);
  const [text, setText] = useState("");
  const [object, setObject] = useState(null);

  useEffect(() => {
    const {
      Reply,
      Type: sentOrRecieved,
      FileType,
      File: file,
      Object: object,
      Message: text,
    } = sendReply.message;

    setFileType(FileType);
    setFile(file);
    setReply(Reply);
    setSentOrReceived(sentOrRecieved);
    setText(text);
    setObject(object);
  }, [sendReply]);

  return (
    <div className="messages__context-reply context-reply-message reactions-popup">
      <div className="context-reply-message__body">
        <button
          type="button"
          className="context-reply-message__close"
          onClick={closeHandler}
        >
          <img src={closeIcon} alt="" />
        </button>
        <div className="message__upload-container message__upload-container_reply">
          {fileType === "text" || fileType === "reply_button" ? (
            <TextReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              text={text}
            />
          ) : fileType === "template" ? (
            <TemplateReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              text={IsJsonString(text) ? JSON.parse(text)["BODY"].text : text}
            />
          ) : fileType === "audio" ? (
            <AudioReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              file={file}
            />
          ) : fileType === "video" ? (
            <VideoReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              file={file}
            />
          ) : fileType === "contacts" ? (
            <ContactsReplyTemplate
              sentOrReceived={sentOrReceived}
              object={object}
            />
          ) : fileType === "location" ? (
            <LocationReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              object={object}
            />
          ) : ["pdf", "docx", "xlsx", "zip", "image"].includes(fileType) ? (
            <FileReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              fileType={fileType}
              file={file}
            />
          ) : (
            <TextReplyTemplate
              sentOrReceived={sentOrReceived}
              userName={sendReply.userName}
              text={text}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SendReplyTemplate;
