import React from "react";
import { arrowDropDown } from "../libs/images";

export default function DropdownIndicator(props) {
  return (
    <img
      style={{
        width: 24,
        height: 24,
        maxWidth: 24,
        maxHeight: 24,
        marginLeft: 12,
      }}
      src={arrowDropDown}
      alt=""
    />
  );
}
