import React, { useState } from "react";
import Header from "../Header";
import applicationConfig from "../../../config";
import { insertLink, mailIcon } from "../../../libs/images";
import { UseFetchPost } from "../../../hooks/fetchFunctions";
import localizations from "../../../localizations.json";

function Logout({
  userData,
  handleLogouStep,
  isPowerLinkPage,
  handleAuthPopupWindow,
  handleSetNotif,
  openChatAuthControl,
  handleToggleChatFromAuth,
  token,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].auth.logout,
  );

  function clearStorage() {
    localStorage.clear();
  }

  async function handleRemoveUser() {
    try {
      const res = UseFetchPost(
        "/api/auth/removeUser",
        undefined,
        null,
        undefined,
        "config",
      );

      if (res?.data?.result) {
        //?
        // handleGetGlobalUserStatus();
        handleSetNotif(
          localizations["he"].auth.notifications.logout.removeUser.success,
          "success",
        );
      }
    } catch (error) {
      console.log("removeUser error: ", error);
    }
  }

  return (
    <div className="get-token-popup__window get-token-popup__window_6">
      <Header
        openChatAuthControl={openChatAuthControl}
        handleToggleChatFromAuth={handleToggleChatFromAuth}
        token={token}
      />

      <div className="get-token-popup__form form-get-token-popup">
        <div className="get-token-popup__wrapper-info company-info">
          <label
            htmlFor="inp-9"
            className="form-get-token-popup__input-wrapper"
          >
            <span className="input-title"></span>
            <input
              type="text"
              id="inp-9"
              defaultValue={userData?.FullName}
              className="form-get-token-popup__input"
              readOnly={true}
            />
          </label>
          <label
            htmlFor="inp-9"
            className="form-get-token-popup__input-wrapper"
          >
            <span className="input-title"></span>
            <input
              type="text"
              id="inp-9"
              defaultValue={userData?.CompanyName}
              className="form-get-token-popup__input"
              readOnly={true}
            />
          </label>
        </div>

        <div className="form-get-token-popup__block form-get-token-popup__block_border-top">
          <button
            onClick={handleLogouStep}
            type="button"
            data-ripple
            className="_whatsapp report-sumbit-btn link"
            style={{ marginBottom: "10px" }}
          >
            {localization.logoutLinkText}
          </button>
          <button
            onClick={(e) => {
              handleRemoveUser();
              handleLogouStep();
              // goToLogin();
              // handleSetOpenLogoutPopup(false);
            }}
            type="submit"
            data-ripple
            className="_whatsapp report-sumbit-btn link"
            style={{ marginBottom: "10px" }}
          >
            {localization.removeUserLinkText}
          </button>

          {isPowerLinkPage && (
            <button
              className="_whatsapp report-sumbit-btn link"
              style={{ marginBottom: "10px" }}
              onClick={() => {
                clearStorage();
                handleAuthPopupWindow("login");
              }}
            >
              {localization.fireberryClearLinkText}
            </button>
          )}
        </div>
      </div>

      <div className="get-token-popup__links-footer">
        <div className="get-token-popup__links-footer-col">
          <p className="get-token-popup__links-footer-text">
            {localizations["he"].auth.footer.version}{" "}
            {applicationConfig.version}
          </p>
          <p className="get-token-popup__links-footer-text">
            {localizations["he"].auth.footer.text}
          </p>
        </div>
        <div className="get-token-popup__links-footer-col">
          <a
            href={`https://${localizations["he"].auth.funnerSupportLink}`}
            className="get-token-popup__link-footer-link get-token-popup__icon-link"
          >
            {localizations["he"].auth.footer.funnerSupportLink}
            <img
              width={24}
              height={24}
              style={{ maxWidth: 24, maxHeight: 24 }}
              src={insertLink}
              alt=""
            />
          </a>
          <a
            href={`mailto:${localizations["he"].auth.funnerSupportEmail}`}
            className="get-token-popup__link-footer-link get-token-popup__icon-link"
          >
            {localizations["he"].auth.footer.funnerSupportEmail}
            <img
              width={24}
              height={24}
              style={{ maxWidth: 24, maxHeight: 24 }}
              src={mailIcon}
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Logout;
