/* eslint-disable no-undef */
import { applicationType } from "../applicationType";

export const numbers = document.querySelectorAll('[data-tid="telephone"]');

const getImagePath = (path) => {
  if (applicationType === "extension") {
    return chrome.runtime.getURL(path);
  } else {
    return path;
  }
};

export const hintLogo = getImagePath("assets/img/hint-logo.png");
export const hintLogoBlue = getImagePath("assets/img/hint-logo-blue.png");
export const funnerLogo = getImagePath("../../../assets/img/Funner-logo.png");
export const funnerLogoRed = getImagePath(
  "../../../assets/img/funner-logo-red.png",
);
export const funnerExtensionLogoLogged = getImagePath(
  "../../../assets/img/faner-logo.png",
);
export const funnerExtensionLogoNotLogged = getImagePath(
  "../../../assets/img/faner-logo-red.png",
);
export const funnerBigLogo = getImagePath("../../../assets/img/logo-big.png");
export const pen = getImagePath("../../../assets/img/pencil-svgrepo-com.svg");
export const penWhite = getImagePath("../../../assets/img/pen-white.svg");
export const fireberryBigLogo = getImagePath(
  "../../../assets/img/FireberryBigLogo.png",
);
export const priorityBigLogo = getImagePath(
  "../../../assets/img/PriorityBigLogo.png",
);
export const priorityLetterLogo = getImagePath(
  "../../../assets/img/PriorityLetterLogo.png",
);
export const priorityDocLogo = getImagePath(
  "../../../assets/img/priorityDoc.png",
);

export const eye = getImagePath("../../../assets/img/eye.svg");
export const funnerLogoTab = getImagePath(
  "../../../assets/img/funner-logo-tab.png",
);
export const uploadImg = getImagePath("../../../assets/img/upload-img.png");
export const profileIMG = getImagePath("../../../assets/img/profile.png");
export const changeImage = getImagePath("../../../assets/img/change-image.svg");
export const videoPlus = getImagePath("../../../assets/img/video-plus.svg");
export const markShield = getImagePath("../../../assets/img/mark-shield.svg");

export const playBtn = getImagePath("../../../assets/img/play-buttton.png");
export const pauseBtn = getImagePath("../../../assets/img/pause.png");
export const exclamationGreen = getImagePath(
  "../../../assets/img/CheckCircleOutlinedGreen.svg",
);
export const exclamationRed = getImagePath(
  "../../../assets/img/exclamationRed.svg",
);
export const activeStatus = getImagePath(
  "../../../assets/img/active-svgrepo-com.svg",
);
export const forwardedLink = getImagePath(
  "../../../assets/img/forward-link.png",
);

export const disabledStatus = getImagePath(
  "../../../assets/img/disabled-svgrepo-com.svg",
);

export const paperPlane = getImagePath("../../../assets/img/paper-plane-1.svg");
export const paperPlaneActive = getImagePath(
  "../../../assets/img/paper-plane-1-green.svg",
);
export const addIconPopover = getImagePath(
  "../../../assets/img/add-icon-popover.png",
);
export const paperPlanePopover = getImagePath(
  "../../../assets/img/paper-plane-popover.png",
);
export const whatsAppIconPopover = getImagePath(
  "../../../assets/img/whatsapp-icon-popover.png",
);
export const uploadIcon = getImagePath("../../../assets/img/upload.svg");
export const resize = getImagePath("../../../assets/img/resize.svg");
export const gear = getImagePath("../../../assets/img/gear.png");
export const curvedArrowLeft = getImagePath(
  "../../../assets/img/curved-arrow-left.svg",
);
export const curvedArrowRight = getImagePath(
  "../../../assets/img/curved-arrow-right.svg",
);
export const userFace = getImagePath("../../../assets/img/faces/user.png");
export const companionFace = getImagePath(
  "../../../assets/img/faces/companion.png",
);
export const contactFace = getImagePath(
  "../../../assets/img/faces/contact.png",
);
export const contactIcon = getImagePath("../../../assets/img/contact-icon.svg");
export const audioIcon = getImagePath("../../../assets/img/audio-icon.svg");

export const download = getImagePath("../../../assets/img/download.png");
export const plusIcon = getImagePath("../../../assets/img/plus-icon.svg");
export const arrowDownSelect = getImagePath(
  "../../../assets/img/down-arrow-svgrepo-com.svg",
);
export const arrowDownSelectHeader = getImagePath(
  "../../../assets/img/down-arrow-select-header.svg",
);
export const arrowDownSelectHeaderWhite = getImagePath(
  "../../../assets/img/down-arrow-select-header-white.svg",
);

export const accountCircle = getImagePath(
  "../../../assets/img/popup/AccountCircleOutlined.svg",
);
export const settingsIcon = getImagePath(
  "../../../assets/img/popup/SettingsOutlined.svg",
);
export const textSnippet = getImagePath(
  "../../../assets/img/TextSnippetOutlined.svg",
);
export const postAdd = getImagePath(
  "../../../assets/img/popup/PostAddOutlined.svg",
);
export const helpOutline = getImagePath(
  "../../../assets/img/popup/HelpOutlineOutlined.svg",
);
export const monetizationon = getImagePath(
  "../../../assets/img/popup/MonetizationonOutlined.svg",
);

export const editIcon = getImagePath(
  "../../../assets/img/doc-popover/EditOutlined.svg",
);
export const articleIcon = getImagePath(
  "../../../assets/img/doc-popover/ArticleOutlined.svg",
);
export const searchIcon = getImagePath(
  "../../../assets/img/doc-popover/SearchOutlined.svg",
);
export const docPhoto = getImagePath("../../../assets/img/doc-popover/doc.png");

export const userTop1 = getImagePath("../../../assets/img/users-top/1.png");
export const userTop2 = getImagePath("../../../assets/img/users-top/2.png");
export const userTop3 = getImagePath("../../../assets/img/users-top/3.png");
export const userTop4 = getImagePath("../../../assets/img/users-top/4.png");
export const userTop5 = getImagePath("../../../assets/img/users-top/5.png");

// export const hintLogo = getImagePath( "../../../assets/img/hint-logo.png");

export const commentIcon = getImagePath(
  "../../../assets/img/CommentOutlined.svg",
);
export const codeIcon = getImagePath(
  "../../../assets/img/msg-popover/CodeOutlined.svg",
);
export const documentScannerIcon = getImagePath(
  "../../../assets/img/DocumentScannerOutlined.svg",
);
export const driveFileRenameIcon = getImagePath(
  "../../../assets/img/DriveFileRenameOutlineOutlined.svg",
);
export const filePresent = getImagePath(
  "../../../assets/img/FilePresentOutlined.svg",
);
export const fileUpload = getImagePath(
  "../../../assets/img/UploadFileOutlined.svg",
);
export const northEastArrow = getImagePath(
  "../../../assets/img/NorthEastOutlined.svg",
);
export const smileFace = getImagePath(
  "../../../assets/img/smiles/smileFace.png",
);
export const closeIcon = getImagePath("../../../assets/img/CloseOutlined.svg");
export const errorIcon = getImagePath("../../../assets/img/ErrorOutline.svg");
// export const errorIconRed = getImagePath( "../../../assets/img/errorRed.svg");
export const errorIconRed = getImagePath(
  "../../../assets/img/ErrorOutlineRed.svg",
);
export const errorOrange = getImagePath("../../../assets/img/error-orange.svg");
export const pinIcon = getImagePath("../../../assets/img/PushPinOutlined.svg");

export const printIcon = getImagePath("../../../assets/img/PrintOutlined.svg");
export const saveAltIcon = getImagePath(
  "../../../assets/img/SaveAltOutlined.svg",
);
export const fileUploadIcon = getImagePath(
  "../../../assets/img/FileUploadOutlined.svg",
);
export const taskIcon = getImagePath("../../../assets/img/TaskOutlined.svg");
export const dateRangeIcon = getImagePath(
  "../../../assets/img/DateRangeFilled.svg",
);

export const whatsappChatSmallIcon = getImagePath(
  "../../../assets/img/wtsp-btn.svg",
);
export const whatsappChatSmallIconRed = getImagePath(
  "../../../assets/img/wtsp-btn-red.svg",
);
export const recordChatSmallIcon = getImagePath(
  "../../../assets/img/record-btn.svg",
);
export const emojiIcon = getImagePath(
  "../../../assets/img/EmojiEmotionsOutlined.svg",
);
export const powerlinkIcon = getImagePath(
  "../../../assets/img/powerlink-icon.svg",
);
export const powerlinkIconOrange = getImagePath(
  "../../../assets/img/powerlink-icon-orange.svg",
);
export const paperPlaneSendIcon = getImagePath(
  "../../../assets/img/SendFilled.svg",
);

export const uploadPreview = getImagePath(
  "../../../assets/img/uploadPreview.png",
);
export const saveIcon = getImagePath("../../../assets/img/SaveOutlined.svg");
export const popupDeleteIcon = getImagePath(
  "../../../assets/img/msg-popover/DeleteOutlined.svg",
);
export const popupCheckIcon = getImagePath(
  "../../../assets/img/msg-popover/CheckFilled.svg",
);
export const preloadGif = getImagePath(
  "../../../assets/img/doc-popover/preload.gif",
);
export const doubleVgray = getImagePath("../../../assets/img/doubleVgray.svg");
export const doubleV = getImagePath("../../../assets/img/doubleV.svg");
export const singleV = getImagePath("../../../assets/img/single-mark.svg");

export const arrowHideChat = getImagePath(
  "../../../assets/img/ArrowBackChatHide.svg",
);
export const docx = getImagePath("../../../assets/img/docx_icon.png");
export const xlsx = getImagePath("../../../assets/img/xlsx.png");
export const zip = getImagePath("../../../assets/img/zip.png");
export const locationIMG = getImagePath("../../../assets/img/location.png");

//===Smile tabs icons==============================================================================================================================================

export const placeholderSmiles = getImagePath(
  "../../../assets/img/smiles/placeholder.svg",
);
export const recentSmiles = getImagePath(
  "../../../assets/img/smiles/recent.svg",
);
export const happySmiles = getImagePath("../../../assets/img/smiles/happy.svg");
export const petSmiles = getImagePath("../../../assets/img/smiles/pet.svg");
export const coffeeSmiles = getImagePath(
  "../../../assets/img/smiles/coffee.svg",
);
export const sportsSmiles = getImagePath(
  "../../../assets/img/smiles/sports.svg",
);
export const carSmiles = getImagePath("../../../assets/img/smiles/car.svg");
export const lampSmiles = getImagePath("../../../assets/img/smiles/lamp.svg");
export const hashtagSmiles = getImagePath(
  "../../../assets/img/smiles/hashtag.svg",
);
export const flagSmiles = getImagePath("../../../assets/img/smiles/flag.svg");

export const locationMap = getImagePath("../../../assets/img/location-map.png");

export const pencilMaterial = getImagePath(
  "../../../assets/img/pencil-material.svg",
);

export const redCross = getImagePath("../../../assets/img/red-cross.svg");
export const greenCheck = getImagePath("../../../assets/img/green-check.svg");
export const greenCheckSquare = getImagePath(
  "../../../assets/img/green-check-square.svg",
);
export const grayCheck = getImagePath(
  "../../../assets/img/gray-check-square.svg",
);
export const mailIcon = getImagePath("../../../assets/img/mail.svg");
export const insertLink = getImagePath(
  "../../../assets/img/InsertLinkOutlined.svg",
);
export const openInNew = getImagePath(
  "../../../assets/img/OpeninNewOutlined.svg",
);

// msg types

export const locationIconMSg = getImagePath(
  "../../../assets/img/msg-types/location.svg",
);

export const audioIconMsg = getImagePath(
  "../../../assets/img/msg-types/audio.svg",
);

export const contactIconMsg = getImagePath(
  "../../../assets/img/msg-types/contact.svg",
);

export const pictureIconMsg = getImagePath(
  "../../../assets/img/msg-types/picture.svg",
);
export const fileIconMsg = getImagePath(
  "../../../assets/img/msg-types/file.png",
);

//========================================================================================================================================================

export const googleAbout = getImagePath(
  "../../../assets/img/popup/google-about.svg",
);
export const whatsappAbout = getImagePath(
  "../../../assets/img/popup/WhatsApp-about.svg",
);
export const facebookAbout = getImagePath(
  "../../../assets/img/popup/Facebook-about.svg",
);
export const mailAbout = getImagePath(
  "../../../assets/img/popup/mail-about.svg",
);
export const facebookRounded = getImagePath(
  "../../../assets/img/facebook-rounded.svg",
);
export const facebookRoundedWhite = getImagePath(
  "../../../assets/img/facebook-rounded-white.svg",
);
export const instagramRounded = getImagePath(
  "../../../assets/img/instagram-rounded.svg",
);

export const chromeExtensionLogo = getImagePath(
  "../../../assets/img/aboutLogos/chrome.png",
);
export const makeLogo = getImagePath("../../../assets/img/aboutLogos/make.png");
export const appStoreLogo = getImagePath(
  "../../../assets/img/aboutLogos/appStore.png",
);
export const googlePlayLogo = getImagePath(
  "../../../assets/img/aboutLogos/googlePlay.png",
);

export const funnerLogoGray = getImagePath(
  "../../../assets/img/popup/funner-logo-gray.svg",
);
export const expandMore = getImagePath(
  "../../../assets/img/popup/ExpandMoreFilled.svg",
);
export const arrowDropDown = getImagePath(
  "../../../assets/img/ArrowDropDownFilled.svg",
);

export const dashboardImg = getImagePath("../../../assets/img/dashboard.svg");
export const builderBgImg = getImagePath("../../../assets/img/builder-bg.png");

export const testPdf = getImagePath("../../../assets/pdf/test.pdf");
export const sandWatch = getImagePath("../../../assets/img/sandwatch.gif");
