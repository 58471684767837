import { Box, SvgIcon } from "@mui/material";
import React from "react";

function NoteMessage({ GlobalUserName, chatCustomizationSettings }) {
  return (
    <div className="note-message-header">
      <div className="note-message-header__icon">
        <SvgIcon
          className="note-message-header__icon"
          sx={{
            color:
              chatCustomizationSettings?.find(
                (option) => option.type === "noteMessage",
              ).value === "custom"
                ? chatCustomizationSettings.find(
                    (option) => option.type === "noteMessage",
                  ).customColor.background + " !important"
                : null,
          }}
        >
          <svg
            width={12}
            height={12}
            viewBox="0 0 12 12"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.994 1.2C11.994 0.54 11.46 0 10.8 0H1.2C0.54 0 0 0.54 0 1.2V8.4C0 9.06 0.54 9.6 1.2 9.6H9.6L12 12L11.994 1.2ZM10.8 1.2V9.102L10.098 8.4H1.2V1.2H10.8ZM2.4 6H9.6V7.2H2.4V6ZM2.4 4.2H9.6V5.4H2.4V4.2ZM2.4 2.4H9.6V3.6H2.4V2.4Z" />
          </svg>
        </SvgIcon>
      </div>
      <Box
        className="note-message-header__text"
        sx={{
          color:
            chatCustomizationSettings?.find(
              (option) => option.type === "noteMessage",
            ).value === "custom"
              ? chatCustomizationSettings.find(
                  (option) => option.type === "noteMessage",
                ).customColor.text + " !important"
              : null,
        }}
      >
        {GlobalUserName}
      </Box>
    </div>
  );
}

export default NoteMessage;
