import React, { useEffect, useRef, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { applicationType } from "../applicationType";
// import SettingsPopup from "./Settings/SettingsPopup";
import FunnerGlobalMessanger from "./Funner/FunnerGlobalMessanger";
import { UseFetchGet, UseFetchPost } from "../hooks/fetchFunctions";
import { hintLogo, uploadPreview } from "../libs/images";
import FireberryPlatform from "./FireberryPlatform/FireberryPlatform";
import { sendMessageToBackground } from "../hooks/extensionActions";
import { getCurrentDateFormatted } from "../libs/helpers";
import PriorityPlatform from "./PriorityPlatform/PriorityPlatform";
import localizations from "../localizations.json";
import { sortListByParam } from "../libs/messages";
import { NotificationIcon } from "../modules/NotificationContext";
import { enqueueSnackbar } from "notistack";
import { Box, Grid } from "@mui/material";

const SettingsPopup =
  applicationType === "web"
    ? React.lazy(() => import("./Settings/SettingsPopup"))
    : null;

function Main({
  token,
  iframeMode,
  userData,
  updateUserData,
  userDataRef,
  userConfig,
  userConfigRef,
  businessInfo,
  setBusinessInfo,
  businessInfoRef,
  handleRemoveToken,
  handleSetNotif,
  handleBrowserNotification,
  updateUserConfig,
  connection,
  isConnection,
  tabActiveRef,
  setIsActiveAppOnPage,
  handleApplicationUserProfile,
  isPowerLinkPage,
  handleSubmitType,
  isActiveAppOnPageRef,
  browserNotifPermissionRef,
  handleSetToken,
}) {
  //! Config and Info
  const [templatesList, setTemplatesList] = useState({
    templates: [],
    whatsappTemplates: [],
    languages: [],
  });
  const [selectedUsersFireberrySync, setSelectedUsersFireberrySync] = useState(
    [],
  );
  const [selectedUsersPrioritySync, setSelectedUsersPrioritySync] = useState(
    [],
  );
  const [funnerUsers, setFunnerUsers] = useState([]);
  const [chatUsersLabelsList, setChatUsersLabelsList] = useState(null);
  const [chatUsersGlobal, setChatUsersGlobal] = useState({
    myChats: [],
    notMyChats: [],
  });
  const [chatUsersFunner, setChatUsersFunner] = useState({
    myChats: [],
    notMyChats: [],
  });
  const getContactsQueryParamsRef = useRef({
    pageOffset: 1,
    searchQuery: "",
    label: "",
    open: "all",
    isOnlyUnread: false,
    assigneeFilter: [],
  });

  const [selectedFunnerChatUser, setSelectedFunnerChatUser] = useState(null);
  const [selectedFireberryChatUser, setSelectedFireberryChatUser] =
    useState(null);
  const [userInSettingsCreationState, setUserInSettingsCreationState] =
    useState(null);
  const [isMyUsersChats, setMyUsersChat] = useState(false);
  const [goToFavouriteMessageState, setGoToFavouriteMessageState] =
    useState(null);

  //* Priority
  const [disableChatGoBack, setDisableChatGoBack] = useState(false);

  const [totalUnredMessages, setTotalUnredMessages] = useState(null);
  const [totalUnreadChats, setTotalUnreadChats] = useState(null);

  //! App modules config
  //* Sound Notification
  // const [isNotifOn, setIsNotifOn] = useState(false);

  //! Popups and other view states
  const [isPriorityDocumentWindow, setPriorityDocumentWindow] = useState(false);
  const [isPrioritySendDocsWindow, setPrioritySendDocsWindow] = useState(false);
  const [isUsersFireberrySyncWindow, setUsersFireberrySyncWindow] =
    useState(false);
  const [isUsersPrioritySyncWindow, setUsersPrioritySyncWindow] =
    useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const isChatOpenLocalStorage = JSON.parse(
    localStorage.getItem("globalFunnerMessangerOpenStatus"),
  );
  const [openUnreadMessagesPopup, setOpenUnreadMessagesPopup] = useState(
    applicationType === "web"
      ? true
      : isChatOpenLocalStorage
      ? isChatOpenLocalStorage.isChatOpen
      : false,
  );
  const [sendUploadPriority, setSendUploadPriority] = useState({
    isUpload: false,
    file: "",
    fileTypeImg: uploadPreview,
  });
  const handleSendPriorityUploadState = (params) => {
    setSendUploadPriority((prevObj) => {
      return { ...prevObj, ...params };
    });
  };
  const resetSendUploadPriority = () => {
    handleSendPriorityUploadState({
      isUpload: false,
      file: "",
      fileTypeImg: uploadPreview,
    });
  };

  //! Refs
  const notifSound = useRef(null);
  const templatesListRef = useRef(null);
  const chatUsersGlobalRef = useRef({
    myChats: [],
    notMyChats: [],
  });
  const chatUsersFunnerRef = useRef({
    myChats: [],
    notMyChats: [],
  });
  const chatUsersMessagesRef = useRef([]);
  const selectedFunnerChatUserRef = useRef(null);
  const selectedFireberryChatUserRef = useRef(null);
  const hintNotifContainerRef = useRef(null);
  const hintNotifContainerRoot = useRef(null);
  const appButtonsRef = useRef(null);
  const goToFavouriteMessageStateRef = useRef(null);

  //! Control functions and handlers
  //* Popups and other view states control
  const handlePriorityDocumentsWindow = (state) => {
    setPriorityDocumentWindow(state);
  };
  const handlePrioritySendDocsWindow = (state) => {
    setPrioritySendDocsWindow(state);
  };
  const handleUsersFireberrySettingsWindow = (state) => {
    setUsersFireberrySyncWindow(state);
  };
  const handleUsersPrioritySettingsWindow = (state) => {
    setUsersPrioritySyncWindow(state);
  };
  const openUnreadMessagesChat = () => {
    const currentUnreadChat = document.querySelector(
      ".unread-messages-popup .single-chat-unread-messages.unread",
    );
    if (currentUnreadChat) {
      setOpenUnreadMessagesPopup(true);
      currentUnreadChat.click();
    }
  };
  function toggleUnreadMessagesPopup() {
    setOpenUnreadMessagesPopup((prev) => !prev);
  }
  const toggleNavPanel = () => {
    setNavOpen((prev) => !prev);
  };
  const closeNavPanel = () => {
    setNavOpen(false);
  };

  function updateGlobalChatUsersStates(myChats, notMyChats) {
    setChatUsersGlobal((prevObj) => {
      return {
        ...prevObj,
        notMyChats: notMyChats,
        myChats: myChats,
      };
    });

    chatUsersGlobalRef.current.notMyChats = notMyChats;
    chatUsersGlobalRef.current.myChats = myChats;
  }
  function updateFunnerChatUsersStates(myChats, notMyChats) {
    setChatUsersFunner((prevObj) => {
      return {
        ...prevObj,
        notMyChats: notMyChats,
        myChats: myChats,
      };
    });

    chatUsersFunnerRef.current.notMyChats = notMyChats;
    chatUsersFunnerRef.current.myChats = myChats;
  }
  const updateChatUser = (userId, newData) => {
    const myChatUsersGlobal = chatUsersGlobalRef.current.myChats;
    const notMyChatUsersGlobal = chatUsersGlobalRef.current.notMyChats;
    const myChatUsersFunner = chatUsersFunnerRef.current.myChats;
    const notMyChatUsersFunner = chatUsersFunnerRef.current.notMyChats;

    updateGlobalChatUsersStates(
      myChatUsersGlobal.map((user) =>
        user.UserDisplayPhoneNumber === userId ? { ...user, ...newData } : user,
      ),
      notMyChatUsersGlobal.map((user) =>
        user.UserDisplayPhoneNumber === userId ? { ...user, ...newData } : user,
      ),
    );
    updateFunnerChatUsersStates(
      myChatUsersFunner.map((user) =>
        user.UserDisplayPhoneNumber === userId ? { ...user, ...newData } : user,
      ),
      notMyChatUsersFunner.map((user) =>
        user.UserDisplayPhoneNumber === userId ? { ...user, ...newData } : user,
      ),
    );

    if (
      selectedFunnerChatUserRef.current &&
      selectedFunnerChatUserRef.current.UserDisplayPhoneNumber === userId
    ) {
      handleSelectedFunnerChatUser({
        ...selectedFunnerChatUserRef.current,
        ...newData,
      });
    }

    if (
      selectedFireberryChatUserRef.current &&
      selectedFireberryChatUserRef.current.UserDisplayPhoneNumber === userId
    ) {
      handleSelectedFireberryChatUser({
        ...selectedFireberryChatUserRef.current,
        ...newData,
      });
    }
  };
  const handleSelectedFunnerChatUser = (user) => {
    setSelectedFunnerChatUser(user);
    selectedFunnerChatUserRef.current = user;
  };
  const handleSelectedFireberryChatUser = (user) => {
    setSelectedFireberryChatUser(user);
    selectedFireberryChatUserRef.current = user;
  };
  const handleChatUserMessages = (id, params) => {
    const findUserIndex = chatUsersMessagesRef.current.findIndex(
      (user) => user.id === id,
    );
    if (findUserIndex === -1) {
      chatUsersMessagesRef.current.push({
        id: id,
        ...params,
      });
    } else {
      Object.assign(chatUsersMessagesRef.current[findUserIndex], params);
    }

    // console.log(chatUsersMessagesRef.current);
  };
  const clearChatUsersMessages = () => {
    chatUsersMessagesRef.current = [];
  };
  const handleGoToFavouriteMessageState = (state) => {
    setGoToFavouriteMessageState(state);
    goToFavouriteMessageStateRef.current = state;
  };

  function closeHintNotification() {
    hintNotifContainerRoot.current.unmount();
  }

  //! Async and fetch functions
  async function getFunnerUsers() {
    try {
      const res = await UseFetchGet(
        "/api/auth/getApplicationUsers",
        undefined,
        null,
        undefined,
        "config",
      );
      if (res?.data?.users) {
        setFunnerUsers(res.data.users);
        return res.data.users;
      } else return [];
    } catch (error) {
      console.log("GetPowerLinkUserglobalList error: ", error);
      return [];
    }
  }
  async function getCompanyLabelsList() {
    try {
      const res = await UseFetchGet(
        "/api/services/GetCompanyLabelsList",
        {},
        null,
        undefined,
        "history",
      );
      if (
        res.data.labelsList.labelsList &&
        res.data.labelsList.labelsList.length
      ) {
        const newLabelsList = JSON.parse(res.data.labelsList.labelsList);
        setChatUsersLabelsList(() => {
          return [
            {
              name: localizations["he"].chat.chatHeader.filterLabel.noLabel,
              color: "#2a3847",
            },
            ...newLabelsList,
          ];
        });
      } else {
        setChatUsersLabelsList(() => {
          return [
            {
              name: localizations["he"].chat.chatHeader.filterLabel.noLabel,
              color: "#2a3847",
            },
          ];
        });
      }
    } catch (err) {
      console.log(err);
      setChatUsersLabelsList(() => {
        return [
          {
            name: localizations["he"].chat.chatHeader.filterLabel.noLabel,
            color: "#2a3847",
          },
        ];
      });
    }
  }

  async function fetchWhatsappTemplates() {
    try {
      const { Udid } = userDataRef.current;
      setTemplatesLoading(true);
      const res = await UseFetchGet(
        "/api/services/GetWhatsappTemplates",
        {
          globaluserid: Udid,
        },
        null,
        undefined,
        "history",
      );
      // const fetchedTemplates = res?.data?.whatsappTemplates || [];
      const fetchedTemplates = res?.data?.whatsappOriginalTemplates || [];
      const fetchedWhatsappTemplates = res?.data?.whatsappTemplates || [];
      const languages = res?.data?.languages || [];

      setTemplatesList({
        templates: [...fetchedTemplates],
        whatsappTemplates: [...fetchedWhatsappTemplates],
        languages: [...languages],
      });
      templatesListRef.current = {
        templates: [...fetchedTemplates],
        whatsappTemplates: [...fetchedWhatsappTemplates],
        languages: [...languages],
      };
    } catch (err) {
      console.log("GetWhatsappTemplates error: ", err);
    } finally {
      setTemplatesLoading(false);
    }
  }
  async function getChatMissingHistory(phoneNumber) {
    try {
      const currentMsgs =
        chatUsersMessagesRef.current?.find((user) => user.id === phoneNumber)
          ?.messages || [];

      let dateFrom,
        dateTo,
        closestBufferIndex = -1;
      if (currentMsgs?.length) {
        closestBufferIndex = currentMsgs.findLastIndex(
          (msg) => msg.FileType === "buffer",
        );
        const lastMessage = currentMsgs[currentMsgs.length - 1];

        dateTo = lastMessage.DateCreatedOriginal;
        dateFrom =
          currentMsgs[closestBufferIndex !== -1 ? closestBufferIndex + 1 : 0]
            .DateCreatedOriginal;
      } else {
        const date = getCurrentDateFormatted();
        dateFrom = date;
        dateTo = date;
      }

      const filteredMessages =
        closestBufferIndex !== -1
          ? currentMsgs.slice(closestBufferIndex + 1)
          : currentMsgs;
      const res = await UseFetchPost(
        "/api/sendMessage/getMissingMessages",
        {
          date: dateFrom,
          dateTo: dateTo,
          ids: filteredMessages.map((msg) => msg.Id),
          chatUserPhoneNumberId: phoneNumber.startsWith("972")
            ? "0" + phoneNumber.slice(3)
            : phoneNumber,
        },
        "history",
      );

      if (res?.data?.messages) {
        return res.data.messages;
      } else {
        return [];
      }
    } catch (error) {
      console.log("getChatUpdateHistory err: ", error);
      return [];
    }
  }

  //! App connection
  function onAppConnectedActions() {
    // getCompanyChatUsersGlobal();
    fetchWhatsappTemplates();
    getFunnerUsers();
    getCompanyLabelsList();
  }

  async function getCompanyChatUsersGlobal() {
    try {
      const params = {
        page_number: 10000,
        page_offset: 0,
        filter_by_open: "all",
        order_by: "last_message_date",
        order_descending: true,
        search_term: "",
        label: "",
      };

      const res = await UseFetchPost(
        "/api/services/GetCompanyChatUsersAll",
        params,
        "history",
      );
      if (res) {
        updateGlobalChatUsersStates(
          res.data?.myChatUsers?.chatUsers
            ? [...res.data.myChatUsers.chatUsers]
            : [],
          res.data?.allChatUsers?.chatUsers
            ? [...res.data.allChatUsers.chatUsers]
            : [],
        );

        return {
          notMyChats: res.data?.allChatUsers?.chatUsers
            ? [...res.data.allChatUsers.chatUsers]
            : [],
          myChats: res.data?.myChatUsers?.chatUsers
            ? [...res.data.myChatUsers.chatUsers]
            : [],
        };
      }
    } catch (err) {
      console.log(err);
    }
  }

  function initSocketListener() {
    connection.on("ReceiveMessage", async function (message) {
      const messageObject = JSON.parse(message);
      onSocketReceiveMessage(messageObject);

      if (applicationType === "extension") {
        const sendContent = {
          messageObject: messageObject,
        };

        sendMessageToBackground("socketReceiveMessage", sendContent);
      }
    });
    connection.on("unreadNotification", async function (message) {
      const messageObject = JSON.parse(message);
      onSocketUnreadNotification(messageObject);

      if (applicationType === "extension") {
        const sendContent = {
          messageObject: messageObject,
        };

        sendMessageToBackground("socketUnreadNotification", sendContent);
      }
    });
    connection.on("updateChatUserUnread", async function (message) {
      const messageObject = JSON.parse(message);
      onSocketUpdateChatUserUnread(messageObject);
      if (applicationType === "extension") {
        const sendContent = {
          messageObject: messageObject,
        };

        sendMessageToBackground("socketUpdateChatUserUnread", sendContent);
      }
    });
    connection.on("Notification", async function (message) {
      const messageObject = JSON.parse(message);

      let getExtensionPermissionStatus = null;
      if (applicationType === "extension") {
        getExtensionPermissionStatus = await sendMessageToBackground(
          "checkNotifPerm",
        );
      }
      onSocketNotification(
        messageObject,
        getExtensionPermissionStatus?.permission || null,
      );

      if (applicationType === "extension") {
        const sendContent = {
          messageObject: messageObject,
        };

        if (getExtensionPermissionStatus?.permission !== "granted")
          sendMessageToBackground("socketNotification", sendContent);
      }
    });
    connection.on("TaggedUsersNotification", async function (message) {
      console.log("TaggedUsersNotification");
      const messageObject = JSON.parse(message);

      let getExtensionPermissionStatus = null;
      if (applicationType === "extension") {
        getExtensionPermissionStatus = await sendMessageToBackground(
          "checkNotifPerm",
        );
      }
      onSocketTaggedUsersNotification(
        messageObject,
        getExtensionPermissionStatus?.permission || null,
      );

      if (applicationType === "extension") {
        const sendContent = {
          messageObject: messageObject,
        };

        if (getExtensionPermissionStatus?.permission !== "granted")
          sendMessageToBackground("socketTaggedUsersNotification", sendContent);
      }
    });
    connection.on("labelUpdate", async function (message) {
      const messageObject = JSON.parse(message);
      onSocketLabelUpdate(messageObject);

      if (applicationType === "extension") {
        const sendContent = {
          messageObject: messageObject,
        };

        sendMessageToBackground("labelUpdate", sendContent);
      }
    });
    // connection.on("botStatusUpdate", async function (message) {
    //   const messageObject = JSON.parse(message);
    //   onSocketBotStatusUpdate(messageObject);

    //   if (applicationType === "extension") {
    //     const sendContent = {
    //       messageObject: messageObject,
    //     };

    //     sendMessageToBackground("botStatusUpdate", sendContent);
    //   }
    // });
    connection.on("conversationOpenStatus", async function (message) {
      const messageObject = JSON.parse(message);
      onSocketConversationOpenStatus(messageObject);

      if (applicationType === "extension") {
        const sendContent = {
          messageObject: messageObject,
        };

        sendMessageToBackground("conversationOpenStatus", sendContent);
      }
    });
    connection.on("funnerServiceNotification", async function (message) {
      const messageObject = JSON.parse(message);
      onSockerFunnerServiceNotification(messageObject);

      if (applicationType === "extension") {
        const sendContent = {
          messageObject: messageObject,
        };

        sendMessageToBackground("funnerServiceNotification", sendContent);
      }
    });
  }
  function onSocketReceiveMessage(messageObject) {
    try {
      Number(messageObject.funnerUserUnreadMsgCount)
        ? setTotalUnredMessages(Number(messageObject.funnerUserUnreadMsgCount))
        : setTotalUnredMessages(null);
      Number(messageObject.funnerUserChatsUnreadCount)
        ? setTotalUnreadChats(Number(messageObject.funnerUserChatsUnreadCount))
        : setTotalUnreadChats(null);

      const newMessage = messageObject.messages[0];
      if (newMessage.FileType !== "info") {
        // let newGlobalNotMyUsersList =
        //   chatUsersGlobalRef.current.notMyChats.filter(
        //     (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
        //   );
        // let newFunnerNotMyUsersList =
        //   chatUsersFunnerRef.current.notMyChats.filter(
        //     (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
        //   );

        const targetNotMyUser = chatUsersGlobalRef.current.notMyChats.find(
          (user) =>
            user.UserDisplayPhoneNumber ===
            newMessage.resultChatUser?.UserDisplayPhoneNumber,
        );
        const currentNotMyUser = { ...newMessage.resultChatUser };
        // if (newMessage.Type === "incoming")
        //   currentNotMyUser.UnreadMessagesNumber = 1;
        // else
        //   currentNotMyUser.UnreadMessagesNumber =
        //     targetNotMyUser.UnreadMessagesNumber;

        let newGlobalNotMyUsersList = [],
          newFunnerNotMyUsersList = [];

        const isSameMessage =
          targetNotMyUser?.LastMessageType ===
            currentNotMyUser?.LastMessageType &&
          (currentNotMyUser?.LastMessageType === "outcoming"
            ? targetNotMyUser?.OutcomingLastMessageId ===
              currentNotMyUser?.OutcomingLastMessageId
            : targetNotMyUser?.incomingLastMessageId ===
              currentNotMyUser?.incomingLastMessageId);
        if (isSameMessage || newMessage.FileType === "note") {
          newGlobalNotMyUsersList = chatUsersGlobalRef.current.notMyChats.map(
            (user) => {
              if (user.UserDisplayPhoneNumber === messageObject.phone) {
                return currentNotMyUser;
              }

              return user;
            },
          );
          newFunnerNotMyUsersList = chatUsersFunnerRef.current.notMyChats.map(
            (user) => {
              if (user.UserDisplayPhoneNumber === messageObject.phone) {
                return currentNotMyUser;
              }

              return user;
            },
          );
        } else {
          newGlobalNotMyUsersList =
            chatUsersGlobalRef.current.notMyChats.filter(
              (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
            );
          newFunnerNotMyUsersList =
            chatUsersFunnerRef.current.notMyChats.filter(
              (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
            );
          newGlobalNotMyUsersList.unshift(currentNotMyUser);

          //! Relate inserting user with active filters
          const { searchQuery, label, open, isOnlyUnread, assigneeFilter } =
            getContactsQueryParamsRef.current;

          const searchCond = !searchQuery?.length
            ? true
            : currentNotMyUser.UserDisplayPhoneNumber.includes(
                searchQuery.toLowerCase(),
              ) ||
              currentNotMyUser.FullName.toLowerCase().includes(
                searchQuery.toLowerCase(),
              );
          const labelCond =
            label && label !== "" ? currentNotMyUser.LableName === label : true;
          const openCond =
            open === "open"
              ? currentNotMyUser.IsOpen === true
              : open === "close"
              ? currentNotMyUser.IsOpen === false
              : true;
          const onlyUnreadCond = isOnlyUnread
            ? currentNotMyUser.UnreadMessagesNumber > 0
            : true;
          const assigneeFilterCond = assigneeFilter.length
            ? assigneeFilter.includes(currentNotMyUser.NotificationContactUser)
            : true;

          // console.log(
          //   "searchCond: ",
          //   searchCond,
          //   "\nlabelCond: ",
          //   labelCond,
          //   "\nopenCond: ",
          //   openCond,
          //   "\nonlyUnreadCond: ",
          //   onlyUnreadCond,
          //   "\nassigneeFilterCond: ",
          //   assigneeFilterCond,
          // );

          if (
            searchCond &&
            labelCond &&
            openCond &&
            onlyUnreadCond &&
            assigneeFilterCond
          )
            newFunnerNotMyUsersList.unshift(currentNotMyUser);
        }

        let currentMyUser = chatUsersGlobalRef.current.myChats.some(
          (user) => user.UserDisplayPhoneNumber === messageObject.phone,
        )
          ? {
              ...chatUsersGlobalRef.current.myChats.find(
                (user) => user.UserDisplayPhoneNumber === messageObject.phone,
              ),
            }
          : null;
        let newGlobalMyUsersList = [...chatUsersGlobalRef.current.myChats];
        let newFunnerMyUsersList = [...chatUsersFunnerRef.current.myChats];

        if (currentMyUser) {
          if (isSameMessage) {
            newGlobalMyUsersList = chatUsersGlobalRef.current.myChats.map(
              (user) => {
                if (user.UserDisplayPhoneNumber === messageObject.phone) {
                  return currentNotMyUser;
                }

                return user;
              },
            );
            newFunnerMyUsersList = chatUsersFunnerRef.current.myChats.map(
              (user) => {
                if (user.UserDisplayPhoneNumber === messageObject.phone) {
                  return currentNotMyUser;
                }

                return user;
              },
            );
          } else {
            newGlobalMyUsersList = chatUsersGlobalRef.current.myChats.filter(
              (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
            );
            newFunnerMyUsersList = chatUsersFunnerRef.current.myChats.filter(
              (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
            );
            currentMyUser = { ...currentNotMyUser };
            newGlobalMyUsersList.unshift(currentMyUser);
            newFunnerMyUsersList.unshift(currentMyUser);
          }
        }

        updateGlobalChatUsersStates(
          newGlobalMyUsersList,
          newGlobalNotMyUsersList,
        );
        updateFunnerChatUsersStates(
          newFunnerMyUsersList,
          newFunnerNotMyUsersList,
        );

        if (
          selectedFunnerChatUserRef.current?.UserDisplayPhoneNumber ===
          currentNotMyUser.UserDisplayPhoneNumber
        ) {
          handleSelectedFunnerChatUser(currentNotMyUser);
        }
        if (
          selectedFireberryChatUserRef.current?.UserDisplayPhoneNumber ===
          currentNotMyUser.UserDisplayPhoneNumber
        ) {
          handleSelectedFireberryChatUser(currentNotMyUser);
        }

        if (
          selectedFunnerChatUserRef.current?.UserDisplayPhoneNumber !==
            currentNotMyUser.UserDisplayPhoneNumber &&
          selectedFireberryChatUserRef.current?.UserDisplayPhoneNumber !==
            currentNotMyUser.UserDisplayPhoneNumber &&
          chatUsersMessagesRef.current.some(
            (user) => user.id === messageObject.phone,
          )
        ) {
          const messagesList = chatUsersMessagesRef.current.find(
            (user) => user.id === messageObject.phone,
          ).messages;
          const existingMessageIndex = messagesList.findIndex(
            (msg) => msg.Id === newMessage.Id,
          );
          let newMessagesList = [...messagesList];
          if (existingMessageIndex !== -1) {
            newMessagesList[existingMessageIndex] = {
              ...newMessagesList[existingMessageIndex],
              ...newMessage,
            };
          } else {
            newMessagesList.push(newMessage);
          }

          newMessagesList = sortListByParam(newMessagesList, "Id");
          handleChatUserMessages(messageObject.phone, {
            messages: [...newMessagesList],
          });
        }
      }
    } catch (error) {
      console.log("onSocketReceiveMessage error", error);
    }
  }
  function onSocketUnreadNotification(messageObject) {
    try {
      Number(messageObject.unreadMsgsNumb)
        ? setTotalUnredMessages(Number(messageObject.unreadMsgsNumb))
        : setTotalUnredMessages(null);
      Number(messageObject.unreadMsgsNumb)
        ? setTotalUnreadChats(Number(messageObject.unread))
        : setTotalUnreadChats(null);
    } catch (error) {
      console.log("onSocketUnreadNotification error: ", error);
    }
  }
  function onSocketUpdateChatUserUnread(messageObject) {
    try {
      updateChatUser(messageObject.chatUserPhoneId, {
        UnreadMessagesNumber: messageObject.unreadNumber,
      });
    } catch (error) {
      console.log("onSocketUpdateChatUserUnread error: ", error);
    }
  }
  function onSocketNotification(messageObject, extPerm) {
    try {
      const enableCustomTime = userConfig.enableCustomTime;
      const notifHintTimeout = userConfig.notifHintTimeout;

      notifSound.current?.play().catch((error) => {
        console.log(error);
      });

      if (enableCustomTime) {
        const container = hintNotifContainerRef.current;

        const phone = messageObject.displayPhoneNumber;
        const targetUser = chatUsersGlobalRef.current.notMyChats.find(
          (user) => user.UserDisplayPhoneNumber === phone,
        );

        const targetLink = messageObject.fireberryAccountLink
          ? messageObject.fireberryAccountLink
          : `https://app.powerlink.co.il/app/views/${
              businessInfo?.LinkToObjectNum
                ? businessInfo.LinkToObjectNum
                : "first" === "second"
                ? "2"
                : "1"
            }?q=${phone}`;

        if (
          (applicationType === "web" &&
            browserNotifPermissionRef.current === "granted") ||
          (applicationType === "extension" && extPerm === "granted")
        ) {
          handleBrowserNotification(
            `Funner - ${targetUser.FullName}`,
            messageObject.notificationText,
          );
        } else {
          enqueueSnackbar(
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    handleSelectedFunnerChatUser(targetUser);
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item>{targetUser?.FullName || ""}</Grid>
                    <Grid item>{messageObject.displayPhoneNumber}</Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {messageObject.notificationText}
              </Grid>
            </Grid>,
            {
              variant: "info",
              iconVariant: {
                info: (
                  <NotificationIcon type={"info"} data={{ link: targetLink }} />
                ),
              },
            },
          );
        }
      }
    } catch (error) {
      console.log("onSocketNotification: ", error);
    }
  }
  function onSocketTaggedUsersNotification(messageObject, extPerm) {
    try {
      if (
        userDataRef.current.Udid === messageObject.notifyContactUserglobalid
      ) {
        notifSound.current?.play().catch((error) => {
          console.log(error);
        });
        // const { enableCustomTime, notifHintTimeout } = await getUserConfig();
        const enableCustomTime = userConfig.enableCustomTime;
        const notifHintTimeout = userConfig.notifHintTimeout;
        // if (getFromLocal("enableCustomTime")) {
        if (enableCustomTime) {
          const container = hintNotifContainerRef.current;

          const phone = messageObject.displayPhoneNumber;
          const targetUser = chatUsersGlobalRef.current.notMyChats.find(
            (user) => user.UserDisplayPhoneNumber === phone,
          );

          if (
            (applicationType === "web" &&
              browserNotifPermissionRef.current === "granted") ||
            (applicationType === "extension" && extPerm === "granted")
          ) {
            handleBrowserNotification(
              `Funner - ${targetUser.FullName}`,
              messageObject.notificationText,
            );
          } else {
            enqueueSnackbar(
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      handleSelectedFunnerChatUser(targetUser);
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item>{targetUser?.FullName || ""}</Grid>
                      <Grid item>{messageObject.displayPhoneNumber}</Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {messageObject.notificationText}
                </Grid>
              </Grid>,
              {
                variant: "info",
              },
            );
          }
        }
      }
    } catch (error) {
      console.log("onSocketTaggedUsersNotification error: ", error);
    }
  }
  function onSocketLabelUpdate(messageObject) {
    try {
      updateChatUser(messageObject.phoneId, { LableName: messageObject.label });
    } catch (error) {
      console.log("onSocketLabelUpdate error: ", error);
    }
  }
  // function onSocketBotStatusUpdate(messageObject) {
  //   try {
  //     const configObject = JSON.parse(messageObject.configObj);
  //     handleChatUserConfig(
  //       messageObject.phoneId,
  //       { isBotActive: configObject.isBotActive },
  //       false,
  //     );
  //   } catch (error) {
  //     console.log("onSocketBotStatusUpdate error: ", error);
  //   }
  // }
  function onSocketConversationOpenStatus(messageObject) {
    try {
      updateChatUser(messageObject.chatUserPhoneId, {
        IsOpen: messageObject.isOpen,
      });
    } catch (error) {
      console.log("onSocketConversationOpenStatus: ", error);
    }
  }
  function onSockerFunnerServiceNotification(messageObject) {
    const { notificationText, type, durationSeconds } = messageObject;
    const scheme = type.split("/");
    enqueueSnackbar(notificationText, {
      autoHideDuration: durationSeconds * 1000,
      variant: "info",
      style: {
        backgroundColor: scheme[0],
        color: scheme[1],
      },
      iconVariant: {
        info: (
          <NotificationIcon
            type="info"
            customColors={{
              bgcolor: scheme[0],
              color: scheme[1],
            }}
          />
        ),
      },
    });
  }

  function activeTabChangesListener() {
    // eslint-disable-next-line no-undef
    chrome.runtime.onMessage.addListener(async function (
      message,
      sender,
      sendResponse,
    ) {
      if (message.type === "socketReceiveMessage") {
        const { messageObject } = message.content;
        onSocketReceiveMessage(messageObject);
      }
      if (message.type === "socketUnreadNotification") {
        const { messageObject } = message.content;
        onSocketUnreadNotification(messageObject);
      }
      if (message.type === "socketUpdateChatUserUnread") {
        const { messageObject } = message.content;
        onSocketUpdateChatUserUnread(messageObject);
      }
      if (message.type === "socketNotification") {
        const { messageObject } = message.content;
        onSocketNotification(messageObject);
      }
      if (message.type === "socketTaggedUsersNotification") {
        const { messageObject } = message.content;
        onSocketTaggedUsersNotification(messageObject);
      }
      if (message.type === "labelUpdate") {
        const { messageObject } = message.content;
        onSocketLabelUpdate(messageObject);
      }
      // if (message.type === "botStatusUpdate") {
      //   const { messageObject } = message.content;
      //   onSocketBotStatusUpdate(messageObject);
      // }
      if (message.type === "conversationOpenStatus") {
        const { messageObject } = message.content;
        onSocketConversationOpenStatus(messageObject);
      }
      if (message.type === "funnerServiceNotification") {
        const { messageObject } = message.content;
        onSockerFunnerServiceNotification(messageObject);
      }
    });
  }

  useEffect(() => {
    onAppConnectedActions();
    if (tabActiveRef.current === false && applicationType === "extension") {
      activeTabChangesListener();
    }

    if (applicationType === "extension") {
      // eslint-disable-next-line no-undef
      chrome.runtime.onMessage.addListener(async function (
        message,
        sender,
        sendResponse,
      ) {
        if (message.type === "closeHintNotif") {
          closeHintNotification();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isConnection) {
      initSocketListener();
    }
  }, [isConnection]);

  return (
    <div
      className={`rtl config-popup root-whatsapp ${
        applicationType === "web" ? "funner-global-web-app-page" : ""
      } ${isNavOpen ? "nav-open" : ""}`}
    >
      {/* Audio notification component */}
      {/* <AudioNotification
        notifSound={notifSound}
        isNotifOn={userConfig.isNotifOn}
        volume={userConfig.audioNotifVol}
      /> */}
      <svg width="0" height="0" style={{ display: "none" }}>
        <defs>
          <filter id="toWhiteColorFilter">
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1
                      0 0 0 0 1
                      0 0 0 0 1
                      0 0 0 1 0"
            />
          </filter>
        </defs>
      </svg>

      {/* On page Bottom Buttons */}
      <div className="funner-status unred-messages" ref={appButtonsRef}>
        {token && (
          <>
            <div
              className={`funner-status__button funner-status__button--chat ${
                !token ? "not-login" : ""
              }`}
              onClick={token ? toggleUnreadMessagesPopup : null}
            >
              <img src={hintLogo} alt="" />
            </div>
            {totalUnredMessages && totalUnreadChats && (
              <div
                style={{
                  fontWeight: "bold",
                  backgroundColor: "red",
                  fontFamily: "Arimo, sans-serif",
                  color: "white",
                  padding: "2px 12px",
                  borderRadius: "14px",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  direction: "ltr",
                }}
                onClick={openUnreadMessagesChat}
              >
                {`${totalUnreadChats} (${totalUnredMessages})`}
              </div>
            )}
          </>
        )}
      </div>
      {applicationType === "web" && (
        <SettingsPopup
          token={token}
          iframeMode={iframeMode}
          businessInfo={businessInfo}
          setBusinessInfo={setBusinessInfo}
          businessInfoRef={businessInfoRef}
          toggleNavPanel={toggleNavPanel}
          closeNavPanel={closeNavPanel}
          handleRemoveToken={handleRemoveToken}
          isNavOpen={isNavOpen}
          setNavOpen={setNavOpen}
          userData={userData}
          updateUserData={updateUserData}
          userConfig={userConfig}
          userConfigRef={userConfigRef}
          updateUserConfig={updateUserConfig}
          templatesList={templatesList}
          fetchWhatsappTemplates={fetchWhatsappTemplates}
          handleSetNotif={handleSetNotif}
          handleUsersFireberrySettingsWindow={
            handleUsersFireberrySettingsWindow
          }
          selectedUsersFireberrySync={selectedUsersFireberrySync}
          setSelectedUsersFireberrySync={setSelectedUsersFireberrySync}
          handleUsersPrioritySettingsWindow={handleUsersPrioritySettingsWindow}
          selectedUsersPrioritySync={selectedUsersPrioritySync}
          setSelectedUsersPrioritySync={setSelectedUsersPrioritySync}
          funnerUsers={funnerUsers}
          setFunnerUsers={setFunnerUsers}
          getFunnerUsers={getFunnerUsers}
          setIsActiveAppOnPage={setIsActiveAppOnPage}
          chatUsersGlobal={chatUsersGlobal}
          handleApplicationUserProfile={handleApplicationUserProfile}
          chatUsersLabelsList={chatUsersLabelsList}
          setChatUsersLabelsList={setChatUsersLabelsList}
          handleSubmitType={handleSubmitType}
          handleSelectedFunnerChatUser={handleSelectedFunnerChatUser}
          updateChatUser={updateChatUser}
          setUserInSettingsCreationState={setUserInSettingsCreationState}
          getCompanyChatUsersGlobal={getCompanyChatUsersGlobal}
          userDataRef={userDataRef}
          chatUsersFunnerRef={chatUsersFunnerRef}
          chatUsersGlobalRef={chatUsersGlobalRef}
          updateFunnerChatUsersStates={updateFunnerChatUsersStates}
          updateGlobalChatUsersStates={updateGlobalChatUsersStates}
          handleSetToken={handleSetToken}
          isMyUsersChats={isMyUsersChats}
          handleGoToFavouriteMessageState={handleGoToFavouriteMessageState}
        />
      )}

      <FunnerGlobalMessanger
        iframeMode={iframeMode}
        openUnreadMessagesPopup={openUnreadMessagesPopup}
        chatUsersFunner={chatUsersFunner}
        chatUsersFunnerRef={chatUsersFunnerRef}
        chatUsersGlobal={chatUsersGlobal}
        chatUsersGlobalRef={chatUsersGlobalRef}
        getContactsQueryParamsRef={getContactsQueryParamsRef}
        getCompanyChatUsersGlobal={getCompanyChatUsersGlobal}
        selectedFunnerChatUser={selectedFunnerChatUser}
        selectedFunnerChatUserRef={selectedFunnerChatUserRef}
        handleSelectedFunnerChatUser={handleSelectedFunnerChatUser}
        updateFunnerChatUsersStates={updateFunnerChatUsersStates}
        updateChatUser={updateChatUser}
        isNavOpen={isNavOpen}
        userData={userData}
        userDataRef={userDataRef}
        userConfig={userConfig}
        userConfigRef={userConfigRef}
        businessInfo={businessInfo}
        funnerUsers={funnerUsers}
        chatUsersLabelsList={chatUsersLabelsList}
        updateUserConfig={updateUserConfig}
        handleSetNotif={handleSetNotif}
        toggleUnreadMessagesPopup={toggleUnreadMessagesPopup}
        templatesList={templatesList}
        templatesLoading={templatesLoading}
        fetchWhatsappTemplates={fetchWhatsappTemplates}
        connection={connection}
        isConnection={isConnection}
        setTotalUnredMessages={setTotalUnredMessages}
        setTotalUnreadChats={setTotalUnreadChats}
        handleUsersFireberrySettingsWindow={handleUsersFireberrySettingsWindow}
        setSelectedUsersFireberrySync={setSelectedUsersFireberrySync}
        handleUsersPrioritySettingsWindow={handleUsersPrioritySettingsWindow}
        setSelectedUsersPrioritySync={setSelectedUsersPrioritySync}
        tabActiveRef={tabActiveRef}
        handlePrioritySendDocsWindow={handlePrioritySendDocsWindow}
        sendUploadPriority={sendUploadPriority}
        resetSendUploadPriority={resetSendUploadPriority}
        chatUsersMessagesRef={chatUsersMessagesRef}
        userInSettingsCreationState={userInSettingsCreationState}
        handleChatUserMessages={handleChatUserMessages}
        clearChatUsersMessages={clearChatUsersMessages}
        disableChatGoBack={disableChatGoBack}
        getChatMissingHistory={getChatMissingHistory}
        isMyUsersChats={isMyUsersChats}
        setMyUsersChat={setMyUsersChat}
        goToFavouriteMessageState={goToFavouriteMessageState}
        goToFavouriteMessageStateRef={goToFavouriteMessageStateRef}
        handleGoToFavouriteMessageState={handleGoToFavouriteMessageState}
      />

      {businessInfo.CompanyType === "fireberry" && (
        <FireberryPlatform
          isPowerLinkPage={isPowerLinkPage}
          userData={userData}
          userDataRef={userDataRef}
          chatUsersGlobal={chatUsersGlobal}
          chatUsersGlobalRef={chatUsersGlobalRef}
          selectedFireberryChatUser={selectedFireberryChatUser}
          selectedFireberryChatUserRef={selectedFireberryChatUserRef}
          handleSelectedFireberryChatUser={handleSelectedFireberryChatUser}
          userConfig={userConfig}
          funnerUsers={funnerUsers}
          handleSetNotif={handleSetNotif}
          chatUsersLabelsList={chatUsersLabelsList}
          updateChatUser={updateChatUser}
          businessInfo={businessInfo}
          templatesList={templatesList}
          templatesLoading={templatesLoading}
          fetchWhatsappTemplates={fetchWhatsappTemplates}
          connection={connection}
          isConnection={isConnection}
          tabActiveRef={tabActiveRef}
          isUsersFireberrySyncWindow={isUsersFireberrySyncWindow}
          handleUsersFireberrySettingsWindow={
            handleUsersFireberrySettingsWindow
          }
          selectedUsersFireberrySync={selectedUsersFireberrySync}
          setSelectedUsersFireberrySync={setSelectedUsersFireberrySync}
          chatUsersMessagesRef={chatUsersMessagesRef}
          handleChatUserMessages={handleChatUserMessages}
          getChatMissingHistory={getChatMissingHistory}
        />
      )}

      {businessInfo.CompanyType === "priority" && (
        <PriorityPlatform
          userData={userData}
          userDataRef={userDataRef}
          businessInfo={businessInfo}
          businessInfoRef={businessInfoRef}
          selectedFunnerChatUser={selectedFunnerChatUser}
          handleSelectedFunnerChatUser={handleSelectedFunnerChatUser}
          isPrioritySendDocsWindow={isPrioritySendDocsWindow}
          handlePrioritySendDocsWindow={handlePrioritySendDocsWindow}
          handleSetNotif={handleSetNotif}
          templatesList={templatesList}
          isPriorityDocumentWindow={isPriorityDocumentWindow}
          handlePriorityDocumentsWindow={handlePriorityDocumentsWindow}
          funnerUsers={funnerUsers}
          chatUsersGlobal={chatUsersGlobal}
          chatUsersGlobalRef={chatUsersGlobalRef}
          isActiveAppOnPageRef={isActiveAppOnPageRef}
          setOpenUnreadMessagesPopup={setOpenUnreadMessagesPopup}
          fetchWhatsappTemplates={fetchWhatsappTemplates}
          isUsersPrioritySyncWindow={isUsersPrioritySyncWindow}
          handleUsersPrioritySettingsWindow={handleUsersPrioritySettingsWindow}
          selectedUsersPrioritySync={selectedUsersPrioritySync}
          setSelectedUsersPrioritySync={setSelectedUsersPrioritySync}
          sendUploadPriority={sendUploadPriority}
          handleSendPriorityUploadState={handleSendPriorityUploadState}
          resetSendUploadPriority={resetSendUploadPriority}
          appButtonsRef={appButtonsRef}
          disableChatGoBack={disableChatGoBack}
          setDisableChatGoBack={setDisableChatGoBack}
          openUnreadMessagesPopup={openUnreadMessagesPopup}
        />
      )}

      <div className="hint-notif-container" ref={hintNotifContainerRef} />
    </div>
  );
}

export default Main;
