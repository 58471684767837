import React, {
  Fragment,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import localizations from "../../localizations.json";
import {
  changeImage,
  docx,
  filePresent,
  locationIMG,
  paperPlaneSendIcon,
  uploadPreview,
  videoPlus,
  xlsx,
  zip,
} from "../../libs/images.js";
import {
  UseFetchGet,
  UseFetchPostAnonymos,
} from "../../hooks/fetchFunctions.js";
import { implementedVars } from "../../libs/implementedVars.js";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import {
  AddLocationOutlined,
  AddPhotoAlternateOutlined,
  Close,
  CodeOutlined,
  CollectionsOutlined,
  DescriptionOutlined,
  DoneAll,
  EditOutlined,
  ExpandMore,
  FileUploadOutlined,
  ImageOutlined,
  OpenInNewOutlined,
  PhoneOutlined,
  ReplyOutlined,
  Send,
  StickyNote2Outlined,
  UploadFileOutlined,
  VideoCameraBackOutlined,
  VideoFileOutlined,
} from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { languageIcons } from "../../libs/languages.js";
import axios from "axios";
import { VisuallyHiddenInput } from "../../modules/VisuallyHiddenInput.jsx";
import FilesListLayout from "../Settings/UploadFiles/FilesListLayout.jsx";

const ParamInput = styled((props) => (
  <TextField
    size="small"
    fullWidth
    // InputProps={{ ...props.InputProps, sx: { bgcolor: "common.white" } }}
    {...props}
  />
))(({ theme }) => ({
  "& input": {
    height: "1.5em",
    // backgroundColor: "white",
    boxSizing: "content-box !important",
  },
  "& .MuiInputBase-root": {
    backgroundColor: "white", // Сделает фон белым
  },
}));

const SingleTemplate = memo(
  ({
    template,
    chatUser,
    isThisActive,
    setActiveTemplateFunction,
    whatsappTemplatesList,
    createParamsObject,
    handleSendTemplate,
    templateFileLink,
    setTemplateFileLink,
    setTemplateFileName,
    filters,
    templatesMenuRef,
    resetTemplate,
    templatesWithSend,
    noSendTemplateAction,
    noUpload,
    hebrewNamePopoverRef,
    setHebrewNamePopover,
  }) => {
    const theme = useTheme();

    const [localization, setLocalization] = useState(
      localizations["he"].templatesMenu,
    );
    const [templateHeader, setTemplateHeader] = useState(null);
    const [templateBody, setTemplateBody] = useState(null);
    const [templateFooter, setTemplateFooter] = useState(null);
    const [templateButtons, setTemplateButtons] = useState(null);

    const [thisTemplateLoading, setThisTemplateLoading] = useState(false);
    const [templateMediaLoading, setTemplateMediaLoading] = useState(false);
    const [chooseFromGallery, setChooseFromGallery] = useState(false);

    const [varsData, setVarsData] = useState([]);
    const [templateErrors, setTemplateErrors] = useState({});
    // const [templateErrors, setTemplateErrors] = useState({
    //   header: false,
    //   body: [],
    // });

    const paramNameByVariable = {
      [localizations["he"].implementedVariables["$contact.accountid"].name]: (
        chatUser,
      ) => chatUser.Id,
      [localizations["he"].implementedVariables[
        "$contact.last_incoming_message"
      ].name]: (chatUser) => chatUser.IncomingLastMessage,
      [localizations["he"].implementedVariables["$contact.accountname"].name]: (
        chatUser,
      ) => chatUser.FullName,
      [localizations["he"].implementedVariables["$contact.phone"].name]: (
        chatUser,
      ) => chatUser.UserDisplayPhoneNumber,
      [localizations["he"].implementedVariables["$contact.contactd"].name]: (
        chatUser,
      ) => "",
      [localizations["he"].implementedVariables["$contact.firstName"].name]: (
        chatUser,
      ) => chatUser.FullName.split(" ")[0],
    };

    const templatePreviewsByFileType = {
      pdf: uploadPreview,
      docx: docx,
      xlsx: xlsx,
      zip: zip,
    };
    const varsRegular = /{{(\d+)}}/g;

    const headerMediaParam = varsData.find((param) => param.name === "data");
    const mediaFile = useMemo(() => {
      if (isThisActive) {
        console.log(headerMediaParam);
      }

      if (headerMediaParam) {
        return {
          type: headerMediaParam.label,
          url: headerMediaParam.text,
        };
      } else return null;
    }, [headerMediaParam?.text]);

    const hebrewName =
      whatsappTemplatesList.find((temp) => template?.id === temp.id)
        ?.hebrewName || null;
    const isVars = (() => {
      const totalCount = template.components?.reduce((count, component) => {
        const headerLocationMatch =
          component?.type === "HEADER" && component.format === "LOCATION"
            ? 4
            : 0;

        const urlMatches =
          component?.buttons?.reduce((btnCount, btn) => {
            if (btn.type === "URL") {
              const urlMatchCount = (btn.url?.match(/{{(\d+)}}/g) || []).length;
              return btnCount + urlMatchCount;
            }
            return btnCount;
          }, 0) || 0;

        const textMatches = (component?.text?.match(/{{(\d+)}}/g) || []).length;

        return count + headerLocationMatch + urlMatches + textMatches;
      }, 0);

      return {
        haveVars: totalCount > 0,
        count: totalCount,
      };
    })();

    // const [varsData, setVarsData] = useState(null);
    const [showImplementedVars, setShowImplementedVars] = useState({
      show: false,
      id: null,
    });

    const hebrewNameBtnRef = useRef(null);

    const toggleImplementedVars = (id) => {
      const prevValue = showImplementedVars.show;
      if (prevValue && id === showImplementedVars.id) {
        setShowImplementedVars({
          show: false,
          id: null,
        });
      } else {
        setShowImplementedVars({
          show: true,
          id: id,
        });
      }
    };
    const placeVarToInput = (id, value) => {
      const targetInput = templatesMenuRef.current.querySelector(
        `#param-input-${id}`,
      );

      targetInput.value = value;
      toggleImplementedVars(id);
      if (templatesWithSend) {
        createParamsObject();
      } else {
        noSendTemplateAction();
      }
    };

    // async function handleImgUploadTemplate(e) {
    //   try {
    //     const file = e.target.files?.[0];
    //     if (!file) {
    //       console.error("No file selected");
    //     }

    //     // if (!file.type.startsWith("image/")) {
    //     //   console.error("Only image files are allowed");
    //     // }

    //     const formData = new FormData();
    //     if (file.type.startsWith("image/")) {
    //       formData.append("image", file);
    //       const response = await UseFetchPostAnonymos(
    //         "/api/services/GetImageLink",
    //         formData,
    //         "config",
    //       );
    //       const { data } = response;
    //       if (data.result == true) {
    //         const fileType = "image";
    //         setTemplateFileLink(data?.link);
    //         setTemplateFileName(file.name);
    //         if (!templatesWithSend) noSendTemplateAction(data?.link);
    //         // console.log('handleImgUploadTemplate: image', messageConfig.templateFileLink)
    //         // renderTemplateFile(fileType);
    //       }
    //     } else if (file.type.startsWith("application/")) {
    //       formData.append("file", file);
    //       const response = await UseFetchPostAnonymos(
    //         "/api/whatsapp/SaveFile",
    //         formData,
    //         "config",
    //       );
    //       const { data } = response;
    //       console.log("data: ", data);
    //       if (data.result == true) {
    //         const fileType = getFileFormatFromURL(data.link);
    //         setTemplateFileLink(data?.link);
    //         setTemplateFileName(file.name);
    //         if (!templatesWithSend) noSendTemplateAction(data?.link);
    //         // renderTemplateFile(fileType);
    //       }
    //       console.log("handleImgUplaoadTemplate: formData", formData);
    //     } else if (file.type.startsWith("video/")) {
    //       const maxSize = 16 * 1024 * 1024; // 16 MB
    //       if (file.size > maxSize) {
    //         e.target.value = null;
    //         return enqueueSnackbar("גודל הווידיאו חייב להיות עד 16 מגה בייט", {
    //           variant: "error",
    //         });
    //       }

    //       formData.append("file", file);
    //       const response = await UseFetchPostAnonymos(
    //         "/api/whatsapp/SaveFile",
    //         formData,
    //         "config",
    //       );
    //       const { data } = response;
    //       console.log("data: ", data);
    //       if (data.result == true) {
    //         const fileType = "video";
    //         setTemplateFileLink(data?.link);
    //         setTemplateFileName(file.name);
    //         if (!templatesWithSend) noSendTemplateAction(data?.link);
    //         // renderTemplateFile(fileType);
    //       }
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   } finally {
    //     // e.target.value = null;
    //   }
    // }
    function getFileFormatFromURL(url) {
      const fileExtension = url.split(".").pop();
      return fileExtension.toLowerCase();
    }

    const handleButtonHebrewNameClick = (e) => {
      const button = hebrewNameBtnRef.current;
      const top = button.offsetTop;
      const left = button.offsetLeft;

      setHebrewNamePopover({
        open: true,
        position: {
          top: top + 30,
          left: left,
        },
        template: template,
        target: e.currentTarget,
      });
    };

    //! Handle template data
    // const handleTemplateComponentParams = (component, params) => {
    //   if (component === "header") {
    //     setTemplateHeader((prevObj) => ({ ...prevObj, ...params }));
    //   } else if (component === "body") {
    //     setTemplateBody((prevObj) => ({ ...prevObj, ...params }));
    //   } else if (component === "footer") {
    //     setTemplateFooter((prevObj) => ({ ...prevObj, ...params }));
    //   } else if (component === "buttons") {
    //     setTemplateButtons((prevObj) => ({ ...prevObj, ...params }));
    //   }
    // };
    const handleTemplateComponentTextParam = (paramName, value, paramIndex) => {
      setVarsData((prevList) => {
        const newList = [...prevList];
        const targetParam = newList.find(
          (param, index) =>
            param.name === paramName &&
            (!paramName.includes("buttons") || index === paramIndex),
        );
        targetParam.text = value;
        return newList;
      });
      handleTemplateErrors({ [paramName]: value === "" });
    };
    const uploadTemplateFile = async (e) => {
      setTemplateMediaLoading(true);
      const fileLink = await handleImgUploadTemplate(e);
      if (fileLink) {
        handleTemplateComponentTextParam("data", fileLink);
      }
      setTemplateMediaLoading(false);
    };
    async function handleImgUploadTemplate(e) {
      try {
        const file = e.target.files?.[0];
        if (!file) {
          console.error("No file selected");
        }

        const formData = new FormData();
        if (file.type.startsWith("image/")) {
          formData.append("image", file);
          const response = await UseFetchPostAnonymos(
            "/api/services/GetImageLink",
            formData,
            "config",
          );
          const { data } = response;
          if (data?.result) {
            return data.link;
          } else return null;
        } else if (file.type.startsWith("application/")) {
          formData.append("file", file);
          const response = await UseFetchPostAnonymos(
            "/api/whatsapp/SaveFile",
            formData,
            "config",
          );
          const { data } = response;
          if (data?.result) {
            return data.link;
          } else return null;
        } else if (file.type.startsWith("video/")) {
          const maxSize = 16 * 1024 * 1024; // 16 MB
          if (file.size > maxSize) {
            e.target.value = null;
            return enqueueSnackbar("גודל הווידיאו חייב להיות עד 16 מגה בייט", {
              variant: "error",
            });
          }

          formData.append("file", file);
          const response = await UseFetchPostAnonymos(
            "/api/whatsapp/SaveFile",
            formData,
            "config",
          );
          const { data } = response;
          if (data?.result) {
            return data.link;
          } else return null;
        } else return null;
      } catch (error) {
        console.error(error);
        return null;
      } finally {
        setTimeout(() => {
          e.target.value = null;
        }, 200);
      }
    }
    const handleTemplateErrors = (params) => {
      setTemplateErrors((prevObj) => ({ ...prevObj, ...params }));
    };

    //! Send template
    // const handleTemplateErrors = (params) => {
    //   setTemplateErrors((prevErrors) => ({ ...prevErrors, ...params }));
    // };
    // const checkValid = () => {
    //   const headerError =
    //     templateHeader &&
    //     templateHeader.format === "TEXT" &&
    //     templateHeader.parameters &&
    //     templateHeader.parameters[0].text === "";

    //   const bodyErrors = templateBody.parameters?.length
    //     ? templateBody.parameters.map((param) => param.text === "")
    //     : [];

    //   const validationObject = {
    //     header: headerError,
    //     body: bodyErrors,
    //   };

    //   console.log(validationObject);

    //   handleTemplateErrors(validationObject);
    //   return !(
    //     validationObject.header && validationObject.body.some((err) => err)
    //   );
    //   // const inputs = templatesMenuRef.current.querySelectorAll(
    //   //   ".form-get-token-popup__input",
    //   // );
    //   // const mediaInput = templatesMenuRef.current.querySelector(
    //   //   ".preview-content-manage-templates__header-media input",
    //   // );
    //   // const container = templatesMenuRef.current.querySelector(
    //   //   ".template-chat-sidebar",
    //   // );
    //   // let allInputsValid = true;
    //   // for (let i = 0; i < inputs.length; i++) {
    //   //   if (!inputs[i].value || inputs[i].value === "") {
    //   //     allInputsValid = false;
    //   //     break;
    //   //   }
    //   // }
    //   // if (mediaInput) {
    //   //   allInputsValid = allInputsValid && mediaInput.files.length > 0;
    //   // }
    //   // if (allInputsValid) {
    //   //   if (container.classList.contains("error"))
    //   //     container.classList.remove("error");
    //   //   return true;
    //   // } else {
    //   //   if (!container.classList.contains("error"))
    //   //     container.classList.add("error");
    //   //   return false;
    //   // }
    // };

    const checkValid = () => {
      const errorsObj = {};
      varsData.forEach((param) => {
        errorsObj[param.name] = param.text === "";
      });
      handleTemplateErrors(errorsObj);
      return !Object.values(errorsObj).some((val) => val);
    };

    const submitSendTemplate = () => {
      const isValid = checkValid();
      // const isValid = true;
      if (isValid) {
        // createParamsObject({
        //   header: templateHeader,
        //   body: templateBody,
        //   footer: templateFooter,
        //   buttons: templateButtons,
        // });
        const formattedParams = {};
        varsData.forEach((param) => {
          formattedParams[param.name] = param.text;
        });
        handleSendTemplate(template.name, formattedParams);
      }
    };

    useEffect(() => {
      //! Template header
      const headerComp = template.components?.find(
        (comp) => comp.type === "HEADER",
      );
      if (headerComp) {
        setTemplateHeader({ ...headerComp });
        // const params = [];
        // if (headerComp.format === "TEXT") {
        //   const paramsMatch = Array.from(
        //     headerComp.text?.matchAll(varsRegular),
        //     (match) => match[1],
        //   );
        //   if (paramsMatch?.length) {
        //     params.push({
        //       type: "text",
        //       text: "",
        //     });
        //   }
        // }
        // if (params.length) {
        //   setTemplateHeader({ ...headerComp, parameters: params });
        // } else {
        //   setTemplateHeader({ ...headerComp });
        // }
      }
      //! Body comp
      const bodyComp = template.components?.find(
        (comp) => comp.type === "BODY",
      );
      setTemplateBody({ ...bodyComp });
      // if (bodyComp) {
      // let bodyParams = [];
      // const paramsMatches = Array.from(
      //   bodyComp.text?.matchAll(varsRegular),
      //   (match) => match[1],
      // );

      // if (paramsMatches?.length) {
      //   bodyParams = paramsMatches.map((param) => ({
      //     type: "text",
      //     text: "",
      //   }));
      // }
      //   if (bodyParams.length) {
      //     setTemplateBody({ ...bodyComp, parameters: bodyParams });
      //   } else {

      //   }
      // }
      //! Footer comp
      const footerComp = template.components?.find(
        (comp) => comp.type === "FOOTER",
      );
      if (footerComp) {
        setTemplateFooter({ ...footerComp });
      }
      //! Buttons comp
      const buttonsComp = template.components?.find(
        (comp) => comp.type === "BUTTONS",
      );
      if (buttonsComp) {
        setTemplateButtons({ ...buttonsComp });
      }
    }, []);

    async function getTemplateVarsData() {
      try {
        setThisTemplateLoading(true);
        const res = await UseFetchGet(
          "/api/make/GetTemplateWithMediaData",
          {
            templateName: template.name,
          },
          null,
          false,
          "app",
        );
        // const res = await axios.get(
        //   `https://chrometest.codersuccess.com/api/make/GetTemplateWithMediaData?templateName=${template.name}`,
        // );

        if (res?.data?.spec?.length > 0) {
          setVarsData(res.data.spec.map((param) => ({ ...param, text: "" })));
        }
      } catch (error) {
        console.error("getTemplateVarsData: ", error);
      } finally {
        setThisTemplateLoading(false);
      }
    }

    useEffect(() => {
      if (isThisActive) {
        getTemplateVarsData();
      }
    }, [isThisActive]);

    return (
      <Box>
        {/* Template content */}
        <Box
          sx={{
            bgcolor: isThisActive ? "#f0f0f0" : "common.white",
            borderRadius: 1,
            boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
            cursor: "pointer",
            p: 1.5,
            position: "relative",
          }}
          onClick={(e) =>
            !isThisActive &&
            !hebrewNameBtnRef?.current?.contains(e.target) &&
            !hebrewNamePopoverRef?.current?.contains(e.target)
              ? setActiveTemplateFunction(template)
              : null
          }
        >
          <Grid container spacing={0}>
            {/* Template header */}
            <Grid item xs={12}>
              <Grid container spacing={1.875} alignItems={"center"}>
                {/* Template name */}
                <Grid item flexGrow={1}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 0.625,
                    }}
                  >
                    {hebrewName ? (
                      <Grid item>
                        <Tooltip
                          title={
                            filters.isHebrew
                              ? template.name
                              : hebrewName.HebrewTemaplateName
                          }
                        >
                          <Typography
                            fontSize={14}
                            sx={{
                              maxWidth: 200,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              width: "auto",
                            }}
                          >
                            {hebrewName
                              ? filters.isHebrew
                                ? hebrewName.HebrewTemaplateName
                                : template.name
                              : template.name}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    ) : (
                      <Fragment>
                        <Grid item>
                          <Typography
                            fontSize={14}
                            sx={{
                              maxWidth: 200,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              width: "auto",
                            }}
                          >
                            {hebrewName
                              ? filters.isHebrew
                                ? hebrewName.HebrewTemaplateName
                                : template.name
                              : template.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            size="small"
                            sx={{ p: 0.5, color: "text.primary" }}
                            onClick={handleButtonHebrewNameClick}
                            ref={hebrewNameBtnRef}
                          >
                            <EditOutlined sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Grid>
                      </Fragment>
                    )}
                  </Box>
                </Grid>
                {/* Template icons */}
                <Grid item sx={{ mr: -1 }}>
                  <Grid
                    container
                    spacing={0.625}
                    alignItems={"center"}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        verticalAlign: "middle",
                      },
                    }}
                  >
                    {templateHeader?.format === "IMAGE" && (
                      <Grid item>
                        <Tooltip
                          title={localization.singleTemplate.media.image}
                        >
                          <ImageOutlined sx={{ fontSize: 18 }} />
                        </Tooltip>
                      </Grid>
                    )}
                    {templateHeader?.format === "VIDEO" && (
                      <Grid item>
                        <Tooltip
                          title={localization.singleTemplate.media.video}
                        >
                          <VideoCameraBackOutlined sx={{ fontSize: 18 }} />
                        </Tooltip>
                      </Grid>
                    )}
                    {templateHeader?.format === "DOCUMENT" && (
                      <Grid item>
                        <Tooltip title={localization.singleTemplate.media.doc}>
                          <StickyNote2Outlined sx={{ fontSize: 18 }} />
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item>
                      {isVars.haveVars ? (
                        <Tooltip title={"פרמטרים דינמיים"}>
                          <SvgIcon
                            sx={{
                              fontSize: 19,
                              "& text": {
                                fontWeight: "600 !important",
                              },
                            }}
                          >
                            <svg
                              width={20}
                              height={18}
                              viewBox="0 0 20 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.6118 7.5C13.2681 7.5 12.9868 7.1625 12.9868 6.75V5.25C12.9868 4.0125 12.1431 3 11.1118 3H9.23682V4.5H11.1118C11.4556 4.5 11.7368 4.8375 11.7368 5.25V6.75C11.7368 7.725 12.2618 8.565 12.9868 8.8725V9.1275C12.2618 9.435 11.7368 10.2675 11.7368 11.25V12.75C11.7368 13.1625 11.4556 13.5 11.1118 13.5H9.23682V15H11.1118C12.1431 15 12.9868 13.9875 12.9868 12.75V11.25C12.9868 10.8375 13.2681 10.5 13.6118 10.5H14.2368V7.5H13.6118Z"
                                fill="black"
                                transform="translate(5)"
                              />
                              <text x={14} y={13} fontSize={14} fill="black">
                                {isVars.count}
                              </text>
                              <path
                                d="M2.98682 5.25V6.75C2.98682 7.1625 2.70557 7.5 2.36182 7.5H1.73682V10.5H2.36182C2.70557 10.5 2.98682 10.8375 2.98682 11.25V12.75C2.98682 13.9875 3.83057 15 4.86182 15H6.73682V13.5H4.86182C4.51807 13.5 4.23682 13.1625 4.23682 12.75V11.25C4.23682 10.275 3.71182 9.435 2.98682 9.1275V8.8725C3.71182 8.565 4.23682 7.725 4.23682 6.75V5.25C4.23682 4.8375 4.51807 4.5 4.86182 4.5H6.73682V3H4.86182C3.83057 3 2.98682 4.0125 2.98682 5.25Z"
                                fill="black"
                                transform="translate(-1)"
                              />
                            </svg>
                          </SvgIcon>
                        </Tooltip>
                      ) : (
                        <DescriptionOutlined sx={{ fontSize: 18 }} />
                      )}
                    </Grid>
                    <Grid item>
                      <SvgIcon
                        sx={{
                          fontSize: 18,
                        }}
                      >
                        <svg
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.0411 1.96167C5.93979 1.96167 3.4106 4.49086 3.4106 7.59217V8.29598V8.93106C3.00682 9.31609 2.70679 9.81139 2.70679 10.4074C2.70679 11.2815 3.31034 11.9297 4.07455 12.2467C4.99023 14.4354 6.82426 16.0379 9.0411 16.0379H11.1525V14.6303H9.0411C7.45157 14.6303 6.00834 13.4335 5.28285 11.5429L5.13302 11.1552L4.72062 11.0975C4.374 11.0498 4.11441 10.77 4.11441 10.4074C4.11441 10.1365 4.25124 9.92388 4.46494 9.8012L4.81822 9.59913V8.99979V8.29598C4.81822 7.89831 5.12436 7.59217 5.52204 7.59217H12.5602C12.9578 7.59217 13.264 7.89831 13.264 8.29598V8.99979V11.815H10.3869C10.3142 11.6094 10.1796 11.4314 10.0016 11.3054C9.82369 11.1794 9.61107 11.1115 9.393 11.1112C9.11301 11.1112 8.84448 11.2225 8.6465 11.4204C8.44851 11.6184 8.33729 11.887 8.33729 12.1669C8.33764 12.4336 8.4389 12.6903 8.62071 12.8853C8.80252 13.0804 9.05142 13.1994 9.3174 13.2185C9.34251 13.2213 9.36775 13.2226 9.393 13.2227H13.9678H14.6716C15.4458 13.2227 16.0792 12.5892 16.0792 11.815V10.4074C16.0792 9.63322 15.4458 8.99979 14.6716 8.99979V8.29598V7.59217C14.6716 4.49086 12.1424 1.96167 9.0411 1.96167ZM9.0411 3.36929C10.9101 3.36929 12.4832 4.57097 13.0427 6.2464C12.8869 6.2092 12.7266 6.18454 12.5602 6.18454H5.52204C5.35558 6.18454 5.19533 6.2092 5.03954 6.2464C5.59902 4.57097 7.17206 3.36929 9.0411 3.36929Z"
                            fill="#2A3847"
                          />
                        </svg>
                      </SvgIcon>
                    </Grid>
                    {languageIcons[template.language] && (
                      <Grid item>{languageIcons[template.language]}</Grid>
                    )}
                    <Grid item>
                      <IconButton
                        onClick={isThisActive ? resetTemplate : null}
                        size="small"
                        sx={{
                          ml: 1,
                          transform: isThisActive
                            ? "rotate(180deg)"
                            : "rotate(0)",
                          p: 0.25,
                        }}
                      >
                        <ExpandMore
                          sx={{ fontSize: 20, color: "common.black" }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Template text preview */}
            <Grid item xs={12}>
              <Typography fontSize={13} color="text.secondary">
                {template.components
                  .find((item) => item.type === "BODY")
                  .text.substring(0, 45)
                  .replace(/^[a-z\u05D0-\u05EA]+$/i, "")}
              </Typography>
            </Grid>
            {/* Template body (display if active) */}
            {isThisActive && (
              <Fragment>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {/* Template preview */}
                    <Grid item xs={12} sx={{ mt: 1.25 }}>
                      <Box
                        sx={{
                          position: "relative",
                          pl: 1.25,
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: "calc(100% - 10px)",
                            width: 0,
                            height: 0,
                            borderRight: "10px solid rgba(25, 118, 210, 0.12)",
                            borderBottom: "15px solid transparent",
                          }}
                        ></Box>
                        <Box
                          sx={{
                            backgroundColor: "rgba(25, 118, 210, 0.12)",
                            minWidth: 240,
                            maxWidth: 325,
                            p: 1.25,
                            borderRadius: " 0 8px 8px 8px",
                          }}
                        >
                          <Grid container spacing={1}>
                            {templateHeader && (
                              <Grid item xs={12}>
                                {templateHeader.format === "TEXT" ? (
                                  <Typography
                                    variant="body2"
                                    fontSize={14}
                                    fontWeight={700}
                                    sx={{
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    {varsData.find(
                                      (param) => param.name === "header_1",
                                    ) &&
                                    varsData.find(
                                      (param) => param.name === "header_1",
                                    )?.text !== ""
                                      ? templateHeader.text.replace(
                                          "{{1}}",
                                          varsData.find(
                                            (param) =>
                                              param.name === "header_1",
                                          ).text,
                                        )
                                      : templateHeader.text}
                                  </Typography>
                                ) : mediaFile?.url ? (
                                  mediaFile.type === "video" ? (
                                    <video
                                      src={mediaFile?.url}
                                      controls={false}
                                      style={{
                                        width: "100%",
                                        height: 175,
                                        background: theme.palette.common.white,
                                        objectFit: "contain",
                                      }}
                                    />
                                  ) : (
                                    <Box
                                      sx={{
                                        width: "100%",
                                        height: 175,
                                        background: theme.palette.common.white,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={
                                          mediaFile.type === "image"
                                            ? mediaFile.url
                                            : templatePreviewsByFileType[
                                                getFileFormatFromURL(
                                                  mediaFile.url,
                                                )
                                              ]
                                        }
                                        alt=""
                                        style={{
                                          width:
                                            mediaFile.type !== "image"
                                              ? "65%"
                                              : "100%",
                                          height:
                                            mediaFile.type !== "image"
                                              ? "65%"
                                              : "100%",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </Box>
                                  )
                                ) : (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: 175,
                                      background: theme.palette.common.white,
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      gap: 0.25,
                                      justifyContent: "center",
                                      color: theme.palette.secondary.main,
                                    }}
                                  >
                                    {templateHeader.format === "IMAGE" ? (
                                      <AddPhotoAlternateOutlined
                                        sx={{ width: 70, height: 70 }}
                                      />
                                    ) : templateHeader.format === "VIDEO" ? (
                                      <VideoFileOutlined
                                        sx={{ width: 70, height: 70 }}
                                      />
                                    ) : templateHeader.format === "DOCUMENT" ? (
                                      <UploadFileOutlined
                                        sx={{ width: 70, height: 70 }}
                                      />
                                    ) : templateHeader.format === "LOCATION" ? (
                                      <AddLocationOutlined
                                        sx={{ width: 70, height: 70 }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    <Typography
                                      fontSize={15}
                                      fontWeight={700}
                                      width="100%"
                                      textAlign={"center"}
                                    >
                                      העלאת קובץ
                                    </Typography>
                                  </Box>
                                )}
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                fontSize={14}
                                whiteSpace={"pre-line"}
                              >
                                {varsData.some((param) =>
                                  param.name.startsWith("body"),
                                )
                                  ? templateBody.text.replaceAll(
                                      /\{\{(\d+)\}\}/g,
                                      (_, match) => {
                                        const param = varsData.find(
                                          (param) =>
                                            param.name ===
                                            `body_${parseInt(match)}`,
                                        );
                                        return param && param.text !== ""
                                          ? param.text
                                          : `{{${match}}}`;
                                      },
                                    )
                                  : templateBody?.text}
                              </Typography>
                            </Grid>

                            {templateFooter && (
                              <Grid item xs={12}>
                                <Typography
                                  variant="subtitle2"
                                  color="text.secondary"
                                  fontSize={13}
                                >
                                  {templateFooter.text}
                                </Typography>
                              </Grid>
                            )}

                            <Grid item xs={12}>
                              <Grid container spacing={1} alignItems={"center"}>
                                <Grid item>
                                  <DoneAll
                                    sx={{
                                      fontSize: 16,
                                      color: "text.secondary",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    variant="body2"
                                    color={"rgba(0, 0, 0, 0.38)"}
                                    fontSize={12}
                                  >
                                    12/12/2022 10:25
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            {templateButtons && (
                              <Grid item xs={12}>
                                {templateButtons.buttons?.map(
                                  (button, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        mx: -1.25,
                                        px: 1.25,
                                        borderTop: "1px solid #c2c2c2",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          gap: 1,
                                          p: 1,
                                          color: theme.palette.secondary.main,
                                        }}
                                      >
                                        <Typography variant="body2">
                                          {button.text}
                                        </Typography>
                                        {button.type === "QUICK_REPLY" && (
                                          <ReplyOutlined />
                                        )}
                                        {button.type === "URL" && (
                                          <OpenInNewOutlined />
                                        )}
                                        {button.type === "PHONE_NUMBER" && (
                                          <PhoneOutlined />
                                        )}
                                        {button.type === "COPY_CODE" && (
                                          <CodeOutlined />
                                        )}
                                      </Box>
                                    </Box>
                                  ),
                                )}
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                    {thisTemplateLoading ? (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: 50,
                          }}
                        >
                          <CircularProgress size={32} color="secondary" />
                        </Box>
                      </Grid>
                    ) : (
                      varsData.length > 0 && (
                        <Grid item xs={12}>
                          <Grid container spacing={1.25}>
                            <Grid item xs={12}>
                              <Typography fontSize={14} fontWeight={600}>
                                {localization.componentsTitle}
                              </Typography>
                            </Grid>

                            {varsData.map((param, index) => {
                              const prevParam = varsData[index - 1];
                              return (
                                <Fragment key={`${param.name}-${index}`}>
                                  {param.name.replace(/\d+/g, "") !==
                                    prevParam?.name.replace(/\d+/g, "") && (
                                    <Grid item xs={12}>
                                      <Typography
                                        fontSize={14}
                                        fontWeight={600}
                                      >
                                        {param.name === "data"
                                          ? localization.header
                                          : localization[
                                              param.name
                                                .replace(/\d+/g, "")
                                                .slice(0, -1)
                                            ]}
                                      </Typography>
                                    </Grid>
                                  )}
                                  <Grid item xs={12} key={param.name}>
                                    <ParamInput
                                      value={param.text}
                                      error={templateErrors?.[param.name]}
                                      onChange={(e) =>
                                        handleTemplateComponentTextParam(
                                          param.name,
                                          e.target.value,
                                          index,
                                        )
                                      }
                                      label={
                                        param.name === "data"
                                          ? param.label
                                          : `{{${param.name.replaceAll(
                                              /\D/g,
                                              "",
                                            )}}}`
                                      }
                                      InputProps={{
                                        endAdornment:
                                          param.name === "data" ? (
                                            <InputAdornment position="end">
                                              {templateMediaLoading ? (
                                                <CircularProgress
                                                  size={20}
                                                  color="secondary"
                                                />
                                              ) : (
                                                <Fragment>
                                                  <IconButton
                                                    size="small"
                                                    component="label"
                                                  >
                                                    <FileUploadOutlined />
                                                    <VisuallyHiddenInput
                                                      type="file"
                                                      accept={
                                                        param.label === "image"
                                                          ? "image/*"
                                                          : param.label ===
                                                            "document"
                                                          ? ".docx,.doc, .sls,.xlsx,.pdf,.zip"
                                                          : param.label ===
                                                            "video"
                                                          ? "video/*"
                                                          : null
                                                      }
                                                      onChange={
                                                        uploadTemplateFile
                                                      }
                                                    />
                                                  </IconButton>
                                                  <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                      setChooseFromGallery(
                                                        param.label,
                                                      )
                                                    }
                                                  >
                                                    <CollectionsOutlined />
                                                  </IconButton>
                                                </Fragment>
                                              )}
                                            </InputAdornment>
                                          ) : null,
                                      }}
                                    />
                                  </Grid>
                                </Fragment>
                              );
                            })}
                          </Grid>
                        </Grid>
                      )
                    )}
                    <Grid item xs={12}></Grid>
                  </Grid>
                  {/* <Grid container spacing={2}> */}
                  {/* Params */}
                  {/* {isVars.haveVars && (
                    <Grid item xs={12}>
                      <Typography fontSize={14} fontWeight={600} mb={1.5}>
                        {localization.componentsTitle}
                      </Typography>
                      {(templateHeader?.parameters?.length ||
                        (templateHeader &&
                          templateHeader?.format !== "TEXT")) && (
                        <Grid container spacing={1.25}>
                          <Grid item xs={12}>
                            <Typography fontSize={14} fontWeight={600}>
                              {localization["header"]}
                            </Typography>
                          </Grid>
                          {templateHeader.parameters?.length &&
                            templateHeader.parameters.map((param) => (
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label={"{{1}}"}
                                  value={param.text}
                                  onChange={(e) =>
                                    handleTemplateComponentTextParam(
                                      "header",
                                      0,
                                      e.target.value,
                                    )
                                  }
                                  size="small"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment>
                                        <IconButton size="small">
                                          <QuickreplyOutlined />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    sx: {
                                      bgcolor: "common.white",
                                      "& input": {
                                        height: "1.625em",
                                      },
                                    },
                                  }}
                                  error={templateErrors.header}
                                />
                              </Grid>
                            ))}
                        </Grid>
                      )}
                      {templateBody?.parameters?.length > 0 && (
                        <Grid container spacing={1.25} mt={0.25}>
                          <Grid item xs={12}>
                            <Typography fontSize={14} fontWeight={600}>
                              {localization["body"]}
                            </Typography>
                          </Grid>
                          {templateBody.parameters?.length &&
                            templateBody.parameters.map((param, index) => (
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label={`{{${index + 1}}}`}
                                  value={param.text}
                                  onChange={(e) =>
                                    handleTemplateComponentTextParam(
                                      "body",
                                      index,
                                      e.target.value,
                                    )
                                  }
                                  size="small"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment>
                                        <IconButton size="small">
                                          <QuickreplyOutlined />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                    sx: {
                                      bgcolor: "common.white",
                                      "& input": {
                                        height: "1.625em",
                                      },
                                    },
                                  }}
                                  error={
                                    templateErrors.body?.length &&
                                    templateErrors.body[index]
                                  }
                                />
                              </Grid>
                            ))}
                        </Grid>
                      )}
                    </Grid>
                  )} */}

                  {templatesWithSend && (
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        onClick={submitSendTemplate}
                        endIcon={
                          <Send
                            sx={{
                              color: "common.white",
                            }}
                          />
                        }
                        fullWidth
                        sx={{
                          p: "6px 16px !important",
                          // width: 200,
                          display: "flex",
                          margin: "0 0 0 auto !important",
                          "& .MuiButton-icon": {
                            marginRight: "-4px !important",
                            marginLeft: "8px !important",
                            transform: "rotate(-180deg)",
                          },
                        }}
                        disabled={thisTemplateLoading}
                      >
                        {localization.singleTemplate.send}
                      </Button>
                    </Grid>
                  )}
                  {/* </Grid> */}
                </Grid>

                <Dialog
                  open={Boolean(chooseFromGallery)}
                  onClose={() => setChooseFromGallery(false)}
                  maxWidth={"lg"}
                  fullWidth
                >
                  <DialogTitle justifyContent={"flex-end"}>
                    <IconButton onClick={() => setChooseFromGallery(false)}>
                      <Close />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <FilesListLayout
                      type={chooseFromGallery}
                      companyId={null}
                      FunnerUserId={null}
                      noActions={true}
                      onClickAction={(fileLink) => {
                        handleTemplateComponentTextParam("data", fileLink);
                        setChooseFromGallery(false);
                      }}
                    />
                  </DialogContent>
                </Dialog>
              </Fragment>
            )}
          </Grid>
        </Box>
      </Box>
      // <div className="single-funner-template">
      //   <div
      //     className={`single-funner-template__content ${
      //       isThisActive ? "single-funner-template__content--active" : ""
      //     }`}
      //     onClick={(e) =>
      //       !isThisActive &&
      //       !hebrewNameBtnRef?.current?.contains(e.target) &&
      //       !hebrewNamePopoverRef?.current?.contains(e.target)
      //         ? setActiveTemplateFunction(template)
      //         : null
      //     }
      //   >
      //     <div className="single-funner-template__header">
      //       <div className={`single-funner-template__name-wrapper`}>
      //         {hebrewName ? (
      //           <Tooltip
      //             title={
      //               filters.isHebrew
      //                 ? template.name
      //                 : hebrewName.HebrewTemaplateName
      //             }
      //           >
      //             <div className="single-funner-template__name">
      //               {hebrewName
      //                 ? filters.isHebrew
      //                   ? hebrewName.HebrewTemaplateName
      //                   : template.name
      //                 : template.name}
      //             </div>
      //           </Tooltip>
      //         ) : (
      //           <>
      //             <div className="single-funner-template__name">
      //               {hebrewName
      //                 ? filters.isHebrew
      //                   ? hebrewName.HebrewTemaplateName
      //                   : template.name
      //                 : template.name}
      //             </div>
      //             <div
      //               className="single-funner-template__icon funner-icon"
      //               style={{ cursor: "pointer" }}
      //               onClick={handleButtonHebrewNameClick}
      //               ref={hebrewNameBtnRef}
      //             >
      //               <svg
      //                 width={21}
      //                 height={20}
      //                 viewBox="0 0 21 20"
      //                 fill="none"
      //                 xmlns="http://www.w3.org/2000/svg"
      //               >
      //                 <path
      //                   d="M15.8451 1.66675C15.6317 1.66675 15.4184 1.74839 15.2559 1.91089L13.5892 3.57756L12.4108 4.75594L3 14.1667V17.5001H6.33333L18.5892 5.24422C18.915 4.91839 18.915 4.39084 18.5892 4.06584L16.4342 1.91089C16.2717 1.74839 16.0584 1.66675 15.8451 1.66675ZM15.8451 3.67847L16.8216 4.65503L15.7441 5.7325L14.7676 4.75594L15.8451 3.67847ZM13.5892 5.93433L14.5658 6.91089L5.64323 15.8334H4.66667V14.8569L13.5892 5.93433Z"
      //                   fill="#2A3847"
      //                 />
      //               </svg>
      //             </div>
      //           </>
      //         )}
      //       </div>
      //       <div className="single-funner-template__icons-row">
      //         {templateHeader?.format === "IMAGE" && (
      //           <Tooltip title={localization.singleTemplate.media.image}>
      //             <ImageOutlined sx={{ fontSize: 18 }} />
      //           </Tooltip>
      //         )}
      //         {templateHeader?.format === "VIDEO" && (
      //           <Tooltip title={localization.singleTemplate.media.video}>
      //             <VideoCameraBackOutlined sx={{ fontSize: 18 }} />
      //           </Tooltip>
      //         )}
      //         {templateHeader?.format === "DOCUMENT" && (
      //           <Tooltip title={localization.singleTemplate.media.doc}>
      //             <StickyNote2Outlined sx={{ fontSize: 18 }} />
      //           </Tooltip>
      //         )}
      //         {/* {["IMAGE", "VIDEO", "DOCUMENT"].includes(
      //           templateHeader?.format,
      //         ) && (
      //           <div className="single-funner-template__icon funner-icon">
      //             <svg
      //               width={18}
      //               height={18}
      //               viewBox="0 0 18 18"
      //               fill="none"
      //               xmlns="http://www.w3.org/2000/svg"
      //             >
      //               <path
      //                 d="M8.56315 1.4668L3.34242 6.68753H6.45065V11.2646C6.45065 11.84 6.93155 12.3209 7.5069 12.3209H9.6194C10.1947 12.3209 10.6756 11.84 10.6756 11.2646V6.68753H13.7839L8.56315 1.4668ZM8.56315 3.45827L10.3841 5.2792H9.26732V10.9125H7.85898V5.2792H6.74222L8.56315 3.45827ZM1.52148 14.4334V15.8417H15.6048V14.4334H1.52148Z"
      //                 fill="black"
      //               />
      //             </svg>
      //           </div>
      //         )} */}
      //         {isVars.haveVars ? (
      //           <Tooltip title={"פרמטרים דינמיים"}>
      //             <Box
      //               className="single-funner-template__icon funner-icon"
      //               sx={{
      //                 "& svg text": {
      //                   fontWeight: "600 !important",
      //                 },
      //               }}
      //             >
      //               <svg
      //                 width={20}
      //                 height={18}
      //                 viewBox="0 0 20 18"
      //                 fill="none"
      //                 xmlns="http://www.w3.org/2000/svg"
      //               >
      //                 <path
      //                   d="M13.6118 7.5C13.2681 7.5 12.9868 7.1625 12.9868 6.75V5.25C12.9868 4.0125 12.1431 3 11.1118 3H9.23682V4.5H11.1118C11.4556 4.5 11.7368 4.8375 11.7368 5.25V6.75C11.7368 7.725 12.2618 8.565 12.9868 8.8725V9.1275C12.2618 9.435 11.7368 10.2675 11.7368 11.25V12.75C11.7368 13.1625 11.4556 13.5 11.1118 13.5H9.23682V15H11.1118C12.1431 15 12.9868 13.9875 12.9868 12.75V11.25C12.9868 10.8375 13.2681 10.5 13.6118 10.5H14.2368V7.5H13.6118Z"
      //                   fill="black"
      //                   transform="translate(5)"
      //                 />
      //                 <text x={14} y={13} fontSize={14} fill="black">
      //                   {isVars.count}
      //                 </text>
      //                 <path
      //                   d="M2.98682 5.25V6.75C2.98682 7.1625 2.70557 7.5 2.36182 7.5H1.73682V10.5H2.36182C2.70557 10.5 2.98682 10.8375 2.98682 11.25V12.75C2.98682 13.9875 3.83057 15 4.86182 15H6.73682V13.5H4.86182C4.51807 13.5 4.23682 13.1625 4.23682 12.75V11.25C4.23682 10.275 3.71182 9.435 2.98682 9.1275V8.8725C3.71182 8.565 4.23682 7.725 4.23682 6.75V5.25C4.23682 4.8375 4.51807 4.5 4.86182 4.5H6.73682V3H4.86182C3.83057 3 2.98682 4.0125 2.98682 5.25Z"
      //                   fill="black"
      //                   transform="translate(-1)"
      //                 />
      //               </svg>
      //             </Box>
      //           </Tooltip>
      //         ) : (
      //           <div className="single-funner-template__icon funner-icon">
      //             <svg
      //               width={17}
      //               height={18}
      //               viewBox="0 0 17 18"
      //               fill="none"
      //               xmlns="http://www.w3.org/2000/svg"
      //             >
      //               <path
      //                 d="M4.33133 1.75928C3.56118 1.75928 2.92371 2.39675 2.92371 3.1669V14.4279C2.92371 15.198 3.56118 15.8355 4.33133 15.8355H12.7771C13.5472 15.8355 14.1847 15.198 14.1847 14.4279V5.98215L9.96183 1.75928H4.33133ZM4.33133 3.1669H9.25802V6.68596H12.7771V14.4279H4.33133V3.1669ZM5.73896 8.7974V10.205H11.3695V8.7974H5.73896ZM5.73896 11.6126V13.0203H11.3695V11.6126H5.73896Z"
      //                 fill="#2A3847"
      //               />
      //             </svg>
      //           </div>
      //         )}
      //         <div className="single-funner-template__icon funner-icon">
      //           <svg
      //             width={18}
      //             height={18}
      //             viewBox="0 0 18 18"
      //             fill="none"
      //             xmlns="http://www.w3.org/2000/svg"
      //           >
      //             <path
      //               d="M9.0411 1.96167C5.93979 1.96167 3.4106 4.49086 3.4106 7.59217V8.29598V8.93106C3.00682 9.31609 2.70679 9.81139 2.70679 10.4074C2.70679 11.2815 3.31034 11.9297 4.07455 12.2467C4.99023 14.4354 6.82426 16.0379 9.0411 16.0379H11.1525V14.6303H9.0411C7.45157 14.6303 6.00834 13.4335 5.28285 11.5429L5.13302 11.1552L4.72062 11.0975C4.374 11.0498 4.11441 10.77 4.11441 10.4074C4.11441 10.1365 4.25124 9.92388 4.46494 9.8012L4.81822 9.59913V8.99979V8.29598C4.81822 7.89831 5.12436 7.59217 5.52204 7.59217H12.5602C12.9578 7.59217 13.264 7.89831 13.264 8.29598V8.99979V11.815H10.3869C10.3142 11.6094 10.1796 11.4314 10.0016 11.3054C9.82369 11.1794 9.61107 11.1115 9.393 11.1112C9.11301 11.1112 8.84448 11.2225 8.6465 11.4204C8.44851 11.6184 8.33729 11.887 8.33729 12.1669C8.33764 12.4336 8.4389 12.6903 8.62071 12.8853C8.80252 13.0804 9.05142 13.1994 9.3174 13.2185C9.34251 13.2213 9.36775 13.2226 9.393 13.2227H13.9678H14.6716C15.4458 13.2227 16.0792 12.5892 16.0792 11.815V10.4074C16.0792 9.63322 15.4458 8.99979 14.6716 8.99979V8.29598V7.59217C14.6716 4.49086 12.1424 1.96167 9.0411 1.96167ZM9.0411 3.36929C10.9101 3.36929 12.4832 4.57097 13.0427 6.2464C12.8869 6.2092 12.7266 6.18454 12.5602 6.18454H5.52204C5.35558 6.18454 5.19533 6.2092 5.03954 6.2464C5.59902 4.57097 7.17206 3.36929 9.0411 3.36929Z"
      //               fill="#2A3847"
      //             />
      //           </svg>
      //         </div>
      //         <div className="single-funner-template__icon funner-icon _lang">
      //           {languageIcons[template.language]}
      //         </div>
      //         <div
      //           className="single-funner-template__icon funner-icon"
      //           style={{
      //             width: 13,
      //             height: 8,
      //             marginRight: 15,
      //             transform: isThisActive ? "rotate(180deg)" : "rotate(0)",
      //           }}
      //           onClick={isThisActive ? resetTemplate : null}
      //         >
      //           <svg
      //             width={13}
      //             height={8}
      //             viewBox="0 0 13 8"
      //             fill="none"
      //             xmlns="http://www.w3.org/2000/svg"
      //           >
      //             <path
      //               d="M11.2117 0.0927734L6.6217 4.67277L2.0317 0.0927734L0.621704 1.50277L6.6217 7.50277L12.6217 1.50277L11.2117 0.0927734Z"
      //               fill="#2A3847"
      //             />
      //           </svg>
      //         </div>
      //       </div>
      //     </div>
      //     <div className="single-funner-template__text-preview">
      //       {template.components
      //         .find((item) => item.type === "BODY")
      //         .text.substring(0, 45)
      //         .replace(/^[a-z\u05D0-\u05EA]+$/i, "")}
      //     </div>

      //     {isThisActive && (
      //       <div className="single-funner-template__body">
      //         <div
      //           className="manage-templates-tab__preview-content preview-content-manage-templates"
      //           style={{ marginTop: 10 }}
      //         >
      //           {templateHeader ? (
      //             <div className="preview-content-manage-templates__header">
      //               {templateHeader.format === "TEXT" ? (
      //                 <p>
      //                   {
      //                     template.components.find(
      //                       (item) => item.type === "HEADER",
      //                     ).text
      //                   }
      //                 </p>
      //               ) : (
      //                 <div
      //                   className={`preview-content-manage-templates__header-media ${
      //                     templateFileLink ? "_media-uploaded" : ""
      //                   }`}
      //                   data-type={templateHeader.format}
      //                 >
      //                   {templateFileLink ? (
      //                     <>
      //                       {templateHeader.format === "VIDEO" ? (
      //                         <video
      //                           src={templateFileLink}
      //                           style={{ maxWidth: "100%" }}
      //                         ></video>
      //                       ) : (
      //                         <img
      //                           src={
      //                             templateHeader.format === "DOCUMENT"
      //                               ? templatePreviewsByFileType[
      //                                   getFileFormatFromURL(templateFileLink)
      //                                 ]
      //                               : templateFileLink
      //                           }
      //                           alt="header media"
      //                         />
      //                       )}
      //                     </>
      //                   ) : (
      //                     <Fragment>
      //                       <img
      //                         src={
      //                           templateHeader.format === "IMAGE"
      //                             ? changeImage
      //                             : templateHeader.format === "VIDEO"
      //                             ? videoPlus
      //                             : templateHeader.format === "DOCUMENT"
      //                             ? filePresent
      //                             : templateHeader.format === "LOCATION"
      //                             ? locationIMG
      //                             : ""
      //                         }
      //                         alt="header media"
      //                       />
      //                       <Typography fontSize={14} fontWeight={600}>
      //                         העלאת קובץ
      //                       </Typography>
      //                     </Fragment>
      //                   )}

      //                   {["IMAGE", "DOCUMENT", "VIDEO"].includes(
      //                     templateHeader.format,
      //                   ) && (
      //                     <label
      //                       className="template-chat-sidebar__select"
      //                       style={noUpload ? { pointerEvents: "none" } : null}
      //                     >
      //                       <input
      //                         type="file"
      //                         accept={
      //                           templateHeader.format === "IMAGE"
      //                             ? "image/*"
      //                             : templateHeader.format === "DOCUMENT"
      //                             ? ".docx,.doc, .sls,.xlsx,.pdf,.zip"
      //                             : templateHeader.format === "VIDEO"
      //                             ? "video/*"
      //                             : null
      //                         }
      //                         onChange={handleImgUploadTemplate}
      //                       />
      //                     </label>
      //                   )}
      //                 </div>
      //               )}
      //             </div>
      //           ) : (
      //             ""
      //           )}
      //           <div
      //             className="preview-content-manage-templates__text"
      //             style={{
      //               whiteSpace: "pre-line",
      //             }}
      //           >
      //             {template?.components?.find((item) => item.type === "BODY")
      //               ?.text
      //               ? template.components.find((item) => item.type === "BODY")
      //                   .text
      //               : "Template text"}
      //           </div>
      //           {template?.components?.some((item) => item.type === "FOOTER") && (
      //             <div className="preview-content-manage-templates__footer">
      //               {
      //                 template.components.find((item) => item.type === "FOOTER")
      //                   .text
      //               }
      //             </div>
      //           )}
      //           <div className="preview-content-manage-templates__bottom">
      //             <div className="preview-content-manage-templates__icon">
      //               <svg
      //                 width={16}
      //                 height={16}
      //                 view-box="0 0 16 16"
      //                 fill="none"
      //                 xmlns="http://www.w3.org/2000/svg"
      //               >
      //                 <path
      //                   d="M12.0001 4.66656L11.0601 3.72656L6.83344 7.95323L7.77344 8.89323L12.0001 4.66656ZM14.8268 3.72656L7.77344 10.7799L4.98677 7.9999L4.04677 8.9399L7.77344 12.6666L15.7734 4.66656L14.8268 3.72656ZM0.273438 8.9399L4.0001 12.6666L4.9401 11.7266L1.2201 7.9999L0.273438 8.9399Z"
      //                   fill="black"
      //                   fillOpacity="0.54"
      //                 ></path>
      //               </svg>
      //             </div>
      //             <div className="preview-content-manage-templates__date">
      //               12.12.2022 10:25
      //             </div>
      //           </div>
      //           {template?.components?.some(
      //             (item) => item.type === "BUTTONS",
      //           ) && (
      //             <div className="preview-content-manage-templates__buttons-container">
      //               {template?.components
      //                 ?.find((item) => item.type === "BUTTONS")
      //                 ?.buttons.map((button, index) => (
      //                   <div
      //                     className={`preview-content-manage-templates__button preview-content-manage-templates__button--${button.type.toLowerCase()}`}
      //                     key={index}
      //                   >
      //                     <span className="_button-text">{button.text}</span>
      //                     <span className="_button-icon">
      //                       {button.type === "QUICK_REPLY" ? (
      //                         <svg
      //                           fill="#1976d2"
      //                           width="64px"
      //                           height="64px"
      //                           viewBox="0 0 32 32"
      //                           version="1.1"
      //                           xmlns="http://www.w3.org/2000/svg"
      //                         >
      //                           <g id="SVGRepo_bgCarrier" strokeWidth={0} />
      //                           <g
      //                             id="SVGRepo_tracerCarrier"
      //                             strokeLinecap="round"
      //                             strokeLinejoin="round"
      //                           />
      //                           <g id="SVGRepo_iconCarrier">
      //                             {"{"}" "{"}"}
      //                             <title>reply</title>
      //                             {"{"}" "{"}"}
      //                             <path d="M3.488 15.136q0 0.96 0.8 1.472l10.72 7.136q0.416 0.288 0.896 0.32t0.928-0.224 0.704-0.672 0.256-0.896v-3.584q3.456 0 6.208 1.984t3.872 5.152q0.64-1.792 0.64-3.552 0-2.912-1.44-5.376t-3.904-3.904-5.376-1.44v-3.584q0-0.48-0.256-0.896t-0.704-0.672-0.928-0.224-0.896 0.32l-10.72 7.136q-0.8 0.512-0.8 1.504z" />
      //                           </g>
      //                         </svg>
      //                       ) : button.type === "URL" ? (
      //                         <svg
      //                           width="64px"
      //                           height="64px"
      //                           viewBox="0 0 24 24"
      //                           fill="none"
      //                           xmlns="http://www.w3.org/2000/svg"
      //                           stroke="#000000"
      //                         >
      //                           <g id="SVGRepo_bgCarrier" strokeWidth={0} />
      //                           <g
      //                             id="SVGRepo_tracerCarrier"
      //                             strokeLinecap="round"
      //                             strokeLinejoin="round"
      //                           />
      //                           <g id="SVGRepo_iconCarrier">
      //                             <path
      //                               d="M11 4H4V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V13"
      //                               stroke="#1976d2"
      //                               strokeLinecap="round"
      //                               strokeLinejoin="round"
      //                               strokeWidth={2}
      //                             />
      //                             <path
      //                               d="M9 15L20 4"
      //                               stroke="#1976d2"
      //                               strokeLinecap="round"
      //                               strokeLinejoin="round"
      //                               strokeWidth={2}
      //                             />
      //                             <path
      //                               d="M15 4H20V9"
      //                               stroke="#1976d2"
      //                               strokeLinecap="round"
      //                               strokeLinejoin="round"
      //                               strokeWidth={2}
      //                             />
      //                           </g>
      //                         </svg>
      //                       ) : button.type === "PHONE_NUMBER" ? (
      //                         <svg
      //                           width="64px"
      //                           height="64px"
      //                           viewBox="0 0 24 24"
      //                           fill="none"
      //                           xmlns="http://www.w3.org/2000/svg"
      //                         >
      //                           <g id="SVGRepo_bgCarrier" strokeWidth={0} />
      //                           <g
      //                             id="SVGRepo_tracerCarrier"
      //                             strokeLinecap="round"
      //                             strokeLinejoin="round"
      //                           />
      //                           <g id="SVGRepo_iconCarrier">
      //                             {"{"}" "{"}"}
      //                             <path
      //                               d="M10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C14.1588 20.0658 10.9183 19.5829 7.6677 16.3323C4.41713 13.0817 3.93421 9.84122 4.00655 7.93309C4.04952 6.7996 4.7008 5.77423 5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617Z"
      //                               fill="#1976d2"
      //                             />
      //                             {"{"}" "{"}"}
      //                           </g>
      //                         </svg>
      //                       ) : button.type === "COPY_CODE" ? (
      //                         <svg
      //                           width="64px"
      //                           height="64px"
      //                           viewBox="0 0 24 24"
      //                           fill="none"
      //                           xmlns="http://www.w3.org/2000/svg"
      //                         >
      //                           <g id="SVGRepo_bgCarrier" strokeWidth={0} />
      //                           <g
      //                             id="SVGRepo_tracerCarrier"
      //                             strokeLinecap="round"
      //                             strokeLinejoin="round"
      //                           />
      //                           <g id="SVGRepo_iconCarrier">
      //                             {"{"}" "{"}"}
      //                             <path
      //                               fillRule="evenodd"
      //                               clipRule="evenodd"
      //                               d="M19.5 16.5L19.5 4.5L18.75 3.75H9L8.25 4.5L8.25 7.5L5.25 7.5L4.5 8.25V20.25L5.25 21H15L15.75 20.25V17.25H18.75L19.5 16.5ZM15.75 15.75L15.75 8.25L15 7.5L9.75 7.5V5.25L18 5.25V15.75H15.75ZM6 9L14.25 9L14.25 19.5L6 19.5L6 9Z"
      //                               fill="#1976d2"
      //                             />
      //                             {"{"}" "{"}"}
      //                           </g>
      //                         </svg>
      //                       ) : (
      //                         ""
      //                       )}
      //                     </span>
      //                     {button.type === "URL" &&
      //                       button.url?.match(/{{(\d+)}}/g) && (
      //                         <div className="_button-link">
      //                           (url: ${button.url})
      //                         </div>
      //                       )}
      //                   </div>
      //                 ))}
      //             </div>
      //           )}
      //         </div>
      //         <div className="chat-sidebar__template template-chat-sidebar">
      //           {varsData &&
      //             varsData?.length > 0 &&
      //             varsData?.some(
      //               (component) =>
      //                 (component?.type === "HEADER" &&
      //                   component.format === "LOCATION") ||
      //                 component?.buttons
      //                   ?.find((btn) => btn.type === "URL")
      //                   ?.url?.match(/{{(\d+)}}/g) ||
      //                 component?.text?.match(/{{(\d+)}}/g),
      //             ) && (
      //               <>
      //                 {varsData.some(
      //                   (comp) =>
      //                     (comp.type !== "HEADER" &&
      //                       comp.text?.match(varsRegular)) ||
      //                     (comp.type === "HEADER" &&
      //                       !["IMAGE", "DOCUMENT", "VIDEO"].includes(
      //                         comp.format,
      //                       )),
      //                 ) && (
      //                   <p className="template-chat-sidebar__title">
      //                     {localization.componentsTitle}
      //                   </p>
      //                 )}

      //                 {varsData.map((component, compIndex) => {
      //                   const varsList =
      //                     component.type === "BUTTONS"
      //                       ? component?.buttons
      //                           ?.filter(
      //                             (btn) =>
      //                               btn.type === "URL" &&
      //                               btn.url.match(varsRegular),
      //                           )
      //                           .map((btn) => btn.url.match(varsRegular)[0])
      //                       : // component?.buttons
      //                         //   ?.find((btn) => btn.type === "URL")
      //                         //   ?.url?.match(varsRegular)
      //                         component?.text?.match(varsRegular);
      //                   return (
      //                     <Fragment key={compIndex}>
      //                       {!(
      //                         component.type === "HEADER" &&
      //                         ["IMAGE", "DOCUMENT", "VIDEO"].includes(
      //                           component.format,
      //                         )
      //                       ) &&
      //                         ((((component.type === "HEADER" &&
      //                           component.format === "TEXT") ||
      //                           (component.type !== "HEADER" &&
      //                             component.type !== "BUTTONS")) &&
      //                           component?.text?.match(/{{(\d+)}}/g)) ||
      //                           (component.type === "HEADER" &&
      //                             component.format === "LOCATION") ||
      //                           (component.type === "BUTTONS" &&
      //                             component?.buttons?.some(
      //                               (btn) =>
      //                                 btn.type === "URL" &&
      //                                 btn.url?.match(/{{(\d+)}}/g),
      //                             ))) && (
      //                           <>
      //                             <p className="template-chat-sidebar__template-msg">
      //                               {localization[component.type.toLowerCase()]}
      //                             </p>
      //                             <ul className="template-chat-sidebar__list">
      //                               {component.type === "HEADER" &&
      //                               component.format === "LOCATION" ? (
      //                                 <>
      //                                   {[
      //                                     "Latitude",
      //                                     "Longitude",
      //                                     "Name",
      //                                     "Address",
      //                                   ].map((param, index) => (
      //                                     <label
      //                                       className="form-get-token-popup__input-wrapper"
      //                                       style={{ marginBottom: 15 }}
      //                                       key={index}
      //                                     >
      //                                       <span
      //                                         className="input-title"
      //                                         style={{
      //                                           color: "#2A3847",
      //                                           paddingTop: 1,
      //                                           paddingBottom: 1,
      //                                         }}
      //                                       >
      //                                         {"{"}
      //                                         {param}
      //                                         {"}"}
      //                                       </span>
      //                                       <input
      //                                         type="text"
      //                                         id={`param-input-${compIndex}-${index}`}
      //                                         data-component={component.type}
      //                                         data-param={index}
      //                                         data-header-location={param}
      //                                         className="form-get-token-popup__input"
      //                                         onKeyUp={() =>
      //                                           templatesWithSend
      //                                             ? createParamsObject()
      //                                             : noSendTemplateAction()
      //                                         }
      //                                       />
      //                                     </label>
      //                                   ))}
      //                                 </>
      //                               ) : (
      //                                 <>
      //                                   {varsList?.map((param, orderIndex) => {
      //                                     const index = param.replace(/{{(\d+)}}/g, (match, number) => number);
      //                                     return (
      //                                     <label
      //                                       className="form-get-token-popup__input-wrapper"
      //                                       style={{ marginBottom: 15 }}
      //                                       key={orderIndex}
      //                                     >
      //                                       <span
      //                                         className="funner-icon"
      //                                         style={{
      //                                           position: "absolute",
      //                                           top: "50%",
      //                                           left: 12,
      //                                           width: 24,
      //                                           height: 24,
      //                                           transform: "translateY(-50%)",
      //                                           zIndex: 5,
      //                                           cursor: "pointer",
      //                                         }}
      //                                         onClick={() =>
      //                                           toggleImplementedVars(
      //                                             `${compIndex}-${index}`,
      //                                           )
      //                                         }
      //                                       >
      //                                         <svg
      //                                           width={24}
      //                                           height={24}
      //                                           viewBox="0 0 24 24"
      //                                           fill="none"
      //                                           xmlns="http://www.w3.org/2000/svg"
      //                                         >
      //                                           <path
      //                                             d="M22.5 16H20.3L22 12H17V18H19V23L22.5 16Z"
      //                                             fill="black"
      //                                             fillOpacity="0.54"
      //                                           />
      //                                           <path
      //                                             d="M4 17.17V4H20V10H22V4C22 2.9 21.1 2 20 2H4C2.9 2 2.01 2.9 2.01 4L2 22L6 18H15V16H5.17L4 17.17Z"
      //                                             fill="black"
      //                                             fillOpacity="0.54"
      //                                           />
      //                                         </svg>
      //                                       </span>
      //                                       {showImplementedVars.show &&
      //                                         showImplementedVars.id ===
      //                                           `${compIndex}-${index}` &&
      //                                         implementedVars?.length && (
      //                                           <div className="implemented-variables-list">
      //                                             {implementedVars.map(
      //                                               (variable, varIndex) => (
      //                                                 <Tooltip
      //                                                   title={variable.label}
      //                                                   placement="right"
      //                                                   key={varIndex}
      //                                                 >
      //                                                   <Box
      //                                                     className="create-new-task-messaging__variable"
      //                                                     sx={{
      //                                                       border:
      //                                                         "0 !important",
      //                                                     }}
      //                                                     onClick={() =>
      //                                                       placeVarToInput(
      //                                                         `${compIndex}-${index}`,
      //                                                         chatUser
      //                                                           ? paramNameByVariable[
      //                                                               variable
      //                                                                 .varName
      //                                                             ](chatUser)
      //                                                           : variable.varName,
      //                                                       )
      //                                                     }
      //                                                   >
      //                                                     {chatUser
      //                                                       ? paramNameByVariable[
      //                                                           variable.varName
      //                                                         ](chatUser)
      //                                                       : variable.label}
      //                                                     {/* {variable.varName} */}
      //                                                   </Box>
      //                                                 </Tooltip>
      //                                               ),
      //                                             )}
      //                                           </div>
      //                                         )}
      //                                       <span
      //                                         className="input-title"
      //                                         style={{
      //                                           color: "#2A3847",
      //                                           paddingTop: 1,
      //                                           paddingBottom: 1,
      //                                         }}
      //                                       >
      //                                         {"{"}
      //                                         {index}
      //                                         {"}"}
      //                                       </span>
      //                                       <input
      //                                         type="text"
      //                                         id={`param-input-${compIndex}-${index}`}
      //                                         data-component={component.type}
      //                                         data-param={index}
      //                                         className="form-get-token-popup__input"
      //                                         onKeyUp={() =>
      //                                           templatesWithSend
      //                                             ? createParamsObject()
      //                                             : noSendTemplateAction()
      //                                         }
      //                                       />
      //                                     </label>
      //                                   )})}
      //                                 </>
      //                               )}
      //                             </ul>
      //                           </>
      //                         )}
      //                     </Fragment>
      //                   );
      //                 })}
      //               </>
      //             )}
      //           <div className="templates-form-error">יש למלא את כל השדות</div>
      //           {templatesWithSend && (
      //             <Button
      //               variant="contained"
      //               onClick={submitSendTemplate}
      //               endIcon={
      //                 <Send
      //                   sx={{
      //                     color: "common.white",
      //                   }}
      //                 />
      //               }
      //               fullWidth
      //               sx={{
      //                 p: "6px 16px !important",
      //                 // width: 200,
      //                 display: "flex",
      //                 margin: "0 0 0 auto !important",
      //                 "& .MuiButton-icon": {
      //                   marginRight: "-4px !important",
      //                   marginLeft: "8px !important",
      //                   transform: "rotate(-180deg)",
      //                 },
      //               }}
      //             >
      //               {localization.singleTemplate.send}
      //             </Button>
      //             // <button
      //             //   className="send-btn chat-input-actions__green-btn"
      //             //   onClick={handleSendTemplate}
      //             // >
      //             //   <span className="icon plane">
      //             //     <img src={paperPlaneSendIcon} alt="" />
      //             //   </span>
      //             // </button>
      //           )}
      //         </div>
      //       </div>
      //     )}
      //   </div>
      // </div>
    );
  },
);

export default SingleTemplate;
