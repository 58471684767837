import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { hintLogo, exclamationGreen, exclamationRed } from "../libs/images";

function HintTemplate({ text, type, isHintIcon, setIsHintActive }) {
  const [isOpen, setOpen] = useState(true);
  const [container, setContainer] = useState(null);

  function closeHint() {
    setOpen(!isOpen);
    setIsHintActive(false);
  }

  useEffect(() => {
    let cancel = false;

    setTimeout(() => {
      if (cancel) return;
      setOpen(false);
      setIsHintActive(false);
    }, 3 * 1000);

    return () => {
      cancel = true;
    };
  }, []);

  // return ReactDOM.createPortal(
  return isOpen ? (
    <div
      className={`general-hint active 
        ${type === "error" ? "error" : ""}
        ${type === "success" ? "success" : ""}
        ${type === "warning" ? "warning" : ""}
      `}
    >
      <div className="general-hint__body">
        <span className="close-hint-btn" onClick={closeHint}>
          X
        </span>
        <div className="general-hint__content">
          {isHintIcon && (
            <div className="general-hint__icon">
              <img src={hintLogo} alt="" className="image" />
              {type === "error" && (
                <img src={exclamationRed} alt="" className="error" />
              )}
              {type === "success" && (
                <img src={exclamationGreen} alt="" className="error" />
              )}
            </div>
          )}
          <div style={{ flex: "1 1 auto" }}>
            <p style={{ whiteSpace: "pre-line" }}>{text}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default HintTemplate;
