export async function sendMessageToBackground(messageType, content = null) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    chrome.runtime.sendMessage(
      { type: messageType, content: content },
      function (response) {
        // eslint-disable-next-line no-undef
        if (chrome.runtime.lastError) {
          // eslint-disable-next-line no-undef
          reject(chrome.runtime.lastError);
        } else {
          resolve(response);
        }
      },
    );
  });
}
