import React, { useEffect, useMemo, useRef, useState } from "react";
import localizations from "../../localizations.json";
import MaterialDropdown from "../../modules/MaterialDropdown";
import { arrowDownSelectHeader, searchIcon } from "../../libs/images";
import SingleTemplate from "./SingleTemplate";
import { UseFetchPost } from "../../hooks/fetchFunctions";
import { implementedVars } from "../../libs/implementedVars";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Close,
  FilterListOutlined,
  KeyboardArrowLeft,
} from "@mui/icons-material";
import { HeaderOutlinedButton } from "../Funner/FunnerHeader";
import { applicationType } from "../../applicationType";

function TemplatesMenu({
  style,
  templatesLoading,
  templatesList,
  whatsappTemplatesList,
  languages,
  closeAccordion,
  chatUser,
  chatUserName,
  currentContact,
  globalID,
  handleSetNotif,
  setTextareaValue,
  textareaRef,
  resetTextArea,
  templatesWithSend,
  defaultSelectedTemplate,
  noSendHandleFunc,
  fetchWhatsappTemplates,
  noUpload,
  existingFile,
  setIsTemplateSending,
  resetTemplateState,
  inBots = false,
}) {
  //! Views and states
  const [localization, setLocalization] = useState(
    localizations["he"].templatesMenu,
  );
  const theme = useTheme();
  const filtersParams = {
    isActive: [
      {
        value: true,
        label: localization.filters.activeStatusFilter.active,
      },
      {
        value: false,
        label: localization.filters.activeStatusFilter.notActive,
      },
    ],
    isHebrew: [
      {
        value: true,
        label: localization.filters.hebrewViewFilter.hebrew,
      },
      {
        value: false,
        label: localization.filters.hebrewViewFilter.notHebrew,
      },
    ],
    languages: languages.map((lang) => {
      return {
        value: lang,
        label: lang,
      };
    }),
    searchQuery: "",
  };
  const matchesBigMobile = useMediaQuery(theme.breakpoints.down(768));

  // const [visibleTemplatesList, setVisibleTemplatesList] = useState([
  //   ...templatesList,
  // ]);

  const [showFiltersAnchor, setShowFiltersAnchor] = useState(null);
  const [filters, setFilters] = useState({
    isActive: true,
    isHebrew: false,
    language: ["all"],
  });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateFileLink, setTemplateFileLink] = useState(null);
  const [templateFileName, setTemplateFileName] = useState(null);
  const [isMediaPlaceholder, setIsMediaPlaceholder] = useState(false);

  const [hebrewNamePopover, setHebrewNamePopover] = useState({
    open: false,
    position: { top: 0, left: 0 },
    template: null,
    target: null,
  });
  const [newHebrewName, setNewHebrewName] = useState("");

  //! Refs
  const templatesMenuRef = useRef(null);
  const filtersRef = useRef(null);
  const hebrewNamePopoverRef = useRef(null);

  //! Functions and handlers
  const openFilters = (e) => {
    const target = e.currentTarget;
    setShowFiltersAnchor(target);
  };
  const closeFilters = () => {
    setShowFiltersAnchor(null);
  };
  const handleFiltersParam = (params) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...params }));
  };
  const handleActiveFilter = (value) => {
    handleFiltersParam({ isActive: value });
  };
  const handleIsHebrewFilter = (value) => {
    handleFiltersParam({ isHebrew: value });
  };
  const handleLanguageFilter = (value) => {
    handleFiltersParam({ language: value });
  };
  const handleSearchFilter = (e) => {
    setFilters((prevFilters) => {
      return { ...prevFilters, searchQuery: e.target.value };
    });
  };

  const handleCloseHebrewNamePopover = () => {
    setHebrewNamePopover({
      open: false,
      position: {
        top: 0,
        left: 0,
      },
      template: null,
      target: null,
    });
    setNewHebrewName("");
  };
  const handleNewHebrewName = (e) => {
    const input = e.target;
    setNewHebrewName(input.value);
  };
  async function submitNewHebrewName() {
    try {
      const params = {
        templateOriginalName: hebrewNamePopover.template.name,
        templateHebrewName: newHebrewName,
      };

      const res = await UseFetchPost(
        "/api/services/updateWhatsappTemplateName",
        params,
        "config",
      );
      if (res?.data?.result) {
        fetchWhatsappTemplates();
        handleSetNotif(
          localization.notifications.updateHebrewName.success,
          "success",
        );
        handleCloseHebrewNamePopover();
      }
    } catch (error) {
      handleSetNotif(
        localization.notifications.updateHebrewName.error,
        "error",
      );
      console.log("updateWhatsappTemplateName error: ", error);
    }
  }

  const handleActiveTemplate = (template, isMediaPlaceholder = false) => {
    console.log("handleActiveTemplate: ", isMediaPlaceholder);
    setSelectedTemplate(template);
    setTemplateFileLink(noUpload ? existingFile : null);
    setIsMediaPlaceholder(isMediaPlaceholder ?? false);
    formTemplateMessage(
      template.components.find((item) => item.type === "BODY").text,
      template.name,
      template,
    );
  };
  function formTemplateMessage(text, name, templateData) {
    if (textareaRef?.current) {
      const textarea = textareaRef.current;

      // setTextareaValue("You are using template message now");
      if (setIsTemplateSending) setIsTemplateSending(true);
      removeTemplateInput();

      // textarea.setAttribute("readonly", "");
    }
  }
  function removeTemplateInput(chatWindow = document) {
    const container = textareaRef.current.closest(".form-group");
    if (!container) return;
    // const span = document.createElement("span");
    // span.textContent = "x";
    // span.classList.add("delete-template-choice");

    // span.addEventListener("click", () => resetTemplate());

    // const oldSpan = container.querySelector(".delete-template-choice");
    // if (oldSpan) {
    //   container.removeChild(oldSpan);
    // }

    // container.appendChild(span);
  }
  function resetTemplate() {
    // resetTextArea();
    if (setIsTemplateSending) setIsTemplateSending(false);
    // const span = textareaRef.current
    //   .closest(".form-group")
    //   .querySelector(".delete-template-choice");
    // span.remove();

    setSelectedTemplate(null);
    setTemplateFileLink(noUpload ? existingFile : null);
  }
  function createParamsObject(directTemplate = null) {
    const varsData = directTemplate
      ? directTemplate.components
      : selectedTemplate.components;
    // const { defaultTemplateMessage } = messageConfig;
    // const paramsObject = [...varsData];
    const paramsObject = JSON.parse(JSON.stringify(varsData));
    const paramsList = [];

    let whatsappPhoneNumber = currentContact;

    // let messageText = defaultTemplateMessage.toString();
    // const paramsListEls = document.getElementsByClassName(
    //   'template-param-input'
    // );
    const paramsListEls = templatesMenuRef.current.querySelectorAll(
      ".form-get-token-popup__input",
    );

    let paramIndex = 1;
    for (const inputIndex in paramsListEls) {
      // const paramElById = document.getElementById(paramsListEls[inputIndex].id);
      const paramElById = templatesMenuRef.current.querySelector(
        "#" + paramsListEls[inputIndex].id,
      );
      if (paramElById != null) {
        const changedParamComponent = paramElById.dataset.component;
        const changedParamIndexInComponent =
          Number(paramElById.dataset.param) + 1;
        const isHeaderLocationParam = paramElById.hasAttribute(
          "data-header-location",
        );

        if (paramElById.value.length > 0) {
          // const varParam = implementedVars.find(
          //   (variable) => variable.label === paramElById.value,
          // );

          paramsList.push({
            component: changedParamComponent,
            indexInComponent: changedParamIndexInComponent,
            text: paramElById.value,
            // text: varParam ? varParam.varName : paramElById.value,
            isHeaderLocationParam: isHeaderLocationParam,
            headerLocationParamName: isHeaderLocationParam
              ? paramElById.dataset.headerLocation
              : null,
          });
        } else {
          paramsList.push({
            component: changedParamComponent,
            indexInComponent: changedParamIndexInComponent,
            text: `{{${changedParamIndexInComponent}}}`,
            isHeaderLocationParam: isHeaderLocationParam,
            headerLocationParamName: isHeaderLocationParam
              ? paramElById.dataset.headerLocation
              : null,
          });
        }
      }
    }

    const returnWhatsappObj = {
      messaging_product: "whatsapp",
      to: whatsappPhoneNumber,
      type: "template",
      template: {
        name: directTemplate ? directTemplate.name : selectedTemplate.name,
        language: {
          code: directTemplate
            ? directTemplate.language
            : selectedTemplate.language,
        },
        components: [],
      },
    };

    const returnObj = {
      BODY: {
        text: "",
      },
    };

    paramsList.forEach((param) => {
      const targetComponent = paramsObject.find(
        (item) => item.type === param.component,
      );
      if (param.component === "BUTTONS") {
        targetComponent.buttons.find((btn) => btn.type === "URL").url =
          targetComponent.buttons
            .find((btn) => btn.type === "URL")
            .url.replace(`{{${param.indexInComponent}}}`, param.text);

        const targetHTMLElement = templatesMenuRef.current.querySelector(
          ".single-funner-template__content--active .preview-content-manage-templates__button.preview-content-manage-templates__button--url ._button-link",
        );
        targetHTMLElement.innerText = `(url: ${
          targetComponent.buttons.find((btn) => btn.type === "URL").url
        })`;

        returnWhatsappObj.template.components.push({
          type: "button",
          index: "0",
          sub_type: "URL",
          parameters: [
            {
              type: "text",
              text: param.text,
            },
          ],
        });
      } else {
        if (param.isHeaderLocationParam) {
          if (
            returnWhatsappObj.template.components.find(
              (item) => item.type === "HEADER",
            )
          ) {
            const whatsappTargetComponent =
              returnWhatsappObj.template.components.find(
                (item) => item.type === "HEADER",
              );
            whatsappTargetComponent.parameters[0].location[
              `${param.headerLocationParamName}`
            ] = param.text;
          } else {
            returnWhatsappObj.template.components.push({
              type: "HEADER",
              parameters: [
                {
                  type: "location",
                  location: {},
                },
              ],
            });

            const headerComponent = returnWhatsappObj.template.components.find(
              (item) => item.type === "HEADER",
            );
            headerComponent.parameters[0].location[
              `${param.headerLocationParamName}`
            ] = param.text;
          }

          if (returnObj["HEADER"]) {
            const whatsappTargetComponent = returnObj["HEADER"];
            whatsappTargetComponent.format = "LOCATION";
            whatsappTargetComponent.location[
              `${param.headerLocationParamName}`
            ] = param.text;
          } else {
            returnObj["HEADER"] = {
              format: "LOCATION",
              location: {},
            };

            const headerComponent = returnObj["HEADER"];
            headerComponent.location[`${param.headerLocationParamName}`] =
              param.text;
          }
        } else {
          targetComponent.text = targetComponent.text.replace(
            `{{${param.indexInComponent}}}`,
            param.text,
          );
          const targetSelector =
            targetComponent.type === "HEADER"
              ? ".single-funner-template__content--active .preview-content-manage-templates__header p"
              : targetComponent.type === "BODY"
              ? ".single-funner-template__content--active .preview-content-manage-templates__text"
              : null;
          if (targetSelector) {
            const targetHTMLElement =
              templatesMenuRef.current.querySelector(targetSelector);
            if (targetHTMLElement)
              targetHTMLElement.innerText = targetComponent.text;
          }

          if (
            returnWhatsappObj.template.components.find(
              (item) => item.type === param.component,
            )
          ) {
            const whatsappTargetComponent =
              returnWhatsappObj.template.components.find(
                (item) => item.type === param.component,
              );
            whatsappTargetComponent.parameters.push({
              type: "text",
              text: param.text,
            });
          } else {
            returnWhatsappObj.template.components.push({
              type: param.component,
              parameters: [
                {
                  type: "text",
                  text: param.text,
                },
              ],
            });
          }
        }
      }
    });

    returnObj["BODY"].text = paramsObject.find(
      (item) => item.type === "BODY",
    ).text;

    if (paramsObject.some((item) => item.type === "HEADER")) {
      const headerComponent = paramsObject.find(
        (item) => item.type === "HEADER",
      );

      if (headerComponent.format !== "LOCATION") {
        returnObj["HEADER"] = {
          format: headerComponent.format,
          text: headerComponent.text,
        };
      }

      if (
        headerComponent.format !== "TEXT" &&
        headerComponent.format !== "LOCATION"
      ) {
        returnWhatsappObj.template.components.push({
          type: "HEADER",
          parameters: [
            {
              type: headerComponent.format.toLowerCase(),
              // text: templateFileLink,
              // text: "",
            },
          ],
        });
      }
    }
    if (paramsObject.some((item) => item.type === "FOOTER")) {
      const footerComponent = paramsObject.find(
        (item) => item.type === "FOOTER",
      );
      returnObj["FOOTER"] = {
        text: footerComponent.text,
      };
    }
    if (paramsObject.some((item) => item.type === "BUTTONS")) {
      const buttonsComponent = paramsObject.find(
        (item) => item.type === "BUTTONS",
      );
      returnObj["BUTTONS"] = {
        buttons: [...buttonsComponent.buttons],
      };
    }
    // const jsonString = "{\"messaging_product\":\"whatsapp\",\"to\":\"380957101261\",\"type\":\"template\",\"template\":{\"name\":\"test_template_button_type_url\",\"language\":{\"code\":\"he\"},\"components\":[{\"type\":\"button\",\"index\":\"0\",\"sub_type\":\"URL\",\"parameters\":[{\"type\":\"text\",\"text\":\"1\"}]}]}}";
    // console.log(JSON.parse(jsonString))
    // console.log(returnWhatsappObj);
    // console.log(returnObj);

    return {
      messageObject: returnObj,
      whatsappTemplateObject: returnWhatsappObj,
    };
  }

  function formatCurrentTime() {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = String(now.getFullYear());
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds()).padStart(7, "0");

    // Формат "MM/DD HH:MM:SS"
    const formatted1 = `${month}/${day} ${hours}:${minutes}:${seconds}`;
    // Формат "YYYY-MM-DDTHH:MM:SS.sssssss"
    const formatted2 = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

    return {
      messageDateTime: formatted1,
      dateRow: formatted2,
    };
  }

  async function handleSendTemplate() {
    // const isValid = checkValid();
    // if (isValid) {
    const componentsObject = createParamsObject();

    if (templateFileLink) {
      const headerObjectMessage = componentsObject.messageObject["HEADER"];
      headerObjectMessage.text = templateFileLink;
      const headerObject =
        componentsObject.whatsappTemplateObject.template.components.find(
          (item) => item.type === "HEADER",
        );
      if (
        headerObject.parameters[0].type === "image" ||
        headerObject.parameters[0].type === "video"
      ) {
        headerObject.parameters[0][`${headerObject.parameters[0].type}`] = {
          link: templateFileLink,
        };
      } else {
        headerObject.parameters[0][`${headerObject.parameters[0].type}`] = {
          link: templateFileLink,
          filename: templateFileName,
        };
      }
    }

    let params = {
      to: currentContact,
      templateName: selectedTemplate.name,
      languageCode: selectedTemplate.language,
      components: JSON.stringify(componentsObject.messageObject),
      frontTemplateObj: JSON.stringify(componentsObject.whatsappTemplateObject),
      userglobalid: globalID,
      templateText: "",
    };

    sendTemplate(params);
    closeAccordion();
    setTimeout(() => {
      const inputParams = templatesMenuRef.current.querySelectorAll(
        ".template-chat-sidebar__contacts-item-name.template-param-input",
      );
      [...inputParams].map((param) => (param.value = ""));
      resetTemplate();
    }, 300);
    // }
  }
  async function sendTemplate(params) {
    const noWhatsappErr = "No phone number WhatsApp account";
    try {
      const response = await UseFetchPost(
        "/api/whatsapp/SendWhatsappTemplateMessage",
        params,
        "messages",
      );
      if (!response.data.result) {
        if (response.data.message === noWhatsappErr) {
          handleSetNotif(
            localization.notifications.sendTemplate.error,
            "error",
          );
        }
        throw new Error("Error: " + response.data.message);
      }
      return response.data;
    } catch (error) {
      console.log("SendWhatsappTemplateMessage error: ", error);
    }
  }

  function noSendTemplateAction(directTemplate = null) {
    const componentsObject = createParamsObject(directTemplate);
    const currentSelectedTemplate = directTemplate
      ? directTemplate
      : selectedTemplate;
    if (templateFileLink) {
      const headerObjectMessage = componentsObject.messageObject["HEADER"];
      headerObjectMessage.text = templateFileLink;
      const headerObject =
        componentsObject.whatsappTemplateObject.template.components.find(
          (item) => item.type === "HEADER",
        );
      if (
        headerObject.parameters[0].type === "image" ||
        headerObject.parameters[0].type === "video"
      ) {
        headerObject.parameters[0][`${headerObject.parameters[0].type}`] = {
          link: templateFileLink,
        };
      } else {
        headerObject.parameters[0][`${headerObject.parameters[0].type}`] = {
          link: templateFileLink,
          filename: templateFileName,
        };
      }
    }

    // let params = {
    //   sendObject: {
    //     to: currentContact,
    //     templateName: selectedTemplate.name,
    //     languageCode: selectedTemplate.language,
    //     components: JSON.stringify(componentsObject.messageObject),
    //     frontTemplateObj: JSON.stringify(
    //       componentsObject.whatsappTemplateObject,
    //     ),
    //     userglobalid: globalID,
    //     templateText: "",
    //   },
    let params = {
      sendObject: {
        to: currentContact,
        templateName: currentSelectedTemplate.name,
        languageCode: currentSelectedTemplate.language,
        components: JSON.stringify(componentsObject.messageObject),
        frontTemplateObj: JSON.stringify(
          componentsObject.whatsappTemplateObject,
        ),
        userglobalid: globalID,
        templateText: "",
        isMediaPlaceholder: isMediaPlaceholder,
      },
      defaultTemplate: { ...currentSelectedTemplate },
    };

    noSendHandleFunc(params);
  }

  useEffect(() => {
    console.log("default Selected Template: ", defaultSelectedTemplate);
    if (defaultSelectedTemplate) {
      // const defaultTemplate = defaultSelectedTemplate.defaultTemplate;
      const defaultTemplate = templatesList.find(
        (temp) => temp.name === defaultSelectedTemplate.sendObject.templateName,
      );
      console.log("default Template: ", defaultTemplate);
      if (defaultTemplate) {
        const frontTemplateObj = JSON.parse(
          defaultSelectedTemplate.sendObject.frontTemplateObj,
        );
        defaultTemplate.components = defaultTemplate.components.map((comp) => {
          return comp;
        });

        console.log()
        handleActiveTemplate(defaultTemplate, defaultSelectedTemplate.sendObject.isMediaPlaceholder);

        setTimeout(() => {
          console.log("default front Template Obj: ", frontTemplateObj);
          frontTemplateObj.template.components.forEach((comp) => {
            if (
              comp.type !== "HEADER" ||
              (comp.type === "HEADER" &&
                comp.parameters.find((param) => param.type === "text"))
            ) {
              comp.parameters.forEach((param, paramIndex) => {
                const targetInp = templatesMenuRef.current.querySelector(
                  `[data-component="${comp.type}"][data-param="${paramIndex}"]`,
                );
                targetInp.value = param.text;
              });
            } else if (
              comp.type === "HEADER" &&
              !comp.parameters.some((param) => param.type === "text")
            ) {
              const param = comp.parameters[0];
              const type = param.type;
              if (param[type]) {
                const data = param[type];
                setTemplateFileLink(data.link);
                setTemplateFileName(data.filename);
              }
            }
          });
          // setTemplateFileLink(data?.link);
          // setTemplateFileName(file.name);

          // createParamsObject(defaultTemplate);
          noSendTemplateAction(defaultTemplate);
        }, 200);
      }
    }
  }, []);

  useEffect(() => {
    if (existingFile) {
      setTemplateFileLink(noUpload ? existingFile : null);
    }
  }, [existingFile]);

  const visibleTemplatesList = useMemo(() => {
    return templatesList.filter((template) => {
      const isActiveCondition = filters.isActive
        ? // ? template.status === "APPROVED"
          // : template.status !== "APPROVED";
          template.status !== "REJECTED"
        : true;
      // const languageCondition =
      //   template.language === (filters.language || languages[0]);
      const languageCondition = filters.language.includes("all")
        ? true
        : filters.language?.length
        ? filters.language.includes(template.language)
        : true;
      const searchCondition =
        filters?.searchQuery && filters.searchQuery !== ""
          ? template.name.includes(filters.searchQuery)
          : true;

      return isActiveCondition && languageCondition && searchCondition;
    });
  }, [filters, templatesList]);

  useEffect(() => {
    if (selectedTemplate && !templatesWithSend) {
      noSendTemplateAction();
    }
  }, [selectedTemplate, templateFileLink, isMediaPlaceholder]);

  useEffect(() => {
    if (resetTemplateState !== null && resetTemplateState !== undefined) {
      resetTemplate();
    }
  }, [resetTemplateState]);

  return (
    <div className="funner-templates-menu" ref={templatesMenuRef} style={style}>
      {templatesWithSend ? (
        <div className="funner-templates-menu__topbar chat-topbar">
          <div
            className="funner-templates-menu__user-info user-info"
            style={{
              height:
                applicationType === "extension" || matchesBigMobile ? 65 : 72,
              borderRadius: 0,
            }}
          >
            {/* <div className="user-info__avatar">וג</div> */}
            <div className="user-info__text-part" style={{ maxWidth: "none" }}>
              <div className="user-info__text-part-content">
                <div className="user-info__titles">
                  <div className="user-info__title">
                    <IconButton
                      color="customWhite"
                      onClick={closeAccordion}
                      size="small"
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                    <div className="user-info__title-text name">
                      {chatUserName}
                    </div>
                  </div>
                  <div className="user-info__title">
                    <div className="user-info__title-icon user-info__close-chat">
                      <svg
                        width={7}
                        height={14}
                        viewBox="0 0 7 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.67708e-06 11.55L0 14L7 7L0 0V2.1L4.522 7L7.67708e-06 11.55Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="user-info__title-text">בחירת תבנית</div>
                  </div>
                </div>
              </div>
            </div>

            <Box>
              <HeaderOutlinedButton
                variant={showFiltersAnchor ? "contained" : "outlined"}
                matchesBigMobile={matchesBigMobile}
                onClick={openFilters}
              >
                <FilterListOutlined />
              </HeaderOutlinedButton>
            </Box>

            <IconButton
              color="customWhite"
              onClick={closeAccordion}
              size="small"
            >
              <Close />
            </IconButton>
          </div>
        </div>
      ) : (
        <Box>
          <IconButton
            variant={showFiltersAnchor ? "contained" : "outlined"}
            onClick={openFilters}
            color="primary"
            size="small"
            sx={{
              borderRadius: 1,
              mb: 1,
              bgcolor: showFiltersAnchor ? "primary.main" : null,
              color: showFiltersAnchor ? "common.white" : null,
            }}
          >
            <FilterListOutlined />
          </IconButton>
        </Box>
      )}

      {templatesLoading && (
        <div className="whatsapp-loader loader active">
          <div className="loader__element"></div>
        </div>
      )}

      <div
        className="funner-templates-menu__topbar chat-topbar chat-sidebar-topbar"
        style={{
          borderRight: 0,
          padding: templatesWithSend ? "25.5px 7px" : "20px 7px",
          backgroundColor: templatesWithSend ? "#eeeeee" : "#f5f5f5",
          height: templatesWithSend
            ? applicationType === "extension" || matchesBigMobile
              ? 60
              : 80
            : null,
        }}
      >
        <div className="chat-sidebar__templates-header">
          {/* <p className="chat-sidebar__templates-title">{localization.title}</p> */}
          <label className="chat-sidebar__templates-search">
            <input
              type="text"
              id="funner-global-messanger__templates-search-input"
              placeholder={localization.inputPlaceholder}
              style={{ marginBottom: 0 }}
              onChange={handleSearchFilter}
              value={filters.searchQuery}
            />
            <span className="chat-sidebar__templates-search-input-icon">
              <img src={searchIcon} alt="" />
            </span>
          </label>
        </div>
      </div>

      <ul className="funner-templates-menu__templates-list">
        {visibleTemplatesList.map((template) => (
          <li
            className="funner-templates-menu__templates-list-item"
            key={template.id}
          >
            <SingleTemplate
              template={template}
              chatUser={chatUser}
              isThisActive={selectedTemplate?.id === template.id}
              setActiveTemplateFunction={handleActiveTemplate}
              whatsappTemplatesList={whatsappTemplatesList}
              createParamsObject={createParamsObject}
              handleSendTemplate={handleSendTemplate}
              templateFileLink={templateFileLink}
              setTemplateFileLink={setTemplateFileLink}
              setTemplateFileName={setTemplateFileName}
              filters={filters}
              templatesMenuRef={templatesMenuRef}
              resetTemplate={resetTemplate}
              templatesWithSend={templatesWithSend}
              hebrewNamePopoverRef={hebrewNamePopoverRef}
              noSendTemplateAction={noSendTemplateAction}
              noUpload={noUpload}
              setHebrewNamePopover={setHebrewNamePopover}
              inBots={inBots}
              isMediaPlaceholder={isMediaPlaceholder}
              setIsMediaPlaceholder={setIsMediaPlaceholder}
            />
          </li>
        ))}

        <Popover
          open={hebrewNamePopover.open}
          onClose={handleCloseHebrewNamePopover}
          anchorEl={hebrewNamePopover.target}
          slotProps={{
            paper: {
              sx: {
                maxWidth: 275,
              },
            },
          }}
        >
          <Box sx={{ py: 1, px: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <IconButton size="small" onClick={handleCloseHebrewNamePopover}>
                  <Close />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={localization.hebrewNamePopover.inputTitle}
                  value={newHebrewName}
                  onChange={handleNewHebrewName}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} textAlign={"right"}>
                <Button
                  variant="contained"
                  onClick={submitNewHebrewName}
                  size="small"
                >
                  {localization.hebrewNamePopover.btnText}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Popover>
      </ul>

      <Popover
        open={Boolean(showFiltersAnchor)}
        onClose={() => closeFilters()}
        anchorEl={showFiltersAnchor}
        slotProps={{
          paper: {
            sx: {
              width: 320,
              mr: templatesWithSend ? 0.875 : -0.875,
              mt: templatesWithSend ? 0.5 : 1.5,
              borderRadius: 1,
              borderColor: "primary.main",
              borderStyle: "solid",
              borderWidth: "1px 1px 2px 2px",
            },
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: templatesWithSend ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: templatesWithSend ? "left" : "right",
        }}
      >
        <Box
          sx={{
            px: 3.375,
            py: 1.375,
          }}
        >
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <Typography>{localization.filters.header}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={1.25}
                flexWrap={"nowrap"}
                alignItems={"center"}
              >
                <Grid item flexGrow={1}>
                  <Typography>
                    {localization.filters.activeStatusFilter.toggleActiveText}
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl fullWidth size="small" sx={{ width: 100 }}>
                    <Select
                      value={filters.isActive}
                      onChange={(e) => handleActiveFilter(e.target.value)}
                    >
                      {filtersParams.isActive.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={1.25}
                flexWrap={"nowrap"}
                alignItems={"center"}
              >
                <Grid item flexGrow={1}>
                  <Typography>
                    {localization.filters.hebrewViewFilter.toggleLanguageText}
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl fullWidth size="small" sx={{ width: 100 }}>
                    <Select
                      value={filters.isHebrew}
                      onChange={(e) => handleIsHebrewFilter(e.target.value)}
                    >
                      {filtersParams.isHebrew.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={1.25}
                flexWrap={"nowrap"}
                alignItems={"center"}
              >
                <Grid item flexGrow={1}>
                  <Typography>
                    {localization.filters.languageFilter.label}
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={filtersParams.languages
                      .filter((lang) => filters.language.includes(lang.value))
                      ?.map((lang) => lang.label)
                      .join(", ")}
                  >
                    <FormControl fullWidth size="small" sx={{ width: 100 }}>
                      <Select
                        value={filters.language}
                        // onChange={(e) => handleLanguageFilter(e.target.value)}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (
                            value.includes("all") &&
                            !filters.language.includes("all")
                          ) {
                            handleLanguageFilter(["all"]);
                          } else {
                            handleLanguageFilter(
                              typeof value === "string"
                                ? value
                                    .split(",")
                                    .filter((valuePart) => valuePart !== "all")
                                : value.filter(
                                    (valuePart) => valuePart !== "all",
                                  ),
                            );
                          }
                        }}
                        multiple
                      >
                        <MenuItem value={"all"}>
                          {localization.filters.languageFilter.all}
                        </MenuItem>
                        {filtersParams.languages.map((item) => (
                          <MenuItem value={item.value} key={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </div>
  );
}

export default TemplatesMenu;
