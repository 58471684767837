import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DocumentScanner, VideoCameraBackOutlined } from "@mui/icons-material";
import localizations from "../../../localizations.json";

const FilesGalleryView = ({
  files,
  type,
  handleDeleteFileFromGallery,
  onEditExistingNameClick,
  handleLoadFilesListOnScroll,
  noActions,
  onClickAction,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [localization, setLocalization] = useState(
    localizations["he"].settingsPopup.uploadFiles,
  );
  // const open = Boolean(anchorEl);

  // const handleClick = (event, file) => {
  //   setAnchorEl(event.currentTarget);
  //   setSelectedFile(file);
  // };

  // const handleContextMenu = (event, file) => {
  //   event.preventDefault();
  //   setAnchorEl(
  //     anchorEl === null
  //       ? {
  //           mouseX: event.clientX + 20,
  //           mouseY: event.clientY - 20,
  //         }
  //       : null,
  //   );
  //   setSelectedFile(file);
  // };
  const handleContextMenu = (event, file) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setSelectedFile(null);
    }, 300);
  };

  return (
    <Box
      maxHeight={"100%"}
      overflow={"auto"}
      px={1}
      py={1.5}
      onScroll={(e) => handleLoadFilesListOnScroll(e, 200)}
    >
      <Grid container spacing={2}>
        {files.map((file) => (
          <Grid
            item
            key={file.Id}
            xs={6}
            sm={4}
            md={3}
            lg={2.4}
            xl={2}
            minWidth={200}
          >
            <Card
              sx={{
                transition: "transform 0.3s ease 0s, box-shadow 0.3s ease 0s",
                cursor: "pointer",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow:
                    "0px 2px 5px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);",
                },
              }}
              onClick={() => onClickAction(file.Link)}
              // onContextMenu={(e) => handleContextMenu(e, file)}
            >
              {type === "image" ? (
                <CardMedia
                  component={"img"}
                  height="100%"
                  image={file.Link}
                  alt={file.Name}
                  sx={{
                    aspectRatio: 2 / 1.25,
                  }}
                />
              ) : (
                <Box
                  height="100%"
                  sx={{
                    aspectRatio: 2 / 1.25,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {type === "video" && (
                    <VideoCameraBackOutlined sx={{ fontSize: 50 }} />
                  )}
                  {type === "document" && (
                    <DocumentScanner sx={{ fontSize: 50 }} />
                  )}
                </Box>
              )}

              <CardContent
                sx={{
                  borderTop: type === "image" ? null : "1px solid #eee",
                }}
              >
                <Grid container spacing={1} alignItems={"center"}>
                  <Grid item xs={12}>
                    <Tooltip title={file.Name} placement="bottom-start">
                      <Typography
                        variant="h5"
                        component="div"
                        fontSize={20}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                        textOverflow={"ellipsis"}
                        maxWidth={"100%"}
                      >
                        {file.Name}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <Typography variant="body2" color="text.secondary">
                      {file.DateCreate}
                    </Typography>
                  </Grid>
                  {!noActions && (
                    <Grid item xs={"auto"}>
                      <IconButton
                        onClick={(event) => handleContextMenu(event, file)}
                        style={{ float: "right" }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Menu
        // open={anchorEl !== null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        // anchorReference="anchorPosition"
        // anchorPosition={
        //   anchorEl !== null
        //     ? { top: anchorEl.mouseY, left: anchorEl.mouseX }
        //     : undefined
        // }
        anchorEl={anchorEl}
      >
        <MenuItem
          onClick={() => {
            handleDeleteFileFromGallery(selectedFile);
            handleClose();
          }}
        >
          {localization.menu.delete}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onEditExistingNameClick(selectedFile);
            handleClose();
          }}
        >
          {localization.menu.editName}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default FilesGalleryView;
