import React, { useEffect, useState } from "react";
import { contactFace, contactIcon } from "../../../../libs/images";

function ContactsReplyTemplate({ sentOrReceived, object }) {
  const [contact, setContact] = useState(null);

  function getContact(object) {
    const objectParsed = JSON.parse(object);
    const name = objectParsed?.name?.first_name;
    const phone = objectParsed?.phones[0]?.phone;
    return { name: name, phone: phone };
  }

  useEffect(() => {
    if (object) {
      const thisContact = getContact(object);
      setContact(thisContact);
    }
  }, [object]);

  return (
    <a
      className={`message__reply-contact reply reply_contact  ${
        sentOrReceived == "incoming" ? "incoming" : "outcoming"
      }`}
    >
      <div className="reply__text-part">
        <p className="reply__top-text">{contact?.name}</p>
        <div className="reply__info">
          <div className="reply__icon">
            <img src={contactIcon} alt="" />
          </div>
          <p className="reply__text">{contact?.phone}</p>
        </div>
      </div>
      <div className="reply-contact__logo-part">
        <div className="reply-contact__logo">
          <img src={contactFace} alt="" />
        </div>
      </div>
    </a>
  );
}

export default ContactsReplyTemplate;
