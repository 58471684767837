import React, { useEffect, useState } from "react";
import { docx, uploadPreview, xlsx, zip } from "../../../../libs/images";

function FileReplyTemplate({ sentOrReceived, userName, fileType, file }) {
  const [fileTypeText, setFileTypeText] = useState(null);
  const [fileTypeImg, setFileTypeImg] = useState(null);

  function getFileInfo(FileType, file) {
    //console.log('getFileInfo', FileType);
    let fileTypeText = "";
    let fileTypeImg = null;

    if (FileType === "pdf") {
      fileTypeText = "PDF";
      fileTypeImg = uploadPreview;
    } else if (FileType === "docx") {
      fileTypeText = "DOCX";
      fileTypeImg = docx;
    } else if (FileType === "xlsx") {
      fileTypeText = "XLSX";
      fileTypeImg = xlsx;
    } else if (FileType === "zip") {
      fileTypeText = "ZIP";
      fileTypeImg = zip;
    } else if (FileType === "image" || FileType.match(/\.(png|jpe?g|gif)$/i)) {
      fileTypeText = "image";
      fileTypeImg = file;
    }

    return { fileTypeText, fileTypeImg };
  }

  useEffect(() => {
    if (file && fileType) {
      const { fileTypeText, fileTypeImg } = getFileInfo(fileType, file);

      setFileTypeText(fileTypeText);
      setFileTypeImg(fileTypeImg);
    }
  }, [file, fileType]);

  return (
    <a
      className={`message__reply-location reply reply_location ${
        sentOrReceived === "incoming" ? "incoming" : "outcoming"
      }`}
    >
      <div className="reply__text-part">
        <p className="reply__top-text">
          {sentOrReceived === "incoming" ? userName : "אתה"}
        </p>
        <div className="reply__info">
          <p className="reply__text">{fileTypeText}</p>
        </div>
      </div>
      <div className="reply__map-part">
        <div className="reply__map document">
          <img src={fileTypeImg} alt="" />
        </div>
      </div>
    </a>
  );
}

export default FileReplyTemplate;
