import React from "react";
import { IsJsonString } from "../../../../libs/helpers";

function ReplyButtonMessage({ replyObject }) {
  return (
    <div className="message__upload-container">
      <div
        className={`message__reply-audio reply ${
          replyObject?.Type === "incoming" ? "incoming" : "outcoming"
        }`}
      >
        <div className="reply__text-part">
          <div className="reply__text">
            <p className="reply-text">
              {replyObject?.FileType === "template" &&
              IsJsonString(replyObject.Message)
                ? JSON.parse(replyObject.Message)["BODY"].text +
                  `<br><span style="opacity: 0.65; display: inline-block; margin-top: 5px">template</span>`
                : replyObject?.Message || ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReplyButtonMessage;
