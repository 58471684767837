import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

export const CardTitle = ({
  children,
  variant,
  color,
  fontSize,
  fontWeight,
  letterSpacing,
  ...params
}) => {
  return (
    <Typography
      variant={variant || "h5"}
      color={color || "#757575"}
      fontSize={fontSize || 22}
      fontWeight={fontWeight || 900}
      letterSpacing={letterSpacing || 0.15}
      {...params}
    >
      {children}
    </Typography>
  );
};
export const CardContentTitle = ({
  children,
  variant,
  color,
  fontSize,
  fontWeight,
  letterSpacing,
  ...params
}) => {
  return (
    <Typography
      variant={variant || "h6"}
      color={color || "#BDBDBD"}
      fontSize={fontSize || 22}
      fontWeight={fontWeight || 900}
      letterSpacing={letterSpacing || 0.15}
      {...params}
    >
      {children}
    </Typography>
  );
};
export const CardText = ({
  color,
  fontSize,
  letterSpacing,
  children,
  ...params
}) => {
  return (
    <Typography
      color={color || "text.primary"}
      fontSize={fontSize || 14}
      letterSpacing={letterSpacing || 0.17}
      {...params}
    >
      {children}
    </Typography>
  );
};

function SettingsCard({ children, headerProps, headerSx }) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        bgcolor: "rgba(215, 215, 215, 0.30)",
        p: 0.75,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 0.75,
        boxShadow: "none",
        borderRadius: 0.5,

        [theme.breakpoints.down(768)]: {
          mt: 6,
          height: "calc(100% - 48px)",
        },
      }}
    >
      <CardHeader
        {...headerProps}
        sx={{
          backgroundColor: "white",
          borderRadius: 0.5,
          "& .MuiCardHeader-action": {
            mr: 0,
            maxWidth: "100%",
          },
          [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
            rowGap: 2,
          },
          ...headerSx,
        }}
      />
      <CardContent
        sx={{
          backgroundColor: "white",
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          borderRadius: 1,
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
}

export default SettingsCard;
