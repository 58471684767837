import React, { useEffect, useRef, useState } from "react";
import {
  carSmiles,
  closeIcon,
  coffeeSmiles,
  flagSmiles,
  happySmiles,
  lampSmiles,
  petSmiles,
  recentSmiles,
  sportsSmiles,
} from "../../libs/images";
import { emojiesArray, emojiesCategories } from "../../libs/emojies";

function EmojiReplyTemplate({
  emojiReplyRef,
  sendEmojiReply,
  handleEmojiReply,
  messagesFeedRef,
  handleSendEmojiReply,
}) {
  const smileList = ["👍", "🙂", "🔥", "❤️", "😂"];

  const [isAllSmiles, setIsAllSmiles] = useState(false);
  const [activeTab, setActiveTab] = useState("recent");

  const [searchInputValue, setSearchInputValue] = useState("");

  const allSmilesRef = useRef(null);
  const emojiBodyRef = useRef(null);

  function scrollToEmojiBlock(event) {
    const btn = event.target.closest(".smile-panel__tabs-title");
    const id = btn.getAttribute("data-id");
    let parent = btn.parentElement.parentElement;

    const element = parent.querySelector(`#${id}`);
    if (element) {
      const emojiBody = emojiBodyRef.current;
      if (emojiBody) {
        emojiBody.scrollTop = element.offsetTop - 140;
      }
    }
  }
  const handleSearchValue = (value) => {
    setSearchInputValue(value);
  };

  const openAllSmilesWindow = () => {
    const parentElement = messagesFeedRef.current;
    console.log(parentElement);

    const newX =
      parentElement.offsetWidth - (sendEmojiReply.coords.x + 475) < 30
        ? parentElement.offsetWidth - 475 - 30
        : sendEmojiReply.coords.x;
    const newY = sendEmojiReply.coords.y - 40;

    handleSendEmojiReply({
      coords: {
        x: newX,
        y: newY,
      },
    });

    setIsAllSmiles(true);
  };

  return isAllSmiles ? (
    <div
      className="context-menu-all-smiles all-smiles-reaction reactions-popup"
      ref={allSmilesRef}
      style={{
        left: sendEmojiReply.coords?.x,
        top: sendEmojiReply.coords?.y,
      }}
    >
      <button
        className="context-menu-close-btn top"
        onClick={() => {
          handleSendEmojiReply({
            visible: false,
            coords: null,
            message: null,
          });
        }}
      >
        <img src={closeIcon} alt="" />
      </button>
      <div className="smile-panel top">
        <div className="smile-panel__tabs">
          <nav className="smile-panel__tabs-navigation">
            <button
              data-id="smileysEmotion"
              type="button"
              className={`smile-panel__tabs-title ${
                activeTab === "recent" ? "_tab-active" : ""
              }`}
              onClick={(e) => {
                setActiveTab("recent");
                scrollToEmojiBlock(e);
              }}
            >
              <img src={recentSmiles} alt="" />
            </button>
            <button
              data-id="peopelBody"
              type="button"
              className={`smile-panel__tabs-title ${
                activeTab === "happy" ? "_tab-active" : ""
              }`}
              onClick={(e) => {
                setActiveTab("happy");
                scrollToEmojiBlock(e);
              }}
            >
              <img src={happySmiles} alt="" />
            </button>
            <button
              data-id="animalsNature"
              type="button"
              className={`smile-panel__tabs-title ${
                activeTab === "pet" ? "_tab-active" : ""
              }`}
              onClick={(e) => {
                setActiveTab("pet");
                scrollToEmojiBlock(e);
              }}
            >
              <img src={petSmiles} alt="" />
            </button>
            <button
              data-id="travelPlaces"
              type="button"
              className={`smile-panel__tabs-title ${
                activeTab === "flag" ? "_tab-active" : ""
              }`}
              onClick={(e) => {
                setActiveTab("flag");
                scrollToEmojiBlock(e);
              }}
            >
              <img src={flagSmiles} alt="" />
            </button>
            <button
              data-id="activities"
              type="button"
              className={`smile-panel__tabs-title ${
                activeTab === "coffee" ? "_tab-active" : ""
              }`}
              onClick={(e) => {
                setActiveTab("coffee");
                scrollToEmojiBlock(e);
              }}
            >
              <img src={coffeeSmiles} alt="" />
            </button>
            <button
              data-id="emojiObjects"
              type="button"
              className={`smile-panel__tabs-title ${
                activeTab === "sports" ? "_tab-active" : ""
              }`}
              onClick={(e) => {
                setActiveTab("sports");
                scrollToEmojiBlock(e);
              }}
            >
              <img src={sportsSmiles} alt="" />
            </button>
            <button
              type="button"
              data-id="emojiSymbols"
              className={`smile-panel__tabs-title ${
                activeTab === "car" ? "_tab-active" : ""
              }`}
              onClick={(e) => {
                setActiveTab("car");
                scrollToEmojiBlock(e);
              }}
            >
              <img src={carSmiles} alt="" />
            </button>
            <button
              data-id="emojiFlags"
              type="button"
              className={`smile-panel__tabs-title ${
                activeTab === "lamp" ? "_tab-active" : ""
              }`}
              onClick={(e) => {
                setActiveTab("lamp");
                scrollToEmojiBlock(e);
              }}
            >
              <img src={lampSmiles} alt="" />
            </button>
          </nav>
          <div className="smile-panel__search top">
            <input
              type="text"
              placeholder="חפש/י אימוג׳י..."
              name=""
              value={searchInputValue}
              onChange={(e) => handleSearchValue(e.target.value)}
            />
          </div>
          <div className="smile-panel__tabs-content top" ref={emojiBodyRef}>
            <div className="smile-panel__tabs-body">
              <div className="smile-panel__smiles-block">
                {Object.entries(emojiesCategories).map(([id, category]) => (
                  <div
                    id={id}
                    key={id}
                    className={
                      id === "emojiFlags"
                        ? "smile-panel__smiles smile-panel__smiles_recent"
                        : "smile-panel__smiles smile-panel__smiles_current"
                    }
                  >
                    <div className="smile-panel__smiles-list">
                      {emojiesArray
                        .filter((emoji) =>
                          emoji.aliases.find((item) =>
                            item.includes(searchInputValue),
                          ),
                        )
                        // .slice(0, 1000)
                        .map((emoji) =>
                          emoji.category === category ? (
                            <button
                              type="button"
                              title={emoji.description}
                              className="smile-panel__smile"
                              onClick={handleEmojiReply}
                              key={`${emoji.emoji}-${emoji.category}`}
                            >
                              <span>{emoji.emoji}</span>
                            </button>
                          ) : null,
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="smile-to-react__list inside-feed"
      ref={emojiReplyRef}
      style={{
        left: sendEmojiReply.coords?.x,
        top: sendEmojiReply.coords?.y,
      }}
    >
      {smileList.map((smile, index) => (
        <div
          className="smile-to-react__item"
          key={smile}
          onClick={handleEmojiReply}
        >
          {smile}
        </div>
      ))}
      <div
        className="smile-to-react__item smile-to-react__item_plus from-message"
        onClick={openAllSmilesWindow}
      >
        +
      </div>
    </div>
  );
}

export default EmojiReplyTemplate;
