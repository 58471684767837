import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import localizations from "../../../localizations.json";

// function LinkifiedText({ text, websitePreviewObj }) {
//   const [localization, setLocalization] = useState(
//     localizations["he"].chat.messages.websitePreview,
//   );
//   const [processedText, setProcessedText] = useState([]);

//   useEffect(() => {
//     if (!text) return;

//     const rx =
//       /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

//     function isImageLink(link) {
//       return link.match(/\.(jpeg|jpg|gif|png|svg|webp)$/) !== null;
//     }

//     let parts = text.split(rx);
//     let updatedText = parts.reduce((acc, part, index) => {
//       if (part === "https") return acc;
//       if (!part.match(rx)) {
//         // acc.push(part);
//         part.split("\n").forEach((textPart, partIndex, array) => {
//           acc.push(textPart);
//           if (partIndex < array.length - 1)
//             acc.push(<br key={`br-${index}-${partIndex}`} />);
//         });
//       } else if (index % 2 === 0) {
//         part.split("\n").forEach((textPart, partIndex, array) => {
//           acc.push(textPart);
//           if (partIndex < array.length - 1)
//             acc.push(<br key={`br-${index}-${partIndex}`} />);
//         });
//       } else {
//         const linkElement = isImageLink(part) ? (
//           <React.Fragment key={`image-${index}`}>
//             <a href={part} target="_blank" rel="noopener noreferrer">
//               {part}
//             </a>
//             <br />
//             <img
//               src={part}
//               alt=""
//               style={{ maxWidth: "100%", marginTop: "5px" }}
//             />
//           </React.Fragment>
//         ) : (
//           <a
//             key={`link-${index}`}
//             href={part}
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             {part}
//           </a>
//         );

//         acc.push(linkElement);
//       }

//       return acc;
//     }, []);

//     const previewElements = websitePreviewObj
//       ? JSON.parse(websitePreviewObj).map((link, index) => {
//           const { url, title, description, previewImage } = link;
//           return (
//             <div key={`preview-${index}`} className="message-url-preview">
//               <div className="message-url-preview__content">
//                 {title && title !== "" && (
//                   <div className="message-url-preview__title">{title}</div>
//                 )}
//                 {description && description !== "" && (
//                   <div className="message-url-preview__description">
//                     {description}
//                   </div>
//                 )}
//                 {previewImage && (
//                   <div className="message-url-preview__image">
//                     <img src={previewImage} alt={title} />
//                   </div>
//                 )}
//                 {url && (
//                   <Button
//                     href={url}
//                     target="_blank"
//                     variant="contained"
//                     size="small"
//                     color="primary"
//                     sx={{ color: "#fff !important", mt: 1 }}
//                   >
//                     {localization.openURL}
//                   </Button>
//                 )}
//               </div>
//             </div>
//           );
//         })
//       : [];

//     setProcessedText([...updatedText, ...previewElements]);
//   }, [text, websitePreviewObj]);

//   return <>{processedText}</>;
// }

function LinkifiedText({
  text,
  websitePreviewObj,
  funnerUsers,
  messageType,
  chatCustomizationSettings,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].chat.messages.websitePreview,
  );
  const [processedText, setProcessedText] = useState([]);

  useEffect(() => {
    if (!text) return;

    const rx =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    function isImageLink(link) {
      return link.match(/\.(jpeg|jpg|gif|png|svg|webp)$/) !== null;
    }

    // function wrapTextConditionally(textPart) {
    //   if (messageType === "note") {
    //     funnerUsers.forEach((user) => {
    //       if (textPart.includes(`@${user.FullName}`)) {
    //         console.log(`@${user.FullName}`);
    //         textPart = textPart.replaceAll(
    //           `@${user.FullName}`,
    //           <span className="highlighted-text">@{user.FullName}</span>,
    //         );
    //       }
    //     });
    //   }

    //   return textPart;
    // }
    function wrapTextConditionally(textPart) {
      let parts = [textPart];

      if (messageType === "note") {
        funnerUsers.forEach((user) => {
          const newParts = [];
          parts.forEach((part, index) => {
            if (
              typeof part === "string" &&
              part.includes(`@${user.FirstName || ""} ${user.LastName || ""}`)
            ) {
              const splitParts = part.split(
                `@${user.FirstName || ""} ${user.LastName || ""}`,
              );
              for (let i = 0; i < splitParts.length; i++) {
                if (i > 0) {
                  newParts.push(
                    <Box
                      component={"span"}
                      key={`highlight-${index}-${i}`}
                      className="highlighted-text"
                      sx={{
                        color:
                          chatCustomizationSettings?.find(
                            (option) => option.type === "noteMessage",
                          )?.value === "custom"
                            ? chatCustomizationSettings?.find(
                                (option) => option.type === "noteMessage",
                              ).customColor.background + " !important"
                            : "#9c27b0",
                        fontWeight: 500,
                      }}
                    >
                      @{user.FullName}
                    </Box>,
                  );
                }
                newParts.push(splitParts[i]);
              }
            } else {
              newParts.push(part);
            }
          });
          parts = newParts;
        });
      }

      return parts;
    }

    let parts = text.split(rx);
    let updatedText = parts.reduce((acc, part, index) => {
      if (part === "https") return acc;
      if (!part.match(rx)) {
        part.split("\n").forEach((textPart, partIndex, array) => {
          acc.push(wrapTextConditionally(textPart));
          if (partIndex < array.length - 1)
            acc.push(<br key={`br-${index}-${partIndex}`} />);
        });
      } else if (index % 2 === 0) {
        part.split("\n").forEach((textPart, partIndex, array) => {
          acc.push(wrapTextConditionally(textPart));
          if (partIndex < array.length - 1)
            acc.push(<br key={`br-${index}-${partIndex}`} />);
        });
      } else {
        const linkElement = isImageLink(part) ? (
          <React.Fragment key={`image-${index}`}>
            <a href={part} target="_blank" rel="noopener noreferrer">
              {part}
            </a>
            <br />
            <img
              src={part}
              alt=""
              style={{ maxWidth: "100%", marginTop: "5px" }}
            />
          </React.Fragment>
        ) : (
          <a
            key={`link-${index}`}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>
        );

        acc.push(linkElement);
      }

      return acc;
    }, []);

    const previewElements = websitePreviewObj
      ? JSON.parse(websitePreviewObj).map((link, index) => {
          const { url, title, description, previewImage } = link;
          return (
            <div key={`preview-${index}`} className="message-url-preview">
              <div className="message-url-preview__content">
                {title && title !== "" && (
                  <div className="message-url-preview__title">{title}</div>
                )}
                {description && description !== "" && (
                  <div className="message-url-preview__description">
                    {description}
                  </div>
                )}
                {previewImage && (
                  <div className="message-url-preview__image">
                    <img src={previewImage} alt={title} />
                  </div>
                )}
                {url && (
                  <Button
                    href={url}
                    target="_blank"
                    variant="contained"
                    size="small"
                    color="primary"
                    sx={{ color: "#fff !important", mt: 1 }}
                  >
                    {localization.openURL}
                  </Button>
                )}
              </div>
            </div>
          );
        })
      : [];

    setProcessedText([...updatedText, ...previewElements]);
  }, [text, websitePreviewObj]);

  return <>{processedText}</>;
}

export default LinkifiedText;

export function linkifyText(text, WebsitePreviewObj = null) {
  if (!text) return text;
  const rx =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

  function isImageLink(link) {
    return (
      link.includes(".jpg") ||
      link.includes(".png") ||
      link.includes(".jpeg") ||
      link.includes(".svg") ||
      link.includes(".webp")
    );
  }

  const processedLinks = new Set();
  let processedText = text
    .replaceAll(/\n/g, "<br>")
    .replace(rx, (match, p1) => {
      processedLinks.add(p1);

      if (isImageLink(p1)) {
        return ` <a href="${p1}" class="_message-in-link" target="_blank" rel="noreferrer">${p1}</a><img className="template-img" src="${p1}" alt="" style="margin-top: 5px" />`;
      } else {
        return `<a href="${p1}" class="_message-in-link" target="_blank" rel="noreferrer">${p1}</a>`;
      }
    });

  // processedText.replace(/\n/g, "<br>");

  if (WebsitePreviewObj) {
    const parsedWebsitePreviewObj = JSON.parse(WebsitePreviewObj);
    if (parsedWebsitePreviewObj.length > 0) {
      parsedWebsitePreviewObj.forEach((link) => {
        const { title, description, previewImage } = link;
        processedText += `
        <div class="message-url-preview">
          <div class="message-url-preview__content">
            ${
              title && title !== ""
                ? `
              <div class="message-url-preview__title">
                ${title}
              </div>
            `
                : ""
            }
            ${
              description && description !== ""
                ? `
              <div class="message-url-preview__description">
                ${description}
              </div>
            `
                : ""
            }
            ${
              previewImage
                ? `
              <div class="message-url-preview__image">
                <img src="${previewImage}" alt="${title}" />
              </div>
            `
                : ""
            }
          </div>
        </div>
      `;
      });
    }
  }
  return processedText;
}
