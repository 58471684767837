import React, { useEffect, useRef, useState } from "react";
import { applicationType } from "./applicationType";
import applicationConfig from "./config";
import {
  UseFetchGet,
  UseFetchPost,
  apiDomainUrlSocket,
} from "./hooks/fetchFunctions";
import localizations from "./localizations.json";
import {
  funnerExtensionLogoLogged,
  funnerExtensionLogoNotLogged,
  hintLogo,
} from "./libs/images";
import {
  getFromChromeStorage,
  getFromLocal,
  saveToChromeStorage,
  saveToLocal,
} from "./hooks/localStorage";
import { IsJsonString } from "./libs/helpers";
import AuthPopup from "./views/Auth/AuthPopup";
import Main from "./views/Main";
import { sendMessageToBackground } from "./hooks/extensionActions";
import { HubConnectionBuilder } from "@microsoft/signalr";
import HintTemplate from "./modules/HintTemplate";
import {
  Box,
  CssBaseline,
  IconButton,
  ScopedCssBaseline,
  SvgIcon,
} from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
  styled,
} from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { position, prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
import {
  NotificationIcon,
  NotificationProvider,
} from "./modules/NotificationContext";
import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from "notistack";
import { Close } from "@mui/icons-material";
import AppLoadError from "./views/TechPages/AppLoadError";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export let tokenGlobal = null;
let connection;

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, stylisRTLPlugin],
});
const theme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: ["Arimo"],
    pxToRem: (size) => `${size}px`,
  },
  palette: {
    mode: "light",
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      main: "#2A3847",
      light: "#1976d2",
      dark: "#202b36",
      contrastText: "#fff",
    },
    secondary: {
      main: "#1976d2",
      light: "rgba(25, 118, 210, 0.04)",
      dark: "#1663af",
      contrastText: "#fff",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
      contrastText: "#fff",
    },
    warning: {
      main: "#ed6c02",
      light: "#ff9800",
      dark: "#e65100",
      contrastText: "#fff",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
      contrastText: "#fff",
    },
    customWhite: {
      main: "#fff",
      light: "#fff",
      dark: "#eee",
      contrastText: "#2A3847",
    },
    customPurple: {
      main: "#9c27b0",
      light: "#d05ce3",
      dark: "#6a0080",
      contrastText: "#fff",
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#f5f5f5",
      A200: "#eeeeee",
      A400: "#bdbdbd",
      A700: "#616161",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    background: {
      paper: "#fff",
      default: "#fff",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(25, 118, 210, 0.08)",
      hoverOpacity: 0.08,
      selected: "rgba(25, 118, 210, 0.08)",
      selectedOpacity: 0.08,
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0, 0, 0, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
  zIndex: {
    appBar: 2100000101,
    drawer: 2100000201,
    fab: 2100000051,
    mobileStepper: 2100000001,
    modal: 2100000301,
    snackbar: 2100000501,
    speedDial: 2100000051,
    tooltip: 2100000501,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 991.98,
      lg: 1280,
      xl: 1536,
    },
  },

  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "700 !important",
          whiteSpace: "nowrap",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontWeight: "700 !important",
          whiteSpace: "nowrap",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#333",
          padding: "5px 9px !important",
          fontSize: 14,
        },
        arrow: {
          color: "#333",
        },
      },
      defaultProps: {
        arrow: true,
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          zIndex: 10000000000000000n,
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: 10000000000000000n,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          zIndex: 2100000052,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          "&.Mui-disabled": {
            "@media(min-width: 991.98px)": {
              width: 0,
            },
          },
        },
      },
    },
    // MuiMultiSectionDigitalClockSection-root
    MuiList: {
      styleOverrides: {
        root: {
          "&.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar": {
            width: 10,
          },
          "&.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar-thumb":
            {
              borderWidth: 2,
            },
        },
      },
    },
  },
});
console.log(theme);

const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  "&.notistack-MuiContent-default": {
    backgroundColor: theme.palette.primary.main,
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: theme.palette.error.main,
  },
  "&.notistack-MuiContent-success": {
    backgroundColor: theme.palette.success.main,
  },
  "&.notistack-MuiContent-info": {
    backgroundColor: theme.palette.info.main,
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: theme.palette.warning.main,
  },
}));

function App() {
  //! Main App Data
  const [isActiveAppOnPage, setIsActiveAppOnPage] = useState(false);
  const [token, setToken] = useState(null);
  // const [connection, setConnection] = useState(null);
  const [isConnection, setIsConnection] = useState(false);
  const [appLoadError, setAppLoadError] = useState(false);

  //! Config and Info
  const [userData, setUserData] = useState(null);
  const [userConfig, setUserConfig] = useState({
    isDisplayName: false,
    isAvatarActive: false,
    isDateTime: true,
    fontSize: "14",
    isMssgRTL: true,
    submitType: {
      ctrlEnter: [
        {
          text: localizations["he"].settingsPopup.userConfig.tabs.chats
            .leftColumn.submitTypes.ctrlEnter.breakLine,
          checked: true,
        },
        {
          text: localizations["he"].settingsPopup.userConfig.tabs.chats
            .leftColumn.submitTypes.ctrlEnter.sendMessage,
          checked: false,
        },
      ],
      shiftEnter: [
        {
          text: localizations["he"].settingsPopup.userConfig.tabs.chats
            .leftColumn.submitTypes.shiftEnter.breakLine,
          checked: true,
        },
        {
          text: localizations["he"].settingsPopup.userConfig.tabs.chats
            .leftColumn.submitTypes.shiftEnter.sendMessage,
          checked: false,
        },
      ],
      enter: [
        {
          text: localizations["he"].settingsPopup.userConfig.tabs.chats
            .leftColumn.submitTypes.enter.breakLine,
          checked: false,
        },
        {
          text: localizations["he"].settingsPopup.userConfig.tabs.chats
            .leftColumn.submitTypes.enter.sendMessage,
          checked: true,
        },
      ],
    },
    isNotifOn: true,
    enableCustomTime: true,
    notifHintTimeout: 5,
    displayInfoBots: true,
    displayInfoAssignee: true,
    openChatAuthControl: true,
    audioNotifVol: 0.5,
  });
  const [submitType, setSubmitType] = useState({
    ctrlEnter: [
      {
        text: localizations["he"].settingsPopup.userConfig.tabs.chats.leftColumn
          .submitTypes.ctrlEnter.breakLine,
        checked: true,
      },
      {
        text: localizations["he"].settingsPopup.userConfig.tabs.chats.leftColumn
          .submitTypes.ctrlEnter.sendMessage,
        checked: false,
      },
    ],
    shiftEnter: [
      {
        text: localizations["he"].settingsPopup.userConfig.tabs.chats.leftColumn
          .submitTypes.shiftEnter.breakLine,
        checked: true,
      },
      {
        text: localizations["he"].settingsPopup.userConfig.tabs.chats.leftColumn
          .submitTypes.shiftEnter.sendMessage,
        checked: false,
      },
    ],
    enter: [
      {
        text: localizations["he"].settingsPopup.userConfig.tabs.chats.leftColumn
          .submitTypes.enter.breakLine,
        checked: false,
      },
      {
        text: localizations["he"].settingsPopup.userConfig.tabs.chats.leftColumn
          .submitTypes.enter.sendMessage,
        checked: true,
      },
    ],
  });
  const [businessInfo, setBusinessInfo] = useState(null);
  const [linkToObjectNum, setLinkToObjectNum] = useState("first");

  //! App modules config
  //* Hints
  const [isHintActive, setIsHintActive] = useState(false);
  const [hintText, setHintText] = useState(null);
  const [hintType, setHintType] = useState(null);
  const [isHintIcon, setIsHintIcon] = useState(true);

  //! Page types
  const [isPowerLinkPage, setPowerLinkPage] = useState(
    window.location.href.includes("app.powerlink.co.il") ||
      window.location.href.includes("app.fireberry.com"),
  );

  //! Popups and other view states
  const [isReloadPopup, setIsReloadPopup] = useState(false);
  const [isNeedResetPassPopup, setIsNeedResetPassPopup] = useState(false);

  const [openAuthPopup, setOpenAuthPopup] = useState(false);
  const [authPopupType, setAuthPopupType] = useState("login");
  const [openChatAuthControl, setOpenChatAuthControl] = useState(true);
  const [iframeMode, setIframeMode] = useState(null);

  //! Refs
  const userDataRef = useRef(null);
  const userConfigRef = useRef({
    isDisplayName: false,
    isAvatarActive: false,
    isDateTime: true,
    fontSize: "14",
    isMssgRTL: true,
    submitType: {
      ctrlEnter: [
        {
          text: localizations["he"].settingsPopup.userConfig.tabs.chats
            .leftColumn.submitTypes.ctrlEnter.breakLine,
          checked: true,
        },
        {
          text: localizations["he"].settingsPopup.userConfig.tabs.chats
            .leftColumn.submitTypes.ctrlEnter.sendMessage,
          checked: false,
        },
      ],
      shiftEnter: [
        {
          text: localizations["he"].settingsPopup.userConfig.tabs.chats
            .leftColumn.submitTypes.shiftEnter.breakLine,
          checked: true,
        },
        {
          text: localizations["he"].settingsPopup.userConfig.tabs.chats
            .leftColumn.submitTypes.shiftEnter.sendMessage,
          checked: false,
        },
      ],
      enter: [
        {
          text: localizations["he"].settingsPopup.userConfig.tabs.chats
            .leftColumn.submitTypes.enter.breakLine,
          checked: false,
        },
        {
          text: localizations["he"].settingsPopup.userConfig.tabs.chats
            .leftColumn.submitTypes.enter.sendMessage,
          checked: true,
        },
      ],
    },
    isNotifOn: true,
    enableCustomTime: true,
    notifHintTimeout: 5,
    displayInfoBots: true,
    displayInfoAssignee: true,
    openChatAuthControl: true,
    audioNotifVol: 0.5,
  });
  const submitTypeRef = useRef({
    ctrlEnter: [
      {
        text: localizations["he"].settingsPopup.userConfig.tabs.chats.leftColumn
          .submitTypes.ctrlEnter.breakLine,
        checked: true,
      },
      {
        text: localizations["he"].settingsPopup.userConfig.tabs.chats.leftColumn
          .submitTypes.ctrlEnter.sendMessage,
        checked: false,
      },
    ],
    shiftEnter: [
      {
        text: localizations["he"].settingsPopup.userConfig.tabs.chats.leftColumn
          .submitTypes.shiftEnter.breakLine,
        checked: true,
      },
      {
        text: localizations["he"].settingsPopup.userConfig.tabs.chats.leftColumn
          .submitTypes.shiftEnter.sendMessage,
        checked: false,
      },
    ],
    enter: [
      {
        text: localizations["he"].settingsPopup.userConfig.tabs.chats.leftColumn
          .submitTypes.enter.breakLine,
        checked: false,
      },
      {
        text: localizations["he"].settingsPopup.userConfig.tabs.chats.leftColumn
          .submitTypes.enter.sendMessage,
        checked: true,
      },
    ],
  });
  const businessInfoRef = useRef(null);
  const refIsConnection = useRef(null);
  const tabActiveRef = useRef(false);
  const isActiveAppOnPageRef = useRef(null);
  const browserNotifPermissionRef = useRef(null);

  //! Control functions and handlers
  function handleSetNotif(text, type, isIcon = true) {
    // setHintType(type);
    // setHintText(text);
    // setIsHintIcon(isIcon);
    // setIsHintActive(true);
    // handleBrowserNotification("Funner", text);

    enqueueSnackbar(text, {
      variant: type,
      hideIconVariant: !isIcon,
    });
  }

  const browserPermissionRequest = () => {
    if (!("Notification" in window)) return;
    if (applicationType === "extension") {
      sendMessageToBackground("browserNotifPermReq");
    } else {
      if (
        Notification.permission === "default" ||
        Notification.permission === "denied"
      ) {
        Notification.requestPermission().then((permissionResult) => {
          browserNotifPermissionRef.current = permissionResult;
          console.log("Permission: ", permissionResult);
        });
      } else browserNotifPermissionRef.current = Notification.permission;
    }
  };
  const handleBrowserNotification = (title, content, icon = hintLogo) => {
    if (applicationType === "extension") {
      sendMessageToBackground("showBrowserNotif", {
        title: title,
        content: content,
        icon: icon,
      });
    }
    if (!("Notification" in window)) return;
    if (applicationType !== "extension") {
      console.log(
        "handleBrowserNotification perm: ",
        browserNotifPermissionRef.current,
      );
      if (browserNotifPermissionRef.current === "granted") {
        const notification = new Notification(title, {
          body: content,
          icon: icon,
        });

        notification.onclick = () => {
          window.focus();
        };
      } else {
        console.log("Уведомления запрещены пользователем");
      }
    }
  };

  const handleSetToken = (token) => {
    if (typeof token == "string") {
      if (token.length > 20) {
        setToken(token);
        tokenGlobal = token;
        if (applicationType === "extension") {
          saveToChromeStorage("whatsapp-token", token);
        } else saveToLocal("whatsapp-token", token);
      }
    }
  };
  function toggleAuthPopup() {
    ////console.log('toggleAuthPopup')
    if (
      !window.location.href.includes("https://app.powerlink.co.il/app/login")
    ) {
      setOpenAuthPopup((prev) => !prev);
    }
  }
  function updateUserData(params) {
    setUserData((prevData) => {
      return {
        ...prevData,
        ...params,
      };
    });

    userDataRef.current = {
      ...userDataRef.current,
      ...params,
    };
  }

  //! Main Functions
  function checkApplicationVersion() {
    checkVersionAction();
    const checkVersionInterval = setInterval(() => {
      checkVersionAction();
    }, 2 * 60000);

    async function checkVersionAction() {
      const res =
        applicationType === "extension"
          ? await UseFetchGet(
              "/api/services/getCurrentVersionExt",
              undefined,
              null,
              undefined,
              "config",
            )
          : await UseFetchGet(
              "/api/services/getCurrentVersionWeb",
              undefined,
              null,
              undefined,
              "config",
            );
      if (res?.data?.result) {
        const versionStatus = compareVersions(
          res.data.version,
          applicationConfig.version,
        );
        // Number(res.data.version.slice(6)) <=
        // Number(applicationConfig.version.slice(6));
        console.log(
          "Funner Version Status:\nApp: ",
          applicationConfig.version,
          "\nServer: ",
          res.data.version,
        );
        setIsReloadPopup(versionStatus === 1);
        // setIsReloadPopup(!isVersionRight);
      }
    }
    function compareVersions(version1, version2) {
      const v1 = version1.split(".").map(Number);
      const v2 = version2.split(".").map(Number);

      for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
        const num1 = v1[i] || 0; //* If version 1 shorter, use 0
        const num2 = v2[i] || 0; //* If version 2 shorter, use 0

        if (num1 > num2) return 1; //* Return 1 if Version 1 is younger
        if (num2 > num1) return -1; //* Return -1 if Version 2 is younger
      }

      return 0; //* Versions are equal
    }
  }
  function fillNewObjectByLists(
    blackList,
    whiteList,
    newExtensionStatusObject,
  ) {
    if (blackList && blackList.enabled) {
      newExtensionStatusObject.defaultEnabled = true;
      newExtensionStatusObject.list = blackList.list.map((item) => ({
        url: item,
        enabled: false,
      }));
    } else if (whiteList && whiteList.enabled) {
      newExtensionStatusObject.defaultEnabled = false;
      newExtensionStatusObject.list = whiteList.list.map((item) => ({
        url: item,
        enabled: true,
      }));
    }
    return newExtensionStatusObject;
  }
  const handleRemoveToken = () => {
    localStorage.removeItem("whatsapp-token");
    localStorage.removeItem("globalFunnerMessangerOpenStatus");
    localStorage.removeItem("settings-active-tab");
    setToken(null);
    if (applicationType === "extension") {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage(
        { action: "removeToken" },
        function (response) {
          console.log(response.message);
        },
      );
    }
    if (applicationType === "web") window.location.reload();
  };
  function createConnection() {
    const isConnection = refIsConnection.current;
    if (isConnection === true) return;
    let connectionRetries = Array(3000).fill(2000);

    connection = new HubConnectionBuilder()
      .withUrl(`${apiDomainUrlSocket}/hub/signalr?access_token=${tokenGlobal}`)
      .withAutomaticReconnect(connectionRetries)
      .build();

    connection
      .start()
      .then(async function (result) {
        refIsConnection.current = true;
        // console.log("Connected!", connection);

        // console.log('Connection result', result);
        // console.log(`Connection State: ${connection.connectionState}`);
      })
      .catch((e) => {
        //console.log('Connection failed: ', e);
      });

    setIsConnection(true);
    //? setConnection(connectionInit);
  }
  function closeConnection() {
    if (refIsConnection.current)
      connection
        .stop()
        .then(() => {
          console.log("Connection stopped");
          refIsConnection.current = false;
          setIsConnection(false);
        })
        .catch((err) =>
          console.error("Error while stopping connection: ", err),
        );
  }
  function resetSavedData() {
    setUserData(null);
    userDataRef.current = null;

    setBusinessInfo(null);
    businessInfoRef.current = null;

    setUserConfig({
      isDisplayName: false,
      isAvatarActive: false,
      isDateTime: true,
      fontSize: "14",
      isMssgRTL: true,
      submitType: {
        ctrlEnter: [
          {
            text: localizations["he"].settingsPopup.userConfig.tabs.chats
              .leftColumn.submitTypes.ctrlEnter.breakLine,
            checked: true,
          },
          {
            text: localizations["he"].settingsPopup.userConfig.tabs.chats
              .leftColumn.submitTypes.ctrlEnter.sendMessage,
            checked: false,
          },
        ],
        shiftEnter: [
          {
            text: localizations["he"].settingsPopup.userConfig.tabs.chats
              .leftColumn.submitTypes.shiftEnter.breakLine,
            checked: true,
          },
          {
            text: localizations["he"].settingsPopup.userConfig.tabs.chats
              .leftColumn.submitTypes.shiftEnter.sendMessage,
            checked: false,
          },
        ],
        enter: [
          {
            text: localizations["he"].settingsPopup.userConfig.tabs.chats
              .leftColumn.submitTypes.enter.breakLine,
            checked: false,
          },
          {
            text: localizations["he"].settingsPopup.userConfig.tabs.chats
              .leftColumn.submitTypes.enter.sendMessage,
            checked: true,
          },
        ],
      },
      isNotifOn: true,
      enableCustomTime: true,
      notifHintTimeout: 5,
      displayInfoBots: true,
      displayInfoAssignee: true,
      openChatAuthControl: true,
    });
    userConfigRef.current = {
      isDisplayName: false,
      isAvatarActive: false,
      isDateTime: true,
      fontSize: "14",
      isMssgRTL: true,
      submitType: {
        ctrlEnter: [
          {
            text: localizations["he"].settingsPopup.userConfig.tabs.chats
              .leftColumn.submitTypes.ctrlEnter.breakLine,
            checked: true,
          },
          {
            text: localizations["he"].settingsPopup.userConfig.tabs.chats
              .leftColumn.submitTypes.ctrlEnter.sendMessage,
            checked: false,
          },
        ],
        shiftEnter: [
          {
            text: localizations["he"].settingsPopup.userConfig.tabs.chats
              .leftColumn.submitTypes.shiftEnter.breakLine,
            checked: true,
          },
          {
            text: localizations["he"].settingsPopup.userConfig.tabs.chats
              .leftColumn.submitTypes.shiftEnter.sendMessage,
            checked: false,
          },
        ],
        enter: [
          {
            text: localizations["he"].settingsPopup.userConfig.tabs.chats
              .leftColumn.submitTypes.enter.breakLine,
            checked: false,
          },
          {
            text: localizations["he"].settingsPopup.userConfig.tabs.chats
              .leftColumn.submitTypes.enter.sendMessage,
            checked: true,
          },
        ],
      },
      isNotifOn: true,
      enableCustomTime: true,
      notifHintTimeout: 5,
      displayInfoBots: true,
      displayInfoAssignee: true,
      openChatAuthControl: true,
    };

    setIsActiveAppOnPage(false);

    if (refIsConnection.current === true) {
      closeConnection();
    }
  }

  //! Async functions
  //* Get app and user info functions
  async function getToken() {
    const token =
      applicationType === "extension"
        ? await getFromChromeStorage("whatsapp-token")
        : getFromLocal("whatsapp-token");

    setToken(token);
    tokenGlobal = token;
    return token;
  }
  async function initData() {
    let isUserAuthorized = false;
    const getParams = new URLSearchParams(document.location.search);
    let iframeMode = getParams.get("iframeMode");

    if (iframeMode && applicationType === "web") {
      const token = getParams.get("token");
      console.log(token);

      setToken(token);
      tokenGlobal = token;
      isUserAuthorized = Boolean(token);
      setIframeMode(iframeMode);
    } else {
      isUserAuthorized = Boolean(await getToken());
    }

    if (isUserAuthorized) {
      if (tabActiveRef.current === true) {
        sendMessageToBackground("setIcon", {
          iconPath: funnerExtensionLogoLogged,
        });
      }
      getSettingsInfoOnLoad();
      setAuthPopupType("logout");
    } else {
      if (tabActiveRef.current === true) {
        sendMessageToBackground("setIcon", {
          iconPath: funnerExtensionLogoNotLogged,
        });
      }
      if (applicationType === "web") {
        setOpenAuthPopup(true);
      }

      resetSavedData();
    }
  }
  async function getSettingsInfoOnLoad() {
    try {
      const userInfo = await fetchApplicationUserProfile();
      if (applicationType === "extension" && tabActiveRef.current) {
        sendMessageToBackground("updateToken");
      }
      if (userInfo) {
        await applyCompanyConfigOptions();
        await applyUserConfigOptions();
        if (applicationType === "web") {
          createConnection();
          setIsActiveAppOnPage(true);
        } else if (
          applicationType === "extension" &&
          tabActiveRef.current === true
        ) {
          createConnection();
        }
      }
      // else {
      //   setIsActiveAppOnPage(true);
      // }
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchApplicationUserProfile() {
    try {
      const res = await UseFetchGet(
        "/api/auth/getApplicationUserProfile",
        undefined,
        null,
        undefined,
        "config",
      );

      if (res?.status === 200) {
        const data = res.data?.data;
        if (data.isUpdatePassword) {
          handleSetNotif(localizations["he"].isUpdatePasswordHint, "error");
          setIsNeedResetPassPopup(true);
        }
        setUserData((prevData) => {
          return {
            ...prevData,
            ...data,
          };
        });

        userDataRef.current = {
          ...userDataRef.current,
          ...data,
        };
        return data;
      } else setAppLoadError(true);
      // else if (res?.request?.status === 401) {
      //   handleRemoveToken();
      // }
      console.log(res);
    } catch (err) {
      console.log("getApplicationUserProfile error: ", err);
    }
  }
  function handleApplicationUserProfile(params) {
    setUserData((prevData) => {
      return {
        ...prevData,
        ...params,
      };
    });

    userDataRef.current = {
      ...userDataRef.current,
      ...params,
    };
  }
  async function applyCompanyConfigOptions() {
    try {
      let cancel = false;
      let controller = new AbortController();
      if (userDataRef.current.CompanyId) {
        const res = await UseFetchGet(
          "/api/services/GetCompanyDetails",
          { companyId: userDataRef.current.CompanyId },
          controller,
          "onFirstLoad",
          "config",
        );

        if (res?.data) {
          if (cancel) return;
          const data = res.data;
          setBusinessInfo(data);
          businessInfoRef.current = data;

          if (data.LinkToObjectNum !== null) {
            setLinkToObjectNum(data.LinkToObjectNum);
          }
        }
      }
    } catch (error) {
      console.log("GetCompanyDetails Error: ", error);
    }
  }
  async function getUserConfig() {
    try {
      const res = await UseFetchPost(
        "/api/services/GetUserConfig",
        {
          userId: userDataRef.current.Udid,
        },
        "config",
      );

      if (res.data.profileConfig) {
        const optionsList = JSON.parse(res.data.profileConfig);
        return optionsList;
      }
    } catch (err) {
      console.log("GetUserConfig error: ", err);
    }
  }
  async function applyUserConfigOptions() {
    try {
      const userConfigObj = await getUserConfig();
      // const userConfigObj = { ...userConfig };

      const newUserConfig = { ...userConfig };
      //! Setting separated options
      //! DisplayName
      if (
        userConfigObj?.isDisplayName !== null &&
        userConfigObj?.isDisplayName !== undefined
      ) {
        newUserConfig.isDisplayName = userConfigObj.isDisplayName;
      }
      //! Avatar
      if (
        userConfigObj?.isAvatarActive !== null &&
        userConfigObj?.isAvatarActive !== undefined
      ) {
        newUserConfig.isAvatarActive = userConfigObj.isAvatarActive;
      }
      //! DateTime
      if (
        userConfigObj?.isDateTime !== null &&
        userConfigObj?.isDateTime !== undefined
      ) {
        newUserConfig.isDateTime = userConfigObj.isDateTime;
      }
      //! Font size
      if (
        userConfigObj?.fontSize !== null &&
        userConfigObj?.fontSize !== undefined
      ) {
        newUserConfig.fontSize = userConfigObj.fontSize;
      }
      //! RTL Message
      if (
        userConfigObj?.isMssgRTL !== null &&
        userConfigObj?.isMssgRTL !== undefined
      ) {
        newUserConfig.isMssgRTL = userConfigObj.isMssgRTL;
      }

      //! Submit type
      // if (
      //   IsJsonString(userConfigObj?.submitType) &&
      //   JSON.parse(userConfigObj.submitType).ctrlEnter
      // ) {
      if (
        userConfigObj?.submitType !== null &&
        userConfigObj?.submitType !== undefined
      ) {
        // const submitTypeObj = JSON.parse(userConfigObj.submitType);

        newUserConfig.submitType = userConfigObj.submitType;
      }
      //! Notif On
      if (
        userConfigObj?.isNotifOn !== null &&
        userConfigObj?.isNotifOn !== undefined
      ) {
        newUserConfig.isNotifOn = userConfigObj.isNotifOn;
      }
      //! Enable Custom Time
      if (
        userConfigObj?.enableCustomTime !== null &&
        userConfigObj?.enableCustomTime !== undefined
      ) {
        newUserConfig.enableCustomTime = userConfigObj.enableCustomTime;
      }
      //! Notif hint timeout
      if (
        userConfigObj?.notifHintTimeout !== null &&
        userConfigObj?.notifHintTimeout !== undefined
      ) {
        newUserConfig.notifHintTimeout = userConfigObj.notifHintTimeout;
      }
      //! Info messages
      if (
        userConfigObj?.displayInfoBots !== null &&
        userConfigObj?.displayInfoBots !== undefined
      ) {
        newUserConfig.displayInfoBots = userConfigObj.displayInfoBots;
      }
      if (
        userConfigObj?.displayInfoAssignee !== null &&
        userConfigObj?.displayInfoAssignee !== undefined
      ) {
        newUserConfig.displayInfoAssignee = userConfigObj.displayInfoAssignee;
      }
      if (
        userConfigObj?.audioNotifVol !== null &&
        userConfigObj?.audioNotifVol !== undefined
      ) {
        newUserConfig.audioNotifVol = userConfigObj.audioNotifVol;
      }
      //! openChatAuthControl
      if (
        userConfigObj?.openChatAuthControl !== null &&
        userConfigObj?.openChatAuthControl !== undefined
      ) {
        newUserConfig.openChatAuthControl = userConfigObj.openChatAuthControl;
        setOpenChatAuthControl(userConfigObj.openChatAuthControl);
        if (applicationType === "extension") {
          if (!userConfigObj.openChatAuthControl) {
            setIsActiveAppOnPage(false);
          } else {
            let isThisPageActive = true;
            const companyPlatformsObject = businessInfoRef.current
              ?.CompanyPlatforms
              ? JSON.parse(businessInfoRef.current.CompanyPlatforms)
              : null;
            const url = decodeURIComponent(window.location.href);

            let newExtensionStatusObject = {
              defaultEnabled: true,
              list: [],
            };

            if (companyPlatformsObject) {
              const thisUserInList = companyPlatformsObject.usersOptions?.find(
                (user) => user.id === userDataRef.current.Udid,
              );

              if (thisUserInList) {
                const savedResult = thisUserInList.extensionStatus;
                if (savedResult) {
                  newExtensionStatusObject = savedResult;
                } else {
                  const blackList = thisUserInList.blackList;
                  const whiteList = thisUserInList.whiteList;

                  newExtensionStatusObject = fillNewObjectByLists(
                    blackList,
                    whiteList,
                    newExtensionStatusObject,
                  );

                  setBusinessInfo((prevObj) => {
                    const newObj = { ...prevObj };

                    let platformsObject = JSON.parse(newObj.CompanyPlatforms);
                    if (platformsObject) {
                      if (!platformsObject.usersOptions)
                        platformsObject.usersOptions = [];
                      if (
                        !platformsObject.usersOptions.find(
                          (user) => user.id === userDataRef.current.Udid,
                        )
                      ) {
                        platformsObject.usersOptions.push({
                          id: userDataRef.current.Udid,
                        });
                      }

                      const currentUser = platformsObject.usersOptions.find(
                        (user) => user.id === userDataRef.current.Udid,
                      );

                      currentUser.extensionStatus = newExtensionStatusObject;
                      console.log(currentUser);
                    } else {
                      platformsObject = {
                        usersOptions: [
                          {
                            id: userDataRef.current.Udid,
                            extensionStatus: newExtensionStatusObject,
                          },
                        ],
                      };
                    }

                    newObj.CompanyPlatforms = JSON.stringify(platformsObject);
                    return newObj;
                  });
                }
              }
            }

            const thisUrlInList = newExtensionStatusObject.list.find((item) =>
              url.includes(item.url),
            );
            isThisPageActive = thisUrlInList
              ? thisUrlInList.enabled
              : newExtensionStatusObject.defaultEnabled;

            console.log("newExtensionStatusObject: ", newExtensionStatusObject);
            console.log("thisUrlInList: ", thisUrlInList);
            console.log("isThisPageActive: ", isThisPageActive);

            setIsActiveAppOnPage(isThisPageActive);
          }
        }
      } else {
        if (applicationType === "extension") {
          let isThisPageActive = true;
          const companyPlatformsObject = businessInfoRef.current
            ?.CompanyPlatforms
            ? JSON.parse(businessInfoRef.current.CompanyPlatforms)
            : null;
          const url = decodeURIComponent(window.location.href);

          let newExtensionStatusObject = {
            defaultEnabled: true,
            list: [],
          };

          if (companyPlatformsObject) {
            const thisUserInList = companyPlatformsObject.usersOptions?.find(
              (user) => user.id === userDataRef.current.Udid,
            );

            if (thisUserInList) {
              const savedResult = thisUserInList.extensionStatus;
              if (savedResult) {
                newExtensionStatusObject = savedResult;
              } else {
                const blackList = thisUserInList.blackList;
                const whiteList = thisUserInList.whiteList;

                newExtensionStatusObject = fillNewObjectByLists(
                  blackList,
                  whiteList,
                  newExtensionStatusObject,
                );

                setBusinessInfo((prevObj) => {
                  const newObj = { ...prevObj };

                  let platformsObject = JSON.parse(newObj.CompanyPlatforms);
                  if (platformsObject) {
                    if (!platformsObject.usersOptions)
                      platformsObject.usersOptions = [];
                    if (
                      !platformsObject.usersOptions.find(
                        (user) => user.id === userDataRef.current.Udid,
                      )
                    ) {
                      platformsObject.usersOptions.push({
                        id: userDataRef.current.Udid,
                      });
                    }

                    const currentUser = platformsObject.usersOptions.find(
                      (user) => user.id === userDataRef.current.Udid,
                    );

                    currentUser.extensionStatus = newExtensionStatusObject;
                  } else {
                    platformsObject = {
                      usersOptions: [
                        {
                          id: userDataRef.current.Udid,
                          extensionStatus: newExtensionStatusObject,
                        },
                      ],
                    };
                  }

                  newObj.CompanyPlatforms = JSON.stringify(platformsObject);
                  return newObj;
                });
              }
            }
          }

          const thisUrlInList = newExtensionStatusObject.list.find((item) =>
            url.includes(item.url),
          );
          isThisPageActive = thisUrlInList
            ? thisUrlInList.enabled
            : newExtensionStatusObject.defaultEnabled;

          setIsActiveAppOnPage(isThisPageActive);
        }
      }

      //! Filters
      if (
        userConfigObj?.unreadFilter !== null &&
        userConfigObj?.unreadFilter !== undefined
      ) {
        newUserConfig.unreadFilter = userConfigObj.unreadFilter;
      }
      if (
        userConfigObj?.openCloseFilter !== null &&
        userConfigObj?.openCloseFilter !== undefined
      ) {
        newUserConfig.openCloseFilter = userConfigObj.openCloseFilter;
      }

      setUserConfig(newUserConfig);
      userConfigRef.current = newUserConfig;
    } catch (err) {
      console.log("Apply User config error: ", err);
    }
  }
  async function updateUserConfig(
    updateParam,
    updateValue,
    isNotif = true,
    userId = null,
  ) {
    console.log(updateParam);
    console.log(updateValue);
    try {
      const newParams = { ...userConfigRef.current };
      newParams[updateParam] = updateValue;
      const JSONParams = JSON.stringify(newParams);
      const response = await UseFetchPost(
        "/api/services/UpdateUserConfig",
        {
          userId: userId ? userId : userDataRef.current.Udid,
          optionsList: JSONParams,
        },
        "config",
      );

      if (response?.data) {
        // if (isNotif) showSavedNotification();
        if (isNotif)
          handleSetNotif(
            localizations["he"].generalNotifications.userConfiguration.saved,
            "success",
          );
        setUserConfig(newParams);
        userConfigRef.current = newParams;
      }
    } catch (error) {
      handleSetNotif(
        localizations["he"].generalNotifications.userConfiguration.error,
        "error",
      );
      console.log("UpdateUserConfig error: ", error);
    }
  }
  function handleSubmitType(e, keybind) {
    setSubmitType((prevType) => {
      const newState = { ...prevType };
      newState[keybind] = newState[keybind].map((type) => {
        if (e.target.value == type.text) {
          return { ...type, checked: true };
        } else {
          return { ...type, checked: false };
        }
      });

      // saveToLocal("submitType", newState);
      // if (applicationType === "extension") {
      //   saveToChromeStorage("submitType", newState);
      // }
      updateUserConfig("submitType", newState, false);
      submitTypeRef.current = newState;

      return newState;
    });
    handleSetNotif(
      localizations["he"].settingsPopup.userConfig.tabs.chats.leftColumn
        .submitTypes.savedNotification,
      "success",
    );
  }
  async function handleTabStatus() {
    const initRequest = await sendMessageToBackground("checkTabActive");
    console.log(initRequest);
    if (initRequest.isThisTabMain) {
      console.log("Extension tabs This tab is active");
      tabActiveRef.current = true;
    } else {
      console.log(
        "Extension tabs Active tabs has url: " + initRequest.activeTab.url,
      );
    }
  }
  function handleToggleChatFromAuth() {
    const prevValue = userConfigRef.current.openChatAuthControl;
    // saveToLocal("openChatAuthControl", !prevValue);
    updateUserConfig("openChatAuthControl", !prevValue);
    setOpenChatAuthControl(!prevValue);

    //? toggleChatInOut();
    if (applicationType === "extension") {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage({
        type: "toggleChatFromAuth",
        content: { value: !prevValue },
      });
    }
    if (prevValue === false) {
      let isThisPageActive = true;
      const companyPlatformsObject = businessInfoRef.current?.CompanyPlatforms
        ? JSON.parse(businessInfoRef.current.CompanyPlatforms)
        : null;
      const url = decodeURIComponent(window.location.href);

      let newExtensionStatusObject = {
        defaultEnabled: true,
        list: [],
      };

      if (companyPlatformsObject) {
        const thisUserInList = companyPlatformsObject.usersOptions?.find(
          (user) => user.id === userDataRef.current.Udid,
        );

        if (thisUserInList) {
          const savedResult = thisUserInList.extensionStatus;
          if (savedResult) {
            newExtensionStatusObject = savedResult;
          } else {
            const blackList = thisUserInList.blackList;
            const whiteList = thisUserInList.whiteList;

            newExtensionStatusObject = fillNewObjectByLists(
              blackList,
              whiteList,
              newExtensionStatusObject,
            );

            setBusinessInfo((prevObj) => {
              const newObj = { ...prevObj };

              let platformsObject = JSON.parse(newObj.CompanyPlatforms);
              if (platformsObject) {
                if (!platformsObject.usersOptions)
                  platformsObject.usersOptions = [];
                if (
                  !platformsObject.usersOptions.find(
                    (user) => user.id === userDataRef.current.Udid,
                  )
                ) {
                  platformsObject.usersOptions.push({
                    id: userDataRef.current.Udid,
                  });
                }

                const currentUser = platformsObject.usersOptions.find(
                  (user) => user.id === userDataRef.current.Udid,
                );

                currentUser.extensionStatus = newExtensionStatusObject;
                console.log(currentUser);
              } else {
                platformsObject = {
                  usersOptions: [
                    {
                      id: userDataRef.current.Udid,
                      extensionStatus: newExtensionStatusObject,
                    },
                  ],
                };
              }

              newObj.CompanyPlatforms = JSON.stringify(platformsObject);
              return newObj;
            });
          }
        }
      }

      const thisUrlInList = newExtensionStatusObject.list.find((item) =>
        url.includes(item.url),
      );

      isThisPageActive = thisUrlInList
        ? thisUrlInList.enabled
        : newExtensionStatusObject.defaultEnabled;

      console.log("newExtensionStatusObject: ", newExtensionStatusObject);

      setIsActiveAppOnPage(isThisPageActive);
    } else {
      setIsActiveAppOnPage(false);
    }
    if (applicationType === "extension") {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage({ type: "updateCompanyPlatforms" });
    }
  }
  function handleExtensionSiteStatus(status, url, userId) {
    setBusinessInfo((prevObj) => {
      const newObj = { ...prevObj };
      const platformsObject = JSON.parse(newObj.CompanyPlatforms);
      if (!platformsObject.usersOptions) platformsObject.usersOptions = [];
      if (!platformsObject.usersOptions.find((user) => user.id === userId)) {
        platformsObject.usersOptions.push({
          id: userId,
        });
      }

      const currentUser = platformsObject.usersOptions.find(
        (user) => user.id === userId,
      );
      console.log("handleExtensionSiteStatus currentUser: ", currentUser);
      const thisExtensionStatus = currentUser.extensionStatus
        ? currentUser.extensionStatus
        : {
            defaultEnabled: true,
            list: [],
          };

      const findUrl = thisExtensionStatus.list.find((item) =>
        url.includes(item.url),
      );
      console.log(findUrl);
      if (status === "enabled" || status === "disabled") {
        if (findUrl) {
          findUrl.enabled = status === "enabled";
        } else {
          thisExtensionStatus.list.push({
            enabled: status === "enabled",
            url: url,
          });
        }
      } else if (status === "byDefault" && findUrl) {
        thisExtensionStatus.list = thisExtensionStatus.list.filter(
          (item) => !url.includes(item.url),
        );
      }
      console.log(
        "handleExtensionSiteStatus thisExtensionStatus: ",
        thisExtensionStatus,
      );

      newObj.CompanyPlatforms = JSON.stringify(platformsObject);
      handleCompanyPlatformSubmitWithParam(
        JSON.stringify(platformsObject),
        userId,
      );
      return newObj;
    });
  }
  async function handleCompanyPlatformSubmitWithParam(platformsObject, id) {
    try {
      const res = await UseFetchPost(
        "/api/services/setOwnerCompanyPlatforms",
        {
          platformsJsonObj: platformsObject,
        },
        null,
        undefined,
        "config",
      );
      if (res?.data?.result) {
        if (applicationType === "extension") {
          let isThisPageActive = true;
          const companyPlatformsObject = platformsObject
            ? JSON.parse(platformsObject)
            : null;
          const url = decodeURIComponent(window.location.href);

          let newExtensionStatusObject = {
            defaultEnabled: true,
            list: [],
          };

          if (companyPlatformsObject) {
            const thisUserInList = companyPlatformsObject.usersOptions?.find(
              (user) => user.id === id,
            );

            if (thisUserInList) {
              const savedResult = thisUserInList.extensionStatus;
              if (savedResult) {
                newExtensionStatusObject = savedResult;
              } else {
                const blackList = thisUserInList.blackList;
                const whiteList = thisUserInList.whiteList;

                newExtensionStatusObject = fillNewObjectByLists(
                  blackList,
                  whiteList,
                  newExtensionStatusObject,
                );

                setBusinessInfo((prevObj) => {
                  const newObj = { ...prevObj };

                  let platformsObject = JSON.parse(newObj.CompanyPlatforms);
                  if (platformsObject) {
                    if (!platformsObject.usersOptions)
                      platformsObject.usersOptions = [];
                    if (
                      !platformsObject.usersOptions.find(
                        (user) => user.id === id,
                      )
                    ) {
                      platformsObject.usersOptions.push({
                        id: id,
                      });
                    }

                    const currentUser = platformsObject.usersOptions.find(
                      (user) => user.id === id,
                    );

                    currentUser.extensionStatus = newExtensionStatusObject;
                    console.log(currentUser);
                  } else {
                    platformsObject = {
                      usersOptions: [
                        {
                          id: id,
                          extensionStatus: newExtensionStatusObject,
                        },
                      ],
                    };
                  }

                  newObj.CompanyPlatforms = JSON.stringify(platformsObject);
                  return newObj;
                });
              }
            }
          }

          const thisUrlInList = newExtensionStatusObject.list.find((item) =>
            url.includes(item.url),
          );
          isThisPageActive = thisUrlInList
            ? thisUrlInList.enabled
            : newExtensionStatusObject.defaultEnabled;

          setIsActiveAppOnPage(isThisPageActive);

          if (applicationType === "extension") {
            // eslint-disable-next-line no-undef
            chrome.runtime.sendMessage({ type: "updateCompanyPlatforms" });
          }
        }
      } else {
        handleSetNotif(
          localizations["he"].generalNotifications.companyPlatforms.error,
          "error",
        );
      }
    } catch (error) {
      console.log("setOwnerCompanyPlatforms error: ", error);
      handleSetNotif(
        localizations["he"].generalNotifications.companyPlatforms.error,
        "error",
      );
    }
  }

  function onChangedListener(changes, namespace) {
    for (let key in changes) {
      if (namespace === "local") {
        if (key === "whatsapp-token") {
          initData();
        }
      }
    }
  }

  async function initApplication() {
    if (applicationType === "extension") {
      applyExtensionOnLoadListeners();
      await handleTabStatus();
    }

    initData();
    checkApplicationVersion();
  }
  function applyExtensionOnLoadListeners() {
    // eslint-disable-next-line no-undef
    chrome.storage.onChanged.addListener(onChangedListener);

    // eslint-disable-next-line no-undef
    chrome.runtime.onMessage.addListener(function (
      message,
      sender,
      sendResponse,
    ) {
      if (message.type === "toggleChatInOut") {
        handleToggleChatFromAuth();
      } else if (message.type === "handleExtensionStatusSite") {
        const { status, url, userId } = message.content;
        console.log(
          "handleExtensionStatusSite extension tab:\nstatus: ",
          status,
          "\nurl: ",
          url,
          "\nuserId: ",
          userId,
        );
        handleExtensionSiteStatus(status, url, userId);
      } else if (message.type === "openSettingsPopup") {
        toggleAuthPopup();
      } else if (message.type === "getApplicationUserProfile") {
        sendResponse({ profileData: userDataRef.current });
      } else if (message.type === "getUserConfig") {
        sendResponse({ userConfig: userConfigRef.current });
      } else if (message.type === "getCompanyPlatforms") {
        sendResponse({
          platformsObj: IsJsonString(businessInfoRef?.current?.CompanyPlatforms)
            ? JSON.parse(businessInfoRef.current.CompanyPlatforms)
            : null,
        });
      } else if (message.type === "getActiveTabs") {
        sendResponse({ isExtensionPage: true });
      } else if (message.type === "checkIsTabAlive") {
        sendResponse({ alive: true });
      } else if (message.type === "setThisTabMain") {
        tabActiveRef.current = true;
        if (!refIsConnection.current && tokenGlobal) createConnection();
      }
    });
  }

  //! UseEffects
  useEffect(() => {
    initApplication();
    browserPermissionRequest();

    let offlineNotif;
    const handleOnline = () => {
      closeSnackbar(offlineNotif);
    };
    const handleOffline = () => {
      offlineNotif = enqueueSnackbar("Connection lost", {
        autoHideDuration: null,
        action: () => {
          return <></>;
        },
        variant: "warning",
      });
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      // handleOnline();
    };
  }, []);

  useEffect(() => {
    businessInfoRef.current = businessInfo;
  }, [businessInfo]);

  useEffect(() => {
    isActiveAppOnPageRef.current = isActiveAppOnPage;
  }, [isActiveAppOnPage]);

  useScript("/assets/playerjs.js");

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <ScopedCssBaseline>
          <StyledSnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            autoHideDuration={
              userConfig.notifHintTimeout === 0
                ? null
                : userConfig.notifHintTimeout * 1000
            }
            iconVariant={{
              success: <NotificationIcon type="success" />,
              error: <NotificationIcon type="error" />,
              warning: <NotificationIcon type="warning" />,
              info: <NotificationIcon type="info" />,
              default: <NotificationIcon type="default" />,
            }}
            action={(key) => (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => closeSnackbar(key)}
              >
                <Close fontSize="small" />
              </IconButton>
            )}
            style={{
              justifyContent: "space-between",
              fontSize: "14px",
              maxWidth: 400,
              flexWrap: "nowrap",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* User Need Reload Popup */}
              {isReloadPopup && (
                <div className="funner-reload-popup">
                  <div className="funner-reload-popup__content">
                    <div className="main-popup-content-title">
                      Your version is old, please reload
                    </div>
                    <div
                      className="popup__blue-btn"
                      style={{ margin: 0 }}
                      onClick={() => window.location.reload()}
                    >
                      Reload
                    </div>
                  </div>
                </div>
              )}

              <AuthPopup
                isOnlyResetPass={isNeedResetPassPopup}
                setIsNeedResetPassPopup={setIsNeedResetPassPopup}
                openAuthPopup={openAuthPopup}
                setOpenAuthPopup={setOpenAuthPopup}
                isPowerLinkPage={isPowerLinkPage}
                userData={userData}
                userDataRef={userDataRef}
                handleSetNotif={handleSetNotif}
                authPopupType={authPopupType}
                setAuthPopupType={setAuthPopupType}
                handleSetToken={handleSetToken}
                handleRemoveToken={handleRemoveToken}
                openChatAuthControl={openChatAuthControl}
                handleToggleChatFromAuth={handleToggleChatFromAuth}
                token={token}
              />

              {isHintActive && hintText && hintType && (
                <HintTemplate
                  text={hintText}
                  type={hintType}
                  isHintIcon={isHintIcon}
                  setIsHintActive={setIsHintActive}
                />
              )}

              {appLoadError && (
                <AppLoadError handleRemoveToken={handleRemoveToken} />
              )}

              {/* Main App Views */}
              {!isReloadPopup && !isNeedResetPassPopup && !appLoadError && (
                <>
                  {/* Active app view */}
                  {isActiveAppOnPage && (
                    <>
                      <Main
                        token={token}
                        iframeMode={iframeMode}
                        userData={userData}
                        updateUserData={updateUserData}
                        userDataRef={userDataRef}
                        userConfig={userConfig}
                        userConfigRef={userConfigRef}
                        businessInfo={businessInfo}
                        setBusinessInfo={setBusinessInfo}
                        businessInfoRef={businessInfoRef}
                        handleRemoveToken={handleRemoveToken}
                        handleSetNotif={handleSetNotif}
                        handleBrowserNotification={handleBrowserNotification}
                        toggleAuthPopup={toggleAuthPopup}
                        updateUserConfig={updateUserConfig}
                        connection={connection}
                        isConnection={isConnection}
                        tabActiveRef={tabActiveRef}
                        setIsActiveAppOnPage={setIsActiveAppOnPage}
                        handleApplicationUserProfile={
                          handleApplicationUserProfile
                        }
                        isPowerLinkPage={isPowerLinkPage}
                        handleSubmitType={handleSubmitType}
                        isActiveAppOnPageRef={isActiveAppOnPageRef}
                        browserNotifPermissionRef={browserNotifPermissionRef}
                        handleSetToken={handleSetToken}
                      />
                    </>
                  )}
                </>
              )}
            </LocalizationProvider>
          </StyledSnackbarProvider>
        </ScopedCssBaseline>
      </ThemeProvider>
    </CacheProvider>
  );
}

function useScript(url) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
}

export default App;
