export const isUpdateTokenPage = () => {
  const currentURL = window.location.href;
  return currentURL.includes("app/settings/integration/apiforms");
};
export const isAppLinkPage = () => {
  const currentURL = window.location.href;
  return (
    currentURL.includes("https://app.powerlink.co.il/app/link/") ||
    currentURL.includes("https://app.fireberry.com/app/link/")
  );
};
export function checkUrlViewsCard(url) {
  if (url.includes("/app/views/") && url.includes("card")) {
    return true;
  } else {
    return false;
  }
}
export function checkUrlViews(url) {
  if (
    url.includes("/app/views/") &&
    !url.includes("card") &&
    !url.includes("list") &&
    !url.includes("board")
  ) {
    return true;
  } else {
    return false;
  }
}
export const ifUserPage = () => {
  const currentURL = window.location.href;
  return (
    currentURL.includes("https://app.powerlink.co.il/app/record") ||
    currentURL.includes("https://app.fireberry.com/app/record")
  );
};