import React, { useEffect, useRef, useState } from "react";
import { IsJsonString, convertSecondstoTime } from "../../../../libs/helpers";
import {
  audioIcon,
  contactFace,
  contactIcon,
  docx,
  locationMap,
  uploadPreview,
  xlsx,
  zip,
} from "../../../../libs/images";

function ReplyMediaTemplate({
  replyObject,
  Id,
  scrollToReplyMessage,
  templatesList,
  chatUsersGlobalRef,
}) {
  const [template, setTemplate] = useState("");
  const [reply, setReply] = useState(null);
  const [contact, setContact] = useState(null);

  const [audioDuration, setAudioDuration] = useState("");
  const [templateText, setTemplateText] = useState("");

  const chatUser =
    chatUsersGlobalRef.current?.notMyChats?.find(
      (user) => user.UserDisplayPhoneNumber === replyObject.ToUser,
    ) || null;

  useEffect(() => {
    if (IsJsonString(replyObject)) {
      const replyObj = JSON.parse(replyObject);
      setReply(replyObj);
    } else {
      setReply(replyObject);
    }

    if (replyObject?.FileType === "contacts") {
      const objectParsed = JSON.parse(replyObject.Object);

      if (objectParsed?.phones) {
        const name = objectParsed?.name?.first_name;
        const phone = objectParsed?.phones[0]?.phone;
        const phoneLink = objectParsed?.phones[0]?.wa_id;
        setContact({ name, phone, phoneLink });
      }
    }

    if (replyObject?.FileType === "audio") {
      const audio = new Audio(replyObject.File);
      let duration = ""; // Declare duration variable outside the event listener

      audio.addEventListener("loadedmetadata", function () {
        duration = audio.duration;
        //console.log('Audio duration:', duration);
        setAudioDuration(convertSecondstoTime(duration)); // Format duration as desired
        // Hide the audio element
        audio.style.display = "none";
      });
    }

    if (replyObject?.FileType === "template") {
      if (IsJsonString(replyObject.Message)) {
        const templateObject = JSON.parse(replyObject.Message);
        let bodyText = "";
        if (templateObject.components) {
          const currentTemplateInList = templatesList.templates.find(
            (item) => item.name === templateObject.name,
          );
          if (currentTemplateInList) {
            const paramsObject = JSON.parse(
              JSON.stringify(currentTemplateInList),
            );

            const comp = paramsObject.components.find(
              (item) => item.type === "BODY",
            );

            if (comp) {
              bodyText = comp.text;

              if (
                templateObject.components.some(
                  (item) => item.type === comp.type,
                )
              ) {
                const params = templateObject.components.find(
                  (item) => item.type === comp.type,
                ).parameters;

                params.forEach((param, index) => {
                  bodyText = bodyText.replace(`{{${index + 1}}}`, param.text);
                });
              }
            }
          } else bodyText = "Deleted template";
          // } else bodyText = templateObject.components.find((comp) => comp.type?.toLowerCase() === "body")?.
        } else bodyText = templateObject["BODY"].text;
        setTemplateText(bodyText);
      } else {
        setTemplateText(replyObject.Message);
      }
    }
  }, []);

  return replyObject?.FileType === "contacts" ? (
    <div
      data-reply-to-id={reply?.Id}
      className={`message__reply-contact reply reply_contact ${
        replyObject?.Type === "incoming" ? "incoming" : "outcoming"
      }`}
      onClick={() => scrollToReplyMessage(reply?.Id)}
    >
      <div className="reply__text-part">
        <p className="reply__top-text">
          {replyObject?.Type === "outcoming" ? "אתה" : chatUser?.FullName || ""}
        </p>

        <div className="reply__info">
          <div className="reply__icon">
            <img src={contactIcon} alt="" />
          </div>
          <div>
            <p className="reply__text">{contact?.name}</p>
            <a href={`tel:${contact?.phoneLink}"`} className="reply__text">
              {contact?.phone}
            </a>
          </div>
        </div>
      </div>
      <div className="reply-contact__logo-part">
        <div className="reply-contact__logo">
          <img src={contactFace} alt="" />
        </div>
      </div>
    </div>
  ) : replyObject?.FileType === "pdf" ||
    replyObject?.FileType === "docx" ||
    replyObject?.FileType === "xlsx" ||
    replyObject?.FileType === "zip" ||
    replyObject?.FileType === "image" ||
    replyObject?.FileType === "gif" ||
    replyObject?.FileType === "jpeg" ||
    replyObject?.FileType === "jpg" ||
    replyObject?.FileType === "png" ? (
    <div
      data-reply-to-id={reply?.Id}
      className={`message__reply-upload reply ${
        replyObject?.Type === "incoming" ? "incoming" : "outcoming"
      }`}
      onClick={() => scrollToReplyMessage(reply?.Id)}
    >
      <div className="reply__text-part">
        <p className="reply__top-text">
          {replyObject?.Type === "outcoming" ? "אתה" : chatUser?.FullName || ""}
        </p>

        <div className="reply__info">
          {replyObject?.FileType === "pdf" ? (
            <p className="reply__text">PDF</p>
          ) : replyObject?.FileType === "docx" ? (
            <p className="reply__text">DOCX</p>
          ) : replyObject?.FileType === "xlsx" ? (
            <p className="reply__text">XLSX</p>
          ) : replyObject?.FileType === "zip" ? (
            <p className="reply__text">ZIP</p>
          ) : replyObject?.FileType === "gif" ? (
            <p className="reply__text">GIF</p>
          ) : replyObject?.FileType === "image" ||
            replyObject?.FileType === "jpeg" ||
            replyObject?.FileType === "jpg" ||
            replyObject?.FileType === "png" ? (
            <p className="reply__text">Image</p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="reply__map-part">
        <div className="reply__upload">
          {replyObject?.FileType === "pdf" ? (
            <img src={uploadPreview} alt="" />
          ) : replyObject?.FileType === "docx" ? (
            <img src={docx} alt="" />
          ) : replyObject?.FileType === "xlsx" ? (
            <img src={xlsx} alt="" />
          ) : replyObject?.FileType === "zip" ? (
            <img src={zip} alt="" />
          ) : replyObject?.FileType === "image" ||
            replyObject?.FileType === "jpeg" ||
            replyObject?.FileType === "jpg" ||
            replyObject?.FileType === "png" ||
            replyObject?.FileType === "gif" ? (
            <img src={replyObject.File} alt="" />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  ) : replyObject?.FileType === "audio" ? (
    <a
      data-reply-to-id={reply?.Id}
      href="#"
      onClick={() => scrollToReplyMessage(reply?.Id)}
      className={`message__reply-audio reply reply_audio ${
        replyObject?.Type === "incoming" ? "incoming" : "outcoming"
      }`}
    >
      <div className="reply__text-part">
        <p className="reply__top-text">
          {replyObject?.Type === "outcoming" ? "אתה" : chatUser?.FullName || ""}
        </p>
        <div className="reply__info">
          <div className="reply__icon">
            <img src={audioIcon} alt="" />
          </div>
          <div
            id={`funner-global-audio-message__duration-${Id}`}
            className="audio-message__duration"
          >
            {audioDuration}
          </div>
        </div>
      </div>
    </a>
  ) : replyObject?.FileType === "video" ? (
    <a
      data-reply-to-id={reply?.Id}
      href="#"
      onClick={() => scrollToReplyMessage(reply?.Id)}
      className={`message__reply-video reply reply_video ${
        replyObject?.Type === "incoming" ? "incoming" : "outcoming"
      }`}
    >
      <div className="reply__text-part">
        <p className="reply__top-text">
          {replyObject?.Type === "outcoming" ? "אתה" : chatUser?.FullName || ""}
        </p>

        <div className="reply__info">
          <p className="reply__text video-duration"></p>
        </div>
      </div>
      <div className="reply__info-video">
        <div className="reply__icon-video">
          <video
            className={`video-message__main-video video-message__main-video-${Id}`}
            src={replyObject.File}
          ></video>
        </div>
      </div>
    </a>
  ) : replyObject?.FileType === "location" ? (
    <a
      data-reply-to-id={reply?.Id}
      href="#"
      onClick={() => scrollToReplyMessage(reply?.Id)}
      className={`message__reply-location reply reply_location ${
        replyObject?.Type === "incoming" ? "incoming" : "outcoming"
      }`}
    >
      <div className="reply__text-part">
        <p className="reply__top-text">
          {replyObject?.Type === "outcoming" ? "אתה" : chatUser?.FullName || ""}
        </p>

        <div className="reply__info">
          <p className="reply__text">מצפה מנחם 77, אמירים, ישראל</p>
        </div>
      </div>
      <div className="reply__map-part">
        <div className="reply__map">
          <img src={locationMap} alt="" />
        </div>
      </div>
    </a>
  ) : replyObject?.FileType === "template" ? (
    <a
      data-reply-to-id={reply?.Id}
      href="#"
      onClick={() => scrollToReplyMessage(reply?.Id)}
      className={`message__reply-template reply ${
        replyObject?.Type === "incoming" ? "incoming" : "outcoming"
      }`}
    >
      <div className="reply__text-part">
        <p className="reply__top-text">
          {replyObject?.Type === "outcoming" ? "אתה" : chatUser?.FullName || ""}
        </p>
        <div className="reply__info">
          <p className="reply__text">
            {templateText}
            <br />
            <span
              style={{ opacity: 0.65, display: "inline-block", marginTop: 5 }}
            >
              template
            </span>
          </p>
        </div>
      </div>
    </a>
  ) : (
    <a
      href="#"
      data-reply-to-id={reply?.Id}
      onClick={() => scrollToReplyMessage(reply?.Id)}
      className={`message__reply-audio reply ${
        replyObject?.Type === "incoming" ? "incoming" : "outcoming"
      }`}
    >
      <div className="reply__text-part">
        <p className="reply__top-text">
          {replyObject?.Type === "outcoming" ? "אתה" : chatUser?.FullName || ""}
        </p>
        <div className="reply__info">
          <p className="reply__text">{replyObject?.Message}</p>
        </div>
      </div>
    </a>
  );
}

export default ReplyMediaTemplate;
