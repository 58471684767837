import React from "react";

function TextReplyTemplate({ sentOrReceived, userName, text }) {
  return (
    <a
      href="#"
      className={`message-attached-reply message__reply-audio reply ${
        sentOrReceived === "incoming" ? "incoming" : "outcoming"
      }`}
    >
      <div className="reply__text-part">
        <p className="reply__top-text">
          {sentOrReceived == "incoming" ? userName : "אתה"}
        </p>
        <div className="reply__info">
          <p className="reply__text">{text}</p>
        </div>
      </div>
    </a>
  );
}

export default TextReplyTemplate;
