import React, { useEffect, useRef, useState } from "react";
import { convertSecondstoTime } from "../../../../libs/helpers";

function VideoMessage({ file, Id }) {
  const videoRef = useRef(null);
  const [playerSize, setPlayerSize] = useState();

  function getVideoDimensions(url) {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");

      video.addEventListener("loadedmetadata", function () {
        const maxWidthDimension = 500;
        const maxHeightDimension = 400;
        let width, height;

        if (video.videoWidth > video.videoHeight) {
          width =
            video.videoWidth > maxWidthDimension
              ? maxWidthDimension
              : video.videoWidth;
          height = (width / video.videoWidth) * video.videoHeight;
        } else {
          height =
            video.videoHeight > maxHeightDimension
              ? maxHeightDimension
              : video.videoHeight;
          width = (height / video.videoHeight) * video.videoWidth;
        }

        resolve({
          width,
          height,
        });
      });

      video.addEventListener("error", function () {
        reject("Ошибка при загрузке видео.");
      });

      video.src = url;

      video.load();
    });
  }
  async function applyPlayerSize(url) {
    const videoSize = await getVideoDimensions(url);
    setPlayerSize(videoSize);
  }

  async function handlePlayer(file) {
    await applyPlayerSize(file);
    if (videoRef.current) {
      const player = new window.Playerjs({
        id: videoRef.current.id,
        file: file,
      });

      return () => {
        player.api("destroy");
      };
    }
  }
  useEffect(() => {
    if (file) {
      handlePlayer(file);
    }
  }, [file]);

  return (
    <div className="video-message__container" style={playerSize}>
      <div
        id={`player-${Id}`}
        ref={videoRef}
        style={{ width: "100%", height: "100%" }}
      ></div>
      {/* <video
            className={`video-message__main-video funner-global-video-message__main-video-${Id}`}
            ref={videoElementRef}
            src={file}
          ></video>
          <div
            className={`video-message__play-container video-message__play-container-${Id}`}
          >
            <button
              className={`video-message__play-btn funner-global-video-message__play-btn-${Id}`}
              ref={playBtnRef}
            ></button>
          </div> */}
    </div>
  );
}

export default VideoMessage;
