import { Box, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import localizations from "../../../localizations.json";
import {
  AddLocationOutlined,
  AddPhotoAlternateOutlined,
  CodeOutlined,
  OpenInNewOutlined,
  PhoneOutlined,
  ReplyOutlined,
  UploadFileOutlined,
  VideoFileOutlined,
} from "@mui/icons-material";
import LinkifiedText from "../../Chat/SingleMessage/linkifyText";

function TemplateMessagePreview({ selectedTemplate }) {
  const [localization, setLocalization] = useState(
    localizations["he"].settingsPopup.templates,
  );
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "relative",
        pl: 1.25,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: "calc(100% - 10px)",
          width: 0,
          height: 0,
          borderRight: "10px solid rgba(25, 118, 210, 0.12)",
          borderBottom: "15px solid transparent",
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: "rgba(25, 118, 210, 0.12)",
          minWidth: 240,
          maxWidth: 325,
          p: 1.25,
          borderRadius: " 0 8px 8px 8px",
        }}
      >
        <Grid container spacing={1.25}>
          {selectedTemplate?.components?.some(
            (item) => item.type === "HEADER",
          ) && (
            <Grid item xs={12}>
              {selectedTemplate?.components?.find(
                (item) => item.type === "HEADER",
              ).format === "TEXT" ? (
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 600, wordBreak: "break-all" }}
                >
                  {
                    selectedTemplate.components.find(
                      (item) => item.type === "HEADER",
                    ).text
                  }
                </Typography>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    height: 120,
                    background: theme.palette.common.white,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: theme.palette.secondary.main,
                  }}
                >
                  {selectedTemplate?.components?.find(
                    (item) => item.type === "HEADER",
                  ).format === "IMAGE" ? (
                    <AddPhotoAlternateOutlined sx={{ width: 70, height: 70 }} />
                  ) : selectedTemplate?.components?.find(
                      (item) => item.type === "HEADER",
                    ).format === "VIDEO" ? (
                    <VideoFileOutlined sx={{ width: 70, height: 70 }} />
                  ) : selectedTemplate?.components?.find(
                      (item) => item.type === "HEADER",
                    ).format === "DOCUMENT" ? (
                    <UploadFileOutlined sx={{ width: 70, height: 70 }} />
                  ) : selectedTemplate?.components?.find(
                      (item) => item.type === "HEADER",
                    ).format === "LOCATION" ? (
                    <AddLocationOutlined sx={{ width: 70, height: 70 }} />
                  ) : (
                    ""
                  )}
                </Box>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body2">
              {selectedTemplate?.components?.find(
                (item) => item.type === "BODY",
              )?.text ? (
                <LinkifiedText
                  text={
                    selectedTemplate.components.find(
                      (item) => item.type === "BODY",
                    ).text
                  }
                  websitePreviewObj={null}
                  funnerUsers={[]}
                  messageType={""}
                  chatCustomizationSettings={null}
                />
              ) : (
                "תוכן התבנית"
              )}
            </Typography>
          </Grid>

          {selectedTemplate?.components?.some(
            (item) => item.type === "FOOTER",
          ) && (
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="rgba(0, 0, 0, 0.38)"
                sx={{ fontWeight: 600, fontSize: "14px !important" }}
              >
                {
                  selectedTemplate.components.find(
                    (item) => item.type === "FOOTER",
                  ).text
                }
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="body2" color={"rgba(0, 0, 0, 0.38)"} fontSize={12}>
              12/12/2022 10:25
            </Typography>
          </Grid>

          {selectedTemplate?.components?.some(
            (item) => item.type === "BUTTONS",
          ) && (
            <Grid item xs={12}>
              {selectedTemplate?.components
                ?.find((item) => item.type === "BUTTONS")
                ?.buttons?.map((button, index) => (
                  <Box
                    key={index}
                    sx={{
                      mx: -1.25,
                      px: 1.25,
                      borderTop: "1px solid #c2c2c2",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                        p: 1,
                        color: theme.palette.secondary.main,
                      }}
                    >
                      <Typography variant="body2">{button.text}</Typography>
                      {button.type === "QUICK_REPLY" && <ReplyOutlined />}
                      {button.type === "URL" && <OpenInNewOutlined />}
                      {button.type === "PHONE_NUMBER" && <PhoneOutlined />}
                      {button.type === "COPY_CODE" && <CodeOutlined />}
                    </Box>
                  </Box>
                ))}
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default TemplateMessagePreview;
