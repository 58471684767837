import React from "react";

function FileUploadPreview({ sendUpload, closeHandler }) {
  return (
    <div className="funner-upload-preview__content">
      <div
        className="funner-upload-preview__close"
        onClick={closeHandler}
      ></div>
      <div className="funner-upload-preview__filename">
        {sendUpload.file.name}
      </div>
      <div className="funner-upload-preview__icon">
        {sendUpload.isVideo ? (
          <video muted src={sendUpload.fileTypeImg} />
        ) : (
          <img src={sendUpload.fileTypeImg} alt="" />
        )}
      </div>
    </div>
  );
}

export default FileUploadPreview;
