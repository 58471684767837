import React, { Fragment, useEffect, useState } from "react";
import { IsJsonString } from "../../../libs/helpers";
import { Alert, Box, Tooltip, Typography, useTheme } from "@mui/material";

function InfoMessageTemplate({
  message,
  userConfig,
  chatCustomizationSettings,
}) {
  const theme = useTheme();
  const colorByType = {
    assigneeChange: "warning",
    OpenCloseConversationLogOpen: "success",
    OpenCloseConversationLogClose: "error",
  };

  const getMessageInfo = IsJsonString(message.Object)
    ? JSON.parse(message.Object)
    : message.Object;

  const messageInfo = getMessageInfo;
  const infoType = getMessageInfo.infoType || getMessageInfo.infofType;
  const directType =
    infoType === "OpenCloseConversationLog"
      ? messageInfo.action
        ? `OpenCloseConversationLog${
            messageInfo.action.charAt(0).toUpperCase() +
            messageInfo.action.slice(1)
          }`
        : "success"
      : infoType;

  const styleType =
    directType === "assigneeChange"
      ? "userChanged"
      : directType === "OpenCloseConversationLogOpen"
      ? "callOpened"
      : directType === "OpenCloseConversationLogClose"
      ? "callClosed"
      : null;

  return messageInfo?.text ? (
    <Box
      sx={{
        marginTop: 1.5,
        display:
          (infoType === "assigneeChange" && !userConfig.displayInfoAssignee) ||
          (infoType === "botChange" && !userConfig.displayInfoBots)
            ? "none"
            : null,
        maxWidth: "100%",
        width: 450,
        ml: "auto",
        mr: "auto",
      }}
    >
      <Alert
        icon={false}
        severity={colorByType[directType] || "warning"}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          py: 0,

          bgcolor:
            chatCustomizationSettings?.find(
              (option) => option.type === styleType,
            )?.value === "custom"
              ? chatCustomizationSettings?.find(
                  (option) => option.type === styleType,
                ).customColor.background + " !important"
              : null,
          color:
            chatCustomizationSettings?.find(
              (option) => option.type === styleType,
            )?.value === "custom"
              ? chatCustomizationSettings?.find(
                  (option) => option.type === styleType,
                ).customColor.text + " !important"
              : null,

          fontSize: (userConfig?.fontSize || "14") + "px",
        }}
      >
        <Tooltip title={messageInfo.toolTip}>
          <Fragment>{messageInfo.textHe || messageInfo.text}</Fragment>
        </Tooltip>
      </Alert>
    </Box>
  ) : null;
}

export default InfoMessageTemplate;
