import React, { memo, useEffect, useRef, useState } from "react";
import deepEqual from "fast-deep-equal";
import {
  Avatar,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { isHebrewString, IsJsonString } from "../../../../libs/helpers";
import { Check, EditOutlined, SyncOutlined } from "@mui/icons-material";
import localizations from "../../../../localizations.json";

const ConversationCase = memo(
  ({
    conversation,
    assignee,
    handleSingleConversationCase,
    onSingleConversationClick,
    selectedConversation,
    sendRequestToWebhook,
  }) => {
    const [localization, setLocalization] = useState(
      localizations["he"].chat.chatHeader.chatWindow.conversations,
    );
    const consversationStatusLabels = {
      Active: localization.singleConversation.opened,
      Finished: localization.singleConversation.closed,
    };
    const [isEditName, setIsEditName] = useState(false);
    const [name, setName] = useState(conversation.name);

    const nameInputRef = useRef(null);

    const submitNewName = () => {
      handleSingleConversationCase(conversation.conversationId, {
        name: name,
      });
      setIsEditName(false);
    };

    useEffect(() => {
      if (isEditName) nameInputRef.current?.focus();
    }, [isEditName]);

    return (
      <ListItem disablePadding>
        <Tooltip
          placement="left"
          title={
            <Box lineHeight={1.4}>
              {localization.singleConversation.info.durationHours}:{" "}
              {Math.round(
                (conversation.info.durationHours + Number.EPSILON) * 100,
              ) / 100}
              <br />
              {localization.singleConversation.info.durationDays}:{" "}
              {Math.round(
                (conversation.info.durationDays + Number.EPSILON) * 100,
              ) / 100}
            </Box>
          }
        >
          <ListItemButton
            sx={{
              backgroundColor:
                conversation.status === "Active" ? "rgb(237, 247, 237)" : null,
            }}
            onClick={() => onSingleConversationClick(conversation)}
            selected={
              selectedConversation?.conversationId ===
              conversation.conversationId
            }
          >
            <Grid container spacing={0.5}>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  flexWrap={"nowrap"}
                  alignItems={"center"}
                  mb={0.5}
                >
                  <Grid item>
                    <Box
                      sx={{
                        width: 235,
                      }}
                    >
                      {isEditName ? (
                        <TextField
                          fullWidth
                          size="small"
                          variant="standard"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          sx={{
                            width: 200,
                          }}
                          inputRef={nameInputRef}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    submitNewName();
                                  }}
                                >
                                  <Check
                                    color="success"
                                    sx={{ fontSize: 18 }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",

                            "&:hover .MuiIconButton-root": {
                              opacity: 1,
                            },
                          }}
                        >
                          <Tooltip
                            title={
                              conversation.name ||
                              conversation.chatSerialSubjectNumber
                            }
                            placement="bottom-start"
                          >
                            <Typography
                              fontSize={16}
                              fontWeight={700}
                              sx={{
                                maxWidth: 240,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                              color="text.secondary"
                            >
                              {conversation.name ||
                                conversation.chatSerialSubjectNumber}
                            </Typography>
                          </Tooltip>
                          <IconButton
                            size="small"
                            sx={{
                              opacity: 0,
                              transition: "opacity 0.2s ease 0s",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsEditName(true);
                            }}
                          >
                            <EditOutlined sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth size="small" sx={{ width: 90 }}>
                      <InputLabel id="demo-simple-select-label">
                        {localization.singleConversation.priority.title}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={conversation.priority}
                        label={localization.singleConversation.priority.title}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleSingleConversationCase(
                            conversation.conversationId,
                            {
                              priority: e.target.value,
                            },
                          );
                        }}
                        sx={{
                          color: "text.secondary",
                          "& .MuiSelect-select": {
                            pl: "8px !important",
                            pr: "20px !important",
                            fontSize: 14,
                          },
                          "& .MuiSelect-icon": {
                            right: 2,
                          },
                          "& fieldset": {
                            borderColor: "rgba(0, 0, 0, 0.15)",
                          },
                        }}
                      >
                        <MenuItem value={1}>
                          {localization.singleConversation.priority.low}
                        </MenuItem>
                        <MenuItem value={2}>
                          {localization.singleConversation.priority.regular}
                        </MenuItem>
                        <MenuItem value={3}>
                          {localization.singleConversation.priority.high}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Typography
                      fontSize={14}
                      sx={{ direction: "rtl", textAlign: "right" }}
                      color="text.secondary"
                    >
                      {conversation.chatSerialSubjectNumber} /{" "}
                      <Box
                        component={"span"}
                        fontSize={12}
                        color="text.secondary"
                      >
                        {conversation.chatSubjectNumber}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography fontSize={14} color="text.secondary">
                      <Box component={"strong"}>
                        {localization.singleConversation.status}:
                      </Box>{" "}
                      {consversationStatusLabels[conversation.status]}
                    </Typography>
                  </Grid>
                  {assignee && (
                    <Grid item>
                      <Tooltip title={assignee.FullName} arrow>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Avatar
                            sx={{
                              width: "18px !important",
                              height: "18px !important",
                              fontSize: "11px !important",
                              WebkitTransition:
                                "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                              transition:
                                "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            }}
                          >
                            {assignee.FullName.split(" ").length > 0 &&
                              `${assignee.FullName.split(" ")[0].slice(0, 1)}${
                                assignee.FullName.split(" ")[1]
                                  ? assignee.FullName.split(" ")[1].slice(0, 1)
                                  : ""
                              }`}
                          </Avatar>
                          <Typography
                            variant="body2"
                            sx={{
                              WebkitTransition:
                                "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                              transition:
                                "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              direction: !isHebrewString(assignee.FullName)
                                ? "rtl"
                                : "ltr",
                            }}
                          >
                            {assignee.FullName}
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1.5} alignItems={"flex-end"}>
                  <Grid item xs={10.5}>
                    <Grid container spacing={0.5}>
                      <Grid item xs={12}>
                        <Typography
                          fontSize={14}
                          whiteSpace={"nowrap"}
                          maxWidth={"100%"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          color="text.secondary"
                        >
                          <Box component={"strong"}>
                            {localization.singleConversation.firstMessage}:
                          </Box>{" "}
                          {/* {conversation.firstMessage?.Message} */}
                          {(() => {
                            if (
                              conversation.firstMessage?.FileType ===
                                "template" &&
                              IsJsonString(conversation.firstMessage.Message)
                            ) {
                              const parsedLastMessage = JSON.parse(
                                conversation.firstMessage.Message,
                              );
                              return parsedLastMessage["BODY"]?.text || "";
                            } else
                              return conversation.firstMessage?.Message || "";
                          })()}
                        </Typography>
                      </Grid>
                      {conversation.lastMessage && (
                        <Grid item xs={12}>
                          <Typography
                            fontSize={14}
                            whiteSpace={"nowrap"}
                            maxWidth={"100%"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            color="text.secondary"
                          >
                            <Box component={"strong"}>
                              {localization.singleConversation.lastMessage}:
                            </Box>{" "}
                            {/* {conversation.lastMessage.Message} */}
                            {(() => {
                              if (
                                conversation.lastMessage?.FileType ===
                                  "template" &&
                                IsJsonString(conversation.lastMessage.Message)
                              ) {
                                const parsedLastMessage = JSON.parse(
                                  conversation.lastMessage.Message,
                                );
                                return parsedLastMessage["BODY"]?.text || "";
                              } else
                                return conversation.lastMessage?.Message || "";
                            })()}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Typography
                          fontSize={12}
                          color="text.disabled"
                          sx={{ direction: "rtl" }}
                        >
                          {conversation.timeOpened} - {conversation.timeClosed}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1.5}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        sendRequestToWebhook(conversation);
                      }}
                    >
                      <SyncOutlined />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ListItemButton>
        </Tooltip>
      </ListItem>
    );
  },
  (prevProps, nextProps) => {
    return (
      deepEqual(prevProps.conversation, nextProps.conversation) &&
      deepEqual(prevProps.assignee, nextProps.assignee) &&
      deepEqual(prevProps.selectedConversation, nextProps.selectedConversation)
    );
  },
);

export default ConversationCase;
