export const validateTextInput = (input, charsCount, wordsCount = null) => {
  const textInput = input;
  if (textInput?.value?.length < charsCount) {
    const errorText = textInput.getAttribute("data-error-text")
      ? textInput.getAttribute("data-error-text")
      : "input text is invalid";
    setError(textInput, errorText);
    return false;
  } else {
    if (
      wordsCount &&
      (input.value.split(" ").length !== wordsCount ||
        (input.value.split(" ").length === wordsCount &&
          input.value.split(" ")[wordsCount - 1] === ""))
    ) {
      const errorText = textInput.getAttribute("data-error-text")
        ? textInput.getAttribute("data-error-text")
        : "there must be 2 words";
      setError(textInput, errorText);
      return false;
    } else {
      clearError(textInput);
      return true;
    }
  }
};
export const validateFullPhoneNumber = (input, regex = null) => {
  const phoneNumberInput = input;
  const phoneNumber = phoneNumberInput.value;

  // if (/^05\d{8}$/.test(phoneNumber)) {
  const regexToCheck = regex ? regex : /^05\d{8}$/;
  if (regexToCheck.test(phoneNumber)) {
    clearError(phoneNumberInput);
    return true;
  } else {
    const errorText = phoneNumberInput.getAttribute("data-error-text")
      ? phoneNumberInput.getAttribute("data-error-text")
      : "tel is invalid";
    setError(phoneNumberInput, errorText);
    return false;
  }
};
export const setError = (el, text) => {
  const error = el.parentElement.querySelector(".error");
  if (error) {
    error.innerText = text;
  }
  if (
    el.closest("label") &&
    !el.closest("label").classList.contains("input-error")
  ) {
    el.closest("label").classList.add("input-error");
  } else if (
    el.closest(".label") &&
    !el.closest(".label").classList.contains("input-error")
  ) {
    el.closest(".label").classList.add("input-error");
  }
};
export const clearError = (el) => {
  const error = el.parentElement.querySelector(".error");
  if (error) {
    error.innerText = "";
  }
  if (el.closest("label")) {
    if (el.closest("label").classList.contains("input-error")) {
      el.closest("label").classList.remove("input-error");
    }
  } else if (el.closest(".label")) {
    if (el.closest(".label").classList.contains("input-error")) {
      el.closest(".label").classList.remove("input-error");
    }
  }
};
