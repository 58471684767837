/* eslint-disable no-undef */

import { IsJsonString } from "../libs/helpers";

export const saveToLocal = (name, data) => {
  localStorage.setItem(name, JSON.stringify(data));
};
export const saveToChromeStorage = (name, data) => {
  chrome.storage.local.set({ [name]: data }, function () {
    // console.log(`${name}: ${data} successfuly saved`);
  });
};

export const getFromLocal = (name) => {
  let value = localStorage.getItem(name);
  value = IsJsonString(value) ? JSON.parse(value) : value;
  return value && value !== "undefined" && value !== "null" ? value : null;
};
export const getFromChromeStorage = (name) => {
  return new Promise((resolve, reject) => {
    chrome.storage.local.get([name], function (result) {
      if (chrome.runtime.lastError) {
        reject(chrome.runtime.lastError);
        console.log(`error trying to get ${name}`);
      } else {
        // console.log(`${name}: ${JSON.stringify(result)} is your result`);
        resolve(result[name]);
      }
    });
  });
};
