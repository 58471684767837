import { SvgIcon } from "@mui/material";

export const languages = [
  {
    language: "Afrikaans",
    code: "af",
  },
  {
    language: "Albanian",
    code: "sq",
  },
  {
    language: "English",
    code: "en",
  },
  {
    language: "Arabic",
    code: "ar",
  },
  {
    language: "Armenian",
    code: "hy",
  },
  {
    language: "Azerbaijani",
    code: "az",
  },
  {
    language: "Basque",
    code: "eu",
  },
  {
    language: "Belarusian",
    code: "be",
  },
  {
    language: "Bengali",
    code: "bn",
  },
  {
    language: "Bulgarian",
    code: "bg",
  },
  {
    language: "Bosnian",
    code: "ba",
  },
  {
    language: "Catalan",
    code: "ca",
  },
  {
    language: "Chinese (Simplified)",
    code: "zh-cn",
  },
  {
    language: "Chinese (Traditional)",
    code: "zh-tw",
  },
  {
    language: "Croatian",
    code: "hr",
  },
  {
    language: "Czech",
    code: "cs",
  },
  {
    language: "Danish",
    code: "da",
  },
  {
    language: "Dutch",
    code: "nl",
  },
  {
    language: "English (United Kingdom)",
    code: "en_GB",
  },
  {
    language: "English (United States)",
    code: "en_US",
  },
  {
    language: "Estonian",
    code: "et",
  },
  {
    language: "Finnish",
    code: "fi",
  },
  {
    language: "French",
    code: "fr",
  },
  {
    language: "Georgian",
    code: "ka",
  },
  {
    language: "German",
    code: "de",
  },
  {
    language: "Greek",
    code: "el",
  },
  {
    language: "Gujarati",
    code: "gu",
  },
  {
    language: "Hebrew",
    code: "he",
  },
  {
    language: "Hindi",
    code: "hi",
  },
  {
    language: "Hungarian",
    code: "hu",
  },
  {
    language: "Icelandic",
    code: "is",
  },
  {
    language: "Indonesian",
    code: "id",
  },
  {
    language: "Irish",
    code: "ga",
  },
  {
    language: "Italian",
    code: "it",
  },
  {
    language: "Japanese",
    code: "ja",
  },
  {
    language: "Kazakh",
    code: "kk",
  },
  {
    language: "Korean",
    code: "ko",
  },
  {
    language: "Latvian",
    code: "lv",
  },
  {
    language: "Lithuanian",
    code: "lt",
  },
  {
    language: "Macedonian",
    code: "mk",
  },
  {
    language: "Malay",
    code: "ms",
  },
  {
    language: "Malayalam",
    code: "ml",
  },
  {
    language: "Maltese",
    code: "mt",
  },
  {
    language: "Marathi",
    code: "mr",
  },
  {
    language: "Mongolian",
    code: "mn",
  },
  {
    language: "Burmese",
    code: "my",
  },
  {
    language: "Nepali",
    code: "ne",
  },
  {
    language: "Norwegian",
    code: "no",
  },
  {
    language: "Persian",
    code: "fa",
  },
  {
    language: "Polish",
    code: "pl",
  },
  {
    language: "Portuguese (Brazil)",
    code: "pt-br",
  },
  {
    language: "Portuguese (Portugal)",
    code: "pt-pt",
  },
  {
    language: "Romanian",
    code: "ro",
  },
  {
    language: "Russian",
    code: "ru",
  },
  {
    language: "Serbian",
    code: "sr",
  },
  {
    language: "Sinhala",
    code: "si",
  },
  {
    language: "Slovak",
    code: "sk",
  },
  {
    language: "Slovenian",
    code: "sl",
  },
  {
    language: "Spanish",
    code: "es",
  },
  {
    language: "Swahili",
    code: "sw",
  },
  {
    language: "Swedish",
    code: "sv",
  },
  {
    language: "Tagalog",
    code: "tl",
  },
  {
    language: "Tamil",
    code: "ta",
  },
  {
    language: "Telugu",
    code: "te",
  },
  {
    language: "Thai",
    code: "th",
  },
  {
    language: "Turkish",
    code: "tr",
  },
  {
    language: "Ukrainian",
    code: "uk",
  },
  {
    language: "Uzbek",
    code: "uz",
  },
  {
    language: "Vietnamese",
    code: "vi",
  },
  {
    language: "Welsh",
    code: "cy",
  },
  {
    language: "Yoruba",
    code: "yo",
  },
];
export const languagesBotBuilder = [
  {
    name: "Afrikaans",
    type: "af",
  },
  {
    name: "Albanian",
    type: "sq",
  },
  {
    name: "English",
    type: "en",
  },
  {
    name: "Arabic",
    type: "ar",
  },
  {
    name: "Armenian",
    type: "hy",
  },
  {
    name: "Azerbaijani",
    type: "az",
  },
  {
    name: "Basque",
    type: "eu",
  },
  {
    name: "Belarusian",
    type: "be",
  },
  {
    name: "Bengali",
    type: "bn",
  },
  {
    name: "Bulgarian",
    type: "bg",
  },
  {
    name: "Bosnian",
    type: "ba",
  },
  {
    name: "Catalan",
    type: "ca",
  },
  {
    name: "Chinese (Simplified)",
    type: "zh-cn",
  },
  {
    name: "Chinese (Traditional)",
    type: "zh-tw",
  },
  {
    name: "Croatian",
    type: "hr",
  },
  {
    name: "Czech",
    type: "cs",
  },
  {
    name: "Danish",
    type: "da",
  },
  {
    name: "Dutch",
    type: "nl",
  },
  {
    name: "English (United Kingdom)",
    type: "en_GB",
  },
  {
    name: "English (United States)",
    type: "en_US",
  },
  {
    name: "Estonian",
    type: "et",
  },
  {
    name: "Finnish",
    type: "fi",
  },
  {
    name: "French",
    type: "fr",
  },
  {
    name: "Georgian",
    type: "ka",
  },
  {
    name: "German",
    type: "de",
  },
  {
    name: "Greek",
    type: "el",
  },
  {
    name: "Gujarati",
    type: "gu",
  },
  {
    name: "Hebrew",
    type: "he",
  },
  {
    name: "Hindi",
    type: "hi",
  },
  {
    name: "Hungarian",
    type: "hu",
  },
  {
    name: "Icelandic",
    type: "is",
  },
  {
    name: "Indonesian",
    type: "id",
  },
  {
    name: "Irish",
    type: "ga",
  },
  {
    name: "Italian",
    type: "it",
  },
  {
    name: "Japanese",
    type: "ja",
  },
  {
    name: "Kazakh",
    type: "kk",
  },
  {
    name: "Korean",
    type: "ko",
  },
  {
    name: "Latvian",
    type: "lv",
  },
  {
    name: "Lithuanian",
    type: "lt",
  },
  {
    name: "Macedonian",
    type: "mk",
  },
  {
    name: "Malay",
    type: "ms",
  },
  {
    name: "Malayalam",
    type: "ml",
  },
  {
    name: "Maltese",
    type: "mt",
  },
  {
    name: "Marathi",
    type: "mr",
  },
  {
    name: "Mongolian",
    type: "mn",
  },
  {
    name: "Burmese",
    type: "my",
  },
  {
    name: "Nepali",
    type: "ne",
  },
  {
    name: "Norwegian",
    type: "no",
  },
  {
    name: "Persian",
    type: "fa",
  },
  {
    name: "Polish",
    type: "pl",
  },
  {
    name: "Portuguese (Brazil)",
    type: "pt-br",
  },
  {
    name: "Portuguese (Portugal)",
    type: "pt-pt",
  },
  {
    name: "Romanian",
    type: "ro",
  },
  {
    name: "Russian",
    type: "ru",
  },
  {
    name: "Serbian",
    type: "sr",
  },
  {
    name: "Sinhala",
    type: "si",
  },
  {
    name: "Slovak",
    type: "sk",
  },
  {
    name: "Slovenian",
    type: "sl",
  },
  {
    name: "Spanish",
    type: "es",
  },
  {
    name: "Swahili",
    type: "sw",
  },
  {
    name: "Swedish",
    type: "sv",
  },
  {
    name: "Tagalog",
    type: "tl",
  },
  {
    name: "Tamil",
    type: "ta",
  },
  {
    name: "Telugu",
    type: "te",
  },
  {
    name: "Thai",
    type: "th",
  },
  {
    name: "Turkish",
    type: "tr",
  },
  {
    name: "Ukrainian",
    type: "uk",
  },
  {
    name: "Uzbek",
    type: "uz",
  },
  {
    name: "Vietnamese",
    type: "vi",
  },
  {
    name: "Welsh",
    type: "cy",
  },
  {
    name: "Yoruba",
    type: "yo",
  },
];

export const languageIcons = {
  he: (
    <SvgIcon sx={{ fontSize: 18 }}>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.41932 1.46631C7.77269 1.46631 6.25767 2.0158 5.03369 2.93258H13.8046C12.581 2.0158 11.0659 1.46631 9.41932 1.46631Z"
          fill="white"
        />
        <path
          d="M16.7508 8.79751C16.7508 7.14465 16.1972 5.62485 15.2746 4.39868H3.56419C2.64153 5.62485 2.08801 7.14465 2.08801 8.79751C2.08801 10.4504 2.64153 11.9702 3.56419 13.1963H15.2746C16.1972 11.9702 16.7508 10.4504 16.7508 8.79751Z"
          fill="white"
        />
        <path
          d="M5.03369 14.6626C6.25766 15.5794 7.77233 16.1289 9.41932 16.1289C11.0663 16.1289 12.581 15.5794 13.805 14.6626H5.03369Z"
          fill="white"
        />
        <path
          d="M15.2746 13.1963H3.56421C3.98246 13.7524 4.47697 14.2454 5.03378 14.6626H13.8047C14.3619 14.2454 14.856 13.7524 15.2746 13.1963Z"
          fill="#3F51B5"
        />
        <path
          d="M13.805 2.93237H5.03378C4.47697 3.34953 3.98246 3.84256 3.56421 4.39865H15.2746C14.856 3.84256 14.3619 3.34953 13.805 2.93237Z"
          fill="#3F51B5"
        />
        <path
          d="M6.85632 10.2638H8.56747L9.10302 11.1817L9.41937 11.7242L9.73572 11.1817L10.2713 10.2638H11.9824L11.6602 9.71249L11.1261 8.79754L11.6602 7.88258L11.9824 7.33126H10.2713L9.73572 6.41337L9.41937 5.87085L9.10302 6.41337L8.56747 7.33126H6.85632L7.17854 7.88258L7.71263 8.79754L7.17854 9.71249L6.85632 10.2638ZM9.41937 10.9969L8.99122 10.2638H9.84606L9.41937 10.9969ZM8.77788 9.89724L8.13638 8.79754L8.77788 7.69783H10.0609L10.7024 8.79754L10.0609 9.89724H8.77788ZM11.3439 9.89724H10.4846L10.9142 9.16117L11.3439 9.89724ZM11.3439 7.69783L10.9142 8.4339L10.4846 7.69783H11.3439ZM9.41937 6.59812L9.84753 7.33126H8.99269L9.41937 6.59812ZM7.49489 7.69783H8.35412L7.92451 8.4339L7.49489 7.69783ZM7.92451 9.16117L8.35412 9.89724H7.49489L7.92451 9.16117Z"
          fill="#3F51B5"
        />
      </svg>
    </SvgIcon>
  ),
  en_US: (
    <SvgIcon sx={{ fontSize: 18 }}>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.6217 2.03317V2.0031C10.9272 1.78494 10.1884 1.6665 9.42171 1.6665C5.37151 1.6665 2.08838 4.94964 2.08838 8.99984C2.08838 9.24734 2.10158 9.4919 2.12541 9.73317H11.6217V2.03317Z"
          fill="#3F51B5"
        />
        <path
          d="M11.6217 4.2333H14.9877C14.6368 3.8241 14.2404 3.45707 13.8081 3.1333H11.6217V4.2333Z"
          fill="#ECEFF1"
        />
        <path
          d="M16.718 9.7334H11.6217H2.12537C2.16277 10.1103 2.23647 10.4755 2.32887 10.8334H16.5148C16.6069 10.4755 16.6806 10.1103 16.718 9.7334Z"
          fill="#ECEFF1"
        />
        <path
          d="M14.6544 14.1333H4.18896C4.6022 14.5546 5.06823 14.9213 5.5724 15.2333H13.2709C13.7751 14.9213 14.2411 14.5546 14.6544 14.1333Z"
          fill="#ECEFF1"
        />
        <path
          d="M16.1408 11.9333H2.70251C2.87081 12.3183 3.07101 12.6861 3.30018 13.0333H15.5432C15.7723 12.6861 15.9722 12.3183 16.1408 11.9333Z"
          fill="#ECEFF1"
        />
        <path
          d="M11.6217 6.43325H16.2842C16.1409 6.05045 15.9685 5.68269 15.7661 5.33325H11.6217V6.43325Z"
          fill="#ECEFF1"
        />
        <path
          d="M11.6217 8.6332H16.7367C16.718 8.2592 16.6806 7.89034 16.608 7.5332H11.6217V8.6332Z"
          fill="#ECEFF1"
        />
        <path
          d="M11.6217 3.1332H13.8085C13.1767 2.6602 12.4698 2.284 11.7035 2.0332H11.6217V3.1332Z"
          fill="#F44336"
        />
        <path
          d="M11.6217 5.3334H15.7661C15.5399 4.94253 15.2799 4.5744 14.9881 4.2334H11.6217V5.3334Z"
          fill="#F44336"
        />
        <path
          d="M11.6217 7.53335H16.6076C16.5306 7.15422 16.417 6.78902 16.2839 6.43335H11.6217V7.53335Z"
          fill="#F44336"
        />
        <path
          d="M11.6217 9.7333H16.718C16.7418 9.49203 16.755 9.24747 16.755 8.99997C16.755 8.87603 16.7426 8.7554 16.7367 8.6333H11.6217V9.7333Z"
          fill="#F44336"
        />
        <path
          d="M16.5145 10.8333H2.32886C2.42639 11.212 2.54849 11.5809 2.70286 11.9333H16.1408C16.2948 11.5809 16.4169 11.212 16.5145 10.8333Z"
          fill="#F44336"
        />
        <path
          d="M15.5432 13.0332H3.30017C3.56087 13.4285 3.8586 13.7966 4.18897 14.1332H14.6547C14.9847 13.7966 15.2825 13.4285 15.5432 13.0332Z"
          fill="#F44336"
        />
        <path
          d="M5.57239 15.2334C6.69219 15.9264 8.00779 16.3334 9.42165 16.3334C10.8355 16.3334 12.1511 15.9264 13.2709 15.2334H5.57239Z"
          fill="#F44336"
        />
        <path
          d="M4.38008 4.96655L4.55058 5.32882L4.93008 5.38602L4.65508 5.66835L4.72108 6.06655L4.38008 5.87882L4.03908 6.06655L4.10508 5.66835L3.83008 5.38602L4.20958 5.32882L4.38008 4.96655Z"
          fill="white"
        />
        <path
          d="M5.84675 4.96655L6.01725 5.32882L6.39675 5.38602L6.12175 5.66835L6.18775 6.06655L5.84675 5.87882L5.50575 6.06655L5.57175 5.66835L5.29675 5.38602L5.67625 5.32882L5.84675 4.96655Z"
          fill="white"
        />
        <path
          d="M2.91341 5.87886L3.25441 6.0666L3.18841 5.6684L3.46341 5.38606L3.08391 5.32886L3.08244 5.3252C2.96144 5.53346 2.85034 5.74796 2.74988 5.9687L2.91341 5.87886Z"
          fill="white"
        />
        <path
          d="M7.31343 4.96655L7.48393 5.32882L7.86343 5.38602L7.58843 5.66835L7.65443 6.06655L7.31343 5.87882L6.97243 6.06655L7.03843 5.66835L6.76343 5.38602L7.14293 5.32882L7.31343 4.96655Z"
          fill="white"
        />
        <path
          d="M8.77998 4.96655L8.95048 5.32882L9.32998 5.38602L9.05498 5.66835L9.12098 6.06655L8.77998 5.87882L8.43898 6.06655L8.50498 5.66835L8.22998 5.38602L8.60948 5.32882L8.77998 4.96655Z"
          fill="white"
        />
        <path
          d="M10.155 4.96655L10.3255 5.32882L10.705 5.38602L10.43 5.66835L10.496 6.06655L10.155 5.87882L9.81398 6.06655L9.87998 5.66835L9.60498 5.38602L9.98448 5.32882L10.155 4.96655Z"
          fill="white"
        />
        <path
          d="M4.38008 7.8999L4.55058 8.26217L4.93008 8.31937L4.65508 8.6017L4.72108 8.9999L4.38008 8.81217L4.03908 8.9999L4.10508 8.6017L3.83008 8.31937L4.20958 8.26217L4.38008 7.8999Z"
          fill="white"
        />
        <path
          d="M5.84675 7.8999L6.01725 8.26217L6.39675 8.31937L6.12175 8.6017L6.18775 8.9999L5.84675 8.81217L5.50575 8.9999L5.57175 8.6017L5.29675 8.31937L5.67625 8.26217L5.84675 7.8999Z"
          fill="white"
        />
        <path
          d="M7.31343 7.8999L7.48393 8.26217L7.86343 8.31937L7.58843 8.6017L7.65443 8.9999L7.31343 8.81217L6.97243 8.9999L7.03843 8.6017L6.76343 8.31937L7.14293 8.26217L7.31343 7.8999Z"
          fill="white"
        />
        <path
          d="M2.9134 7.8999L3.0839 8.26217L3.4634 8.31937L3.1884 8.6017L3.2544 8.9999L2.9134 8.81217L2.5724 8.9999L2.6384 8.6017L2.3634 8.31937L2.7429 8.26217L2.9134 7.8999Z"
          fill="white"
        />
        <path
          d="M8.77998 7.8999L8.95048 8.26217L9.32998 8.31937L9.05498 8.6017L9.12098 8.9999L8.77998 8.81217L8.43898 8.9999L8.50498 8.6017L8.22998 8.31937L8.60948 8.26217L8.77998 7.8999Z"
          fill="white"
        />
        <path
          d="M10.155 7.8999L10.3255 8.26217L10.705 8.31937L10.43 8.6017L10.496 8.9999L10.155 8.81217L9.81398 8.9999L9.87998 8.6017L9.60498 8.31937L9.98448 8.26217L10.155 7.8999Z"
          fill="white"
        />
        <path
          d="M4.74666 6.43335L4.91716 6.79562L5.29666 6.85282L5.02166 7.13515L5.08766 7.53335L4.74666 7.34562L4.40566 7.53335L4.47166 7.13515L4.19666 6.85282L4.57616 6.79562L4.74666 6.43335Z"
          fill="white"
        />
        <path
          d="M6.21333 6.43335L6.38383 6.79562L6.76333 6.85282L6.48833 7.13515L6.55433 7.53335L6.21333 7.34562L5.87233 7.53335L5.93833 7.13515L5.66333 6.85282L6.04283 6.79562L6.21333 6.43335Z"
          fill="white"
        />
        <path
          d="M3.27998 6.43335L3.45048 6.79562L3.82998 6.85282L3.55498 7.13515L3.62098 7.53335L3.27998 7.34562L2.93898 7.53335L3.00498 7.13515L2.72998 6.85282L3.10948 6.79562L3.27998 6.43335Z"
          fill="white"
        />
        <path
          d="M7.68 6.43335L7.8505 6.79562L8.23 6.85282L7.955 7.13515L8.02101 7.53335L7.68 7.34562L7.339 7.53335L7.405 7.13515L7.13 6.85282L7.5095 6.79562L7.68 6.43335Z"
          fill="white"
        />
        <path
          d="M9.14668 6.43335L9.31718 6.79562L9.69668 6.85282L9.42168 7.13515L9.48768 7.53335L9.14668 7.34562L8.80568 7.53335L8.87168 7.13515L8.59668 6.85282L8.97618 6.79562L9.14668 6.43335Z"
          fill="white"
        />
        <path
          d="M10.5217 6.43335L10.6922 6.79562L11.0717 6.85282L10.7967 7.13515L10.8627 7.53335L10.5217 7.34562L10.1807 7.53335L10.2467 7.13515L9.97168 6.85282L10.3512 6.79562L10.5217 6.43335Z"
          fill="white"
        />
        <path
          d="M4.74666 3.5L4.91716 3.86227L5.29666 3.91947L5.02166 4.2018L5.08766 4.6L4.74666 4.41227L4.40566 4.6L4.47166 4.2018L4.19666 3.91947L4.57616 3.86227L4.74666 3.5Z"
          fill="white"
        />
        <path
          d="M6.21333 3.5L6.38383 3.86227L6.76333 3.91947L6.48833 4.2018L6.55433 4.6L6.21333 4.41227L5.87233 4.6L5.93833 4.2018L5.66333 3.91947L6.04283 3.86227L6.21333 3.5Z"
          fill="white"
        />
        <path
          d="M7.68 3.5L7.8505 3.86227L8.23 3.91947L7.955 4.2018L8.02101 4.6L7.68 4.41227L7.339 4.6L7.405 4.2018L7.13 3.91947L7.5095 3.86227L7.68 3.5Z"
          fill="white"
        />
        <path
          d="M9.14668 3.5L9.31718 3.86227L9.69668 3.91947L9.42168 4.2018L9.48768 4.6L9.14668 4.41227L8.80568 4.6L8.87168 4.2018L8.59668 3.91947L8.97618 3.86227L9.14668 3.5Z"
          fill="white"
        />
        <path
          d="M10.5217 3.5L10.6922 3.86227L11.0717 3.91947L10.7967 4.2018L10.8627 4.6L10.5217 4.41227L10.1807 4.6L10.2467 4.2018L9.97168 3.91947L10.3512 3.86227L10.5217 3.5Z"
          fill="white"
        />
        <path
          d="M6.18774 3.13333L6.12174 2.73513L6.39674 2.4528L6.1947 2.42236C5.97874 2.52833 5.76937 2.64456 5.56587 2.7707L5.50574 3.13333L5.84674 2.9456L6.18774 3.13333Z"
          fill="white"
        />
        <path
          d="M7.31343 2.0332L7.48393 2.39547L7.86343 2.45267L7.58843 2.735L7.65443 3.1332L7.31343 2.94547L6.97243 3.1332L7.03843 2.735L6.76343 2.45267L7.14293 2.39547L7.31343 2.0332Z"
          fill="white"
        />
        <path
          d="M8.77998 2.0332L8.95048 2.39547L9.32998 2.45267L9.05498 2.735L9.12098 3.1332L8.77998 2.94547L8.43898 3.1332L8.50498 2.735L8.22998 2.45267L8.60948 2.39547L8.77998 2.0332Z"
          fill="white"
        />
        <path
          d="M10.155 2.0332L10.3255 2.39547L10.705 2.45304L10.43 2.73537L10.496 3.1332L10.155 2.94547L9.81398 3.1332L9.87998 2.735L9.60498 2.45267L9.98448 2.39547L10.155 2.0332Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  ),
};
