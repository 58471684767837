import React from "react";

function IconSvg({ iconString, size, color, colorFillType }) {
  return (
    <div
      className={`funner-icon-svg ${
        colorFillType === "stroke"
          ? "funner-icon-svg--stroke"
          : "funner-icon-svg--fill"
      }`}
      style={{
        position: "relative",
        width: size.width,
        height: size.height,
        flex: `0 0 ${size.width}px`,
        color: color,
      }}
      dangerouslySetInnerHTML={{
        __html: iconString,
      }}
    ></div>
  );
}

export default IconSvg;
