import React, { createContext, useContext, useState, useCallback } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Box, Slide, SvgIcon } from "@mui/material";

const NotificationContext = createContext();

export function useNotification() {
  return useContext(NotificationContext);
}

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export const NotificationProvider = ({ children, defaultDuration = 6000 }) => {
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    type: "info",
    duration: defaultDuration,
  });
  const [messageNotification, setMessageNotification] = useState({
    open: false,
    message: "",
    type: "info",
    duration: defaultDuration,
  });

  const showNotification = useCallback(
    (message, type = "info", duration = defaultDuration) => {
      setNotification({ open: true, message, type, duration });
    },
    [defaultDuration],
  );

  const showMessageNotification = useCallback(
    (message, type = "info", duration = defaultDuration) => {
      setMessageNotification({ open: true, message, type, duration });
    },
    [defaultDuration],
  );

  const handleClose = useCallback((event, reason) => {
    if (reason === "clickaway") return;
    setNotification((prev) => ({ ...prev, open: false }));
  }, []);

  const handleCloseMessageNotif = useCallback((event, reason) => {
    if (reason === "clickaway") return;
    setMessageNotification((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <NotificationContext.Provider
      value={{ showNotification, showMessageNotification }}
    >
      {children}
      <Snackbar
        open={notification.open}
        // autoHideDuration={notification.duration}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
      >
        <Alert
          onClose={handleClose}
          severity={notification.type}
          variant="filled"
          // sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={messageNotification.open}
        // autoHideDuration={messageNotification.duration}
        onClose={handleCloseMessageNotif}
        TransitionComponent={SlideTransition}
      >
        <Alert
          onClose={handleCloseMessageNotif}
          severity={messageNotification.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {messageNotification.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};

export const NotificationIcon = ({ type, data, customColors }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: 28,
        height: 28,
        mr: 1,
        border: `2px solid ${
          customColors?.color ? customColors.color : "#fff"
        }`,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      component={type === "info" ? "a" : "div"}
      href={type === "info" && data ? data.link : null}
      target={type === "info" ? "_blank" : null}
    >
      <SvgIcon sx={{ fontSize: 28, color: "inherit" }}>
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="174.000000pt"
          height="162.000000pt"
          viewBox="0 0 174.000000 162.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <metadata>
            Created by potrace 1.10, written by Peter Selinger 2001-2011
          </metadata>
          <g
            transform="translate(0.000000,162.000000) scale(0.100000,-0.100000)"
            fill={customColors?.color ? customColors.color : "#ffffff"}
            stroke="none"
          >
            <path
              d="M540 1173 c-14 -9 -35 -26 -47 -37 l-23 -19 0 -429 c0 -388 2 -428
16 -428 9 0 88 70 175 156 172 171 175 173 297 174 95 0 178 68 170 137 l-3
28 -165 0 c-154 0 -168 -2 -210 -24 -25 -13 -60 -37 -78 -54 -27 -27 -34 -29
-42 -17 -6 8 -9 88 -7 180 l2 165 330 5 c277 4 336 8 366 21 49 23 79 67 79
118 l0 41 -417 -1 c-370 0 -421 -2 -443 -16z"
            />
          </g>
        </svg>
      </SvgIcon>
      <SvgIcon
        sx={{
          fontSize: type === "success" ? 14 : 12,
          position: "absolute",
          borderRadius: "50%",
          bottom: 0,
          left: 0,
          transform: "translate(-30%, 30%)",
          zIndex: 5,
          bgcolor: customColors?.bgcolor
            ? customColors.bgcolor
            : `${type}.main`,
          color: customColors?.color ? customColors.color : "#fff",
        }}
      >
        {type === "success" ? (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0599 5.05337L6.66659 9.44671L4.27325 7.06004L3.33325 8.00004L6.66659 11.3334L11.9999 6.00004L11.0599 5.05337ZM7.99992 1.33337C4.31992 1.33337 1.33325 4.32004 1.33325 8.00004C1.33325 11.68 4.31992 14.6667 7.99992 14.6667C11.6799 14.6667 14.6666 11.68 14.6666 8.00004C14.6666 4.32004 11.6799 1.33337 7.99992 1.33337ZM7.99992 13.3334C5.05325 13.3334 2.66659 10.9467 2.66659 8.00004C2.66659 5.05337 5.05325 2.66671 7.99992 2.66671C10.9466 2.66671 13.3333 5.05337 13.3333 8.00004C13.3333 10.9467 10.9466 13.3334 7.99992 13.3334Z"
              fill={customColors?.color ? customColors.color : "white"}
            />
          </svg>
        ) : (
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.33325 8.99992H7.66659V10.3333H6.33325V8.99992ZM6.33325 3.66659H7.66659V7.66659H6.33325V3.66659ZM6.99325 0.333252C3.31325 0.333252 0.333252 3.31992 0.333252 6.99992C0.333252 10.6799 3.31325 13.6666 6.99325 13.6666C10.6799 13.6666 13.6666 10.6799 13.6666 6.99992C13.6666 3.31992 10.6799 0.333252 6.99325 0.333252ZM6.99992 12.3333C4.05325 12.3333 1.66659 9.94659 1.66659 6.99992C1.66659 4.05325 4.05325 1.66659 6.99992 1.66659C9.94659 1.66659 12.3333 4.05325 12.3333 6.99992C12.3333 9.94659 9.94659 12.3333 6.99992 12.3333Z"
              fill={customColors?.color ? customColors.color : "white"}
            />
          </svg>
        )}
      </SvgIcon>
    </Box>
  );
};
